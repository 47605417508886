import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";

import classes from "./Dashboard.module.scss";

import DashSidebar from "./DashSidebar/DashSidebar";
import Topbar from "./Topbar/Topbar";
import Mainboard from "./Mainboard/Mainboard";
import { ProfileDetailsAPICall } from "../../Utils/APICalls";

const Dashboard = () => {
  // useEffect(() => {
  //   ProfileDetailsAPICall().then((response: any) => {
  //     const responseData = response.data;
  //     sessionStorage.setItem(
  //       "user-details",
  //       JSON.stringify(responseData.data.user_list)
  //     );
  //     sessionStorage.setItem(
  //       "userId",
  //       responseData.data.user_list.UserId
  //     );
  //     // sessionStorage.setItem("isAdmin" ,responseData.data.user_list.IsAdmin )
  //   });
  // }, []);

  return (
    <>
      <div className={classes.wrapper}>
        <div>
          <DashSidebar />
        </div>
        <div style={{ width: "-webkit-fill-available", overflowX: "scroll" }} className={classes.mainWrapper}>
          <Mainboard>
            <Outlet />
          </Mainboard>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
