import React, { useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton, Stack, Tooltip } from "@mui/material";
import DataTableComponent from "../../components/DataTableComponent/DataTableComponent";
import CMRUploadDataTable from "../../components/CMRUploadDataTable/CMRUploadDataTable";
import MultipleFileUpload from "../../components/MultipleFileUpload/MultipleFileUpload";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CMRViewPanNumbersModal from "../../components/Modals/CMRViewPanNumbersModal/CMRViewPanNumbersModal";
import { Helmet } from "react-helmet";
import { GetCMRStatusAPICall, uploadCMRAPICall } from "../../Utils/APICalls";
import SucessSnackBar from "../../components/SnackBar/SuccessSnackBar";
import ErrorSnackBar from "../../components/SnackBar/ErrorSnackBar";

const titletextStyle = {
  fontSize: "14px",
  fontWeight: "bold",
  color: "#8C8B8F",
};
const contenttextStyle = { fontSize: "14px" };

const dataNew = [
  {
    dateTime: "23-11-2023 03:20 PM",
    user: "chethan",
    status: "success",
    pannumbers: [
      "BSJPC6270L",
      "BSJPC6270L",
      "BSJPC6270L",
      "BSJPC6270L",
      "BSJPC6270L",
      "BSJPC6270L",
      "BSJPC6270L",
      "BSJPC6270L",
      "BSJPC6270L",
      "BSJPC6270L",
      "BSJPC6270L",
      "BSJPC6270L",
      "BSJPC6270L",
      "BSJPC6270L",
      "BSJPC6270L",
    ],
  },
  {
    dateTime: "23-11-2023 03:20 PM",
    user: "chethan",
    status: "success",
    pannumbers: [
      "BSJPC6270L",
      "BSJPC6270L",
      "BSJPC6270L",
      "BSJPC6270L",
      "BSJPC6270L",
      "BSJPC6270L",
      "BSJPC6270L",
    ],
  },
  {
    dateTime: "23-11-2023 03:20 PM",
    user: "chethan",
    status: "failed",
    pannumbers: [
      "BSJPC6270L",
      "BSJPC6270L",
      "BSJPC6270L",
      "BSJPC6270L",
      "BSJPC6270L",
      "BSJPC6270L",
      "BSJPC6270L",
    ],
  },
  {
    dateTime: "23-11-2023 03:20 PM",
    user: "chethan",
    status: "in process",
    pannumbers: [
      "BSJPC6270L",
      "BSJPC6270L",
      "BSJPC6270L",
      "BSJPC6270L",
      "BSJPC6270L",
      "BSJPC6270L",
      "BSJPC6270L",
    ],
  },
];

export default function UploadCMR() {
  const [loading, setloading] = useState(false);
  const [data, setdata] = useState();
  const [panNumbers, setPanNumbers] = useState([]);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [openViewPanModal, setOpenViewPanModal] = useState(false);
  const [uploadingLoading, setUploadingLoading] = useState(false);
  const [successMessage, setsuccessMessage] = useState("");
  const [errorMessage, seterrorMessage] = useState("");
  const [paginationCount, setpaginationCount] = useState(1);
  const [pagenumber, setPageNumber] = useState(1);
  const [totalRows, setTotalRows] = useState(1);

  useEffect(() => {
    GetCMRData();
  }, [pagenumber]);

  const GetCMRData = () => {
    let obj: any = {
      page_no: pagenumber,
      limit: 20,
    };
    setloading(true);
    GetCMRStatusAPICall(obj)
      .then((response: any) => {
        setloading(false);
        if (response.data.data.fetch_data) {
          setdata(
            response.data.data.fetch_data ? response.data.data.fetch_data : []
          );
          setTotalRows(Math.ceil(response.data.data.count / 20));
        }
      })
      .catch((error: any) => {
        // console.log("error", error);
        setloading(false);
      });
  };

  const handleUploadMultipleFileUploadModal = () => {
    setOpenUploadModal(true);
  };
  const handleRefreshTable = () => {
    GetCMRData();
  };
  const handlePageChange = (value: any, page: number) => {
    // getClients(page, perPage, "", "", "", "", "");
    setpaginationCount(page);
    setPageNumber(page);
  };

  const handleCloseUploadModal = () => {
    setOpenUploadModal(false);
  };

  const handleSubmitFileData = (fileData: any) => {
    setUploadingLoading(true);
    const formData = new FormData();

    const names = fileData.map((a: any) => a.path.slice(0, 10));

    fileData.forEach((file: any, index: any) => {
      formData.append(`files`, file);
    });

    formData.append(`pan_no`, JSON.stringify(names));

    uploadCMRAPICall(formData)
      .then((response: any) => {
       // console.log("responseData", response.data.data.message);
        setOpenUploadModal(false);
        GetCMRData();
        setsuccessMessage(response.data.data.message);
        setTimeout(() => {
          setsuccessMessage("");
        }, 5000);
        setUploadingLoading(false);
      })
      .catch((error: any) => {
       // console.log("error", error);
        if (error.response.data.error.message) {
          seterrorMessage(error.response.data.error.message);
          setTimeout(() => {
            seterrorMessage("");
          }, 5000);
        } else {
          seterrorMessage("Error Occured");
          setTimeout(() => {
            seterrorMessage("");
          }, 5000);
        }

        setUploadingLoading(false);
      });
  };

  const handleOpenViewPanModal = (pannumbers: any) => {
    setPanNumbers(pannumbers);
    setOpenViewPanModal(true);
  };
  const handleCloseViewPanModal = () => {
    setOpenViewPanModal(false);
  };

  const convertTimestampToFormattedString = (timestamp: any) => {
    const date = new Date(timestamp);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are zero-based, so we add 1
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;

    // Format the individual components into the desired string
    const formattedString = `${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}-${year} ${hours}:${minutes
      .toString()
      .padStart(2, "0")} ${ampm}`;

    return formattedString;
  };
  const columns = [
    {
      name: <b style={titletextStyle}>Sl No</b>,
      selector: "emailTitle",
      sortable: false,
      cell: (row: any, index: any) => (
        <b style={contenttextStyle}>{index + 1 + paginationCount * 20 - 20}</b>
      ),
    },
    {
      name: <b style={titletextStyle}>Date and Time</b>,
      selector: "dateTime",
      sortable: false,
      cell: (row: any) => (
        <b style={contenttextStyle}>
         {row.CreatedTS}
        </b>
      ),
    },
    {
      name: <b style={titletextStyle}>User</b>,
      selector: "user_name",
      sortable: false,
      cell: (row: any) => <b style={contenttextStyle}>{row.user_name}</b>,
    },

    {
      name: <b style={titletextStyle}>Status</b>,
      selector: "status",
      sortable: false,
      cell: (row: any) => <b style={contenttextStyle}>{row.status}</b>,
      width: "100px",
    },
    {
      name: <b style={titletextStyle}>Actions</b>,
      selector: "spamReportRate",
      sortable: false,
      allowOverflow: true,
      button: true,
      width: "120px",
      cell: (row: any) => (
        <b style={contenttextStyle}>
          <Tooltip title="View Pan Numbers">
            <IconButton
              onClick={() => {
                handleOpenViewPanModal(row.pan_list);
              }}
            >
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
        </b>
      ),
    },
  ];
  return (
    <div>
      <Helmet>
        <title>Upload CMR </title>
        <meta name="Uploadcmr" content="uploadcmr" />
      </Helmet>

      <CMRUploadDataTable
        columns={columns}
        handleAdd={handleUploadMultipleFileUploadModal}
        loading={loading}
        data={data}
        handleRefreshTable={handleRefreshTable}
        paginationCount={paginationCount}
        handlePageChange={handlePageChange}
        totalRows={totalRows}
      />

      <MultipleFileUpload
        open={openUploadModal}
        handleClose={handleCloseUploadModal}
        accepectedFileTypes={[".pdf"]}
        handleSubmitData={handleSubmitFileData}
        uploadingLoading={uploadingLoading}
      />

      <CMRViewPanNumbersModal
        openModal={openViewPanModal}
        handleClose={handleCloseViewPanModal}
        data={panNumbers}
      />
      <SucessSnackBar message={successMessage} />
      <ErrorSnackBar message={errorMessage} />
    </div>
  );
}
