import React, { useEffect, useState } from "react";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import classes from "./DashSidebar.module.scss";
import Avatar from "@mui/material/Avatar";
import logo from "../../../assets/Images/logo192.png";
import AutoAwesomeMosaicIcon from "@mui/icons-material/AutoAwesomeMosaic";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { useNavigate, useLocation } from "react-router-dom";
import GavelIcon from "@mui/icons-material/Gavel";
import { useDispatch, useSelector } from "react-redux";
import Tooltip from "@mui/material/Tooltip";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import CoPresentIcon from "@mui/icons-material/CoPresent";
import BackupIcon from "@mui/icons-material/Backup";
import LinkIcon from "@mui/icons-material/Link";
import { setCollapsed } from "../../../Store/Project/ProjectSlice";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import DynamicFeedIcon from "@mui/icons-material/DynamicFeed";
import { confirmAlert } from "react-confirm-alert";
import LogoutIcon from "@mui/icons-material/Logout";
import ModeOfTravelIcon from "@mui/icons-material/ModeOfTravel";
import GroupsIcon from "@mui/icons-material/Groups";
import ReceiptIcon from "@mui/icons-material/Receipt";

const DashSidebar = () => {
  const collapsed = useSelector((state: any) => state.project.collapsed);
  const [isAdmin, setIsAdmin] = useState<boolean>(true);

  // const isAdmin = sessionStorage.getItem("isAdmin")
  useEffect(() => {
    const storedValue = sessionStorage.getItem("isAdmin");
    if (storedValue !== null) {
      setIsAdmin(JSON.parse(storedValue));
    } else {
      // setIsAdmin(false)
    }
  }, []);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const pathName = useLocation().pathname;
  let profileDetails: any = sessionStorage.getItem("user-details");
  profileDetails = profileDetails
    ? JSON.parse(profileDetails)
    : {
        FullName: "",
        Mobile: "",
        Email: "",
      };

  const handleLogout = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="confitmalertUI">
            <LogoutIcon />
            <p>Are you sure you want to logout</p>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "20px",
              }}
            >
              <button
                onClick={() => {
                  onClose();
                }}
                className="confitmbutton"
              >
                No
              </button>
              <button
                onClick={() => {
                  onClose();
                  sessionStorage.clear();
                  navigate("/");
                }}
                className="confitmbutton"
              >
                Yes
              </button>
            </div>
          </div>
        );
      },
    });
  };
  // console.log("isAdmin", isAdmin);
  return (
    <div className={classes.sidebar}>
      <Sidebar
        collapsed={!collapsed}
        backgroundColor="#FFFFFF"
        width="220px"
        transitionDuration={600}
      >
        <div className={classes.wrapper}>
          <div className={classes.wrapperTop}>
            <div className={classes.brand}>
              <Avatar alt="" src={logo}>
                F
              </Avatar>
              {collapsed && <div className={classes.brandTitle}>Composite</div>}
            </div>

            <div className={classes.sidebarMenu}>
              <div className={classes.collapse}>
                <div
                  onClick={() => dispatch(setCollapsed(!collapsed))}
                  style={{ cursor: "pointer" }}
                >
                  <MenuOpenIcon
                    sx={{
                      transform: !collapsed ? "rotate(180deg)" : "",
                      transition: "transform 0.4s ease",
                      fontSize: "20px",
                      color: "#8b93ff",
                    }}
                  />
                </div>
              </div>
              <Menu
                menuItemStyles={{
                  button: {
                    color: "#4F507F",
                    borderRadius: "6px",
                    margin: "2px 5px",
                    height: "40px",

                    "&:hover": {
                      backgroundColor: "#8B93FF",
                      color: "white",
                      fill: "white",
                    },
                  },
                }}
                closeOnClick={true}
              >
                <MenuItem
                  active={pathName.includes("/dashboard/analytics")}
                  icon={
                    <Tooltip title="DashBoard" placement="right">
                      <AutoAwesomeMosaicIcon
                        style={{
                          fill: pathName.includes("/dashboard/analytics")
                            ? "white"
                            : "",
                          fontSize: "18px",
                          minWidth: 0,
                          width: "20px",
                        }}
                      />
                    </Tooltip>
                  }
                  onClick={() => navigate("analytics")}
                  style={{
                    background: pathName.includes("/dashboard/analytics")
                      ? "#8B93FF"
                      : "",
                    color: pathName.includes("/dashboard/analytics")
                      ? "white"
                      : "",
                  }}
                >
                  {" "}
                  Dashboard
                </MenuItem>

                <SubMenu
                  icon={
                    <Tooltip title="Clients" placement="right">
                      <GroupAddIcon
                        style={{ fontSize: "18px", minWidth: 0, width: "20px" }}
                      />
                    </Tooltip>
                  }
                  label="Clients"
                  active={pathName.includes("/clients")}
                  style={{
                    background: pathName.includes("/clients") ? "#8B93FF" : "",
                    color: pathName.includes("/clients") ? "white" : "",
                  }}
                >
                  <div className={classes.submenu}>
                    <div className={classes.subitem}>
                      <MenuItem
                        onClick={() => navigate("clients/newkyc")}
                        active={pathName.includes("/clients/newkyc")}
                        style={{
                          background: pathName.includes("/clients/newkyc")
                            ? "#8B93FF"
                            : "",
                          color: pathName.includes("/clients/newkyc")
                            ? "white"
                            : "",
                        }}
                      >
                        EKYC
                      </MenuItem>
                    </div>
                    <div className={classes.subitem}>
                      <MenuItem
                        onClick={() => navigate("clients/rekyc")}
                        active={pathName.includes("/clients/rekyc")}
                        style={{
                          background: pathName.includes("/clients/rekyc")
                            ? "#8B93FF"
                            : "",
                          color: pathName.includes("/clients/rekyc")
                            ? "white"
                            : "",
                        }}
                      >
                        Re KYC
                      </MenuItem>
                    </div>

                    {/* <div className={classes.subitem}>
                      <MenuItem
                        onClick={() => navigate("clients/nri")}
                        active={pathName.includes("/clients/nri")}
                        style={{
                          background: pathName.includes("/clients/nri")
                            ? "#8B93FF"
                            : "",
                          color: pathName.includes("/clients/nri")
                            ? "white"
                            : "",
                        }}
                      >
                        NRI
                      </MenuItem>
                    </div> */}
                  </div>
                </SubMenu>

                {/* --------------------------------------------Updates--------------------------------------- */}

                <Menu
                  menuItemStyles={{
                    button: {
                      color: "#4F507F",
                      borderRadius: "6px",
                      margin: "2px 5px",
                      height: "40px",

                      "&:hover": {
                        backgroundColor: "#8B93FF",
                        color: "white",
                        fill: "white",
                      },
                    },
                  }}
                  closeOnClick={true}
                >
                  <SubMenu
                    icon={
                      <Tooltip title="Updates" placement="right">
                        <DynamicFeedIcon
                          style={{
                            fontSize: "18px",
                            minWidth: 0,
                            width: "20px",
                          }}
                        />
                      </Tooltip>
                    }
                    label="Updates"
                    // onClick={() => navigate("clients/newkyc")}
                    active={pathName.includes("/updates")}
                    style={{
                      background: pathName.includes("/updates")
                        ? "#8B93FF"
                        : "",
                      color: pathName.includes("/updates") ? "white" : "",
                    }}
                  >
                    <div className={classes.submenu}>
                      <div className={classes.subitem}>
                        <MenuItem
                          onClick={() => navigate("updates/financialupdate")}
                          active={pathName.includes("/updates/financialupdate")}
                          style={{
                            background: pathName.includes(
                              "/updates/financialupdate"
                            )
                              ? "#8B93FF"
                              : "",
                            color: pathName.includes("/updates/financialupdate")
                              ? "white"
                              : "",
                          }}
                        >
                          Financial Update
                        </MenuItem>
                      </div>
                      <div className={classes.subitem}>
                        <MenuItem
                          onClick={() => navigate("updates/accountclosure")}
                          active={pathName.includes("/updates/accountclosure")}
                          style={{
                            background: pathName.includes(
                              "/updates/accountclosure"
                            )
                              ? "#8B93FF"
                              : "",
                            color: pathName.includes("/updates/accountclosure")
                              ? "white"
                              : "",
                          }}
                        >
                          Account Closure
                        </MenuItem>
                      </div>
                      <div className={classes.subitem}>
                        <MenuItem
                          onClick={() => navigate("updates/ddpi")}
                          active={pathName.includes("/updates/ddpi")}
                          style={{
                            background: pathName.includes("/updates/ddpi")
                              ? "#8B93FF"
                              : "",
                            color: pathName.includes("/updates/ddpi")
                              ? "white"
                              : "",
                          }}
                        >
                          DDPI Update
                        </MenuItem>
                      </div>
                      <div className={classes.subitem}>
                        <MenuItem
                          onClick={() => navigate("updates/bankupdate")}
                          active={pathName.includes("/updates/bankupdate")}
                          style={{
                            background: pathName.includes("/updates/bankupdate")
                              ? "#8B93FF"
                              : "",
                            color: pathName.includes("/updates/bankupdate")
                              ? "white"
                              : "",
                          }}
                        >
                          Bank Update
                        </MenuItem>
                      </div>
                      <div className={classes.subitem}>
                        <MenuItem
                          onClick={() => navigate("updates/emailmobileupdate")}
                          active={pathName.includes(
                            "/updates/emailmobileupdate"
                          )}
                          style={{
                            background: pathName.includes(
                              "/updates/emailmobileupdate"
                            )
                              ? "#8B93FF"
                              : "",
                            color: pathName.includes(
                              "/updates/emailmobileupdate"
                            )
                              ? "white"
                              : "",
                          }}
                        >
                          Email&Mobile Update
                        </MenuItem>
                      </div>
                      <div className={classes.subitem}>
                        <MenuItem
                          onClick={() => navigate("updates/nomineeupdate")}
                          active={pathName.includes("/updates/nomineeupdate")}
                          style={{
                            background: pathName.includes(
                              "/updates/nomineeupdate"
                            )
                              ? "#8B93FF"
                              : "",
                            color: pathName.includes("/updates/nomineeupdate")
                              ? "white"
                              : "",
                          }}
                        >
                          Nominee Update
                        </MenuItem>
                      </div>
                      <div className={classes.subitem}>
                        <MenuItem
                          onClick={() => navigate("updates/segmentupdate")}
                          active={pathName.includes("/updates/segmentupdate")}
                          style={{
                            background: pathName.includes(
                              "/updates/segmentupdate"
                            )
                              ? "#8B93FF"
                              : "",
                            color: pathName.includes("/updates/segmentupdate")
                              ? "white"
                              : "",
                          }}
                        >
                          Segment Update
                        </MenuItem>
                      </div>
                    </div>
                  </SubMenu>
                </Menu>

                {/* --------------------------------------------Updates--------------------------------------- */}

                <MenuItem
                  onClick={() => navigate("followups")}
                  icon={
                    <Tooltip title="Follow Ups" placement="right">
                      <LiveHelpIcon
                        style={{ fontSize: "18px", minWidth: 0, width: "20px" }}
                      />
                    </Tooltip>
                  }
                  active={pathName.includes("/followups")}
                  style={{
                    background: pathName.includes("/followups")
                      ? "#8B93FF"
                      : "",
                    color: pathName.includes("/followups") ? "white" : "",
                  }}
                >
                  {" "}
                  Follow ups
                </MenuItem>
                {isAdmin && (
                  <MenuItem
                    onClick={() => navigate("users")}
                    icon={
                      <Tooltip title="Users" placement="right">
                        <SupervisedUserCircleIcon
                          style={{
                            fontSize: "18px",
                            minWidth: 0,
                            width: "20px",
                          }}
                        />
                      </Tooltip>
                    }
                    active={pathName.includes("/users")}
                    style={{
                      background: pathName.includes("/users") ? "#8B93FF" : "",
                      color: pathName.includes("/users") ? "white" : "",
                    }}
                  >
                    Users
                  </MenuItem>
                )}
                {isAdmin && (
                  <MenuItem
                    onClick={() => navigate("tariff")}
                    icon={
                      <Tooltip title="Tariff" placement="right">
                        <GavelIcon
                          style={{
                            fontSize: "18px",
                            minWidth: 0,
                            width: "20px",
                          }}
                        />
                      </Tooltip>
                    }
                    active={pathName.includes("/tariff")}
                    style={{
                      background: pathName.includes("/tariff") ? "#8B93FF" : "",
                      color: pathName.includes("/tariff") ? "white" : "",
                    }}
                  >
                    Tariff
                  </MenuItem>
                )}

                {/* <MenuItem
                  onClick={() => navigate("pan-aadhaar-check")}
                  icon={
                    <Tooltip title="Pan Aadhaar Check" placement="right">
                      <LinkIcon
                        sx={{ transform: "rotate(45deg)" }}
                        style={{ fontSize: "18px", minWidth: 0, width: "20px" }}
                      />
                    </Tooltip>
                  }
                  active={pathName.includes("/pan-aadhaar-check")}
                  style={{
                    background: pathName.includes("/pan-aadhaar-check")
                      ? "#8B93FF"
                      : "",
                    color: pathName.includes("/pan-aadhaar-check")
                      ? "white"
                      : "",
                  }}
                >
                  Pan Aadhaar Check
                </MenuItem> */}

                {isAdmin && (
                  <SubMenu
                    icon={
                      <Tooltip title="UpLoads" placement="right">
                        <BackupIcon
                          style={{
                            fontSize: "18px",
                            minWidth: 0,
                            width: "20px",
                          }}
                        />
                      </Tooltip>
                    }
                    label="UpLoads"
                    // onClick={() => navigate("clients/newkyc")}
                    active={pathName.includes("/upload")}
                    style={{
                      background: pathName.includes("/upload") ? "#8B93FF" : "",
                      color: pathName.includes("/upload") ? "white" : "",
                    }}
                  >
                    <div className={classes.submenu}>
                      {isAdmin && (
                        <Tooltip title="Upload Demat" placement="right">
                          <MenuItem
                            onClick={() => navigate("uploaddemat")}
                            // icon={
                            //   <UploadFileIcon
                            //     style={{
                            //       fill: pathName.includes("uploaddemat")
                            //         ? "white"
                            //         : "",
                            //     }}
                            //   />
                            // }
                            active={pathName.includes("/uploaddemat")}
                            style={{
                              background: pathName.includes("/uploaddemat")
                                ? "#8B93FF"
                                : "",
                              color: pathName.includes("/uploaddemat")
                                ? "white"
                                : "",
                            }}
                          >
                            {" "}
                            Upload Demat
                          </MenuItem>
                        </Tooltip>
                      )}
                      {/* {isAdmin && (
                        <Tooltip
                          title="Upload Client Details"
                          placement="right"
                        >
                          <MenuItem
                            onClick={() => navigate("uploadclientdetails")}
                            active={pathName.includes("/uploadclientdetails")}
                            style={{
                              background: pathName.includes(
                                "/uploadclientdetails"
                              )
                                ? "#8B93FF"
                                : "",
                              color: pathName.includes("/uploadclientdetails")
                                ? "white"
                                : "",
                            }}
                          >
                            {" "}
                            Upload Client Details
                          </MenuItem>
                        </Tooltip>
                      )} */}
                      {/* {isAdmin && (
                        <Tooltip
                          title="Upload Referral Clients"
                          placement="right"
                        >
                          <MenuItem
                            onClick={() => navigate("uploadreferralclients")}
                            active={pathName.includes("/uploadreferralclients")}
                            style={{
                              background: pathName.includes(
                                "/uploadreferralclients"
                              )
                                ? "#8B93FF"
                                : "",
                              color: pathName.includes("/uploadreferralclients")
                                ? "white"
                                : "",
                            }}
                          >
                            {" "}
                            Upload Referral Client
                          </MenuItem>
                        </Tooltip>
                      )}
                      {isAdmin && (
                        <Tooltip title="Upload CMR" placement="right">
                          <MenuItem
                            onClick={() => navigate("uploadcmr")}
                            active={pathName.includes("/uploadcmr")}
                            style={{
                              background: pathName.includes("/uploadcmr")
                                ? "#8B93FF"
                                : "",
                              color: pathName.includes("/uploadcmr")
                                ? "white"
                                : "",
                            }}
                          >
                            {" "}
                            Upload CMR
                          </MenuItem>
                        </Tooltip>
                      )} */}
                    </div>
                  </SubMenu>
                )}
                {isAdmin && <p className={classes.othersText}>Others</p>}
                {isAdmin && (
                  <MenuItem
                    onClick={() => navigate("locationlogs")}
                    icon={
                      <Tooltip title="Location Logs" placement="right">
                        <ModeOfTravelIcon
                          style={{
                            fontSize: "18px",
                            minWidth: 0,
                            width: "20px",
                          }}
                        />
                      </Tooltip>
                    }
                    active={pathName.includes("/locationlogs")}
                    style={{
                      background: pathName.includes("/locationlogs")
                        ? "#8B93FF"
                        : "",
                      color: pathName.includes("/locationlogs") ? "white" : "",
                    }}
                  >
                    Location Logs
                  </MenuItem>
                )}
                {isAdmin && (
                  <MenuItem
                    onClick={() => navigate("clientadmin")}
                    icon={
                      <Tooltip title="Client Admin" placement="right">
                        <GroupsIcon
                          style={{
                            fontSize: "18px",
                            minWidth: 0,
                            width: "20px",
                          }}
                        />
                      </Tooltip>
                    }
                    active={pathName.includes("/clientadmin")}
                    style={{
                      background: pathName.includes("/clientadmin")
                        ? "#8B93FF"
                        : "",
                      color: pathName.includes("/clientadmin") ? "white" : "",
                    }}
                  >
                    Client Admin
                  </MenuItem>
                )}
                {isAdmin && (
                  <MenuItem
                    onClick={() => navigate("demat")}
                    icon={
                      <Tooltip title="Demat" placement="right">
                        <ReceiptIcon
                          style={{
                            fontSize: "18px",
                            minWidth: 0,
                            width: "20px",
                          }}
                        />
                      </Tooltip>
                    }
                    active={pathName.includes("/demat")}
                    style={{
                      background: pathName.includes("/demat") ? "#8B93FF" : "",
                      color: pathName.includes("/demat") ? "white" : "",
                    }}
                  >
                    Demat
                  </MenuItem>
                )}
                {/* <MenuItem
                  onClick={() => navigate("pdfannotator")}
                  icon={
                    <Tooltip title="Pdf Annoatator" placement="right">
                      <TextSnippetIcon
                        style={{ fontSize: "18px", minWidth: 0, width: "20px" }}
                      />
                    </Tooltip>
                  }
                  active={pathName.includes("/pdfannotator")}
                  style={{
                    background: pathName.includes("/pdfannotator")
                      ? "#8B93FF"
                      : "",
                    color: pathName.includes("/pdfannotator") ? "white" : "",
                  }}
                >
                  PDF Annotator
                </MenuItem> */}
              </Menu>
            </div>
          </div>

          <div className={classes.profile}>
            <div className={classes.profileContainer}>
              <Avatar alt="" src="" sx={{ background: "#8B93FF" }}>
                {profileDetails.FullName[0]}
              </Avatar>{" "}
              {collapsed && (
                <div className={classes.data}>
                  <div className={classes.name}>{profileDetails.FullName}</div>
                  <div className={classes.email}>{profileDetails.Email}</div>
                </div>
              )}
            </div>
            <div className={classes.logoutButton} onClick={handleLogout}>
              Logout
            </div>
          </div>
        </div>
      </Sidebar>
    </div>
  );
};

export default DashSidebar;
