import React, { useState } from "react";
import { Formik, Form, useFormikContext } from "formik";
import * as Yup from "yup";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import Button from "@mui/joy/Button";
const schema = Yup.object().shape({
  selectStage: Yup.string().required("Rejected Stage is Required"),

  reasonForRejection: Yup.string().required("Reason for Rejection is Required"),
});

interface props {
  stagesData: any;
  handleRejectClient: any;
  disabled: any;
  rejectLoading?: any;
}

const mappingStagesData: any = {
  pan: "Pan details",
  kra_or_aadhaar: "KRA verification or Digi-locker",
  nominee: "Nominee ",
  esign: "Esign section",
  segment: "Trading preferences",
  customer_details: "Personal details",
  bank: "Bank details",
  ipv: "Identification verification",
  document_upload: "Upload documents",
  t_and_c: "Terms and conditions",
};

export default function Reject({
  stagesData,
  handleRejectClient,
  disabled,
  rejectLoading,
}: props) {
  const [stageName, setStageName] = useState<any>();

  const InitialValues = {
    selectStage: "",
    reasonForRejection: "",
  };

  

  return (
    <div>
      <Formik
        validationSchema={schema}
        initialValues={InitialValues}
        onSubmit={(values: any) => {
          //   console.log("values", values);
          handleRejectClient(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,

          setFieldValue,
        }) => {
          // console.log("errors", errors)
          return (
            <form noValidate onSubmit={handleSubmit}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div>
                  <FormControl sx={{ width: "300px" }} size="small">
                    <InputLabel id="demo-simple-select-label">Stage</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={stageName}
                      disabled={disabled}
                      name="selectStage"
                      label="Stage"
                      error={touched.selectStage && Boolean(errors.selectStage)}
                      onChange={(event: any) => {
                        setFieldValue("selectStage", event.target.value);
                        setStageName(event.target.value);
                      }}
                    >
                      {stagesData &&
                        stagesData.map((item: any) => (
                          <MenuItem key={item} value={item}>
                            {mappingStagesData[item]}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </div>
                <div>
                  <TextField
                    value={values.reasonForRejection}
                    disabled={disabled}
                    name="reasonForRejection"
                    onChange={handleChange}
                    label="Reason for Rejection"
                    size="small"
                    sx={{ marginLeft: "20px", width: "300px" }}
                    error={
                      touched.reasonForRejection &&
                      Boolean(errors.reasonForRejection)
                    }
                    // helperText={
                    //   touched.reasonForRejection &&
                    //   errors.reasonForRejection?.toString()
                    // }
                  />
                </div>

                <div>
                  <Button
                    type="submit"
                    sx={{
                      background: "#FF5A5A",
                      color: "white",
                      width: "160px",
                      borderRadius: "4px",
                      marginLeft: "20px",
                      "&:hover": {
                        background: "#FF5A5A",
                        color: "white",
                      },
                    }}
                    // disabled={disabled}
                    loading={rejectLoading}
                  >
                    Reject
                  </Button>
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
}
