import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { InputAdornment, Stack, TextField, Typography } from "@mui/material";
import Button from "@mui/joy/Button";
import { GetPanAadhaarAPICall } from "../../Utils/APICalls";

const schema = Yup.object().shape({
  pannumber: Yup.string()

    .required("PanNumber is Required")
    .max(10)
    .matches(/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/, "Enter Valid PanNumber")
    .transform((value: any) => value && value.toUpperCase()),
});

export default function PanAadhaarCheck() {
  const [loading, setloading] = useState(false);
  const [displayResponse, setdisplayresponse] = useState(false);
  const [pannumbervalue, setpannumber] = useState("");
  const [ prefillLoading , setPrefillLoading] = useState(false)
  const [errorMessage ,setErrorMessage] = useState("")
  
  const [responseData, setResponseData] = useState({
    last_updated: "",
    full_name: "",
    last_name: "",
    pan: "",
    category: "",
    first_name: "",
    status: "",
    is_pan_aadhaar_linked: false,
  });

  const handleCheckPanAadhaarSeeding = (values: any) => {
    let obj: any = {
      pan_no: values.pannumber,
    };
    setloading(true)
    GetPanAadhaarAPICall(obj)
      .then((response: any) => {
       // console.log("responsedata", response.data);
        setloading(false)
        setResponseData(response.data.data);
        setdisplayresponse(true);
      })
      .catch((error: any) => {
      //  console.log("error", error);
        setdisplayresponse(false)
        if(error.response.data.error.message){
            setErrorMessage(error.response.data.error.message)
            setTimeout(()=>{
                setErrorMessage("")
            },3000)
        }
        setloading(false)
      });
  };

  const handleClearResponse = () => {
    setPrefillLoading(true)
    setTimeout(()=>{
        setPrefillLoading(false)
    },300)
    setpannumber("");
    setdisplayresponse(false);
    setResponseData({
      last_updated: "",
      full_name: "",
      last_name: "",
      pan: "",
      category: "",
      first_name: "",
      status: "",
      is_pan_aadhaar_linked: false,
    });
  };

  const initialValues = {
    pannumber: pannumbervalue,
  };

  return (
    <div>
        {!prefillLoading &&  <Formik
        validationSchema={schema}
        initialValues={{
          pannumber: "",
        }}
        onSubmit={(values: any) => {
          handleCheckPanAadhaarSeeding(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <>
            <form
              noValidate
              onSubmit={handleSubmit}
              onKeyDown={(event) => {
                event.code === "Enter" ||
                  (event.code === "NumpadEnter" && handleSubmit());
                // event.code.includes("Enter") && handleSubmit()
              }}
            >
              <>
                <div style={{ display: "flex", margin: "20px" }}>
                  <div>
                    <TextField
                      id="outlined-basic"
                      label="Enter PAN Number"
                      name="pannumber"
                      variant="outlined"
                      value={values.pannumber.toUpperCase()}
                      error={touched.pannumber && Boolean(errors.pannumber)}
                      helperText={
                        touched.pannumber && errors.pannumber?.toString()
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{ maxLength: 10 }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Typography color="textSecondary">
                              {values.pannumber.length}/10
                            </Typography>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <div>
                    {values.pannumber && !errors.pannumber ? (
                      <Button
                        type="submit"
                        loading={loading}
                        id="panVerificationContinueButton"
                        onClick={() => {
                          handleSubmit();
                        }}
                        sx={{ marginLeft: "20px" }}
                      >
                        Submit
                      </Button>
                    ) : (
                      <Button
                        type="submit"
                        disabled
                        sx={{ marginLeft: "20px" }}
                      >
                        Submit
                      </Button>
                    )}
                  </div>
                </div>
              </>
            </form>
          </>
        )}
      </Formik> }
      
      {errorMessage.length>0&& <p style={{color:'red'}} >{errorMessage}</p>}
      {displayResponse && (
        <div>
          <Stack width={500} padding={"20px 50px"}>
            <Stack direction={"row"}>
              <Typography sx={{ marginRight: "10px" }}>
                Is Pan Aadhaar Linked
              </Typography>{" "}
              : {responseData.is_pan_aadhaar_linked ? "true" : "false"}
            </Stack>
            <Stack direction={"row"}>
              <Typography sx={{ marginRight: "10px" }}>Last Updated</Typography>{" "}
              : {responseData.last_updated}
            </Stack>
            <Stack direction={"row"}>
              <Typography sx={{ marginRight: "10px" }}>Full Name</Typography> :{" "}
              {responseData.full_name}
            </Stack>
            <Stack direction={"row"}>
              <Typography sx={{ marginRight: "10px" }}>First Name</Typography> :{" "}
              {responseData.first_name}
            </Stack>
            <Stack direction={"row"}>
              <Typography sx={{ marginRight: "10px" }}>
                Response Message
              </Typography>{" "}
              : {responseData.last_name}
            </Stack>
            <Stack direction={"row"}>
              <Typography sx={{ marginRight: "10px" }}>Pan</Typography> :{" "}
              {responseData.pan}
            </Stack>
            <Stack direction={"row"}>
              <Typography sx={{ marginRight: "10px" }}>Category</Typography> :{" "}
              {responseData.category}
            </Stack>
            <Stack direction={"row"}>
              <Typography sx={{ marginRight: "10px" }}>Status</Typography> :{" "}
              {responseData.status}
            </Stack>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"center"}
              marginTop={"20px"}
            >
              <Button variant="outlined" onClick={handleClearResponse}>
                Clear
              </Button>
            </Stack>
          </Stack>
        </div>
      )}
    </div>
  );
}
