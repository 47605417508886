import React from "react";
import { Helmet } from "react-helmet";

import GeneralBoard from "../../containers/GeneralBoard/GeneralBoard";

export default function DashboardPage() {
  return (
    <>
      <Helmet>
        <title>Dashboard </title>
        <meta name="Dashboard" content="Dashboard" />
      </Helmet>
      <GeneralBoard />
    </>
  );
}
