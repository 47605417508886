import classes from "./ForgotPassword.module.scss";
import { useFormik } from "formik";
import { forgotpwSchema, signUpSchema } from "../../Schemas/Schemas";
import Button from "@mui/joy/Button";
import Input from "@mui/joy/Input";
import { Link, useNavigate } from "react-router-dom";

import { useDispatch } from "react-redux";
import { setOtpFlag } from "../../Store/Project/ProjectSlice";
import { GetOTPAPICall } from "../../Utils/APICalls";
import { IGetOTP } from "../../Utils/dtos";
import { useState } from "react";
import ErrorSnackBar from "../../components/SnackBar/ErrorSnackBar";
import signUp from "../../assets/Images/loginimage.svg";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const initialValues = {
    name: "",
  };
  const [loading, setLoading] = useState(false);
  const [errormessage, seterrormessage] = useState("");
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema: forgotpwSchema,
      onSubmit: (values, action) => {
        // console.log("values", values);
        // action.resetForm();
        const requestBody: IGetOTP = {
          user_details: values.name,
        };
        sessionStorage.setItem("userDetails", values.name);
        setLoading(true);
        GetOTPAPICall(requestBody)
          .then((res: any) => {
            // console.log(res.data);
            setLoading(false);

            dispatch(setOtpFlag(true));
            // const Token = res.data.data.accessToken;
            // sessionStorage.setItem("Token", Token);
            navigate("/otpScreen");
          })
          .catch((error: any) => {
            // console.log(error.response.data.error.message);
            setLoading(false);
            if (error.response.data.message) {
              seterrormessage(error.response.data.message);
              setTimeout(() => {
                seterrormessage("");
              }, 5000);
            } else{
              seterrormessage("Invalid user details.");
              setTimeout(() => {
                seterrormessage("");
              }, 5000);
            }
          });
      },
    });
  return (
    <>
      <div className={classes.container}>
        <div className={classes.modal}>
          <div className={classes.modalContainer}>
            <div className={classes.modalLeft}>
              <h1 className={classes.modalTitle}>Forgot Password?</h1>
              <p className={classes.modalSubTitle}>
                No Worries, We'll send you reset instructions.{" "}
              </p>
              <form onSubmit={handleSubmit} className={classes.formWrapper}>
                <div className={classes.inputBlock}>
                  <Input
                    type="name"
                    autoComplete="off"
                    name="name"
                    id="name"
                    placeholder="Enter Email or Phone Number"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    size="lg"
                  />
                  {errors.name && touched.name ? (
                    <p className={classes.formError}>{errors.name}</p>
                  ) : null}
                </div>

                <div className={classes.modalButtons}>
                  <Button
                    type="submit"
                    size="lg"
                    style={{ padding: "14px 20px", background: "#8B93FF" }}
                    loading={loading}
                  >
                    Reset Password
                  </Button>

                  <a href="#" className={classes.link}>
                    <Link
                      to={"/"}
                      style={{ textDecoration: "none", color: "#383838" }}
                    >
                      Back to Login ?{" "}
                    </Link>
                  </a>
                </div>
                <ErrorSnackBar message={errormessage} />
              </form>
              <p className={classes.signUp}></p>
            </div>
            <div className={classes.modalRight}>
              <img src={signUp} alt="" className={classes.signupImage} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
