import * as React from "react";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { Formik } from "formik";
import {
  Stack,
  Fade,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  ThemeProvider,
  Container,
  FormHelperText,
  Typography,
  Box,
  Button,
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
} from "@mui/material";

import { AddFollowUpScheme } from "../../../Schemas/Schemas";
import { AddInitialFollowUp } from "../../../Utils/InitialValues";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import dayjs, { Dayjs } from "dayjs";
import { useEffect } from "react";
import styles from "../TariffModal/TariffModal.module.scss";
// import { styled } from '@mui/system';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(1),
  },

  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {/* {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null} */}
    </DialogTitle>
  );
}

interface Props {
  openDateModal: any;
  handleUpdateDate: any;
  handleCloseDatepopUp: any;
  followDate?: any;
}

const statusData = [
  "Positive",
  "Call back",
  "Come back",
  "No response",
  "Hard to convert",
  "DND",
  "Negative",
];

export default function FollowUpDateChange({
  openDateModal,
  handleUpdateDate,
  handleCloseDatepopUp,
  followDate,
}: Props) {
  const [showPassword, setShowPassword] = React.useState(false);

  const [role, setRole] = React.useState<any>();
  const [roleData, setRoleData] = React.useState<any>();

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const renderDate = dayjs().subtract(7, "days");

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const blue = {
    100: "#DAECFF",
    200: "#b6daff",
    400: "#3399FF",
    500: "#007FFF",
    600: "#0072E5",
    900: "#003A75",
  };

  const grey = {
    50: "#f6f8fa",
    100: "#eaeef2",
    200: "#d0d7de",
    300: "#afb8c1",
    400: "#8c959f",
    500: "#6e7781",
    600: "#57606a",
    700: "#424a53",
    800: "#32383f",
    900: "#24292f",
  };

  const StyledTextarea = styled(TextareaAutosize)(
    ({ theme }) => `
    width: 100%;
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 12px;
    border-radius: 12px 12px 0 12px;
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
    box-shadow: 0px 2px 2px ${
      theme.palette.mode === "dark" ? grey[900] : grey[50]
    };
  
    &:hover {
      border-color: ${blue[400]};
    }
  
    &:focus {
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${
        theme.palette.mode === "dark" ? blue[500] : blue[200]
      };
    }
  
    // firefox
    &:focus-visible {
      outline: 0;
    }
  `
  );

  const InitialValues = {
    remarks: "",
    status: "",
    update_date: dayjs(followDate),
  };

  const close = (e: any) => {
    if (e.keyCode === 27) {
      handleCloseDatepopUp();
    }
  };

  //   const type = data ? "edit" : "add";
  return (
    <BootstrapDialog
      onClose={close}
      aria-labelledby="customized-dialog-title"
      open={openDateModal}
    >
      <BootstrapDialogTitle
        id="customized-dialog-title"
        onClose={handleCloseDatepopUp}
      >
        FollowUp
      </BootstrapDialogTitle>

      <Formik
        validationSchema={AddFollowUpScheme}
        initialValues={InitialValues}
        onSubmit={(values) => {
          //  console.log("values", values);
          handleUpdateDate(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <>
            {/* {console.log("values", values, "errors", errors)} */}
            <form noValidate onSubmit={handleSubmit}>
              <DialogContent
                style={{
                  border: "1px solid #DDDCE1",
                  margin: "0px 20px 20px 20px",
                  borderRadius: "4px",
                  padding: "0px 20px",
                }}
              >
                <Box component="form" noValidate>
                  <Grid
                    container
                    rowSpacing={2}
                    columnSpacing={{ xs: 1, sm: 2, md: 5 }}
                  >
                    <Grid item xs={12}>
                      <p className={styles.textfieldLabel}>Status</p>
                      <FormControl fullWidth>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={values.status}
                          displayEmpty
                          renderValue={
                            values.status !== ""
                              ? undefined
                              : () => {
                                  return (
                                    <p
                                      style={{
                                        margin: 0,
                                        fontWeight: 300,
                                        color: "#adadb1",
                                        fontSize: "13px",
                                      }}
                                    >
                                      Select Status
                                    </p>
                                  );
                                }
                          }
                          name="status"
                          onChange={(event: any) => {
                            // console.log(event.target.value);
                            setFieldValue("status", event.target.value);
                          }}
                          error={touched.status && Boolean(errors.status)}
                        >
                          {statusData.map((item: any) => (
                            <MenuItem key={item} value={item}>
                              {item}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText style={{ color: "#d32f2f" }}>
                          {touched.status && errors.status}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <p className={styles.textfieldLabel}>Remarks</p>
                      <StyledTextarea
                        id="remarks"
                        minRows={3}
                        aria-label="Remarks"
                        placeholder="Remarks"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="remarks"
                        value={values.remarks}
                      />
                      <FormHelperText
                        style={{ color: "#d32f2f", paddingLeft: "15px" }}
                      >
                        {touched.remarks && errors.remarks?.toString()}
                      </FormHelperText>
                    </Grid>
                    <Grid item xs={12}>
                      <p className={styles.textfieldLabel}>
                        Next Followup Date&Time
                      </p>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                          sx={{ width: "100%" }}
                          label=""
                          name="update_date"
                          viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                            seconds: renderTimeViewClock,
                          }}
                          disablePast
                          value={values.update_date}
                          minDate={renderDate}
                          minTime={dayjs().set("hour", 8)}
                          maxTime={dayjs().set("hour", 20)}
                          format="DD/MM/YYYY hh:mm A"
                          onChange={(date) => {
                            handleChange({
                              target: {
                                name: "update_date",
                                value: date,
                              },
                            });
                          }}
                          {...(DateTimePicker as any)}
                          error={
                            touched.update_date && Boolean(errors.update_date)
                          }
                          helperText={touched.update_date && errors.update_date}
                        />
                        <FormHelperText
                          style={{ color: "#d32f2f", paddingLeft: "15px" }}
                        >
                          {Boolean(errors.update_date)?.toString() === "true"
                            ? "Date and Time should be more than present time"
                            : ""}
                        </FormHelperText>
                      </LocalizationProvider>
                    </Grid>
                  </Grid>
                </Box>
                <Stack
                  justifyContent={"flex-start"}
                  direction={"row"}
                  margin={"15px 0px"}
                  gap={"10px"}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{
                      background: "#8B93FF",
                      boxShadow: "none",
                      textTransform: "none",
                      "&:hover": {
                        background: "#8B93FF",
                        boxShadow: "none",
                      },
                    }}
                  >
                    Save changes
                  </Button>
                  <Button
                    onClick={(event: any) => {
                      handleCloseDatepopUp();
                    }}
                    sx={{
                      color: "#8B93FF",
                      boxShadow: "none",
                      textTransform: "none",
                      "&:hover": {
                        color: "#8B93FF",
                        background: "transparent",
                        boxShadow: "none",
                      },
                    }}
                  >
                    Cancel
                  </Button>
                </Stack>
              </DialogContent>
            </form>
          </>
        )}
      </Formik>
    </BootstrapDialog>
  );
}
