import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { orderBy } from "lodash";
import classes from "./DataTableComponent.module.scss";
import SearchIcon from "@mui/icons-material/Search";
import {
  Button,
  Stack,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Pagination from "@mui/material/Pagination";
import dayjs, { Dayjs } from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateRange } from "@mui/x-date-pickers-pro";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { LicenseInfo } from "@mui/x-date-pickers-pro";
import { width } from "@mui/system";
import StageMapingData from "../../Utils/stages.json";
import StyledLoader from "../Styledloader/StyledLoader";

// LicenseInfo.setLicenseKey(
//   'x0jTPl0USVkVZV0SsMjM1kDNyADM5cjM2ETPZJVSQhVRsIDN0YTM6IVREJ1T0b9586ef25c9853decfa7709eee27a1e',
// );

interface Props {
  columns: any;
  checkboxEnabled?: boolean;
  loading?: boolean;
  data?: any;
  totalRows?: number;
  pagination?: boolean;
  paginationServer?: boolean;
  handlePageChange?: any;
  handlePerRowsChange?: any;
  selectedTab?: string;
  paginationCount?: any;
  recordsCount?: any;
}

const DataTableComponent = ({
  columns,
  checkboxEnabled = false,
  loading = false,
  pagination = false,
  paginationServer = false,
  data = [],
  handlePageChange,
  handlePerRowsChange,
  totalRows,
  paginationCount,
  recordsCount,
}: Props) => {
  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#F3F4FF",
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
        // height:'40px'
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
    headRow: {
      style: {
        borderBottom: "none",
      },
    },
    header: {
      style: {
        fontSize: "16px",
        minHeight: "0px",
        paddingLeft: "3px",
        paddingRight: "3px",
      },
    },
    contextMenu: {
      style: {
        fontSize: "14px",
        fontWeight: 400,
        paddingLeft: "5px",
        paddingRight: "5px",
        transform: "translate3d(0, -100%, 0)",
        transitionDuration: "125ms",
        transitionTimingFunction: "cubic-bezier(0, 0, 0.2, 1)",
        willChange: "transform",
      },
      activeStyle: {
        transform: "translate3d(0, 0, 0)",
      },
    },
  };

  const handleSort = (column: any, sortDirection: any) => {
    // console.log("column", column, "sortDirection", sortDirection);
  };

  // console.log("paginationCount", paginationCount, data.length);
  return (
    <>
      <div className={classes.tableWrapper}>
        <div className={classes.table}>
          <DataTable
            data={data}
            columns={columns}
            title=" "
            // pagination={pagination}
            onSort={handleSort}
            highlightOnHover
            selectableRows={checkboxEnabled}
            customStyles={customStyles}
            fixedHeader
            // fixedHeaderScrollHeight={"600px"}
            actions
            progressPending={loading}
            paginationServer={paginationServer}
            // paginationTotalRows={totalRows}
            // selectableRows
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            progressComponent={<StyledLoader />}
          />
        </div>
        {pagination && (
          <>
            {data.length > 0 && !loading && (
              <Stack
                alignItems={"center"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                // marginTop={data.length >= 9 ? "10px" : "300px"}
                marginTop={"10px"}
                marginBottom={"20px"}
              >
                <p className={classes.datacount}>
                  Showing {paginationCount * 20 - (20 - data.length)}/
                  {recordsCount} Records{" "}
                </p>
                <Pagination
                  onChange={(value: any, page: any) => {
                    handlePageChange(value, page);
                    // setpaginationCount(page);
                  }}
                  page={paginationCount}
                  count={totalRows}
                  variant="outlined"
                  shape="rounded"
                  sx={{
                    button: {
                      "&.Mui-selected": {
                        backgroundColor: "#8B93FF",
                        color: "white",
                      },
                    },
                  }}
                />
              </Stack>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default DataTableComponent;
