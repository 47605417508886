import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { Formik } from "formik";
import {
  IApproveClientApplication,
  IClientData,
  IDeleteClient,
  IRejectFinancialUpdateApplication,
} from "../../../Utils/dtos";
import {
  Stack,
  Fade,
  Box,
  IconButton,
  Modal,
  Divider,
  Tooltip,
} from "@mui/material";
import Button from "@mui/joy/Button";
import { js2xml } from "xml-js";
import {
  ApproveApplicationDDPIAPICall,
  DeleteClientDDPIAPICall,
  GetClientDataDDPIAPICall,
  GetRekycStagesAPICall,
  RejectApplicationDDPIAPICall,
} from "../../../Utils/APICalls";
import Styles from "./DDPIUpdateDetailspopup.module.scss";
import Tabs from "../../Tabs/Tabs";
import ErrorSnackBar from "../../SnackBar/ErrorSnackBar";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteClient from "../DeleteClient/DeleteClient";
import SucessSnackBar from "../../SnackBar/SuccessSnackBar";
import DDPIBase64ToImage from "../../DDPIBase64/DDPIBase64";
import DDPIReject from "../RejectComponent/DDPIReject";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import VerticalTabs from "../../Tabs/TabsVeritical";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "96%",
  height: "95%",
  bgcolor: "background.paper",
  borderRadius: "20px",
  boxShadow: 24,
  p: 2,
};

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

interface Props {
  open: boolean;
  handleClose: any;
  handleSubmitData: any;
  data: string;
  rejectedReason?: any;
  deletedReason?: any;
  selectedTabNew?: string;
  handlePermanentDeleteClient?: any;
  clientDeleted: boolean;
  stageName?: any;
  clientCompleted: boolean;
}

export default function DDPIUpdateDetailspopup({
  open,
  handleClose,
  data,
  handleSubmitData,
  rejectedReason,
  selectedTabNew,
  deletedReason,
  handlePermanentDeleteClient,
  clientDeleted,
  clientCompleted,
  stageName,
}: Props) {
  // const [open, setOpen] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [openDeletePopUp, setDeletePopUp] = useState(false);
  const [clientData, setClientData] = useState<any>({
    Email: "",
    PanNo: "",
    Mobile: "",
    Documents: [],
    Latitude: "",
    CreatedTS: "",
    Longitude: "",
    ApprovedTS: "",
    ClientName: "",
    IsApproved: "",
    clientcode: "",
    CompletedTS: "",
    IsCompleted: "",
    LastLoginTS: "",
    AnnualIncome: "",
  });
  const [clientBankData, setClientBankData] = useState<any>({
    BankName: "",
    IFSCCode: "",
    Micr: "",
    AcNo: "",

    NameAsInBank: "",
  });
  const [KRAData, setKRAData] = useState<any>();
  const [AadhaarData, setAadhaarData] = useState<any>({
    AadhaarNum: "",
    ClientAadharName: "",
    DobOnAadhaar: "",
    AadhaarAddress: "",
    AadhaarGender: "",
  });
  const [tradingPreference, setTradingPrefernce] = useState<any>({
    mutual_funds: "",
    equity: "",
    futures_and_options: "",
    currency: "",
    commodity: "",
  });
  const [selectedTab, setselectedTab] = useState<string>("DDPI");
  const [successMessage, setSuccessMessage] = useState("");

  const [tabLabels, setTabLabels] = useState(["DDPI"]);
  const [docType, setDocType] = useState("e_sign_pdf");
  const [stages, setStages] = useState<any>();
  const [stagesData, setStagesData] = useState<any>([]);
  const [rejectLoading, setRejectLoading] = useState(false);
  const [approveLoading, setApproveLoading] = useState(false);
  const [geojithLoading, setgeojithLoading] = useState(false);
  const [kraUploadLoading, setKraUploadLoading] = useState(false);

  useEffect(() => {
    getDetails();

    GetRekycStagesAPICall()
      .then((response: any) => {
        const responseData = response.data;
        const sortedStages = responseData.sort(
          (a: any, b: any) => a.Sequence - b.Sequence
        );

        setStagesData(sortedStages);
      })
      .catch((error: any) => {
        //  console.log("error", error);
      });
  }, []);

  useEffect(() => {
    sessionStorage.removeItem(`${data}_e_sign_pdf`);
  }, [handleClose]);

  // console.log(data);
  const isAdmin = sessionStorage.getItem("isAdmin");
  const getDetails = () => {
    const obj: IClientData = {
      client_id: data,
    };
    GetClientDataDDPIAPICall(obj)
      .then((response: any) => {
        // console.log("responseclient", response.data.client_data.client_details);
        setClientData(response.data.client_data.client_details);
      })
      .catch((error: any) => {});
  };

  const handleRejectClient = (reject: any) => {
    //  console.log("data", reject);

    let obj: IRejectFinancialUpdateApplication = {
      client_id: data,
      error_msg: reject.reasonForRejection,
    };
    setRejectLoading(true);
    RejectApplicationDDPIAPICall(obj)
      .then((response: any) => {
        const responseData = response.data;
        // console.log("responseData", responseData);
        setRejectLoading(false);
        handleClose("Client Rejected");
      })
      .catch((error: any) => {
        setRejectLoading(false);
        // console.log("error", error);
      });
  };
  const handleApproveClient = () => {
    let obj: IApproveClientApplication = {
      client_id: data,
    };
    setApproveLoading(true);
    ApproveApplicationDDPIAPICall(obj)
      .then((response: any) => {
        const responseData = response.data;
        //  console.log("responseData", responseData);
        setApproveLoading(false);
        handleClose("Client Approved");
      })
      .catch((error: any) => {
        // console.log("error", error);
        setApproveLoading(false);
        if (error.response.data.error.message) {
          seterrorMessage(error.response.data.error.message);
          setTimeout(() => {
            seterrorMessage("");
          }, 5000);
        }
      });
  };

  const handleChangeTab = (tab: any) => {
    //  console.log(tab);
    setselectedTab(tab);
    if (tab === "Aadhaar") {
      setDocType("aadhar_client_photo");
    }

    if (tab === "Photo") {
      setDocType("webcam_client_photo");
    }

    if (tab === "IncomeProof") {
      setDocType("income_proof");
    }
    if (tab === "Esign") {
      setDocType("e_sign_pdf");
    }

    if (tab === "CMR") {
      setDocType("cmr");
    }
  };

  const handleOpenDeletePopUp = () => {
    setDeletePopUp(true);
  };
  const handleCloseDeletepopUp = () => {
    setDeletePopUp(false);
  };

  const handleDeleteClient = (dataDelete: any) => {
    let obj: IDeleteClient = {
      client_id: data,
      deleted_reason: dataDelete.deleted_reason,
    };
    DeleteClientDDPIAPICall(obj)
      .then((response: any) => {
        const responseData = response.data;
        // console.log("responseData", responseData);
        setDeletePopUp(false);
        handleClose("Deleted");
        setSuccessMessage("Client Deleted Successfully");
        setTimeout(() => {
          setSuccessMessage("");
        }, 5000);
      })
      .catch((error: any) => {
        // console.log("error", error);
        setDeletePopUp(false);
        //  handleClose("Deleted");
        if (error.response.data.error.message) {
          seterrorMessage(error.response.data.error.message);
          setTimeout(() => {
            seterrorMessage("");
          }, 5000);
        }
      });
  };

  const formatDate = (dateString: any) => {
    const date = new Date(`${dateString} UTC`);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    const hour = date.getHours() % 12 || 12;
    const minute = date.getMinutes().toString().padStart(2, "0");
    const meridian = date.getHours() >= 12 ? "PM" : "AM";

    return `${day}-${month}-${year} ${hour}:${minute} ${meridian}`;
  };

  const close = (e: any) => {
    if (e.keyCode === 27) {
      handleClose();
    }
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        closeAfterTransition
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
        onClose={close}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className={Styles.topContainerTitle}>
              <p
                style={{
                  margin: "0px 10px",
                  fontSize: "14px",
                  fontWeight: 500,
                }}
              >
                <IconButton
                  onClick={handleClose}
                  size="small"
                  style={{
                    background: "#8B93FF",
                    color: "white",
                    borderRadius: 4,
                  }}
                >
                  <KeyboardBackspaceIcon
                    style={{
                      fontSize: "18px",
                      fontWeight: 600,
                    }}
                  />
                </IconButton>
                <span style={{ marginLeft: "10px" }}>Client Details</span>
              </p>
              <div className={Styles.title}>
                {clientData && clientData.ClientName}
              </div>
            </div>
            <div className={Styles.wrapper}>
              <div className={Styles.leftContainer}>
                <p className={Styles.clientInfo}>Client Info</p>
                {rejectedReason && selectedTabNew === "rejected" && (
                  <>
                    <div style={{ color: "red" }}>
                      <span style={{ fontWeight: 700 }}>Rejected Reason:</span>{" "}
                      <span style={{ fontSize: "14px" }}>
                        {" "}
                        {rejectedReason}
                      </span>
                    </div>
                    <Divider sx={{ borderColor: "grey", margin: "10px 0" }} />
                  </>
                )}
                {deletedReason && selectedTabNew === "deleted" && (
                  <>
                    <div style={{ color: "red" }}>
                      <span style={{ fontWeight: 700 }}>Deleted Reason:</span>{" "}
                      <span style={{ fontSize: "14px" }}> {deletedReason}</span>
                    </div>
                    <Divider sx={{ borderColor: "grey", margin: "10px 0" }} />
                  </>
                )}

                <div className={Styles.contentdiv}>
                  <p className={Styles.title}>
                    Mobile :{" "}
                    <span className={Styles.content}>
                      {clientData && clientData.Mobile}
                    </span>
                  </p>
                  <p className={Styles.title}>
                    Email :{" "}
                    <span className={Styles.content}>
                      {clientData && clientData.Email}
                    </span>
                  </p>
                </div>
                <Divider sx={{ borderColor: "grey", margin: "10px 0" }} />
                <div className={Styles.contentdiv}>
                  <p className={Styles.title}>
                    Client Name :{" "}
                    <span className={Styles.content}>
                      {clientData && clientData.ClientName}
                    </span>
                  </p>
                  <p className={Styles.title}>
                    CLIENT Code :{" "}
                    <span className={Styles.content}>
                      {clientData && clientData.clientcode}
                    </span>
                  </p>
                </div>

                <Divider sx={{ borderColor: "grey", margin: "10px 0" }} />

                <p
                  className={Styles.title}
                  style={{
                    // textDecoration: "underline",
                    background: "#d9e4fd",
                    // width: "max-content",
                    padding: "3px 20px",
                    borderRadius: "5px",
                    textAlign: "center",
                  }}
                >
                  Basic Info
                </p>

                <div className={Styles.contentdiv}>
                  <div style={{ width: "360px" }}>
                    <div>
                      <div>
                        <p className={Styles.title}>
                          Mobile No :{" "}
                          <span className={Styles.content}>
                            {clientData && clientData.Mobile}
                          </span>
                        </p>
                      </div>
                      <div>
                        <p className={Styles.title}>
                          PAN Number :{" "}
                          <span className={Styles.content}>
                            {clientData && clientData.PanNo}
                          </span>
                        </p>
                      </div>
                      <div>
                        <p className={Styles.title}>
                          Created At :{" "}
                          <span className={Styles.content}>
                            {clientData.CreatedTS === ""
                              ? ""
                              : formatDate(clientData.CreatedTS)}
                          </span>
                        </p>
                      </div>
                      <div>
                        <p className={Styles.title}>
                          Approved At :{" "}
                          <span className={Styles.content}>
                            {clientData.ApprovedTS === ""
                              ? ""
                              : formatDate(clientData.ApprovedTS)}
                          </span>
                        </p>
                      </div>

                      {/* <div>
                        <p className={Styles.title}>
                          Location :{" "}
                          <span className={Styles.content}>
                            {clientData && clientData.Latitude},
                            {clientData && clientData.Longitude}
                          </span>
                        </p>
                      </div> */}
                      <div>
                        <p className={Styles.title}>
                          Doc :{" "}
                          <span className={Styles.content}>
                            {clientData && clientData.Documents
                              ? clientData.Documents[0]?.Value
                              : "-"}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div style={{ marginTop: "0px" }}>
                    <p className={Styles.title} style={{ marginLeft: "20px" }}>
                      Client Name :{" "}
                      <span className={Styles.content}>
                        {clientData && clientData.ClientName}
                      </span>
                    </p>
                    <p className={Styles.title} style={{ marginLeft: "20px" }}>
                      Email :{" "}
                      <span className={Styles.content}>
                        {clientData && clientData.Email}
                      </span>
                    </p>
                    <p className={Styles.title} style={{ marginLeft: "20px" }}>
                      LastLogin TS :{" "}
                      <span className={Styles.content}>
                        {clientData.LastLoginTS === ""
                          ? ""
                          : formatDate(clientData.LastLoginTS)}
                      </span>
                    </p>
                    <p className={Styles.title} style={{ marginLeft: "20px" }}>
                      Completed TS :{" "}
                      <span className={Styles.content}>
                        {clientData.CompletedTS === ""
                          ? ""
                          : formatDate(clientData.CompletedTS)}
                      </span>
                    </p>

                    {/* <p className={Styles.title} style={{ marginLeft: "20px" }}>
                      AnnualIncome :{" "}
                      <span className={Styles.content}>
                        {clientData && clientData.AnnualIncome}
                      </span>
                    </p> */}
                  </div>
                </div>

                <Divider sx={{ borderColor: "grey", margin: "10px 0" }} />
              </div>

              <div className={Styles.rightContainer} style={{ width: "75%" }}>
                <div style={{ width: "20%" }}>
                  <VerticalTabs
                    labels={tabLabels}
                    onChangeTab={handleChangeTab}
                    selectedTab={selectedTab}
                  />
                </div>

                <div
                  style={{
                    width: "80%",
                    border: "1px solid #DDDCE1",
                    height: "100%",
                    borderRadius: "4px",
                  }}
                >
                  <DDPIBase64ToImage data={data} docType={docType} />
                </div>
              </div>
            </div>
            <div className={Styles.buttonWrapper}>
              {!clientDeleted && (
                <Tooltip title="Delete Client">
                  <IconButton
                    onClick={handleOpenDeletePopUp}
                    sx={{
                      // position: "absolute",
                      // bottom: 10,
                      border: "1px solid red",
                    }}
                  >
                    <DeleteIcon sx={{ color: "red" }} />
                  </IconButton>
                </Tooltip>
              )}
              {clientDeleted && (
                <Tooltip title="Permanent Delete Client">
                  <IconButton
                    onClick={handlePermanentDeleteClient}
                    sx={{
                      // position: "absolute",
                      // bottom: 30,
                      border: "1px solid red",
                    }}
                  >
                    <DeleteIcon sx={{ color: "red" }} />
                  </IconButton>
                </Tooltip>
              )}

              <DeleteClient
                open={openDeletePopUp}
                handleDeleteClient={handleDeleteClient}
                handleCloseDeletepopUp={handleCloseDeletepopUp}
              />
              <SucessSnackBar message={successMessage} />
              <ErrorSnackBar message={errorMessage} />

              <Stack
                direction={"row"}
                justifyContent={"space-evenly"}
                alignItems={"center"}
                marginLeft={"20px"}
              >
                {selectedTabNew !== "deleted" && !rejectedReason && (
                  <DDPIReject
                    stagesData={stagesData}
                    handleRejectClient={handleRejectClient}
                    rejectLoading={rejectLoading}
                    disabled={
                      clientData && clientData.IsCompleted === "true"
                        ? false
                        : true
                    }
                  />
                )}
                {isAdmin === "true" && (
                  <div>
                    {selectedTabNew !== "deleted" &&
                      selectedTabNew !== "rejected" &&
                      selectedTabNew !== "approved" && (
                        <Button
                          type="submit"
                          onClick={handleApproveClient}
                          loading={approveLoading}
                          disabled={
                            clientData && clientData.IsCompleted === "true"
                              ? false
                              : true
                          }
                          sx={{
                            background: "#3CBC49",
                            color: "white",
                            width: "160px",
                            borderRadius: "4px",
                            marginLeft: "20px",
                            "&:hover": {
                              background: "#3CBC49",
                              color: "white",
                            },
                          }}
                        >
                          Approve
                        </Button>
                      )}
                  </div>
                )}

                <ErrorSnackBar message={errorMessage} />
              </Stack>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
