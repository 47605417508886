import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import classes from "./MultipleFileUpload.module.scss";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import {
  Stack,
  Fade,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Box,
 
  IconButton,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import Button from "@mui/joy/Button";


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

interface Props {
  open: boolean;
  handleClose: any;
  accepectedFileTypes: any;
  handleSubmitData: any;
  uploadingLoading: any;
}

export default function MultipleFileUpload({
  open,
  handleClose,
  accepectedFileTypes,
  handleSubmitData,
  uploadingLoading,
}: Props) {
  const [selectedFile, setSelectedFile] = React.useState<[] | any>([]);
  const [disableUpload, setdisableUpload] = React.useState(false);

  const [checkfilerepeat, setcheckfilerepeat] = useState<[] | any>(
    selectedFile
  );

  const panPdfRegex = /^[A-Z]{5}\d{4}[A-Z]\.pdf$/;

  const onDrop = (acceptedFilestype: any) => {
    const files = acceptedFilestype.map((a: any) => a.path);
    let AllFiles: any = [...selectedFile, ...acceptedFilestype];

    const every = files.every((a: any) => a.includes(".pdf"));

    const checkPanPdf = files.every((b: any) => panPdfRegex.test(b));

    function hasDuplicates(array: any) {
      const uniqueSet = new Set(array);
      return uniqueSet.size !== array.length;
    }

    const duplicates: any = hasDuplicates(AllFiles.map((a: any) => a.path));

    if (checkPanPdf && !duplicates && files.length<=15) {
      if (selectedFile) {
        setSelectedFile((prev: any) => [...prev, ...acceptedFilestype]);
      } else {
        setSelectedFile(acceptedFilestype);
      }

      // console.log(acceptedFilestype);
    } else {
      setdisableUpload(true);
      setSelectedFile([]);
      setTimeout(() => {
        setdisableUpload(false);
      }, 5000);
    }
  };

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: accepectedFileTypes,
    multiple: true,
    // maxFiles: 15,
  });

  const files = acceptedFiles.map(
    (file: any) => selectedFile && <li key={file.path}>{file.path}</li>
  );

  const handleDeleteFile = (index: any) => {
    setSelectedFile((prevFiles: any) =>
      prevFiles.filter((file: any, i: any) => i !== index)
    );
  };

  return (
    <div>
      <BootstrapDialog
        //   onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        onClose={(event: any) => {
          if (event.keyCode === 27) {
            handleClose();
          }
        }}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => {
            handleClose();
            setSelectedFile([]);
            setdisableUpload(false);
          }}
        >
          Upload File
        </BootstrapDialogTitle>

        <DialogContent dividers>
          <Stack>
            <section className="container">
              <div
                {...getRootProps()}
                className={classes.uploadArea}
                style={{ borderColor: "#1C2536" }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <FileUploadIcon fontSize="large" sx={{ color: "#1C2536" }} />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <input {...getInputProps()} />
                  <p style={{ color: "#1C2536" }}>
                    Select File or Drag and Drop
                  </p>
                </div>
              </div>
              <aside>
                {selectedFile && selectedFile.length > 0 ? (
                  <div>
                    {selectedFile.map((a: any, index: any) => (
                      <div className={classes.fileDetails}>
                        <p className={classes.fileName}>
                          {index + 1}. {a.path}
                        </p>
                        <div>
                          <IconButton
                            onClick={() => {
                              handleDeleteFile(index);
                            }}
                          >
                            <DeleteOutlineRoundedIcon sx={{ fill: "red" }} />
                          </IconButton>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  ""
                )}

                <h4>
                  {disableUpload && (
                    <span style={{ color: "red" }}>
                      {" "}
                      Only pdf files max (15) with Unique Names as PAN Numbers are
                      accepected
                    </span>
                  )}
                </h4>
              </aside>
            </section>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            variant="outlined"
            loading={uploadingLoading}
            disabled={selectedFile.length === 0}
            onClick={() => {
              handleSubmitData(selectedFile);
              setSelectedFile([]);
              setdisableUpload(false);
            }}
          >
            Upload
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
