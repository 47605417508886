import React from "react";

import LoaderStyles from "./StyledLoader.module.scss";

export default function StyledLoader() {
  return (
    <div
      style={{
        height: "50vh",
        width: "70vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <span className={LoaderStyles.loader}></span>
    </div>
  );
}
