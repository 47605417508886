import React, { useState, useRef, useEffect } from "react";

import {
  Box,
  Button,
  Divider,
  IconButton,
  Modal,
  TextField,
  ThemeProvider,
} from "@mui/material";
import classes from "./AddNominee.module.scss";

import { Stack } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import { theme } from "../../ThemeProvider/ThemeProvider";
import { Formik } from "formik";
import {
  addbankaccountschema,
  addnomineeschema,
} from "../../../Schemas/Schemas";

interface Props {
  open: boolean;
  handleClose: any;
  handleSubmitData: any;
  nomineeId: any;
  data: any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "65%",
  //   height: "90%",
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  //   p: 4,
};

export default function AddNominee({
  open,
  handleClose,
  handleSubmitData,
  nomineeId,
  data,
}: Props) {
  const [loading, setloading] = useState(false);

  const [micrnumber, setmicrnumber] = useState("");

  const addNomineeAccount = {
    nomineeId: "",
    fullname: "",
    dob: "",
    number: "",
    email: "",
    relationship: "",
    share: "",
    address: "",
    pincode: "",
    city: "",
    state: "",
    country: "",
    gname: "",
    gdob: "",
    gemail: "",
    gmobile: "",
    grelation: "",
    gaddress: "",
    gpincode: "",
    gstate: "",
    gcity: "",
    gcountry: "",
  };

  return (
    <Modal
      open={open}
      //   onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ThemeProvider theme={theme}>
        <Formik
          validationSchema={addnomineeschema}
          initialValues={addNomineeAccount}
          onSubmit={(values) => {
            handleSubmitData(values);
            handleClose();
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => (
            <>
              <form noValidate onSubmit={handleSubmit}>
                <Box sx={style}>
                  <Stack
                    sx={{ position: "absolute", right: "20px", top: "20px" }}
                  >
                    <IconButton
                      size="small"
                      disableFocusRipple
                      disableRipple
                      sx={{
                        background: "white",
                      }}
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      <CloseIcon
                        sx={{ color: "rgba(79, 79, 79, 1)", fontSize: "12px" }}
                      />
                    </IconButton>
                  </Stack>
                  <div className={classes.mainWrapper}>
                    <div className={classes.imageTitleContainer}>
                      <p className={classes.title}>Add Nominee</p>
                    </div>
                    <div className={classes.fieldsWrapper}>
                      <Stack
                        spacing={0.5}
                        sx={{
                          border: "1px dotted #E1E2E3",
                          padding: "20px",
                          borderRadius: "10px",
                        }}
                      >
                        <Stack
                          justifyContent={"space-between"}
                          spacing={2}
                          direction={"row"}
                        >
                          <TextField
                            id="nomineeId"
                            name="nomineeId"
                            label="Id"
                            variant="outlined"
                            size="small"
                            onBlur={handleBlur}
                            value={nomineeId}
                            error={
                              touched.nomineeId && Boolean(errors.nomineeId)
                            }
                            helperText={
                              touched.nomineeId && errors.nomineeId?.toString()
                            }
                          />
                          <TextField
                            id="fullname"
                            fullWidth
                            name="fullname"
                            label="Full Name"
                            variant="outlined"
                            size="small"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.fullname}
                            error={touched.fullname && Boolean(errors.fullname)}
                            helperText={
                              touched.fullname && errors.fullname?.toString()
                            }
                          />
                          <TextField
                            id="dob"
                            fullWidth
                            name="dob"
                            label="DOB"
                            variant="outlined"
                            size="small"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.dob}
                            error={touched.dob && Boolean(errors.dob)}
                            helperText={touched.dob && errors.dob?.toString()}
                          />
                          <TextField
                            id="number"
                            fullWidth
                            name="number"
                            label="Mobile No."
                            variant="outlined"
                            size="small"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.number}
                            error={touched.number && Boolean(errors.number)}
                            helperText={
                              touched.number && errors.number?.toString()
                            }
                          />
                        </Stack>
                        <Stack
                          justifyContent={"space-between"}
                          spacing={2}
                          direction={"row"}
                        >
                          <TextField
                            id="email"
                            fullWidth
                            name="email"
                            label="Email"
                            variant="outlined"
                            size="small"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                            error={touched.email && Boolean(errors.email)}
                            helperText={
                              touched.email && errors.email?.toString()
                            }
                          />
                          <TextField
                            id="relationship"
                            fullWidth
                            name="relationship"
                            label="Relationship"
                            variant="outlined"
                            size="small"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.relationship}
                            error={
                              touched.relationship &&
                              Boolean(errors.relationship)
                            }
                            helperText={
                              touched.relationship &&
                              errors.relationship?.toString()
                            }
                          />
                          <TextField
                            id="share"
                            fullWidth
                            name="share"
                            label="Share"
                            variant="outlined"
                            size="small"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.share}
                            error={touched.share && Boolean(errors.share)}
                            helperText={
                              touched.share && errors.share?.toString()
                            }
                          />
                        </Stack>
                        <Stack
                          justifyContent={"space-between"}
                          spacing={2}
                          direction={"row"}
                        >
                          <TextField
                            id="address"
                            fullWidth
                            name="address"
                            label="Address"
                            variant="outlined"
                            size="small"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.address}
                            error={touched.address && Boolean(errors.address)}
                            helperText={
                              touched.address && errors.address?.toString()
                            }
                          />

                          <TextField
                            id="pincode"
                            fullWidth
                            name="pincode"
                            label="Pincode"
                            variant="outlined"
                            size="small"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.pincode}
                            error={touched.pincode && Boolean(errors.pincode)}
                            helperText={
                              touched.pincode && errors.pincode?.toString()
                            }
                          />
                        </Stack>
                        <Stack
                          justifyContent={"space-between"}
                          spacing={2}
                          direction={"row"}
                        >
                          <TextField
                            id="city"
                            fullWidth
                            name="city"
                            label="city"
                            variant="outlined"
                            size="small"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.city}
                            error={touched.city && Boolean(errors.city)}
                            helperText={touched.city && errors.city?.toString()}
                          />
                          <TextField
                            id="state"
                            fullWidth
                            name="state"
                            label="state"
                            variant="outlined"
                            size="small"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.state}
                            error={touched.state && Boolean(errors.state)}
                            helperText={
                              touched.state && errors.state?.toString()
                            }
                          />
                          <TextField
                            id="country"
                            fullWidth
                            name="country"
                            label="country"
                            variant="outlined"
                            size="small"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.country}
                            error={touched.country && Boolean(errors.country)}
                            helperText={
                              touched.country && errors.country?.toString()
                            }
                          />
                        </Stack>
                      </Stack>

                      <Stack
                        spacing={0.5}
                        sx={{
                          border: "1px dotted #E1E2E3",
                          padding: "20px",
                          borderRadius: "10px",
                          marginTop: "10px",
                        }}
                      >
                        <Stack
                          justifyContent={"space-between"}
                          spacing={2}
                          direction={"row"}
                        >
                          <TextField
                            id="gname"
                            fullWidth
                            name="gname"
                            label="Guardian Name"
                            variant="outlined"
                            size="small"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.gname}
                            error={touched.gname && Boolean(errors.gname)}
                            helperText={
                              touched.gname && errors.gname?.toString()
                            }
                          />
                          <TextField
                            id="gdob"
                            fullWidth
                            name="gdob"
                            label="Guardian DOB"
                            variant="outlined"
                            size="small"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.gdob}
                            error={touched.gdob && Boolean(errors.gdob)}
                            helperText={touched.gdob && errors.gdob?.toString()}
                          />
                          <TextField
                            id="gemail"
                            fullWidth
                            name="gemail"
                            label="Guardian Email"
                            variant="outlined"
                            size="small"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.gemail}
                            error={touched.gemail && Boolean(errors.gemail)}
                            helperText={
                              touched.gemail && errors.gemail?.toString()
                            }
                          />
                        </Stack>
                        <Stack
                          justifyContent={"space-between"}
                          spacing={2}
                          direction={"row"}
                        >
                          <TextField
                            id="gmobile"
                            fullWidth
                            name="gmobile"
                            label="Guardian No."
                            variant="outlined"
                            size="small"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.gmobile}
                            error={touched.gmobile && Boolean(errors.gmobile)}
                            helperText={
                              touched.gmobile && errors.gmobile?.toString()
                            }
                          />
                          <TextField
                            id="grelation"
                            fullWidth
                            name="grelation"
                            label="Guardian Relation"
                            variant="outlined"
                            size="small"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.grelation}
                            error={
                              touched.grelation && Boolean(errors.grelation)
                            }
                            helperText={
                              touched.grelation && errors.grelation?.toString()
                            }
                          />
                          <TextField
                            id="gpincode"
                            fullWidth
                            name="gpincode"
                            label="Guardian Pincode"
                            variant="outlined"
                            size="small"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.gpincode}
                            error={touched.gpincode && Boolean(errors.gpincode)}
                            helperText={
                              touched.gpincode && errors.gpincode?.toString()
                            }
                          />
                        </Stack>
                        <Stack
                          justifyContent={"space-between"}
                          spacing={2}
                          direction={"row"}
                        >
                          <TextField
                            id="gaddress"
                            fullWidth
                            name="gaddress"
                            label="Guardian Address"
                            variant="outlined"
                            size="small"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.gaddress}
                            error={touched.gaddress && Boolean(errors.gaddress)}
                            helperText={
                              touched.gaddress && errors.gaddress?.toString()
                            }
                          />
                        </Stack>
                        <Stack
                          justifyContent={"space-between"}
                          spacing={2}
                          direction={"row"}
                        >
                          <TextField
                            id="gcity"
                            fullWidth
                            name="gcity"
                            label="Guardian City"
                            variant="outlined"
                            size="small"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.gcity}
                            error={touched.gcity && Boolean(errors.gcity)}
                            helperText={
                              touched.gcity && errors.gcity?.toString()
                            }
                          />
                          <TextField
                            id="gstate"
                            fullWidth
                            name="gstate"
                            label="Guardian State"
                            variant="outlined"
                            size="small"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.gstate}
                            error={touched.gstate && Boolean(errors.gstate)}
                            helperText={
                              touched.gstate && errors.gstate?.toString()
                            }
                          />
                          <TextField
                            id="gcountry"
                            fullWidth
                            name="gcountry"
                            label="Guardian Country"
                            variant="outlined"
                            size="small"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.gcountry}
                            error={touched.gcountry && Boolean(errors.gcountry)}
                            helperText={
                              touched.gcountry && errors.gcountry?.toString()
                            }
                          />
                        </Stack>
                      </Stack>
                      <Stack width={"80%"} margin={"20px auto"}>
                        {values.fullname && values.number && values.share ? (
                          <Button variant="contained" type="submit">
                            Add
                          </Button>
                        ) : (
                          <Button variant="contained" disabled>
                            Add
                          </Button>
                        )}
                      </Stack>
                    </div>
                  </div>
                </Box>
              </form>
            </>
          )}
        </Formik>
      </ThemeProvider>
    </Modal>
  );
}
