import React, { useState } from "react";
import { Helmet } from "react-helmet";
import {
  Box,
  
  IconButton,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import Button from "@mui/joy/Button";

import DragandDropFile from "../../components/DragAndDrop/DragandDrop";
import CloseIcon from "@mui/icons-material/Close";
import { uploadClientInfoAPICall } from "../../Utils/APICalls";
import SucessSnackBar from "../../components/SnackBar/SuccessSnackBar";
import ErrorSnackBar from "../../components/SnackBar/ErrorSnackBar";

const styleNew = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "55%",
  transform: "translate(-50%, -50%)",
  width: "60vW",
  height: "50vH",
  bgcolor: "background.paper",

  p: 1,
  boxShadow: 24,
  borderRadius: "10px",
};

export default function UploadClientDetails() {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState("");
  const [clientinfoUploadErrorMessage, setclientinfoUploadErrorMessage] =
    useState("");
  const [loading, setloading] = useState(false);
  let acceptedFormats = ["csv"];

  const handleFileChangeUpload = (event: any) => {
    const file = event[0];
   // console.log(event);
    if (!file) {
      setSelectedFile(null);

      return;
    }
    const fileType = file.type.split("/")[1];

    if (!acceptedFormats.includes(fileType)) {
      setSelectedFile(null);
      setErrorMessage(
        `Invalid file format. Only ${acceptedFormats.join(
          ", "
        )} files are accepted.`
      );
     // console.log("error");
      return;
    }

    setSelectedFile(file);
    setOpen(true);
    setErrorMessage("");
  };

  const handleUpload = () => {
    setloading(true);
    const formData = new FormData();
    const obj: any = {
      file: selectedFile,
    };
    formData.append("file", obj.file);

    uploadClientInfoAPICall(formData)
      .then((response: any) => {
        const responseData = response.data;
        setloading(false);
       // console.log(responseData);
        setSelectedFile(null);
        setSuccessMessage("Successfully inserted the data");
        setTimeout(() => {
          setSuccessMessage("");
        }, 5000);
      })
      .catch((error: any) => {
       // console.log(error);
        setloading(false);
        setclientinfoUploadErrorMessage("Error inserting the data");
        setTimeout(() => {
          setclientinfoUploadErrorMessage("");
        }, 5000);

        setSelectedFile(null);
      });
  };
  return (
    <div>
      <Helmet>
        <title>Upload Client Info </title>
        <meta name="UploadDemat" content="UploadDemat" />
      </Helmet>
      {selectedFile ? (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          // onClose={handleCloseModal}
          closeAfterTransition
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Box sx={styleNew}>
            <Stack position={"absolute"} right={10}>
              <IconButton
                onClick={() => {
                  setSelectedFile(null);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Stack>
            <Stack
              alignItems={"center"}
              justifyContent={"center"}
              height={"100%"}
            >
              <Typography sx={{ margin: "20px 0" }}>
                {" "}
                {selectedFile && selectedFile.name}
              </Typography>
              <Button
               
                sx={{ width: "250px" }}
                onClick={handleUpload}
                loading={loading}
              >
                Upload Client Details
              </Button>
            </Stack>
          </Box>
        </Modal>
      ) : (
        <Stack marginTop={"38px"}>
          <DragandDropFile
            handleFileChangeUpload={handleFileChangeUpload}
            acceptedFormats={acceptedFormats}
            errorMessage={errorMessage}
            selectedFile={selectedFile}
          />
        </Stack>
      )}
      <SucessSnackBar message={successMessage} />
      <ErrorSnackBar message={clientinfoUploadErrorMessage} />
    </div>
  );
}
