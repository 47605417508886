import React, { useEffect, useState } from "react";
import {
  Button,
  Chip,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import RefreshIcon from "@mui/icons-material/Refresh";
import PersonIcon from "@mui/icons-material/Person";
import { Helmet } from "react-helmet";
import {
  AssignClientAPICall,
  ListUsersAPICall,
  GetRekycStagesAPICall,
  AssignUserEmailMobileUpdateAPICall,
  GetDashboardEMUpdateAPICall,
  PermanentDeleteClientEmialMobileAPICall,
} from "../../Utils/APICalls";
import {
  IAssignUserBankUpdate,
  IGetDashboardBankUpdate,
} from "../../Utils/dtos";
import Tooltip from "@mui/material/Tooltip";
import Tabs from "../../components/Tabs/Tabs";
import stagesData from "../../Utils/rekycstages.json";
import SucessSnackBar from "../../components/SnackBar/SuccessSnackBar";
import { DateRange } from "@mui/x-date-pickers-pro";
import dayjs, { Dayjs } from "dayjs";
import DataTableComponentEmailMobileUpdate from "../../components/DataTableComponentEmailMobileUpdate/DataTableComponentEmailMobileUpdate";
import EmailMobileUpdateAssignUser from "../../components/Modals/EmailMobileUpdateAssignUser/EmailMobileUpdateAssignUser";
import EmailMobileUpdateDetailspopup from "../../components/Modals/EmailMobileUpdateDetailsModal/EmailMobileUpdateDetailspopup";
import classes from "./NewKyc.module.scss";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import FinUpdateFilters from "../../components/Filters/FinUpdateFilters";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { confirmAlert } from "react-confirm-alert";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import AssignUser from "../../components/Modals/AssignUser/AssignUser";

const titletextStyle = {
  fontSize: "14px",
  fontWeight: "bold",
  color: "#0F1419",
  cursor: "pointer",
};
const contenttextStyle = { fontSize: "12px", color: "#555555" };

const mappingValues: any = {
  All: "all",
  "In Progress": "non_esigned",
  "Approval Pending": "approval_pending",
  // Rejected: "rejected",
  Approved: "approved",
  Deleted: "deleted",
};
export default function EmailandMobileUpdate() {
  const [data, setdata] = useState([]);
  const [loading, setloading] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedTab, setselectedTab] = useState<string>("All");
  const [totalRows, setTotalRows] = useState(5);
  const [perPage, setPerPage] = useState(20);
  const [rekycClientDetailspopup, setclientDetailsPopup] = useState({
    status: false,
    data: "",
    requestId: "",
  });
  const [clientId, setClientId] = useState("");
  const [userName, setUserName] = useState("");
  const [clientMessage, setClientMessage] = useState("");
  const [stagesData, setStagesData] = useState<any>();
  const [users, setusers] = useState<any>();
  const [searchData, setSearchData] = useState("");
  const [stageFilterData, setStageFilterData] = useState("");
  const [userFilterData, setUserFilterData] = useState("");
  const [rejectedReason, setRejectedReason] = useState("");
  const [deletedReason, setDeletedReason] = useState("");
  const [pagenumber, setPageNumber] = useState(1);
  const [paginationCount, setpaginationCount] = useState(1);
  const [dateRangeFilterData, setDateRangeFilterData] = useState<any>({
    fromDate: dayjs().subtract(2, "month"),
    toDate: dayjs(new Date()),
  });
  const [recordsCount, setRecordsCount] = useState(0);
  const [stageName, setStageName] = useState<any>("all");
  const [sortactivity, setsortactivity] = useState<any>("DESC");
  const [clientDeleted, setClientDeleted] = useState(false);
  const [clientCompleted, setClientCompleted] = useState(false);
  const [rotate, setRotate] = useState(false);
  // const [isAdmin, setIsAdmin] = useState(true);
  const isAdmin: any = sessionStorage.getItem("isAdmin");
  const [value, setValue] = useState<DateRange<Dayjs>>([
    dayjs().subtract(2, "month"),
    dayjs(),
  ]);
  const [datevalue, setdateValue] = useState<DateRange<Dayjs | any>>([
    dayjs().subtract(2, "month"),
    dayjs(),
  ]);
  const [userId, setUserId] = useState<any>("");
  const [openFilters, setOpenFilters] = useState(false);

  function formatDatePayload(dateString: any) {
    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
  }

  const columns = [
    {
      name: <b style={titletextStyle}>SrNo</b>,
      selector: "srno",
      sortable: true,
      width: "60px",
      cell: (row: any, index: any) => (
        <b style={contenttextStyle}>{index + 1 + paginationCount * 20 - 20}</b>
      ),
    },

    {
      name: <b style={titletextStyle}>PAN</b>,
      selector: "pan_no",
      sortable: true,
      width: "100px",
      cell: (row: any) => (
        // <Tooltip title={!row.is_completed ? "Click to Login" : ""}>
        <b
          style={{ fontSize: "12px", cursor: "pointer" }}
          onClick={() => {
            // if (!row.is_completed) {
            //   handleAdminLogintoClientAccount(row);
            // }
          }}
        >
          {row.pan_no}
        </b>
        // </Tooltip>
      ),
    },
    {
      name: <b style={titletextStyle}>Name</b>,
      selector: "client_name",
      sortable: true,

      cell: (row: any) => <b style={contenttextStyle}>{row.client_name}</b>,
    },
    {
      name: <b style={titletextStyle}>CCode</b>,
      selector: "client_code",
      sortable: true,
      width: "80px",
      cell: (row: any) => <b style={contenttextStyle}>{row.client_code}</b>,
    },
    {
      name: <b style={titletextStyle}>Email</b>,
      selector: "email",
      width: "200px",
      sortable: true,
      cell: (row: any) => (
        <b style={contenttextStyle}>{row?.existing_details?.email}</b>
      ),
    },
    {
      name: <b style={titletextStyle}>New Email</b>,
      selector: "email",
      width: "200px",
      sortable: true,
      cell: (row: any) => (
        <b style={contenttextStyle}>{row?.new_details?.email}</b>
      ),
    },
    {
      name: <b style={titletextStyle}>Mobile</b>,
      selector: "mobile",
      sortable: true,
      width: "105px",
      cell: (row: any) => (
        <b style={contenttextStyle}>{row?.existing_details?.mobile}</b>
      ),
    },
    {
      name: <b style={titletextStyle}>New Mobile</b>,
      selector: "mobile",
      sortable: true,
      width: "105px",
      cell: (row: any) => (
        <b style={contenttextStyle}>
          {row?.new_details ? row.new_details?.mobile : "-"}
        </b>
      ),
    },
    {
      name: <b style={titletextStyle}>User</b>,
      selector: "handling_user",
      sortable: true,
      width: "100px",
      cell: (row: any) => (
        <>
          {row.handling_user === "" ? (
            <Tooltip title="Assign User ">
              <IconButton
                onClick={() => {
                  handleAssign(row);
                }}
              >
                <PersonIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <b
              style={{ fontSize: "12px", cursor: "pointer" }}
              onClick={() => {
                handleAssign(row);
              }}
            >
              {row.handling_user}
            </b>
          )}
        </>
      ),
    },
    {
      name: <b style={titletextStyle}>Actions</b>,
      selector: "spamReportRate",
      sortable: false,
      allowOverflow: true,
      button: true,
      width: "160px",
      cell: (row: any) => (
        <b style={contenttextStyle}>
          {/* {mappingValues[selectedTab] === "approved" ? (
            <Tooltip title="KRA Upload">
              <IconButton
                onClick={() => {
                  handleViewClientDetails(row);
                }}
              >
                <CloudUploadIcon />
              </IconButton>
            </Tooltip>
          ) : (
            ""
          )} */}

          <Tooltip title="View Details">
            <IconButton
              onClick={() => {
                handleViewClientDetails(row);
              }}
            >
              <RemoveRedEyeOutlinedIcon style={{ color: "black" }} />
            </IconButton>
          </Tooltip>
        </b>
      ),
    },
  ];
  const columnsForRejectedandDeleted = [
    {
      name: <b style={titletextStyle}>SrNo</b>,
      selector: "srno",
      sortable: true,
      width: "60px",
      cell: (row: any, index: any) => (
        <b style={contenttextStyle}>{index + 1 + paginationCount * 20 - 20}</b>
      ),
    },

    {
      name: <b style={titletextStyle}>PAN</b>,
      selector: "pan_no",
      sortable: true,
      width: "100px",
      cell: (row: any) => (
        // <Tooltip title={!row.is_completed ? "Click to Login" : ""}>
        <b
          style={{ fontSize: "12px", cursor: "pointer" }}
          onClick={() => {
            // if (!row.is_completed) {
            //   handleAdminLogintoClientAccount(row);
            // }
          }}
        >
          {row.pan_no}
        </b>
        // </Tooltip>
      ),
    },
    {
      name: <b style={titletextStyle}>Name</b>,
      selector: "client_name",
      sortable: true,

      cell: (row: any) => <b style={contenttextStyle}>{row.client_name}</b>,
    },
    {
      name: <b style={titletextStyle}>CCode</b>,
      selector: "client_code",
      sortable: true,
      width: "80px",
      cell: (row: any) => <b style={contenttextStyle}>{row.client_code}</b>,
    },
    {
      name: <b style={titletextStyle}>Email</b>,
      selector: "email",
      width: "200px",
      sortable: true,
      cell: (row: any) => (
        <b style={contenttextStyle}>{row?.existing_details?.email}</b>
      ),
    },
    {
      name: <b style={titletextStyle}>New Email</b>,
      selector: "email",
      width: "200px",
      sortable: true,
      cell: (row: any) => (
        <b style={contenttextStyle}>{row?.new_details?.email}</b>
      ),
    },
    {
      name: <b style={titletextStyle}>Mobile</b>,
      selector: "mobile",
      sortable: true,
      width: "105px",
      cell: (row: any) => (
        <b style={contenttextStyle}>{row?.existing_details?.mobile}</b>
      ),
    },
    {
      name: <b style={titletextStyle}>New Mobile</b>,
      selector: "mobile",
      sortable: true,
      width: "105px",
      cell: (row: any) => (
        <b style={contenttextStyle}>
          {row?.new_details ? row.new_details?.mobile : "-"}
        </b>
      ),
    },

    {
      name: <b style={titletextStyle}>User</b>,
      selector: "handling_user",
      sortable: true,
      width: "100px",
      cell: (row: any) => (
        <>
          {row.handling_user === "" ? (
            <Tooltip title="Assign User ">
              <IconButton
                onClick={() => {
                  handleAssign(row);
                }}
              >
                <PersonIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <b
              style={{ fontSize: "12px", cursor: "pointer" }}
              onClick={() => {
                handleAssign(row);
              }}
            >
              {row.handling_user}
            </b>
          )}
        </>
      ),
    },
    {
      name: <b style={titletextStyle}>Reason</b>,
      selector: "reason",
      sortable: true,

      cell: (row: any) => (
        <b style={contenttextStyle}>
          {(row.rejected_reason && row.rejected_reason) ||
            (row.deleted_reason && row.deleted_reason)}
        </b>
      ),
    },
    {
      name: <b style={titletextStyle}>Actions</b>,
      selector: "spamReportRate",
      sortable: false,
      allowOverflow: true,
      button: true,
      width: "160px",
      cell: (row: any) => (
        <b style={contenttextStyle}>
          <Tooltip title="View Details">
            <IconButton
              onClick={() => {
                handleViewClientDetails(row);
              }}
            >
              <RemoveRedEyeOutlinedIcon style={{ color: "black" }} />
            </IconButton>
          </Tooltip>
        </b>
      ),
    },
  ];

  useEffect(() => {
    GetRekycStagesAPICall()
      .then((response: any) => {
        // console.log("responseDatastages", response.data);
        const responseData = response.data;

        setStagesData(responseData.data.stages);
      })
      .catch((error: any) => {
        //  console.log("error", error);
      });

    ListUsersAPICall()
      .then((response: any) => {
        const responseData = response.data;
        setusers(responseData.data.data);
        // console.log(responseData.data.user_list);
      })
      .catch((error: any) => {});
  }, []);

  useEffect(() => {
    setdata([]);
    const delayDebounceFn = setTimeout(() => {
      GetRekycDashboard();
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [
    selectedTab,
    userId,
    stageName,
    pagenumber,
    sortactivity,
    dateRangeFilterData,
    searchData,
  ]);

  const handleRefreshData = () => {
    setPageNumber(1);
    setpaginationCount(1);
    GetRekycDashboard();
  };

  const GetRekycDashboard = () => {
    setloading(true);
    let obj: IGetDashboardBankUpdate = {
      page_no: pagenumber,
      page_size: perPage,
      query: searchData,
      type: stageName !== "" ? stageName : mappingValues[selectedTab],
      filter_user: userId ? userId?.full_name : "",
      filter_date_from:
        dateRangeFilterData.fromDate === ""
          ? ""
          : formatDatePayload(dateRangeFilterData.fromDate),
      filter_date_to:
        dateRangeFilterData.toDate === ""
          ? ""
          : formatDatePayload(dateRangeFilterData.toDate),
      sort_activity: sortactivity,
    };
    GetDashboardEMUpdateAPICall(obj)
      .then((response: any) => {
        setdata(response.data.data.data ? response.data.data.data : []);

        setTotalRows(Math.ceil(response.data.data.total_count / 20));
        setRecordsCount(
          response.data.data.total_count ? response.data.data.total_count : 0
        );
        setloading(false);
      })
      .catch((error: any) => {
        // console.log("error", error);
        setloading(false);
      });
  };

  const handleSearchChange = (data: any) => {
    setSearchData(data);
    setPageNumber(1);
    setpaginationCount(1);
    // getClients(1, perPage, data);
  };

  const handleUserChange = (data: any) => {
    setPageNumber(1);
    setUserFilterData(data);
    setpaginationCount(1);
    // getClients(1, perPage, data);
  };

  const handleAssign = (data: any) => {
    if (isAdmin === false) {
      const obj: any = {
        client_id: data._id,
        admin_id: sessionStorage.getItem("userId"),
      };
      AssignClientAPICall(obj).then((response: any) => {
        //  console.log("response", response);
        alert("Assigned to me");
      });
    } else {
      setOpen(true);
      setClientId(data._id);
    }
  };

  const handleViewClientDetails = (data: any) => {
    // console.log("clientData", data);
    if (data.handling_user === "") {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="confitmalertUI">
              <PersonIcon />
              <p>Please assign an user</p>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <button
                  onClick={() => {
                    onClose();
                  }}
                  className="confitmbutton"
                >
                  OK
                </button>
              </div>
            </div>
          );
        },
      });
      // window.alert("Please Asign User");
    } else {
      setclientDetailsPopup({
        status: true,
        data: "",
        requestId: data._id,
      });
      setClientId(data._id);
      setClientDeleted(data.is_deleted);
      setClientCompleted(data.is_completed);
      if (data.deleted_reason) {
        setDeletedReason(data.deleted_reason);
      } else {
        setDeletedReason("");
      }
      if (data.rejected_reason) {
        setRejectedReason(data.rejected_reason);
      } else {
        setRejectedReason("");
      }
    }
  };

  const handleCloseClientDetailsPopup = (data: any) => {
    sessionStorage.removeItem(
      `${rekycClientDetailspopup.requestId}_e_sign_pdf`
    );
    sessionStorage.removeItem(`${rekycClientDetailspopup.requestId}_ipv`);
    //  console.log("data", data);
    setclientDetailsPopup({
      status: false,
      data: "",
      requestId: "",
    });

    // getClients(1, perPage, "", "", "", "", "");

    if (data === "Deleted") {
      GetRekycDashboard();
      setClientMessage("Client Deleted Successfully");
      setTimeout(() => {
        setClientMessage("");
      }, 5000);
    } else if (data === "Client Approved") {
      GetRekycDashboard();
      setClientMessage("Client Approved Successfully");
      setTimeout(() => {
        setClientMessage("");
      }, 5000);
    } else if (data === "Client Rejected") {
      GetRekycDashboard();
      setClientMessage("Client Rejected Successfully");
      setTimeout(() => {
        setClientMessage("");
      }, 5000);
    } else {
      setClientMessage(""); // Reset the message for other cases
    }
  };

  const handlePermanentDeleteClient = () => {
    //  console.log("data", data);
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="confitmalertUI">
            <DeleteOutlineRoundedIcon />
            <p>Are you sure, client will be permanently deleted</p>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "20px",
              }}
            >
              <button
                onClick={() => {
                  onClose();
                }}
                className="confitmbutton"
              >
                No
              </button>
              <button
                onClick={() => {
                  onClose();
                  let reqObj: any = {
                    user_id: clientId,
                  };

                  PermanentDeleteClientEmialMobileAPICall(reqObj)
                    .then((response: any) => {
                      const responseData = response.data;
                      GetRekycDashboard();
                      setClientMessage("Client Deleted Successfully");
                      setclientDetailsPopup({
                        status: false,
                        data: "",
                        requestId: "",
                      });
                      setTimeout(() => {
                        setClientMessage("");
                      }, 5000);
                    })
                    .catch((error) => {});
                }}
                className="confitmbutton"
              >
                Yes
              </button>
            </div>
          </div>
        );
      },
    });
  };

  const handleAssignePopUpClose = () => {
    setOpen(false);
  };

  const handleAssigneID = (data: any) => {
    setOpen(false);

    const obj: any = {
      client_id: clientId,
      admin_id: data,
    };

    AssignUserEmailMobileUpdateAPICall(obj)
      .then((response: any) => {
        // alert(`Assigned to me ${response.data.data.User_name}`);
        setUserName(response.data.data.User_name);
        GetRekycDashboard();
        setClientMessage("User Assigned Successfully");
        setTimeout(() => {
          setClientMessage("");
        }, 5000);
      })
      .catch((error: any) => {});
  };

  const handleSubmitDetailsPopup = (data: any) => {
    //  console.log("data", data);
  };

  const handlePageChange = (value: any, page: number) => {
    // getClients(page, perPage, "", "", "", "", "");
    setpaginationCount(page);
    setPageNumber(page);
  };

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setPerPage(newPerPage);
    GetRekycDashboard();
  };

  const handleChangeTab = (tab: any) => {
    setselectedTab(tab);
  };

  const formatDate = (date: any) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hour = String(date.getHours()).padStart(2, "0");
    const minute = String(date.getMinutes()).padStart(2, "0");
    const second = String(date.getSeconds()).padStart(2, "0");
    return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
  };

  const handleDateRangeChange = (data: any) => {
    setDateRangeFilterData({
      fromDate: !data[0].$d ? "" : formatDate(data[0].$d),
      toDate: !data[1].$d ? "" : formatDate(data[1].$d),
    });
  };

  const handleClearFilters = () => {
    setdateValue([null, null]);
    setSearchData("");
    setUserId("");
    setUserFilterData("");
    handleDateRangeChange(["", ""]);
  };
  function formatDatePayloadFilters(dateString: any) {
    // Create a new Date object from the provided date string
    const date = new Date(dateString);

    // Extract the year, month, and day from the Date object
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-indexed, so we add 1
    const day = String(date.getDate()).padStart(2, "0");

    // Concatenate the year, month, and day with a hyphen separator to get the desired format
    const formattedDate = `${day}-${month}-${year}`;

    return formattedDate;
  }
  const handleCloseFilters = () => {
    setOpenFilters(false);
  };
  const handleSelectChange = (event: SelectChangeEvent) => {
    setpaginationCount(1);
    setStageName(event.target.value as string);

    setPageNumber(1);
  };

  return (
    <div style={{ marginTop: "6px", width: "100%" }}>
      <Helmet>
        <title>Email & Mobile Update</title>
        <meta name="Email & Mobile Update" content="Email & Mobile Update" />
      </Helmet>

      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "space-between",
          position: "relative",
        }}
      >
        <div className={classes.category}>
          <FormControl sx={{ width: "230px" }} size="small">
            <InputLabel id="demo-simple-select-label">Category</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={stageName}
              name="selectStage"
              label="Category"
              onChange={handleSelectChange}
            >
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="non_esigned">In Progress</MenuItem>
              <MenuItem value="approval_pending">Approval Pending</MenuItem>
              <MenuItem value="rejected">Rejected</MenuItem>
              <MenuItem value="approved">Approved</MenuItem>
              <MenuItem value="deleted">Deleted</MenuItem>
            </Select>
          </FormControl>
        </div>

        {/* {datevalue &&
          datevalue[0] &&
          datevalue[0]?.$d &&
          datevalue &&
          datevalue[1] &&
          datevalue[1]?.$d  && ( */}
        <div className={classes.chipsSection}>
          {/* <p className={classes.appliedFilters}>Applied Filters</p> */}
          {datevalue && datevalue[0] && datevalue[0]?.$d && (
            <div className={classes.chips}>
              From Date
              <div>
                <Chip
                  label={
                    !datevalue[0].$d
                      ? ""
                      : formatDatePayloadFilters(datevalue[0]?.$d)
                  }
                  variant="outlined"
                  size="small"
                  onDelete={() => {
                    setdateValue(["", ""]);
                    handleDateRangeChange(["", ""]);
                  }}
                />
              </div>
            </div>
          )}
          {datevalue && datevalue[1] && datevalue[1]?.$d && (
            <div className={classes.chips}>
              To Date
              <div>
                <Chip
                  label={
                    !datevalue[1].$d
                      ? ""
                      : formatDatePayloadFilters(datevalue[1]?.$d)
                  }
                  variant="outlined"
                  size="small"
                  onDelete={() => {
                    setdateValue([null, null]);
                    handleDateRangeChange(["", ""]);
                  }}
                />
              </div>
            </div>
          )}

          {userId && (
            <div className={classes.chips}>
              User
              <div>
                <Chip
                  label={userId?.full_name}
                  variant="outlined"
                  size="small"
                  onDelete={() => {
                    setUserId("");
                    setUserFilterData("");
                  }}
                />
              </div>
            </div>
          )}
        </div>
        {/* )} */}

        <div className={classes.emailListHeader}>
          <Button
            variant="contained"
            onClick={handleClearFilters}
            sx={{
              background: "#8B93FF",
              boxShadow: "none",
              textTransform: "none",
              "&:hover": {
                background: "#8B93FF",
                boxShadow: "none",
              },
            }}
          >
            Clear
          </Button>

          <div className={classes.searchInput}>
            <div className={classes.searchIcon}>
              <SearchIcon style={{ color: "#8B93FF" }} />
            </div>
            <input
              type="text"
              className={classes.inputBox}
              placeholder="Search...."
              value={searchData}
              onChange={(e) => {
                handleSearchChange(e.target.value);
              }}
            />
          </div>

          <Button
            variant="outlined"
            startIcon={<FilterListIcon style={{ color: "#8B93FF" }} />}
            onClick={() => setOpenFilters(true)}
            sx={{
              textTransform: "none",
              color: "#000",
              border: "1px solid #DDDCE1",
              "&:hover": {
                color: "#000",
                border: "1px solid #DDDCE1",
              },
            }}
          >
            Filters
          </Button>
          {openFilters && (
            <FinUpdateFilters
              selectedTab={selectedTab}
              datevalue={datevalue}
              setdateValue={setdateValue}
              handleDateRangeChange={handleDateRangeChange}
              userId={userId}
              isAdmin={isAdmin}
              setUserId={setUserId}
              handleUserChange={handleUserChange}
              users={users}
              handleCloseFilters={handleCloseFilters}
            />
          )}
        </div>
      </div>

      <div>
        <DataTableComponentEmailMobileUpdate
          columns={
            stageName === "deleted" || stageName === "rejected"
              ? columnsForRejectedandDeleted
              : columns
          }
          data={data}
          loading={loading}
          pagination={true}
          paginationServer={true}
          handlePerRowsChange={handlePerRowsChange}
          handlePageChange={handlePageChange}
          totalRows={totalRows}
          paginationCount={paginationCount}
          recordsCount={recordsCount}
        />
      </div>
      {rekycClientDetailspopup.status && (
        <EmailMobileUpdateDetailspopup
          data={rekycClientDetailspopup.requestId}
          handleSubmitData={handleSubmitDetailsPopup}
          open={rekycClientDetailspopup.status}
          handleClose={handleCloseClientDetailsPopup}
          rejectedReason={rejectedReason}
          deletedReason={deletedReason}
          selectedTabNew={mappingValues[selectedTab]}
          handlePermanentDeleteClient={handlePermanentDeleteClient}
          clientDeleted={clientDeleted}
          clientCompleted={clientCompleted}
          stageName={stageName}
        />
      )}

      <SucessSnackBar message={clientMessage} />
      <AssignUser
        open={open}
        handleClose={handleAssignePopUpClose}
        assigneID={handleAssigneID}
        userData={users}
      />
    </div>
  );
}
