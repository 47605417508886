import React, { useEffect, useState } from "react";
import styles from "./Filters.module.scss";
import { DateRangePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { FormControl, IconButton, MenuItem, Select } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

interface props {
  selectedTab: any;
  datevalue: any;
  setdateValue: any;
  handleDateRangeChange: any;
  handleCloseFilters: any;
}

function DematFilters({
  selectedTab,
  datevalue,
  setdateValue,
  handleDateRangeChange,

  handleCloseFilters,
}: props) {
  return (
    <div className={styles.filterWrapper}>
      <div className={styles.topContainer}>
        <p className={styles.title}>Filters</p>
        <div>
          <IconButton onClick={handleCloseFilters}>
            <HighlightOffIcon style={{ color: "#8B93FF" }} />
          </IconButton>
        </div>
      </div>
      <div className={styles.filterSection}>
        {selectedTab !== "today" && selectedTab !== "completed" && (
          <div className={styles.singleFilter}>
            <p className={styles.textfieldLabel}>Date Range </p>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer
                components={["DateRangePicker", "DateRangePicker"]}
              >
                <DemoItem component="DateRangePicker">
                  <DateRangePicker
                    value={datevalue}
                    sx={{ width: "350px" }}
                    format="DD/MM/YYYY"
                    onChange={(newValue: any) => {
                      setdateValue(newValue);
                      if (newValue[0] && newValue[1]) {
                        handleDateRangeChange(newValue);
                      }
                    }}
                    slotProps={{
                      textField: {
                        size: "small",
                        sx: {
                          borderRadius: "4px",
                          background: "#F2F2F6",
                          outline: "none",
                          fontSize: "14px",
                          fontWeight: 500,
                          padding: "0 0px",
                          color: "#000000",
                          boxShadow: "none",
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                        },
                      },
                    }}
                  />
                </DemoItem>
              </DemoContainer>
            </LocalizationProvider>
          </div>
        )}
      </div>
    </div>
  );
}

export default DematFilters;
