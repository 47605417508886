import { Helmet } from "react-helmet";

export default function RolesPage() {
  return (
    <>
      <Helmet>
        <title>Roles </title>
        <meta name="Roles" content="Roles" />
      </Helmet>
      <div>Roles</div>
    </>
  );
}
