import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Stack,
  Fade,
  InputLabel,
  Box,
  IconButton,
  Modal,
  Button,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";

import {
  ListUsersAPICall,
} from "../../../Utils/APICalls";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  height: "60%",
  bgcolor: "background.paper",
  borderRadius: "20px",
  boxShadow: 24,
  p: 2,
};

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

interface Props {
  open: boolean;
  handleClose: any;
  assigneID: any;
  userData?:any;
}

export default function DDPIAssignUser({ open, handleClose, assigneID ,userData}: Props) {
  const [userId, setUserId] = useState("");
  const [users, setusers] = useState<any>();


  const handleChange = (event: SelectChangeEvent) => {
    setUserId(event.target.value as string);
   // console.log(event.target);
  };

  const handleAssignClick = () => {
    assigneID(userId);
  };
  const close = (e: any) => {
    if (e.keyCode === 27) {
      handleClose();
    }
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        closeAfterTransition
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
        onClose={close}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Stack alignItems={"end"}>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Stack>
            <Stack
              alignItems={"center"}
              justifyContent={"space-evenly"}
              height={"60%"}
            >
              <FormControl sx={{ width: "250px" }}>
                <InputLabel id="demo-simple-select-label">Assign To</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={userId}
                  label="Assign To"
                  onChange={handleChange}
                >
                  {userData &&
                    userData.map((data: any) => {
                      return (
                        <MenuItem value={data.FullName} key={data.FullName}>
                          {data.FullName}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
              <Button
                variant="contained"
                sx={{ width: "200px" }}
                onClick={handleAssignClick}
              >
                Assign
              </Button>
            </Stack>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
