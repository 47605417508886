import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { orderBy } from "lodash";
import classes from "./DataTableComponentNRI.module.scss";
import SearchIcon from "@mui/icons-material/Search";
import {
  Button,
  Stack,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Pagination from "@mui/material/Pagination";
import dayjs, { Dayjs } from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateRange } from "@mui/x-date-pickers-pro";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { LicenseInfo } from "@mui/x-date-pickers-pro";
import { width } from "@mui/system";
import StageMapingData from "../../Utils/stages.json";
import StyledLoader from "../Styledloader/StyledLoader";

// LicenseInfo.setLicenseKey(
//   'x0jTPl0USVkVZV0SsMjM1kDNyADM5cjM2ETPZJVSQhVRsIDN0YTM6IVREJ1T0b9586ef25c9853decfa7709eee27a1e',
// );

interface Props {
  columns: any;
  checkboxEnabled?: boolean;
  loading?: boolean;
  data?: any;
  totalRows?: number;
  searchparams: any;
  pagination?: boolean;
  paginationServer?: boolean;
  handleAdd: any;
  handlePageChange?: any;
  handlePerRowsChange?: any;
  addButton?: boolean;
  handleSearchChange?: any;
  handleUserChange?: any;
  handleStageChange?: any;
  handleDateRangeChange?: any;
  stagesData?: any;
  users?: any;
  selectedTab?: string;
  followUpData?: any;
  paginationCount?: any;
  addTariff?: any;
  handleAddTariff?: any;
  tableRefreshFilters?: any;
}



const DataTableComponentNRI = ({
  columns,
  checkboxEnabled = false,
  loading = false,
  pagination = false,
  paginationServer = false,
  data = [],
  searchparams,
  handleAdd,
  handlePageChange,
  handlePerRowsChange,
  totalRows,
  addButton = false,
  addTariff = false,
  handleSearchChange,
  handleUserChange,
  handleStageChange,
  handleDateRangeChange,
  stagesData,
  users,
  selectedTab,
  followUpData,
  paginationCount,
  handleAddTariff,
  tableRefreshFilters,
}: Props) => {
  const handleChange = (data: any) => {

  };
  const [oldData, setoldData] = useState([]);
  const [query, setQuery] = useState("");

  const [dataValue, setData] = useState(1);
  const [stageName, setStageName] = useState<any>("");
  const [userId, setUserId] = useState("");
  const [value, setValue] = useState<DateRange<Dayjs>>([
    dayjs().subtract(2, "month"),
    dayjs(),
  ]);
  // const [value, setValue] = useState<DateRange<Dayjs>>([
  //   null,
  //   null
  // ]);
  const [userDataAdded, setuserDataAdded] = useState([]);
  const mappingData: any = StageMapingData;

  useEffect(() => {
    if (users && !followUpData) {
      const userData: any = [
        ...users,
        { UserId: "non_assigned", FullName: "non_assigned" },
      ];
      setuserDataAdded(userData);
    } else if (users && followUpData === "FollowUp") {
      setuserDataAdded(users);
    }
  }, [users, followUpData]);

  const isAdmin = sessionStorage.getItem("isAdmin");

  useEffect(() => {
    setoldData(data);

    // console.log("data",data[0] && data[0].appno)
  }, [data]);

  useEffect(() => {
    if (tableRefreshFilters !== "") {
      handleClearFilters();
    }
  }, [tableRefreshFilters]);
  const handlePaginationChange = (event: any, page: any) => {
    setData(page);
    //console.log( page);
  };
  const handleClearFilters = () => {
    setValue([null, null]);
    setQuery("");
    handleSearchChange("");
    setStageName("");
    handleStageChange("");
    setUserId("");
    handleUserChange("");
    // setpaginationCount(1)
    handlePageChange("", 1);
    handleDateRangeChange(["", ""]);
  };
  const handleTabChangeClearFilters = () => {
    setValue([dayjs().subtract(1, "month"), dayjs()]);
    setQuery("");
    handleSearchChange("");
    setStageName("");
    handleStageChange("");
    setUserId("");
    handleUserChange("");
    // setpaginationCount(1)
    handlePageChange("", 1);
    handleDateRangeChange([dayjs().subtract(1, "month"), dayjs()]);
  };

  useEffect(() => {
    if (searchparams[0] !== "Email" && searchparams[0] !== "FullName") {
      // handleTabChangeClearFilters();
    }
  }, [selectedTab]);

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#F3F6FA",
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
        // height:'40px'
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
    headRow: {
      style: {
        borderBottom: "none",
      },
    },
    header: {
      style: {
        fontSize: "16px",
        minHeight: "30px",
        paddingLeft: "3px",
        paddingRight: "3px",
      },
    },
    contextMenu: {
      style: {
        fontSize: "14px",
        fontWeight: 400,
        paddingLeft: "5px",
        paddingRight: "5px",
        transform: "translate3d(0, -100%, 0)",
        transitionDuration: "125ms",
        transitionTimingFunction: "cubic-bezier(0, 0, 0.2, 1)",
        willChange: "transform",
      },
      activeStyle: {
        transform: "translate3d(0, 0, 0)",
      },
    },
  };

  const handleSort = (column: any, sortDirection: any) => {
    // console.log("column", column, "sortDirection", sortDirection);
  };
  return (
    <>
      <div className={classes.tableWrapper}>
        <div className={classes.emailListHeader}>
          {searchparams[0] !== "Email" && (
            <div className={classes.searchInput}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <input
                type="text"
                className={classes.inputBox}
                placeholder="Search...."
                value={query}
                onChange={(e) => {
                  setQuery(e.target.value);
                  handleSearchChange(e.target.value);
                }}
              />
            </div>
          )}

          {!addButton && (
            <div style={{ paddingTop: "0px" }}>
              <FormControl sx={{ width: "200px" }} size="small">
                <InputLabel id="demo-simple-select-label">Stage</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={stageName}
                  name="selectStage"
                  label="Stage"
                  onChange={(event: any) => {
                    setStageName(event.target.value);
                    handleStageChange(event.target.value.Stage_Id);
                  }}
                >
                  {stagesData &&
                    stagesData.map((item: any) => (
                      <MenuItem key={item.Stage_Id} value={item}>
                        {item.Stage_Name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
          )}
          {!addButton && isAdmin === "true" && (
            <div style={{ paddingTop: "0px" }}>
              <FormControl sx={{ width: "200px" }} size="small">
                <InputLabel id="demo-simple-select-label">Users</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={userId}
                  label="Users"
                  onChange={(event: any) => {
                    setUserId(event.target.value);
                    handleUserChange(event.target.value);
                  }}
                >
                  {userDataAdded &&
                    userDataAdded.map((data: any) => {
                      return (
                        <MenuItem value={data.FullName} key={data.FullName}>
                          {data.FullName === "non_assigned"
                            ? "Non Assigned"
                            : data.FullName}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </div>
          )}
          {!addButton &&
            selectedTab !== "today" &&
            selectedTab !== "completed" && (
              <div style={{ marginBottom: "10px" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer
                    components={["DateRangePicker", "DateRangePicker"]}
                  >
                    <DemoItem component="DateRangePicker">
                      <DateRangePicker
                        value={value}
                        sx={{ width: "350px" }}
                        format="DD/MM/YYYY"
                        onChange={(newValue: any) => {
                         // console.log("newValue", newValue);
                          setValue(newValue);
                          if (newValue[0] && newValue[1])
                            handleDateRangeChange(newValue);
                        }}
                        slotProps={{ textField: { size: "small" } }}
                      />
                    </DemoItem>
                  </DemoContainer>
                </LocalizationProvider>
              </div>
            )}

          {!addButton && (
            <Button
              variant="contained"
              sx={{ marginTop: "-5px" }}
              onClick={handleClearFilters}
            >
              Clear
            </Button>
          )}
   
  
        </div>
        <div>
          <DataTable
            data={data}
            columns={columns}
            title=" "

            onSort={handleSort}
            highlightOnHover
            selectableRows={checkboxEnabled}
            customStyles={customStyles}
            onSelectedRowsChange={handleChange}
            fixedHeader
         
            actions
            progressPending={loading}
            paginationServer={paginationServer}

            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            progressComponent={<StyledLoader />}
          />
        </div>
        {data.length > 0 && !loading && (
          <Stack
            alignItems={"center"}
            // marginTop={data.length >= 9 ? "10px" : "300px"}
            marginTop={"10px"}
            marginBottom={"20px"}
          >
            <Pagination
              onChange={(value: any, page: any) => {
                handlePageChange(value, page);
                // setpaginationCount(page);
              }}
              page={paginationCount}
              count={totalRows}
              variant="outlined"
              shape="rounded"
            />
          </Stack>
        )}
      </div>
    </>
  );
};

export default DataTableComponentNRI;
