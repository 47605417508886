import {
    Box,
    Button,
    Fade,
    IconButton,
    Modal,
    Stack,
    TextField,
    Tooltip,
    Typography,
  } from "@mui/material";
  import React, { useState, useEffect } from "react";
  import CloseIcon from "@mui/icons-material/Close";
  import VisibilityIcon from "@mui/icons-material/Visibility";
  import DataTableComponent from "../../DataTableComponent/DataTableComponent";
  import DataTable from "react-data-table-component";
  import EditIcon from "@mui/icons-material/Edit";
  import FollowUpDataTableComponent from "../../../pages/Followups/FollowUpsDataTable";
  import InputLabel from "@mui/material/InputLabel";
  import MenuItem from "@mui/material/MenuItem";
  import FormControl from "@mui/material/FormControl";
  import Select, { SelectChangeEvent } from "@mui/material/Select";
  import Table from "@material-ui/core/Table";
  import TableCell from "@material-ui/core/TableCell";
  import TableRow from "@material-ui/core/TableRow";
  import { makeStyles } from "@material-ui/core/styles";
  
  import {
    GetFollowUpHistoryAPICall,
    addFollowUpDateAPICall,
  } from "../../../Utils/APICalls";
  
  import { IAddFollowUpDate } from "../../../Utils/dtos";
  
  interface Props {
    open: boolean;
    handleClose: any;
    handleSubmitData: any;
    data: any;
    followDate?: any;
  }
  
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    height: "90%",
    bgcolor: "background.paper",
    borderRadius: "20px",
    boxShadow: 24,
    p: 2,
  };
  
  const titletextStyle = {
    fontSize: "14px",
    fontWeight: "bold",
    color: "#8C8B8F",
  };
  const contenttextStyle = { fontSize: "12px" };
  
  export default function NRILogsModal({
    open,
    handleClose,
    handleSubmitData,
    data,
    followDate,
  }: Props) {
    const [loading, setLoading] = useState(false);

    const [openDateModal, setOpenDateModal] = useState(false);

  
    useEffect(() => {
      getFollowUpHistory();
      const close = (e: any) => {
        if (e.keyCode === 27) {
          handleClose();
        }
      };
  
      window.addEventListener("keydown", close);
  
      return () => {
        window.removeEventListener("keydown", close);
      };
    }, []);
  
  
    const getFollowUpHistory = () => {};
  
  
  
    const useStyles = makeStyles({
      table: {
        width: "70%",
      },
    });
    const classes = useStyles();
    const RowStyles = {
      background: "#F3F6F9",
      height: "20px",
      fontWeight: 600,
    };
    const formatDate = (dateString: any) => {
      const date = new Date(dateString);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear();
      const hour = date.getHours() % 12 || 12;
      const minute = date.getMinutes().toString().padStart(2, "0");
      const meridian = date.getHours() >= 12 ? "PM" : "AM";
  
      return `${day}-${month}-${year} ${hour}:${minute} ${meridian}`;
    };
  
    return (
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          closeAfterTransition
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <>
            <Fade in={open}>
              <Box sx={style}>
                <Stack style={{ position: "absolute", top: 0, right: 10 }}>
                  <IconButton onClick={handleClose}>
                    <CloseIcon />
                  </IconButton>
                </Stack>
                <Stack
                  marginTop={"20px"}
                  width={"100%"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      textAlign: "left",
                      width: "70%",
                      textDecoration: "underline",
                    }}
                  >
                    Log Details
                  </Typography>
                  <Table className={classes.table}>
                    <TableRow style={RowStyles}>
                      <TableCell variant="head" style={RowStyles}>
                        Created TS
                      </TableCell>
                      <TableCell>
                        {data
                          ? data.CreatedTS === null
                            ? ""
                            : formatDate(data.CreatedTS.slice(0, 16))
                          : ""}
                      </TableCell>
                    </TableRow>
                   
                    <TableRow style={RowStyles}>
                      <TableCell variant="head" style={RowStyles}>
                        Email Verified TS
                      </TableCell>
                      <TableCell>
                        {data
                          ? data.EmailVerifiedTS === null
                            ? ""
                            : formatDate(data.EmailVerifiedTS.slice(0, 16))
                          : ""}
                      </TableCell>
                    </TableRow>
          
            
                    {/* )} */}
  
                    <TableRow style={RowStyles}>
                      <TableCell variant="head" style={RowStyles}>
                        Bank Details TS
                      </TableCell>
                      <TableCell>
                        {data
                          ? data.BankDetailsTS === null
                            ? ""
                            : formatDate(data.BankDetailsTS.slice(0, 16))
                          : ""}
                      </TableCell>
                    </TableRow>
                   
                    <TableRow style={RowStyles}>
                      <TableCell variant="head" style={RowStyles}>
                        Completed TS
                      </TableCell>
                      <TableCell>
                        {data
                          ? data.CompletedTS === null
                            ? ""
                            : formatDate(data.CompletedTS.slice(0, 16))
                          : ""}
                      </TableCell>
                    </TableRow>
                    <TableRow style={RowStyles}>
                      <TableCell variant="head" style={RowStyles}>
                        Approved TS
                      </TableCell>
                      <TableCell>
                        {data
                          ? data.ApprovedTS === null
                            ? ""
                            : formatDate(data.ApprovedTS.slice(0, 16))
                          : ""}
                      </TableCell>
                    </TableRow>
                    <TableRow style={RowStyles}>
                      <TableCell variant="head" style={RowStyles}>
                        Rejected TS
                      </TableCell>
                      <TableCell>
                        {data
                          ? data.RejectedTS === null
                            ? ""
                            : formatDate(data.RejectedTS.slice(0, 16))
                          : ""}
                      </TableCell>
                    </TableRow>
                    <TableRow style={RowStyles}>
                      <TableCell variant="head" style={RowStyles}>
                        Modified TS
                      </TableCell>
                      <TableCell>
                        {data
                          ? data.ModifiedTS === null
                            ? ""
                            : formatDate(data.ModifiedTS.slice(0, 16))
                          : ""}
                      </TableCell>
                    </TableRow>
       
                    <TableRow style={RowStyles}>
                      <TableCell variant="head" style={RowStyles}>
                      Last Login TS 
                      </TableCell>
                      <TableCell>
                        {data
                          ? data.LastLoginTS === null
                            ? ""
                            : formatDate(data.LastLoginTS.slice(0, 16))
                          : ""}
                      </TableCell>
                    </TableRow>
                    <TableRow style={RowStyles}>
                      <TableCell variant="head" style={RowStyles}>
                        Final TS
                      </TableCell>
                      <TableCell>
                        {data
                          ? data.EsignTS === null
                            ? ""
                            : formatDate(data.EsignTS.slice(0, 16))
                          : ""}
                      </TableCell>
                    </TableRow>
                  </Table>
                </Stack>
              </Box>
            </Fade>
          </>
        </Modal>
      </div>
    );
  }
  