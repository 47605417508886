import * as React from "react";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { Formik } from "formik";
import {
  Stack,
  Fade,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Box,
  Button,
  IconButton,
  FormControl,
  Select,
  MenuItem,
  Tooltip,
  Grid,
} from "@mui/material";
import {
  AddBrokerageSchema,
  TariffAddScheme,
  UserAddScheme,
  UserEditScheme,
} from "../../../Schemas/Schemas";
import { IEditUser } from "../../../Utils/dtos";
import EditIcon from "@mui/icons-material/Edit";
import styles from "./TariffModal.module.scss";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(1),
  },

  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
    </DialogTitle>
  );
}

interface Props {
  open: boolean;
  handleClose: any;
  brokeragetemplateData?: any;
  handleSubmitData: any;
  data?: any;
}

export default function BrokerageModalpopup({
  open,
  handleClose,
  handleSubmitData,
  data,
  brokeragetemplateData,
}: Props) {
  const [type, setType] = React.useState("add");

  React.useEffect(() => {
    // setRole(brokeragetemplateData[0]);
  }, [brokeragetemplateData]);
  React.useEffect(() => {
    if (data) {
      setType("view");
    } else {
      setType("add");
    }
  }, [data]);

  const handleEditClick = () => {
    setType("edit");
  };

  const AddBrokerage = {
    name: data?.name ? data.name : "",
    description: data?.description ? data.description : "",
  };
  console.log("data", data);

  //   const type = data ? "view" : "add";

  return (
    <BootstrapDialog
      //   onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      onClose={(event: any) => {
        if (event.keyCode === 27) {
          handleClose();
        }
      }}
    >
      <BootstrapDialogTitle
        id="customized-dialog-title"
        onClose={() => {
          handleClose();
        }}
      >
        {type === "add"
          ? "Add Brokerage"
          : type === "edit"
          ? "Edit Brokerage"
          : "View Brokerage"}
        {/* {type === "view" && (
          <Tooltip title="Edit Details">
            <IconButton onClick={handleEditClick}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        )} */}
      </BootstrapDialogTitle>

      <Formik
        validationSchema={AddBrokerageSchema}
        initialValues={AddBrokerage}
        onSubmit={(values) => {
          //   console.log("values", values);
          handleSubmitData(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <>
            {/* {console.log("values", values, "errors", errors)} */}
            <form noValidate onSubmit={handleSubmit}>
              <DialogContent
                style={{
                  border: "1px solid #DDDCE1",
                  margin: "0px 20px 20px 20px",
                  borderRadius: "4px",
                  maxWidth: "unset",
                  width: "800px",
                }}
              >
                <Box component="form" noValidate>
                  <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 2 }}
                  >
                    <Grid item xs={6}>
                      <p className={styles.textfieldLabel}>Brokerage Name</p>
                      <TextField
                        id="name"
                        fullWidth
                        placeholder="Enter Brokerage Name"
                        variant="outlined"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name?.toUpperCase()}
                        error={touched.name && Boolean(errors.name)}
                        helperText={touched.name && errors.name?.toString()}
                        disabled={type === "view" ? true : false}
                        sx={{
                          input: {
                            color: "#344054",
                            fontSize: "14px",
                            height: "10px",
                          },
                        }}
                        inputProps={{ maxLength: 100 }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <p className={styles.textfieldLabel}>Description</p>
                      <TextField
                        id="description"
                        fullWidth
                        placeholder="Enter description"
                        variant="outlined"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.description}
                        disabled={type === "view" ? true : false}
                        error={
                          touched.description && Boolean(errors.description)
                        }
                        helperText={
                          touched.description && errors.description?.toString()
                        }
                        sx={{
                          input: {
                            color: "#344054",
                            fontSize: "14px",
                            height: "10px",
                          },
                        }}
                        inputProps={{ maxLength: 100 }}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Stack
                  justifyContent={"flex-start"}
                  direction={"row"}
                  margin={"15px 0px"}
                  gap={"10px"}
                >
                  {type !== "view" && (
                    <>
                      {values.name && values.description ? (
                        <Button
                          type="submit"
                          variant="contained"
                          sx={{
                            background: "#8B93FF",
                            boxShadow: "none",
                            textTransform: "none",
                            "&:hover": {
                              background: "#8B93FF",
                              boxShadow: "none",
                            },
                          }}
                        >
                          Save changes
                        </Button>
                      ) : (
                        <Button
                          type="submit"
                          variant="contained"
                          disabled
                          sx={{
                            background: "#8B93FF",
                            boxShadow: "none",
                            textTransform: "none",
                            "&:hover": {
                              background: "#8B93FF",
                              boxShadow: "none",
                            },
                          }}
                        >
                          Save changes
                        </Button>
                      )}
                    </>
                  )}
                  <Button
                    onClick={() => handleClose()}
                    sx={{
                      color: "#8B93FF",
                      boxShadow: "none",
                      textTransform: "none",
                      "&:hover": {
                        color: "#8B93FF",
                        background: "transparent",
                        boxShadow: "none",
                      },
                    }}
                  >
                    Cancel
                  </Button>
                </Stack>
              </DialogContent>
            </form>
          </>
        )}
      </Formik>
    </BootstrapDialog>
  );
}
