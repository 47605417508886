import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import DataTableComponent from "../../components/DataTableComponent/DataTableComponent";
import { Button, Icon, IconButton, Stack } from "@mui/material";

import {
  DeleteBrokerageRecordAPICall,
  DeletetariffRecordAPICall,
  GetBrokerageTemplatesAPICall,
  createBokerageDataAPICall,
  createTariffDataAPICall,
  editBrokerageDataAPICall,
  editTariffDataAPICall,
  getBrokeragedetailsAPICall,
  gettariffdetailsAPICall,
} from "../../Utils/APICalls";
import UserFormModalpopup from "../../components/Modals/UserFormModalpopup";
import {
  ICreateTariffData,
  IDeleteTariffRecord,
  IDeleteUser,
  IEditTariffData,
  IEditUser,
} from "../../Utils/dtos";
import ErrorSnackBar from "../../components/SnackBar/ErrorSnackBar";
import SucessSnackBar from "../../components/SnackBar/SuccessSnackBar";
import VisibilityIcon from "@mui/icons-material/Visibility";
import TariffModalpopup from "../../components/Modals/TariffModal/TariffModel";
import TariffDeleteModal from "../../components/Modals/TariffModal/TariffDeleteModal";
import classes from "./Tariff.module.scss";
import SearchIcon from "@mui/icons-material/Search";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import DeleteIcon from "../../assets/Images/userdeleteicon.svg";
import EditIcon from "../../assets/Images/userediticon.svg";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import { confirmAlert } from "react-confirm-alert";
import BrokerageModalpopup from "../../components/Modals/TariffModal/BrokerageModal";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";

const titletextStyle = {
  fontSize: "14px",
  fontWeight: "bold",
  color: "#0F1419",
  cursor: "pointer",
};
const contenttextStyle = { fontSize: "12px", color: "#555555" };

export default function TariffPage() {
  const columnsBrokerage = [
    {
      name: <b style={titletextStyle}>Sr No</b>,
      selector: "srno",
      sortable: true,
      width: "100px",
      cell: (row: any, index: any) => (
        <b style={contenttextStyle}>{index + 1}</b>
      ),
    },
    {
      name: <b style={titletextStyle}>Name</b>,
      selector: "name",
      sortable: true,
      cell: (row: any) => <b style={contenttextStyle}>{row.name}</b>,
    },
    {
      name: <b style={titletextStyle}>Description</b>,
      selector: "description",
      sortable: true,
      cell: (row: any) => <b style={contenttextStyle}>{row.description}</b>,
    },
    {
      name: <b style={titletextStyle}>Created</b>,
      selector: "created_ts",
      sortable: true,

      cell: (row: any) => (
        <div>
          <b style={contenttextStyle}>{row.created_ts?.slice(0, 10)}</b>
        </div>
      ),
    },

    {
      name: <b style={titletextStyle}>Actions</b>,
      selector: "spamReportRate",
      sortable: false,
      allowOverflow: true,
      button: true,
      width: "100px",
      cell: (row: any) => (
        <b style={contenttextStyle}>
          <Stack direction={"row"} gap={"10px"}>
            {/* <img
              src={EditIcon}
          
            /> */}
            <RemoveRedEyeOutlinedIcon
              style={{ cursor: "pointer" }}
              onClick={() => {
                handleEditBrokerage(row);
              }}
            />
            <img
              src={DeleteIcon}
              style={{ cursor: "pointer" }}
              onClick={() => {
                handleDeleteBrokerage(row);
              }}
            />
          </Stack>
        </b>
      ),
    },
  ];
  const columns = [
    {
      name: <b style={titletextStyle}>Sr No</b>,
      selector: "srno",
      sortable: true,
      width: "100px",
      cell: (row: any, index: any) => (
        <b style={contenttextStyle}>{index + 1}</b>
      ),
    },
    {
      name: <b style={titletextStyle}>Name</b>,
      selector: "name",
      sortable: true,
      cell: (row: any) => <b style={contenttextStyle}>{row.partner_name}</b>,
    },
    {
      name: <b style={titletextStyle}>Partner Id</b>,
      selector: "partnerid",
      sortable: true,
      cell: (row: any) => <b style={contenttextStyle}>{row.partner_id}</b>,
    },
    {
      name: <b style={titletextStyle}>Brokerage Template</b>,
      selector: "brokerageTemplate",
      sortable: true,
      cell: (row: any) => (
        <div>
          <b style={contenttextStyle}>{row.brokerage_template_name}</b>
        </div>
      ),
    },

    {
      name: <b style={titletextStyle}>Actions</b>,
      selector: "spamReportRate",
      sortable: false,
      allowOverflow: true,
      button: true,
      width: "100px",
      cell: (row: any) => (
        <b style={contenttextStyle}>
          <Stack direction={"row"} gap={"10px"}>
            <RemoveRedEyeOutlinedIcon
              style={{ cursor: "pointer" }}
              onClick={() => {
                handleEdit(row);
              }}
            />
            <img
              src={DeleteIcon}
              style={{ cursor: "pointer" }}
              onClick={() => {
                handleDelete(row);
              }}
            />
          </Stack>
        </b>
      ),
    },
  ];

  const [data, setdata] = useState<any>([]);
  const [dataBrokerages, setdataBrokerages] = useState<any>([]);
  const [loading, setloading] = useState(false);
  const [tariffAddErrorMessage, setTariffAdderrorMessage] = useState("");
  const [tariffId, setTariffId] = useState("");
  const [brokerageID, setBrokerageID] = useState("");
  const [openTariffModal, setopenTariffModal] = useState({
    status: false,
    deleteStatus: false,
    data: {},
    user_id: "",
  });
  const [openBrokerageModal, setopenBrokerageModal] = useState({
    status: false,
    deleteStatus: false,
    data: {},
    user_id: "",
  });
  const [message, setmessage] = useState("");
  const [addMessage, setAddUserMessage] = useState("");
  const [BrokerageTemplateData, setBrokerageTemplateData] = useState([]);
  const [search, setSearch] = useState("");
  const [tariffTotalRecords, setTariffTotalRecords] = useState("");
  const [brokerageTotalRecords, setBrokerageTotalRecords] = useState("");

  const [tab, setTab] = useState(1);
  function activeTab(tabNumber: any) {
    setTab(tabNumber);
  }

  useEffect(() => {
    getTariff();
    GetBrokerages();
  }, []);

  const GetBrokerages = () => {
    getBrokeragedetailsAPICall()
      .then((response: any) => {
        setBrokerageTemplateData(response.data.data.data);
        setdataBrokerages(response.data.data.data);
        setBrokerageTotalRecords(response.data.data.total_count);
      })
      .catch((error: any) => {});
  };

  const handleEditBrokerage = (bdata: any) => {
    setBrokerageID(bdata._id);
    let obj: any = {
      name: bdata.name,
      description: bdata.description,
    };
    // console.log(obj);
    handleBrokerageEditModalOpen(obj);
  };
  const handleDeleteBrokerage = (bdata: any) => {
    let obj: any = {
      brokerage_template_id: bdata._id,
    };
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="confitmalertUI">
            <DeleteOutlineRoundedIcon />
            <p>Are you sure you want to delete</p>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "20px",
              }}
            >
              <button
                onClick={() => {
                  onClose();
                }}
                className="confitmbutton"
              >
                No
              </button>
              <button
                onClick={() => {
                  onClose();
                  DeleteBrokerageRecordAPICall(obj)
                    .then((response: any) => {
                      // console.log(response.data.message);

                      GetBrokerages();
                      const responseData = response.data;

                      setmessage(response.data.message);
                      setTimeout(() => {
                        setmessage("");
                      }, 5000);
                    })
                    .catch((error) => {
                      // console.log("error", error.response.data.error.message);
                      if (error.response.data.message) {
                        setTariffAdderrorMessage(error.response.data.message);
                        setTimeout(() => {
                          setTariffAdderrorMessage("");
                        }, 5000);
                      }
                    });
                }}
                className="confitmbutton"
              >
                Yes
              </button>
            </div>
          </div>
        );
      },
    });
  };

  const getTariff = () => {
    gettariffdetailsAPICall()
      .then((response: any) => {
        // console.log(response.data.data.tariff_list);
        setdata(response.data.data.data);
        setTariffTotalRecords(response.data.data.total_count);
      })
      .catch((error) => {});
  };
  const handleEdit = (data: any) => {
    setTariffId(data._id);
    let obj: any = {
      partnerName: data.partner_name,
      partnerId: data.partner_id,
      equityDelivery: data.brokerage_charges?.equity_delivery,
      equityIntraday: data.brokerage_charges?.equity_intraday,
      futures: data.brokerage_charges?.futures,
      options: data.brokerage_charges?.options,
      brokerageTemplate: data.brokerage_template_name
        ? BrokerageTemplateData.filter(
            (a: any) => a.name === data.brokerage_template_name
          )[0]
        : "",
    };
    // console.log(obj);
    handleUserEditModalOpen(obj);
  };
  const handleDelete = (data: any) => {
    // handleDeleteModalPopup(data);

    let obj: IDeleteTariffRecord = {
      tariff_id: data._id,
    };
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="confitmalertUI">
            <DeleteOutlineRoundedIcon />
            <p>Are you sure you want to delete</p>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "20px",
              }}
            >
              <button
                onClick={() => {
                  onClose();
                }}
                className="confitmbutton"
              >
                No
              </button>
              <button
                onClick={() => {
                  onClose();
                  DeletetariffRecordAPICall(obj)
                    .then((response: any) => {
                      // console.log(response.data.message);

                      getTariff();
                      const responseData = response.data;

                      setmessage(response.data.message);
                      setTimeout(() => {
                        setmessage("");
                      }, 5000);
                    })
                    .catch((error) => {
                      // console.log("error", error.response.data.error.message);
                      if (error.response.data.error.message) {
                        setTariffAdderrorMessage(
                          error.response.data.error.message
                        );
                        setTimeout(() => {
                          setTariffAdderrorMessage("");
                        }, 5000);
                      }
                    });
                }}
                className="confitmbutton"
              >
                Yes
              </button>
            </div>
          </div>
        );
      },
    });
  };

  // User Modal Popup Start

  const handleUserModalOpen = () => {
    setopenTariffModal({
      status: true,
      deleteStatus: false,
      data: {},
      user_id: "",
    });
  };
  const handleBrokerageModalOpen = () => {
    setopenBrokerageModal({
      status: true,
      deleteStatus: false,
      data: {},
      user_id: "",
    });
  };
  const handleAddTariffOpen = () => {
    setopenTariffModal({
      status: true,
      deleteStatus: false,
      data: {},
      user_id: "",
    });
  };
  const handleUserEditModalOpen = (data: IEditUser) => {
    setopenTariffModal({
      status: true,
      deleteStatus: false,
      data: {
        ...data,
      },
      user_id: "",
    });
  };
  const handleBrokerageEditModalOpen = (data: any) => {
    setopenBrokerageModal({
      status: true,
      deleteStatus: false,
      data: {
        ...data,
      },
      user_id: "",
    });
  };
  const handleBrokerageModalClose = () => {
    setopenBrokerageModal({
      status: false,
      deleteStatus: false,
      data: {},
      user_id: "",
    });
  };
  const handletariffModalClose = () => {
    setopenTariffModal({
      status: false,
      deleteStatus: false,
      data: {},
      user_id: "",
    });
  };
  const handletariffModalSubmit = (data: any) => {
    if (Object.keys(openTariffModal.data).length) {
      console.log("dataedit", data);
      let obj: IEditTariffData = {
        partner_name: data.partnerName,
        partner_id: data.partnerId,
        equity_intraday: data.equityIntraday,
        equity_delivery: data.equityDelivery,
        futures: data.futures,
        options: data.options,
        brokerage_template_name: data.brokerageTemplate.name,
        tariff_id: tariffId,
      };
      editTariffDataAPICall(obj)
        .then((response: any) => {
          // console.log(response.data.message);
          handletariffModalClose();
          getTariff();
          const responseData = response.data;

          setmessage("Tariff created successfully.");
          setTimeout(() => {
            setmessage("");
          }, 5000);
        })
        .catch((error) => {
          setTariffAdderrorMessage("Error Editing The data");
          setTimeout(() => {
            setTariffAdderrorMessage("");
          }, 5000);
        });
      // handletariffModalClose();
    } else {
      let obj: ICreateTariffData = {
        partner_name: data.partnerName,
        partner_id: data.partnerId,
        equity_intraday: data.equityIntraday,
        equity_delivery: data.equityDelivery,
        futures: data.futures,
        options: data.options,
        brokerage_template_name: data.brokerageTemplate.name,
      };
      createTariffDataAPICall(obj)
        .then((response: any) => {
          // console.log(response.data.message);
          handletariffModalClose();
          getTariff();
          const responseData = response.data;

          setmessage("Tariff created successfully.");
          setTimeout(() => {
            setmessage("");
          }, 5000);
        })
        .catch((error) => {
          // console.log(error.response.data.error.message);
          if (error.response.data.message) {
            setTariffAdderrorMessage(error.response.data.message);
            setTimeout(() => {
              setTariffAdderrorMessage("");
            }, 5000);
          }
        });
    }
  };
  const handleBrokerageModalSubmit = (data: any) => {
    if (Object.keys(openBrokerageModal.data).length) {
      console.log("dataedit", data);
      let obj: any = {
        name: data.name?.toUpperCase(),
        description: data.description,

        brokerage_template_id: brokerageID,
      };
      editBrokerageDataAPICall(obj)
        .then((response: any) => {
          // console.log(response.data.message);
          handleBrokerageModalClose();
          GetBrokerages();
          const responseData = response.data;

          setmessage(responseData.message);
          setTimeout(() => {
            setmessage("");
          }, 5000);
        })
        .catch((error) => {
          setTariffAdderrorMessage("Error Editing The data");
          setTimeout(() => {
            setTariffAdderrorMessage("");
          }, 5000);
        });
      // handletariffModalClose();
    } else {
      let obj: any = {
        name: data.name.toUpperCase(),
        description: data.description,
      };
      createBokerageDataAPICall(obj)
        .then((response: any) => {
          // console.log(response.data.message);
          handleBrokerageModalClose();
          GetBrokerages();
          const responseData = response.data;

          setmessage("Brokerage template created successfully.");
          setTimeout(() => {
            setmessage("");
          }, 5000);
        })
        .catch((error) => {
          // console.log(error.response.data.error.message);
          if (error.response.data.message) {
            setTariffAdderrorMessage(error.response.data.message);
            setTimeout(() => {
              setTariffAdderrorMessage("");
            }, 5000);
          }
        });
    }
  };

  const handleDeleteTariff = (data: IDeleteUser) => {
    // console.log("deletedata", data);
    handletariffModalClose();
  };

  const handleSearchChange = (searchData: string) => {
    // console.log("searchData", searchData);
    setSearch(searchData);
  };
  const handlePageChange = (value: any, page: any) => {
    // console.log(page);
  };

  const filterdata = data?.filter((a: any) => {
    return (
      a.partner_name.toLowerCase().includes(search.toLowerCase()) ||
      a.partner_id.toLowerCase().includes(search.toLowerCase()) ||
      a.brokerage_template_name.toLowerCase().includes(search.toLowerCase())
    );
  });
  const filterdataBrokerages = dataBrokerages?.filter((a: any) => {
    return (
      a.name.toLowerCase().includes(search.toLowerCase()) ||
      a.description.toLowerCase().includes(search.toLowerCase())
    );
  });

  // User Modal Popup End
  return (
    <div style={{ marginTop: "6px", width: "100%" }}>
      <Helmet>
        <title>Tariff </title>
        <meta name="Tariff" content="Tariff" />
      </Helmet>
      <ErrorSnackBar message={tariffAddErrorMessage} />
      <SucessSnackBar message={message} />
      <div className={classes.tabsWrapper}>
        <div className={classes.tabsCatagory}>
          <div
            className={classes.tabsItem}
            onClick={() => {
              activeTab(1);
              setSearch("");
              GetBrokerages();
            }}
            style={{
              backgroundColor: tab === 1 ? "#8B93FF" : "",
              color: tab == 1 ? "white" : "",
            }}
          >
            Tariff
          </div>
          <div
            className={classes.tabsItem}
            onClick={() => {
              activeTab(2);
              setSearch("");
              GetBrokerages();
            }}
            style={{
              backgroundColor: tab === 2 ? "#8B93FF" : "",
              color: tab == 2 ? "white" : "",
            }}
          >
            Brokerages
          </div>
        </div>
      </div>
      <>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "space-between",
            position: "relative",
          }}
        >
          <div>
            {tab === 1 ? (
              <Button
                variant="contained"
                onClick={handleUserModalOpen}
                sx={{
                  background: "#8B93FF",
                  boxShadow: "none",
                  textTransform: "none",
                  "&:hover": {
                    background: "#8B93FF",
                    boxShadow: "none",
                  },
                }}
                startIcon={<AddBoxOutlinedIcon />}
              >
                Add Tariff
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={handleBrokerageModalOpen}
                sx={{
                  background: "#8B93FF",
                  boxShadow: "none",
                  textTransform: "none",
                  "&:hover": {
                    background: "#8B93FF",
                    boxShadow: "none",
                  },
                }}
                startIcon={<AddBoxOutlinedIcon />}
              >
                Add Brokerage
              </Button>
            )}
          </div>

          <div className={classes.searchInput}>
            <div className={classes.searchIcon}>
              <SearchIcon style={{ color: "#8B93FF" }} />
            </div>
            <input
              type="text"
              className={classes.inputBox}
              placeholder="Search...."
              value={search}
              onChange={(e) => {
                handleSearchChange(e.target.value);
              }}
            />
          </div>
        </div>

        {tab === 1 ? (
          <DataTableComponent
            columns={columns}
            data={filterdata}
            loading={loading}
            pagination={false}
            handlePageChange={handlePageChange}
            recordsCount={tariffTotalRecords}
          />
        ) : (
          <DataTableComponent
            columns={columnsBrokerage}
            data={filterdataBrokerages}
            loading={loading}
            pagination={false}
            handlePageChange={handlePageChange}
            recordsCount={brokerageTotalRecords}
          />
        )}

        {/* <DataTableComponent
          columns={columns}
          data={filterdata}
          loading={loading}
          pagination={false}
          handlePageChange={handlePageChange}
        /> */}
      </>
      {openTariffModal.status && (
        <TariffModalpopup
          open={openTariffModal.status}
          handleClose={handletariffModalClose}
          handleSubmitData={handletariffModalSubmit}
          data={
            Object.keys(openTariffModal.data).length
              ? openTariffModal.data
              : undefined
          }
          brokeragetemplateData={BrokerageTemplateData}
        />
      )}
      {openBrokerageModal.status && (
        <BrokerageModalpopup
          open={openBrokerageModal.status}
          handleClose={handleBrokerageModalClose}
          handleSubmitData={handleBrokerageModalSubmit}
          data={
            Object.keys(openBrokerageModal.data).length
              ? openBrokerageModal.data
              : undefined
          }
          brokeragetemplateData={BrokerageTemplateData}
        />
      )}
      {openTariffModal.deleteStatus && (
        <TariffDeleteModal
          open={openTariffModal.deleteStatus}
          handleClose={handletariffModalClose}
          handleSubmitData={handleDeleteTariff}
          data={openTariffModal.user_id}
        />
      )}
    </div>
  );
}
