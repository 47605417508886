import {
  Box,
  Button,
  Fade,
  IconButton,
  Modal,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DataTableComponent from "../../DataTableComponent/DataTableComponent";
import DataTable from "react-data-table-component";
import EditIcon from "@mui/icons-material/Edit";
import FollowUpDataTableComponent from "../../../pages/Followups/FollowUpsDataTable";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";

import {
  GetFollowUpHistoryAPICall,
  addFollowUpDateAPICall,
} from "../../../Utils/APICalls";

import { IAddFollowUpDate } from "../../../Utils/dtos";

interface Props {
  open: boolean;
  handleClose: any;
  handleSubmitData: any;
  data: any;
  followDate?: any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  height: "max-content",
  bgcolor: "background.paper",
  borderRadius: "20px",
  boxShadow: 24,
  p: 2,
};

const titletextStyle = {
  fontSize: "14px",
  fontWeight: "bold",
  color: "#8C8B8F",
};
const contenttextStyle = { fontSize: "12px" };

export default function ClientLogsModal({
  open,
  handleClose,
  handleSubmitData,
  data,
  followDate,
}: Props) {
  const [loading, setLoading] = useState(false);
  const [age, setAge] = React.useState("");
  const [openFollowUp, setOpenFollowUp] = useState(false);
  const [openDateModal, setOpenDateModal] = useState(false);
  const [editHistoryModal, setEditHistoryModal] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [followUpDate, setFollowUpDate] = useState<any>("");
  const [historyRowData, setHistoryRowData] = useState({
    id: "",
    Status: "",
    Remarks: "",
    UserId: "",
    UserName: "",
  });
  const today = new Date();

  useEffect(() => {
    const close = (e: any) => {
      if (e.keyCode === 27) {
        handleClose();
      }
    };

    window.addEventListener("keydown", close);

    return () => {
      window.removeEventListener("keydown", close);
    };
  }, []);

  const useStyles = makeStyles({
    table: {
      width: "90%",
    },
  });
  const classes = useStyles();
  const RowStyles = {
    background: "rgb(243, 244, 255)",
    height: "20px",
    fontWeight: 600,
    border: "1px solid grey",
  };
  const formatDate = (dateString: any) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    const hour = date.getHours() % 12 || 12;
    const minute = date.getMinutes().toString().padStart(2, "0");
    const meridian = date.getHours() >= 12 ? "PM" : "AM";

    return `${day}-${month}-${year} ${hour}:${minute} ${meridian}`;
  };
  function convertUTCToIST(utcDateStr: any) {
    // Create a new Date object from the UTC date string
    const utcDate = new Date(utcDateStr);

    // Get the UTC time in milliseconds since January 1, 1970
    const utcTime = utcDate.getTime();

    // Add the offset for IST (5 hours 30 minutes) in milliseconds
    const istOffset = 5.5 * 60 * 60 * 1000;

    // Create a new date object with IST time
    const istDate = new Date(utcTime + istOffset);

    // Format the IST date to match the required format
    const year = istDate.getFullYear();
    const month = String(istDate.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(istDate.getDate()).padStart(2, "0");
    const hours = String(istDate.getHours()).padStart(2, "0");
    const minutes = String(istDate.getMinutes()).padStart(2, "0");
    const seconds = String(istDate.getSeconds()).padStart(2, "0");
    const milliseconds = String(istDate.getMilliseconds()).padStart(3, "0");

    // Return the formatted date string in the desired format
    return convertToFormattedDateTimetoTextField(istDate);
  }
  const convertToFormattedDateTimetoTextField = (dateTimeString: any) => {
    const dateTime = new Date(dateTimeString);

    const day = dateTime.getDate();
    const month = dateTime.getMonth() + 1; // Months are zero-indexed
    const year = dateTime.getFullYear();

    let hours = dateTime.getHours();
    let minutes = dateTime.getMinutes();
    const period = hours >= 12 ? "PM" : "AM";

    // Convert to 12-hour format
    if (hours > 12) {
      hours -= 12;
    } else if (hours === 0) {
      hours = 12;
    }

    return `${day < 10 ? "0" : ""}${day}-${
      month < 10 ? "0" : ""
    }${month}-${year} ${hours}:${minutes < 10 ? "0" : ""}${minutes} ${period}`;
  };

  

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        closeAfterTransition
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <>
          <Fade in={open}>
            <Box sx={style}>
              <Stack style={{ position: "absolute", top: 10, right: 10 }}>
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Stack>
              <Stack
                marginTop={"20px"}
                width={"100%"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Typography
                  variant="h6"
                  sx={{
                    textAlign: "left",
                    width: "90%",
                    marginBottom: "10px",
                  }}
                >
                  Log Details
                </Typography>
                <Table className={classes.table}>
                  <TableRow style={RowStyles}>
                    <TableCell variant="head" style={RowStyles}>
                      Created TS
                    </TableCell>
                    <TableCell style={{ ...RowStyles, fontWeight: 500 }}>
                      {data
                        ? data.CreatedTS === null
                          ? ""
                          : data.CreatedTS
                          ? formatDate(data.CreatedTS.slice(0, 16))
                          : "-"
                        : ""}
                    </TableCell>
                  </TableRow>
                  <TableRow style={RowStyles}>
                    <TableCell variant="head" style={RowStyles}>
                      Mobile Verified TS
                    </TableCell>
                    <TableCell style={{ ...RowStyles, fontWeight: 500 }}>
                      {data
                        ? data.MobileVerifiedTS === null
                          ? ""
                          : data.MobileVerifiedTS
                          ? formatDate(data.MobileVerifiedTS.slice(0, 16))
                          : "-"
                        : ""}
                    </TableCell>
                  </TableRow>
                  <TableRow style={RowStyles}>
                    <TableCell variant="head" style={RowStyles}>
                      Email Verified TS
                    </TableCell>
                    <TableCell style={{ ...RowStyles, fontWeight: 500 }}>
                      {data
                        ? data.EmailVerifiedTS === null
                          ? ""
                          : data.EmailVerifiedTS
                          ? formatDate(data.EmailVerifiedTS?.slice(0, 16))
                          : "-"
                        : ""}
                    </TableCell>
                  </TableRow>
                  {data.DigiLockerTS === null && (
                    <TableRow style={RowStyles}>
                      <TableCell variant="head" style={RowStyles}>
                        KRA TS
                      </TableCell>
                      <TableCell style={{ ...RowStyles, fontWeight: 500 }}>
                        {data
                          ? data.KraFetchedTS === null
                            ? ""
                            : data.KraFetchedTS
                            ? formatDate(data.KraFetchedTS?.slice(0, 16))
                            : "-"
                          : ""}
                      </TableCell>
                    </TableRow>
                  )}

                  {data.KraFetchedTS === null && (
                    <TableRow style={RowStyles}>
                      <TableCell variant="head" style={RowStyles}>
                        Digilocker TS
                      </TableCell>
                      <TableCell style={{ ...RowStyles, fontWeight: 500 }}>
                        {data
                          ? data.DigiLockerTS === null
                            ? ""
                            : data.DigiLockerTS
                            ? formatDate(data.DigiLockerTS?.slice(0, 16))
                            : "-"
                          : ""}
                      </TableCell>
                    </TableRow>
                  )}

                  <TableRow style={RowStyles}>
                    <TableCell variant="head" style={RowStyles}>
                      Bank Details TS
                    </TableCell>
                    <TableCell style={{ ...RowStyles, fontWeight: 500 }}>
                      {data
                        ? data.BankDetailsTS === null
                          ? ""
                          : data.BankDetailsTS
                          ? formatDate(data.BankDetailsTS?.slice(0, 16))
                          : "-"
                        : ""}
                    </TableCell>
                  </TableRow>
                  <TableRow style={RowStyles}>
                    <TableCell variant="head" style={RowStyles}>
                      Esign TS
                    </TableCell>
                    <TableCell style={{ ...RowStyles, fontWeight: 500 }}>
                      {data
                        ? data.EmailVerifiedTS === null
                          ? ""
                          : data.EmailVerifiedTS
                          ? formatDate(data.EmailVerifiedTS?.slice(0, 16))
                          : "-"
                        : ""}
                    </TableCell>
                  </TableRow>
                  <TableRow style={RowStyles}>
                    <TableCell variant="head" style={RowStyles}>
                      Completed TS
                    </TableCell>
                    <TableCell style={{ ...RowStyles, fontWeight: 500 }}>
                      {data
                        ? data.CompletedTS === null
                          ? ""
                          : data.CompletedTS
                          ? formatDate(data.CompletedTS?.slice(0, 16))
                          : "-"
                        : ""}
                    </TableCell>
                  </TableRow>
                  <TableRow style={RowStyles}>
                    <TableCell variant="head" style={RowStyles}>
                      Approved TS
                    </TableCell>
                    <TableCell style={{ ...RowStyles, fontWeight: 500 }}>
                      {data
                        ? data.ApprovedTS === null
                          ? ""
                          : data.ApprovedTS
                          ? convertUTCToIST(data.ApprovedTS)
                          : "-"
                        : ""}
                    </TableCell>
                  </TableRow>
                  <TableRow style={RowStyles}>
                    <TableCell variant="head" style={RowStyles}>
                      Rejected TS
                    </TableCell>
                    <TableCell style={{ ...RowStyles, fontWeight: 500 }}>
                      {data
                        ? data.RejectedTS === null
                          ? ""
                          : data.RejectedTS
                          ? convertUTCToIST(data.RejectedTS)
                          : "-"
                        : ""}
                    </TableCell>
                  </TableRow>
                  <TableRow style={RowStyles}>
                    <TableCell variant="head" style={RowStyles}>
                      Modified TS
                    </TableCell>
                    <TableCell style={{ ...RowStyles, fontWeight: 500 }}>
                      {data
                        ? data.ModifiedTS === null
                          ? ""
                          : data.ModifiedTS
                          ? formatDate(data.ModifiedTS?.slice(0, 16))
                          : "-"
                        : ""}
                    </TableCell>
                  </TableRow>
                  <TableRow style={RowStyles}>
                    <TableCell variant="head" style={RowStyles}>
                      KRA Uploaded TS
                    </TableCell>
                    <TableCell style={{ ...RowStyles, fontWeight: 500 }}>
                      {data
                        ? data.KrauploadedTS === null
                          ? ""
                          : data.KrauploadedTS
                          ? formatDate(data.KrauploadedTS?.slice(0, 16))
                          : "-"
                        : ""}
                    </TableCell>
                  </TableRow>
                  {/* <TableRow style={RowStyles}>
                    <TableCell variant="head" style={RowStyles}>
                      Last Login TS
                    </TableCell>
                    <TableCell style={{ ...RowStyles, fontWeight: 500 }}>
                      {data
                        ? data.LastLoginTS === null
                          ? ""
                          : data.LastLoginTS
                          ? formatDate(data.LastLoginTS?.slice(0, 16))
                          : "-"
                        : ""}
                    </TableCell>
                  </TableRow> */}
                </Table>
              </Stack>
            </Box>
          </Fade>
        </>
      </Modal>
    </div>
  );
}
