import React, { useState } from "react";
import { Formik, Form, useFormikContext } from "formik";
import * as Yup from "yup";
import {
  Box,
  Button,
  Fade,
  IconButton,
  Modal,
  Stack,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30%",
  height: "30%",
  bgcolor: "background.paper",
  borderRadius: "20px",
  boxShadow: 24,
  p: 2,
};

const schema = Yup.object().shape({
  deleted_reason: Yup.string().required("Reason for Delete is Required"),
});

interface props {
  open: any;
  handleDeleteClient: any;
  handleCloseDeletepopUp: any;
}

export default function DeleteClient({
  open,
  handleDeleteClient,
  handleCloseDeletepopUp,
}: props) {
  const [stageName, setStageName] = useState<any>();

  const InitialValues = {
    deleted_reason: "",
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        closeAfterTransition
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <IconButton
              onClick={handleCloseDeletepopUp}
              style={{ position: "absolute", top: 0, right: 10 }}
            >
              <CloseIcon />
            </IconButton>

            <Formik
              validationSchema={schema}
              initialValues={InitialValues}
              onSubmit={(values: any) => {
                //   console.log("values", values);
                handleDeleteClient(values);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,

                setFieldValue,
              }) => {
                // console.log("errors", errors)
                return (
                  <form noValidate onSubmit={handleSubmit}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      <div>
                        <TextField
                          value={values.deleted_reason}
                          name="deleted_reason"
                          onChange={handleChange}
                          label="Reason for Delete"
                          size="small"
                          sx={{ width: "300px", marginTop:"20px" }}
                          error={
                            touched.deleted_reason &&
                            Boolean(errors.deleted_reason)
                          }
                        />
                      </div>

                      <div>
                        <Button
                          variant="outlined"
                          type="submit"
                          sx={{ marginTop: "20px" }}
                        >
                          Delete Client
                        </Button>
                      </div>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
