import * as React from "react";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { Formik } from "formik";
import {
  Stack,
  Fade,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  ThemeProvider,
  Container,
  FormHelperText,
  Typography,
  Box,
  Button,
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
} from "@mui/material";

import { TodayFollowUpScheme } from "../../../Schemas/Schemas";
import { AddFollowUp } from "../../../Utils/InitialValues";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import dayjs, { Dayjs } from "dayjs";
// import { styled } from '@mui/system';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

interface Props {
  openModal: boolean;
  handleClose: any;
  data: any;
}

export default function CMRViewPanNumbersModal({
  openModal,
  handleClose,
  data,
}: Props) {
  const blue = {
    100: "#DAECFF",
    200: "#b6daff",
    400: "#3399FF",
    500: "#007FFF",
    600: "#0072E5",
    900: "#003A75",
  };

  const grey = {
    50: "#f6f8fa",
    100: "#eaeef2",
    200: "#d0d7de",
    300: "#afb8c1",
    400: "#8c959f",
    500: "#6e7781",
    600: "#57606a",
    700: "#424a53",
    800: "#32383f",
    900: "#24292f",
  };

  return (
    <BootstrapDialog
      onClose={(event: any) => {
        if (event.keyCode === 27) {
          handleClose();
        }
      }}
      aria-labelledby="customized-dialog-title"
      open={openModal}
    >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        Pan Numbers
      </BootstrapDialogTitle>
      <Stack padding={"20px 20px"}>
        <Stack direction={"row"} alignItems={"center"}>
          <Typography sx={{ width: "60px", fontSize: "13px", fontWeight: 600 }}>
            Sl NO
          </Typography>
          <Typography
            sx={{
              padding: "5px 0",
              width: "150px",
              fontSize: "13px",
              fontWeight: 600,
            }}
          >
            {" "}
            PAN
          </Typography>
          <Typography
            sx={{
              padding: "5px 0",
              width: "150px",
              fontSize: "13px",
              fontWeight: 600,
            }}
          >
            {" "}
            STATUS
          </Typography>
        </Stack>
        <Divider />
        {data.map((a: any, index: any) => (
          <>
            <Stack direction={"row"} alignItems={"center"}>
              <Typography sx={{ width: "60px" }}>{index + 1}</Typography>
              <Typography sx={{ padding: "5px 0", width: "150px" }}>
                {" "}
                {a.pan_num}
              </Typography>
              <Typography sx={{ padding: "5px 0", width: "150px" }}>
                {" "}
                {a.status}
              </Typography>
            </Stack>
            <Divider />
          </>
        ))}
      </Stack>
    </BootstrapDialog>
  );
}
