import signUp from "../../assets/Images/loginimage.svg";
import classes from "./ChangePassword.module.scss";
import { useFormik } from "formik";
import { confirmpwSchema, signUpSchema } from "../../Schemas/Schemas";
import Button from "@mui/joy/Button";
import Input from "@mui/joy/Input";
import { Link, useNavigate } from "react-router-dom";
import { changePasswordAPICall } from "../../Utils/APICalls";
import { IChangePassword } from "../../Utils/dtos";
import { useState } from "react";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import SucessSnackBar from "../../components/SnackBar/SuccessSnackBar";

const ChangePassword = () => {
  const navigate = useNavigate();
  const initialValues = {
    password: "",
    // confirm_password: "",
  };
  const [loading, setloading] = useState(false);
  const [successmessage, setsuccessmessage] = useState("");
  const [showAccountNumber, setShowAccountNumber] = useState(false);
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema: confirmpwSchema,
      onSubmit: (values, action) => {
        //  console.log("values", values);
        // action.resetForm();

        const requestBody: any = {
          user_details: sessionStorage.getItem("userDetails"),
          new_password: values.password,
          // new_password: values.password
        };
        const Token = sessionStorage.getItem("ChangePwToken");
        const config = {
          headers: { Authorization: `Bearer ${Token}` },
        };
        setloading(true);
        changePasswordAPICall(requestBody)
          .then((res: any) => {
            // console.log(res.data);
            setloading(false);
            setsuccessmessage("Successfully Changed the Password");
            setTimeout(() => {
              setsuccessmessage("");
            }, 5000);
            navigate("/");
          })
          .catch((err) => {
            // console.log(err.data);
            setloading(false);
          });
      },
    });
  const handleClickShowAccountNumber = () =>
    setShowAccountNumber((show) => !show);

  const handleMouseDownAccountNumber = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  return (
    <>
      <div className={classes.container}>
        <div className={classes.modal}>
          <div className={classes.modalContainer}>
            <div className={classes.modalLeft}>
              <h1 className={classes.modalTitle}>Change Password</h1>
              <p className={classes.modalDesc}></p>
              <div className={classes.loginData}>
                <form onSubmit={handleSubmit}>
                  <div className={classes.inputBlock}>
                    <TextField
                      type={showAccountNumber ? "text" : "password"}
                      autoComplete="off"
                      name="password"
                      id="password"
                      placeholder="Enter New Password"
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleClickShowAccountNumber}
                              onMouseDown={handleMouseDownAccountNumber}
                              edge="end"
                            >
                              {showAccountNumber ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />

                    {errors.password && touched.password ? (
                      <p className={classes.formError}>{errors.password}</p>
                    ) : null}
                  </div>
                  <SucessSnackBar message={successmessage} />

                  {/* <div className={classes.inputBlock}>
                    <Input
                      type="password"
                      autoComplete="off"
                      name="confirm_password"
                      id="confirm_password"
                      size="lg"
                      placeholder="Confirm Password"
                      value={values.confirm_password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />

                    {errors.confirm_password && touched.confirm_password ? (
                      <p className={classes.formError}>
                        {errors.confirm_password}
                      </p>
                    ) : null}
                  </div> */}

                  <div className={classes.modalButtons}>
                    <Button
                      type="submit"
                      size="lg"
                      style={{ padding: "14px 20px", background: "#8B93FF" }}
                      loading={loading}
                    >
                      Reset Password
                    </Button>
                    <a href="#" className={classes.link}>
                      <Link
                        to={"/"}
                        style={{ textDecoration: "none", color: "#383838" }}
                      >
                        Back to Login?
                      </Link>
                    </a>
                  </div>
                </form>
                <p className={classes.signUp}>
                  Don't have an account?{" "}
                  <a href="#" className={classes.link}>
                    <Link to={"/"} style={{ textDecoration: "none" }}>
                      Sign Up now
                    </Link>
                  </a>
                </p>
              </div>
            </div>
            <div className={classes.modalRight}>
              <img src={signUp} alt="" className={classes.signupImage} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
