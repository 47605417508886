import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import {
  IClientDataBankUpdate,
  IDeleteClientBankUpdate,
  IRejectApplicationBankUpdate,
} from "../../../Utils/dtos";
import {
  Stack,
  Fade,
  Box,
  IconButton,
  Modal,
  Divider,
  Tooltip,
} from "@mui/material";
import Button from "@mui/joy/Button";
import { js2xml } from "xml-js";
import {
  ApproveApplicationBankUpdateAPICall,
  DeleteClientBankUpdateAPICall,
  GetClientDataBankUpdateAPICall,
  GetRekycStagesAPICall,
  RejectApplicationBankUpdateAPICall,
} from "../../../Utils/APICalls";
import Styles from "./BankUpdateDetailspopup.module.scss";
import Tabs from "../../Tabs/Tabs";
import ErrorSnackBar from "../../SnackBar/ErrorSnackBar";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteClient from "../DeleteClient/DeleteClient";
import SucessSnackBar from "../../SnackBar/SuccessSnackBar";
import BankUpdateBase64ToImage from "../../BankUpdateBase64/BankUpdateBase64";
import EmailMobileUpdateReject from "../RejectComponent/EmailMobileUpdateReject";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import VerticalTabs from "../../Tabs/TabsVeritical";
import BankUpdateReject from "../RejectComponent/BankUpdateReject";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "96%",
  height: "95%",
  bgcolor: "background.paper",
  borderRadius: "20px",
  boxShadow: 24,
  p: 2,
};

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

interface Props {
  open: boolean;
  handleClose: any;
  handleSubmitData: any;
  data: string;
  rejectedReason?: any;
  deletedReason?: any;
  selectedTabNew?: string;
  handlePermanentDeleteClient?: any;
  clientDeleted: boolean;
  stageName?: any;
  clientCompleted: boolean;
}

export default function BankUpdateDetailspopup({
  open,
  handleClose,
  data,
  handleSubmitData,
  rejectedReason,
  selectedTabNew,
  deletedReason,
  handlePermanentDeleteClient,
  clientDeleted,
  clientCompleted,
  stageName,
}: Props) {
  // const [open, setOpen] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [openDeletePopUp, setDeletePopUp] = useState(false);
  const [clientData, setClientData] = useState<any>();

  const [selectedTab, setselectedTab] = useState<string>("Esign");
  const [successMessage, setSuccessMessage] = useState("");
  const [tabLabels, setTabLabels] = useState(["Esign"]);
  const [docType, setDocType] = useState("e_sign_pdf");
  const [stagesData, setStagesData] = useState<any>([]);
  const [rejectLoading, setRejectLoading] = useState(false);
  const [approveLoading, setApproveLoading] = useState(false);

  useEffect(() => {
    getDetails();

    GetRekycStagesAPICall()
      .then((response: any) => {
        const responseData = response.data;
        const sortedStages = responseData.sort(
          (a: any, b: any) => a.Sequence - b.Sequence
        );

        setStagesData(sortedStages);
      })
      .catch((error: any) => {
        //  console.log("error", error);
      });
  }, []);

  useEffect(() => {
    sessionStorage.removeItem(`${data}_e_sign_pdf`);
  }, [handleClose]);

  // console.log(data);
  const isAdmin = sessionStorage.getItem("isAdmin");
  const getDetails = () => {
    const obj: any = {
      user_id: data,
    };
    GetClientDataBankUpdateAPICall(obj)
      .then((response: any) => {
        // console.log("responseclient", response.data.client_data);
        setClientData(response.data.data);
      })
      .catch((error: any) => {});
  };

  const handleRejectClient = (reject: any) => {
    //  console.log("data", reject);

    let obj: any = {
      user_id: data,
      rejected_reason: reject.reasonForRejection,
    };
    setRejectLoading(true);
    RejectApplicationBankUpdateAPICall(obj)
      .then((response: any) => {
        const responseData = response.data;
        // console.log("responseData", responseData);
        setRejectLoading(false);
        handleClose("Client Rejected");
      })
      .catch((error: any) => {
        setRejectLoading(false);
        if (error.response.data.message) {
          seterrorMessage(error.response.data.message);
          setTimeout(() => {
            seterrorMessage("");
          }, 5000);
        }
        // console.log("error", error);
      });
  };
  const handleApproveClient = () => {
    let obj: any = {
      user_id: data,
    };
    setApproveLoading(true);
    ApproveApplicationBankUpdateAPICall(obj)
      .then((response: any) => {
        const responseData = response.data;
        //  console.log("responseData", responseData);
        setApproveLoading(false);
        handleClose("Client Approved");
      })
      .catch((error: any) => {
        // console.log("error", error);
        setApproveLoading(false);
        if (error.response.data.message) {
          seterrorMessage(error.response.data.message);
          setTimeout(() => {
            seterrorMessage("");
          }, 5000);
        }
      });
  };

  const handleChangeTab = (tab: any) => {
    //  console.log(tab);
    setselectedTab(tab);

    if (tab === "Esign") {
      setDocType("e_sign_pdf");
    }
  };

  const handleOpenDeletePopUp = () => {
    setDeletePopUp(true);
  };
  const handleCloseDeletepopUp = () => {
    setDeletePopUp(false);
  };

  const handleDeleteClient = (dataDelete: any) => {
    let obj: any = {
      user_id: data,
      deleted_reason: dataDelete.deleted_reason,
    };
    DeleteClientBankUpdateAPICall(obj)
      .then((response: any) => {
        const responseData = response.data;
        // console.log("responseData", responseData);
        setDeletePopUp(false);
        handleClose("Deleted");
        setSuccessMessage("Client Deleted Successfully");
        setTimeout(() => {
          setSuccessMessage("");
        }, 5000);
      })
      .catch((error: any) => {
        // console.log("error", error);
        setDeletePopUp(false);
        //  handleClose("Deleted");
        if (error.response.data.error.message) {
          seterrorMessage(error.response.data.error.message);
          setTimeout(() => {
            seterrorMessage("");
          }, 5000);
        }
      });
  };

  const close = (e: any) => {
    if (e.keyCode === 27) {
      handleClose();
    }
  };

  const convertToFormattedDateTimetoTextField = (dateTimeString: any) => {
    const dateTime = new Date(dateTimeString);

    const day = dateTime.getDate();
    const month = dateTime.getMonth() + 1; // Months are zero-indexed
    const year = dateTime.getFullYear();

    let hours = dateTime.getHours();
    let minutes = dateTime.getMinutes();
    const period = hours >= 12 ? "PM" : "AM";

    // Convert to 12-hour format
    if (hours > 12) {
      hours -= 12;
    } else if (hours === 0) {
      hours = 12;
    }

    return `${day < 10 ? "0" : ""}${day}-${
      month < 10 ? "0" : ""
    }${month}-${year} ${hours}:${minutes < 10 ? "0" : ""}${minutes} ${period}`;
  };
  function convertUTCToIST(utcDateStr: any) {
    // Create a new Date object from the UTC date string
    const utcDate = new Date(utcDateStr);

    // Get the UTC time in milliseconds since January 1, 1970
    const utcTime = utcDate.getTime();

    // Add the offset for IST (5 hours 30 minutes) in milliseconds
    const istOffset = 5.5 * 60 * 60 * 1000;

    // Create a new date object with IST time
    const istDate = new Date(utcTime + istOffset);

    // Format the IST date to match the required format
    const year = istDate.getFullYear();
    const month = String(istDate.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(istDate.getDate()).padStart(2, "0");
    const hours = String(istDate.getHours()).padStart(2, "0");
    const minutes = String(istDate.getMinutes()).padStart(2, "0");
    const seconds = String(istDate.getSeconds()).padStart(2, "0");
    const milliseconds = String(istDate.getMilliseconds()).padStart(3, "0");

    // Return the formatted date string in the desired format
    return convertToFormattedDateTimetoTextField(istDate);
  }

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        closeAfterTransition
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
        onClose={close}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className={Styles.topContainerTitle}>
              <p
                style={{
                  margin: "0px 10px",
                  fontSize: "14px",
                  fontWeight: 500,
                }}
              >
                <IconButton
                  onClick={handleClose}
                  size="small"
                  style={{
                    background: "#8B93FF",
                    color: "white",
                    borderRadius: 4,
                  }}
                >
                  <KeyboardBackspaceIcon
                    style={{
                      fontSize: "18px",
                      fontWeight: 600,
                    }}
                  />
                </IconButton>
                <span style={{ marginLeft: "10px" }}>Client Details</span>
              </p>
              <div className={Styles.title}>
                {clientData && clientData?.ClientDetails?.ClientName}
              </div>
            </div>
            <div className={Styles.wrapper}>
              <div className={Styles.leftContainer}>
                <p className={Styles.clientInfo}>Client Info</p>
                {rejectedReason && stageName === "rejected" && (
                  <>
                    <div style={{ color: "red" }}>
                      <span style={{ fontWeight: 700 }}>Rejected Reason:</span>{" "}
                      <span style={{ fontSize: "14px" }}>
                        {" "}
                        {rejectedReason}
                      </span>
                    </div>
                    <Divider sx={{ borderColor: "grey", margin: "10px 0" }} />
                  </>
                )}
                {deletedReason && stageName === "deleted" && (
                  <>
                    <div style={{ color: "red" }}>
                      <span style={{ fontWeight: 700 }}>Deleted Reason:</span>{" "}
                      <span style={{ fontSize: "14px" }}> {deletedReason}</span>
                    </div>
                    <Divider sx={{ borderColor: "grey", margin: "10px 0" }} />
                  </>
                )}

                <div className={Styles.contentdiv}>
                  <p className={Styles.title}>
                    Mobile :{" "}
                    <span className={Styles.content}>
                      {clientData && clientData.ClientDetails?.MobileNumber}
                    </span>
                  </p>
                  <p className={Styles.title}>
                    Email :{" "}
                    <span className={Styles.content}>
                      {clientData && clientData.ClientDetails?.Email}
                    </span>
                  </p>
                </div>
                <Divider sx={{ borderColor: "grey", margin: "10px 0" }} />
                <div className={Styles.contentdiv}>
                  <p className={Styles.title}>
                    Client Name :{" "}
                    <span className={Styles.content}>
                      {clientData && clientData.ClientDetails?.ClientName}
                    </span>
                  </p>
                  <p className={Styles.title}>
                    CLIENT Code :{" "}
                    <span className={Styles.content}>
                      {clientData && clientData.ClientDetails?.ClientCode}
                    </span>
                  </p>
                </div>
                <Divider sx={{ borderColor: "grey", margin: "10px 0" }} />
                <div className={Styles.contentdiv}>
                  <div>
                    <p className={Styles.title}>
                      Cateogry :{" "}
                      <span className={Styles.content}>
                        {clientData?.ClientDetails?.Type}
                      </span>
                    </p>
                  </div>
                  <p className={Styles.title}>
                    Status :{" "}
                    <span className={Styles.content}>
                      {clientData?.ClientDetails?.AccountStatus}
                    </span>
                  </p>
                </div>

                <Divider sx={{ borderColor: "grey", margin: "10px 0" }} />

                <p
                  className={Styles.title}
                  style={{
                    // textDecoration: "underline",
                    background: "#d9e4fd",
                    // width: "max-content",
                    padding: "3px 20px",
                    borderRadius: "5px",
                    textAlign: "center",
                  }}
                >
                  Basic Info
                </p>

                <div className={Styles.contentdiv}>
                  <div style={{ width: "360px" }}>
                    <div>
                      <div>
                        <p className={Styles.title}>
                          Mobile No :{" "}
                          <span className={Styles.content}>
                            {clientData &&
                              clientData.ClientDetails?.MobileNumber}
                          </span>
                        </p>
                      </div>
                      <div>
                        <p className={Styles.title}>
                          PAN Number :{" "}
                          <span className={Styles.content}>
                            {clientData && clientData.ClientDetails?.PANNumber}
                          </span>
                        </p>
                      </div>
                      <div>
                        <p className={Styles.title}>
                          Created At :{" "}
                          <span className={Styles.content}>
                            {clientData?.EventTimestamps?.created_ts === ""
                              ? ""
                              : convertUTCToIST(
                                  clientData?.EventTimestamps?.created_ts
                                )}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div style={{ marginTop: "0px" }}>
                    <p className={Styles.title} style={{ marginLeft: "20px" }}>
                      Client Name :{" "}
                      <span className={Styles.content}>
                        {clientData && clientData.ClientDetails?.ClientName}
                      </span>
                    </p>
                    <p className={Styles.title} style={{ marginLeft: "20px" }}>
                      Email :{" "}
                      <span className={Styles.content}>
                        {clientData && clientData.ClientDetails?.Email}
                      </span>
                    </p>

                    <p className={Styles.title} style={{ marginLeft: "20px" }}>
                      Completed TS :{" "}
                      <span className={Styles.content}>
                        {clientData?.EventTimestamps?.completed_ts === ""
                          ? ""
                          : convertUTCToIST(
                              clientData?.EventTimestamps?.completed_ts
                            )}
                        {/* {clientData.CompletedTS === null
                          ? ""
                          : formatDate(clientData.CompletedTS)} */}
                      </span>
                    </p>
                  </div>
                </div>

                <Divider sx={{ borderColor: "grey", margin: "10px 0" }} />
                {clientData?.ClientDetails?.Type !==
                  "Secondary Bank Modification" && (
                  <>
                    <p
                      className={Styles.title}
                      style={{
                        background: "#d9e4fd",
                        padding: "3px 20px",
                        borderRadius: "5px",
                        textAlign: "center",
                      }}
                    >
                      Existing Bank Details
                    </p>
                    <div>
                      <p className={Styles.title}>
                        Priority :{" "}
                        <span className={Styles.content}>
                          {clientData &&
                            clientData?.ExistingDetails[0]?.priority}
                        </span>
                      </p>
                      <p className={Styles.title}>
                        Bank Name :{" "}
                        <span className={Styles.content}>
                          {clientData && clientData?.ExistingDetails[0]?.name}
                        </span>
                      </p>
                      <p className={Styles.title}>
                        Account No :{" "}
                        <span className={Styles.content}>
                          {clientData &&
                            clientData?.ExistingDetails[0]?.account_no}
                        </span>
                      </p>
                      <p className={Styles.title}>
                        IFSC :{" "}
                        <span className={Styles.content}>
                          {clientData && clientData?.ExistingDetails[0]?.ifsc}
                        </span>
                      </p>
                      <p className={Styles.title}>
                        MICR :{" "}
                        <span className={Styles.content}>
                          {clientData && clientData?.ExistingDetails[0]?.micr}
                        </span>
                      </p>
                    </div>
                  </>
                )}
                {clientData && clientData?.NewDetails && (
                  <>
                    <p
                      className={Styles.title}
                      style={{
                        // textDecoration: "underline",
                        background: "#d9e4fd",
                        // width: "max-content",
                        padding: "3px 20px",
                        borderRadius: "5px",
                        textAlign: "center",
                      }}
                    >
                      New Bank Details
                    </p>
                    <div>
                      <p className={Styles.title}>
                        Beneficiary Name:{" "}
                        <span className={Styles.content}>
                          {clientData && clientData?.NewDetails?.customer_name}
                        </span>
                      </p>
                      <p className={Styles.title}>
                        Bank Name :{" "}
                        <span className={Styles.content}>
                          {clientData && clientData?.NewDetails?.bank_name}
                        </span>
                      </p>
                      <p className={Styles.title}>
                        Branch Name :{" "}
                        <span className={Styles.content}>
                          {clientData && clientData?.NewDetails?.branch_name}
                        </span>
                      </p>
                      <p className={Styles.title}>
                        Account No :{" "}
                        <span className={Styles.content}>
                          {clientData && clientData?.NewDetails?.account_no}
                        </span>
                      </p>
                      <p className={Styles.title}>
                        IFSC :{" "}
                        <span className={Styles.content}>
                          {clientData && clientData?.NewDetails?.ifsc}
                        </span>
                      </p>
                      <p className={Styles.title}>
                        MICR :{" "}
                        <span className={Styles.content}>
                          {clientData && clientData?.NewDetails?.micr}
                        </span>
                      </p>
                      <p className={Styles.title}>
                        Address :{" "}
                        <span className={Styles.content}>
                          {clientData && clientData?.NewDetails?.address}
                        </span>
                      </p>
                    </div>
                  </>
                )}
              </div>

              <div className={Styles.rightContainer} style={{ width: "75%" }}>
                <div style={{ width: "20%" }}>
                  <VerticalTabs
                    labels={tabLabels}
                    onChangeTab={handleChangeTab}
                    selectedTab={selectedTab}
                  />
                </div>
                <div
                  style={{
                    width: "80%",
                    border: "1px solid #DDDCE1",
                    height: "100%",
                    borderRadius: "4px",
                  }}
                >
                  <BankUpdateBase64ToImage data={data} docType={docType} />
                </div>
              </div>
            </div>
            <div className={Styles.buttonWrapper}>
              {clientData?.BooleanValues?.is_deleted === false && (
                <Tooltip title="Delete Client">
                  <IconButton
                    onClick={handleOpenDeletePopUp}
                    sx={{
                      // position: "absolute",
                      // bottom: 10,
                      border: "1px solid red",
                    }}
                  >
                    <DeleteIcon sx={{ color: "red" }} />
                  </IconButton>
                </Tooltip>
              )}
              {clientData?.BooleanValues?.is_deleted === true && (
                <Tooltip title="Permanent Delete Client">
                  <IconButton
                    onClick={handlePermanentDeleteClient}
                    sx={{
                      // position: "absolute",
                      // bottom: 30,
                      border: "1px solid red",
                    }}
                  >
                    <DeleteIcon sx={{ color: "red" }} />
                  </IconButton>
                </Tooltip>
              )}

              <DeleteClient
                open={openDeletePopUp}
                handleDeleteClient={handleDeleteClient}
                handleCloseDeletepopUp={handleCloseDeletepopUp}
              />
              <SucessSnackBar message={successMessage} />
              <ErrorSnackBar message={errorMessage} />

              <Stack
                direction={"row"}
                justifyContent={"space-evenly"}
                // marginTop={"15px"}
                alignItems={"center"}
                marginLeft={"20px"}
              >
                {stageName !== "deleted" &&
                  stageName !== "rejected" &&
                  clientData?.BooleanValues?.is_rejected === false &&
                  clientData?.BooleanValues?.is_completed === true && (
                    <BankUpdateReject
                      stagesData={stagesData}
                      handleRejectClient={handleRejectClient}
                      rejectLoading={rejectLoading}
                      disabled={false}
                      // disabled={
                      //   clientData && clientData.IsCompleted === true
                      //     ? false
                      //     : true
                      // }
                    />
                  )}
                {isAdmin === "true" && (
                  <div>
                    {stageName !== "deleted" &&
                      stageName !== "rejected" &&
                      clientData?.BooleanValues?.is_completed === true &&
                      clientData?.BooleanValues?.is_approved === false &&
                      clientData?.BooleanValues?.is_rejected === false && (
                        <Button
                          type="submit"
                          onClick={handleApproveClient}
                          loading={approveLoading}
                          disabled={false}
                          sx={{
                            background: "#3CBC49",
                            color: "white",
                            width: "160px",
                            borderRadius: "4px",
                            marginLeft: "20px",
                            "&:hover": {
                              background: "#3CBC49",
                              color: "white",
                            },
                          }}
                        >
                          Approve
                        </Button>
                      )}
                  </div>
                )}

                <ErrorSnackBar message={errorMessage} />
              </Stack>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
