import React from "react";
import Login from "../../containers/Login/Login";
import { Helmet } from "react-helmet";

export default function LoginPage() {
  return (
    <>
      <Helmet>
        <title>Login </title>
        <meta name="Login" content="Login" />
      </Helmet>
      <Login />
    </>
  );
}
