import classes from "./OtpScreen.module.scss";
import React, { useEffect } from "react";
import { useFormik } from "formik";
import { signUpSchema } from "../../Schemas/Schemas";
import Button from "@mui/joy/Button";
import Input from "@mui/joy/Input";
import { Link, useNavigate } from "react-router-dom";
import { MuiOtpInput } from "mui-one-time-password-input";
import { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { setOtpFlag } from "../../Store/Project/ProjectSlice";
import { forgetPasswordAPICall } from "../../Utils/APICalls";
import { IForgetPassword } from "../../Utils/dtos";
import ErrorSnackBar from "../../components/SnackBar/ErrorSnackBar";
import signUp from "../../assets/Images/loginimage.svg";

const OtpScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const otpFlag = useSelector((state: any) => state.project.otpFlag);
  const initialValues = {
    email: "",
  };
  const [errormessage, seterrormessage] = useState("");
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema: signUpSchema,
      onSubmit: (values, action) => {
        //  console.log("values", values);
        // action.resetForm();
      },
    });
  const [otp, setOtp] = useState("");
  const [loading, setloading] = useState(false);
  const handleOtp = (newValue: any) => {
    setOtp(newValue);
  };
  const handleVerify = () => {
    const user = sessionStorage.getItem("userDetails");
    const requestBody: IForgetPassword = {
      user_details: user,
      otp: otp,
    };
    const Token = sessionStorage.getItem("Token");
    const config = {
      headers: { Authorization: `Bearer ${Token}` },
    };
    setloading(true);
    forgetPasswordAPICall(requestBody)
      .then((res: any) => {
        setloading(false);
        //  console.log(res.data);
        {
          otpFlag ? navigate("/changePassword") : navigate("/dashboard");
        }
        // const ChangePwToken = res.data.data.accessToken;
        // sessionStorage.setItem("ChangePwToken", ChangePwToken);
        dispatch(setOtpFlag(false));
      })
      .catch((error: any) => {
        setloading(false);
        // console.log(err.data);
        if (error.response.data.message) {
          seterrormessage(error.response.data.message);
          setTimeout(() => {
            seterrormessage("");
          }, 5000);
        }
      });
  };
  useEffect(() => {
    if (otp.length === 6) {
      handleVerify();
    }
  }, [otp]);

  return (
    <>
      <div className={classes.container}>
        <div className={classes.modal}>
          <div className={classes.modalContainer}>
            <div className={classes.modalLeft}>
              <h1 className={classes.modalTitle}>OTP Verification</h1>
              <p className={classes.modalSubTitle}>
                Enter the verification code sent to your mobile or email
              </p>
              <form onSubmit={handleSubmit}>
                <div className={classes.inputBlock}>
                  <MuiOtpInput length={6} value={otp} onChange={handleOtp} />
                </div>
                {/* <Link to={"/changePassword"} style={{ textDecoration: "none" }}> */}
                <div className={classes.modalButtons}>
                  <Button
                    type="submit"
                    size="lg"
                    style={{ padding: "14px 20px", background: "#8B93FF" }}
                    onClick={handleVerify}
                    loading={loading}
                  >
                    Verify
                  </Button>
                </div>
                <ErrorSnackBar message={errormessage} />
                {/* </Link> */}
              </form>
              <p className={classes.signUp}></p>
            </div>
            <div className={classes.modalRight}>
              <img src={signUp} alt="" className={classes.signupImage} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OtpScreen;
