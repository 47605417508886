import React, { useState } from "react";
import DragandDropFile from "../../components/DragAndDrop/DragandDrop";
import { Helmet } from "react-helmet";
import { Box, IconButton, Modal, Stack, Typography } from "@mui/material";
import Button from "@mui/joy/Button";
import { uploadDocumentsAPICall } from "../../Utils/APICalls";
import Base64ToImage from "../../components/Base64ToImage/Base64ToImage";
import BasicTable from "./DataTable";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import ExcelDownloadComponent from "../../components/SampleExcelDematUpload/SampleUploadDematExcelFile";
import CloseIcon from "@mui/icons-material/Close";
import ErrorSnackBar from "../../components/SnackBar/ErrorSnackBar";
import SucessSnackBar from "../../components/SnackBar/SuccessSnackBar";

const styleNew = {
  position: "absolute" as "absolute",
  top: "55%",
  left: "55%",
  transform: "translate(-50%, -50%)",
  width: "60vW",
  height: "60vH",
  bgcolor: "background.paper",

  p: 1,
  boxShadow: "none",
  borderRadius: "10px",
};
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50vW",
  height: "80vH",
  bgcolor: "background.paper",
  overflowY: "scroll",
  p: 1,
  boxShadow: "none",
  borderRadius: "10px",
};

function UploadDemat() {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [open, setOpen] = useState(false);
  const [duplicateData, setDuplicateData] = useState<any>([]);
  const [openTableModal, setopenTableModal] = useState(false);
  const [duplicateMessage, setduplicateMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setloading] = useState(false);
  let acceptedFormats = ["csv"];

  const handleFileChangeUpload = async (event: any) => {
    const file = event[0];

    if (!file) {
      setSelectedFile(null);
      return;
    }

    setSelectedFile(file);
    setOpen(true);
    setErrorMessage("");
  };

  const handleUpload = () => {
    setloading(true);
    const formData = new FormData();
    const obj: any = {
      file: selectedFile,
    };
    formData.append("file", obj.file);

    uploadDocumentsAPICall(formData)
      .then((response: any) => {
        const responseData = response.data;
        setloading(false);
      
        setSuccessMessage("Successfully Uploaded");
        setopenTableModal(false);
        setTimeout(() => {
          setSuccessMessage("");
        }, 5000);

        // if (responseData.data.Duplicate_demat_id_list.length) {
        //   setduplicateMessage("Duplicate Records Found");
        //   setDuplicateData(responseData.data.Duplicate_demat_id_list);
        //   setopenTableModal(true);

        //   setTimeout(() => {
        //     setduplicateMessage("");
        //   }, 5000);
        // } else {
        //   setDuplicateData([]);
        //   setSuccessMessage("Successfully Uploaded");
        //   setopenTableModal(false);
        //   setTimeout(() => {
        //     setSuccessMessage("");
        //   }, 5000);
        // }
        setSelectedFile(null);
      })
      .catch((error: any) => {
        // console.log(error);
        setloading(false);
        setopenTableModal(false);
        setSelectedFile(null);
      });
  };

  const handleCloseModal = () => {
    setopenTableModal(false);
  };

  const csvData = "DPType,DPId,DPClientID"; // Replace with your CSV data
  const handleSampleDownload = () => {
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "data.csv";
    a.click();
    URL.revokeObjectURL(url);
  };

  return (
    <div>
      <Helmet>
        <title>Upload Demat </title>
        <meta name="UploadDemat" content="UploadDemat" />
      </Helmet>
      {/* <ExcelDownloadComponent /> */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          width: "100%",
        }}
      >
        <Button
          onClick={handleSampleDownload}
          sx={{
            background: "#8B93FF",
            textTransform: "none",
            boxShadow: "none",
            "&:hover": {
              background: "#8B93FF",
              boxShadow: "none",
            },
          }}
        >
          Download Sample
        </Button>
      </div>

      {selectedFile ? (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          // onClose={handleCloseModal}
          closeAfterTransition
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Box sx={styleNew}>
            <Stack position={"absolute"} right={10}>
              <IconButton
                onClick={() => {
                  setSelectedFile(null);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Stack>
            <Stack
              alignItems={"center"}
              justifyContent={"center"}
              height={"100%"}
            >
              <Typography sx={{ margin: "20px 0" }}>
                {" "}
                {selectedFile && selectedFile.name}
              </Typography>
              <Button
                loading={loading}
                sx={{
                  width: "160px",
                  background: "#5A6ACF",
                  textTransform: "none",
                  boxShadow: "none",
                  "&:hover": {
                    background: "#5A6ACF",
                    boxShadow: "none",
                  },
                }}
                onClick={handleUpload}
              >
                Upload
              </Button>
            </Stack>
          </Box>
        </Modal>
      ) : (
        <DragandDropFile
          handleFileChangeUpload={handleFileChangeUpload}
          acceptedFormats={acceptedFormats}
          errorMessage={errorMessage}
          selectedFile={selectedFile}
        />
      )}
      <ErrorSnackBar message={duplicateMessage} />
      <SucessSnackBar message={successMessage} />
      {duplicateData && (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={openTableModal}
          // onClose={handleCloseModal}
          closeAfterTransition
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Box sx={style}>
            <IconButton onClick={handleCloseModal}>
              <KeyboardBackspaceIcon />
            </IconButton>

            <BasicTable rowData={duplicateData} headerName={"Demat Id"} />
          </Box>
        </Modal>
      )}
    </div>
  );
}

export default UploadDemat;
