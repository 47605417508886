import React, { useEffect, useState } from "react";
import styles from "./Filters.module.scss";
import { DateRangePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { FormControl, IconButton, MenuItem, Select } from "@mui/material";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const mappingStagesData: any = {
  "PAN Details": "Pan details",
  "KRA Details/Aadhar Validation": "KRA verification or Digi-locker",
  "Nominee Details": "Nominee ",
  "E-Sign": "Esign section",
  "Segment Details": "Trading preferences",
  "Profile Page": "Personal details",
  "Bank Account": "Bank details",
  "Web Image": "Identification verification",
  "Document Upload": "Upload documents",
  "Terms and Conditions": "Terms and conditions",
};

interface props {
  selectedTab: any;
  datevalue: any;
  setdateValue: any;
  handleDateRangeChange: any;
  userId: any;
  isAdmin: any;
  setUserId: any;
  handleUserChange: any;
  users: any;
  handleCloseFilters:any
}

function FinUpdateFilters({
  selectedTab,
  datevalue,
  setdateValue,
  handleDateRangeChange,
  userId,
  isAdmin,
  setUserId,
  handleUserChange,
  users,
  handleCloseFilters
}: props) {
  const [userDataAdded, setuserDataAdded] = useState([]);
  useEffect(() => {
    if (users) {
      const userData: any = [
        ...users,
        { UserId: "non_assigned", FullName: "Non Assigned" },
      ];
      setuserDataAdded(userData);
    }
  }, [users]);
  return (
    <div className={styles.filterWrapper}>
      <div className={styles.topContainer}>
      <p className={styles.title}>Filters</p>
      <div>
        <IconButton onClick={handleCloseFilters}><HighlightOffIcon style={{color:"#8B93FF"}} /></IconButton>
      </div>
      </div>
      <div className={styles.filterSection}>
        {selectedTab !== "today" && selectedTab !== "completed" && (
          <div className={styles.singleFilter}>
            <p className={styles.textfieldLabel}>Date Range </p>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer
                components={["DateRangePicker", "DateRangePicker"]}
              >
                <DemoItem component="DateRangePicker">
                  <DateRangePicker
                    value={datevalue}
                    sx={{ width: "350px" }}
                    format="DD/MM/YYYY"
                    onChange={(newValue: any) => {
                      setdateValue(newValue);
                      if (newValue[0] && newValue[1]) {
                        handleDateRangeChange(newValue);
                      }
                    }}
                    slotProps={{
                      textField: {
                        size: "small",
                        sx: {
                          borderRadius: "4px",
                          background: "#F2F2F6",
                          outline: "none",
                          fontSize: "14px",
                          fontWeight: 500,
                          padding: "0 0px",
                          color: "#000000",
                          boxShadow: "none",
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                        },
                      },
                    }}
                  />
                </DemoItem>
              </DemoContainer>
            </LocalizationProvider>
          </div>
        )}
    

        {isAdmin === "true" && (
          <div className={styles.singleFilter}>
            <p
              className={styles.textfieldLabel}
              style={{ marginBottom: "10px" }}
            >
              Users{" "}
            </p>
            <FormControl sx={{ width: "200px" }} size="small">
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={userId}
                placeholder="Users"
                displayEmpty
                renderValue={
                  userId !== ""
                    ? undefined
                    : () => {
                        return (
                          <p style={{ margin: 0, fontWeight: 400 }}>
                            Select User
                          </p>
                        );
                      }
                }
                onChange={(event: any) => {
                  setUserId(event.target.value);
                  handleUserChange(event.target.value);
                }}
                sx={{
                  borderRadius: "4px",
                  // width: "-webkit-fill-available",
                  background: "#F2F2F6",
                  outline: "none",
                  fontSize: "14px",
                  fontWeight: 500,
                  padding: "0 0px",
                  color: "#000000",
                  boxShadow: "none",
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                }}
              >
                {userDataAdded &&
                  userDataAdded.map((data: any) => {
                    return (
                      <MenuItem value={data} key={data.full_name}>
                        {data.full_name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </div>
        )}
      </div>
    </div>
  );
}

export default FinUpdateFilters;
