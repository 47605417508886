import React, { useEffect, useRef, useState } from "react";
import uploadStyles from "./PdfEditor.module.scss";
import Draggable from "react-draggable";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import {
  Button,
  Checkbox,
  Drawer,
  IconButton,
  Radio,
  Stack,
  TextField,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import TitleOutlinedIcon from "@mui/icons-material/TitleOutlined";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import RadioButtonCheckedOutlinedIcon from "@mui/icons-material/RadioButtonCheckedOutlined";
import PdfEditIcon from "../../../assets/Images/pdfediticon.svg";
import PdfDeleteIcon from "../../../assets/Images/pdfdeleteicon.svg";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import { useNavigate } from "react-router-dom";
import { setselectedfile } from "../../../Store/Project/ProjectSlice";

const mappingImages: any = {
  text: <TitleOutlinedIcon style={{ color: "#8b93ff" }} />,
  image: <ImageOutlinedIcon style={{ color: "#8b93ff" }} />,
  checkbox: <CheckBoxOutlinedIcon style={{ color: "#8b93ff" }} />,
  radio: <RadioButtonCheckedOutlinedIcon style={{ color: "#8b93ff" }} />,
};

function PDFAnnotations() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
  const selectedfile = useSelector((state: any) => state.project.selectedfile);

  const file = selectedfile ? selectedfile : [];
  const [fields, setFields] = useState<any>([]);
  const [updatedFields, setUpdatedFields] = useState<any>([]);
  const [selectedFile, setSelectedFile] = React.useState<[] | any>(file);
  const [coordinatesX, setCoordinatesX] = useState(100);
  const [coordinatesY, setCoordinatesY] = useState(100);
  const [size, setSize] = useState({ width: 200, height: 200 });
  const [editFeildData, setEditFeildData] = useState<any>();
  const [dragFeildData, setdragFeildData] = useState<any>();
  const [openDrawer, setopenDrawer] = useState(false);
  const formats: any = ["png", "jpeg", "jpg"];
  const [activeDrags, setActiveDrags] = useState(0);
  const [addFeildClicked, setAddFeildClicked] = useState(false);
  const [createFeild, setCreateFeild] = useState<any>();
  const [selectedIndex, setSelectedIndex] = useState(0);

  const previewRef = useRef<any>();
  const layer2 = previewRef.current;
  const onStart = () => {
    setActiveDrags(activeDrags + 1);
  };

  const onStop = () => {
    setActiveDrags(activeDrags - 1);
  };

  const dragHandlers = { onStart, onStop };

  const addField = (type: any, x: any, y: any) => {
    setAddFeildClicked(true);
    setCreateFeild({
      type: type,
      x: x,
      y: y,
    });
  };

  const addWidths = (numberWidth: any, stringWidth: any) => {
    const numFromString = parseFloat(stringWidth);
    // const unit = stringWidth?.replace(numFromString, "");

    const totalWidth = numberWidth + numFromString;

    return totalWidth;
  };
  const handleClick = (event: any, index: any) => {
    // console.log(
    //   "layer2.getBoundingClientRect().left",
    //   layer2.getBoundingClientRect()
    // );
    if (createFeild?.type) {
      // console.log(
      //   "clicked",
      //   event.clientX - layer2.getBoundingClientRect().left,
      //   "old",
      //   event.nativeEvent.offsetX,
      //   event.clientY - layer2.getBoundingClientRect().top
      // );
      setCoordinatesX(event.nativeEvent.offsetX);
      setCoordinatesY(event.nativeEvent.offsetY);
      const feildName = `feild_name_${fields.length + 1}`;
      const sequence = fields.length + 1;
      const xCreated = event.clientX - layer2.getBoundingClientRect().left;
      const yCreated = event.clientY - layer2.getBoundingClientRect().top;
      const width = "50px";
      const height = "30px";
      const checkwidth = "20px";
      const checkheight = "20px";

      if (createFeild.type === "text" || createFeild.type === "image") {
        setFields([
          ...fields,
          {
            type: createFeild.type,
            x: event.clientX - layer2.getBoundingClientRect().left,
            y: event.clientY - layer2.getBoundingClientRect().top,
            page_number: index + 1,
            lowerLeftX: event.clientX - layer2.getBoundingClientRect().left,
            lowerLeftY:
              layer2.getBoundingClientRect().height -
              addWidths(
                event.clientY - layer2.getBoundingClientRect().top,
                height
              ),
            upperRightX: addWidths(
              event.clientX - layer2.getBoundingClientRect().left,
              width
            ),
            upperRightY:
              layer2.getBoundingClientRect().height -
              event.clientY -
              layer2.getBoundingClientRect().top,

            feildName,
            sequence,
            xCreated,
            yCreated,
            width,
            height,
          },
        ]);
        setUpdatedFields([
          ...updatedFields,
          {
            type: createFeild.type,
            x: event.clientX - layer2.getBoundingClientRect().left,
            y: event.clientY - layer2.getBoundingClientRect().top,
            page_number: index + 1,
            lowerLeftX: event.clientX - layer2.getBoundingClientRect().left,
            lowerLeftY:
              layer2.getBoundingClientRect().height -
              addWidths(
                event.clientY - layer2.getBoundingClientRect().top,
                height
              ),
            upperRightX: addWidths(
              event.clientX - layer2.getBoundingClientRect().left,
              width
            ),
            upperRightY:
              layer2.getBoundingClientRect().height -
              event.clientY -
              layer2.getBoundingClientRect().top,

            feildName,
            sequence,
            xCreated,
            yCreated,
            width,
            height,
          },
        ]);
      } else {
        setFields([
          ...fields,
          {
            type: createFeild.type,
            x: event.clientX - layer2.getBoundingClientRect().left,
            y: event.clientY - layer2.getBoundingClientRect().top,
            page_number: index + 1,
            lowerLeftX: event.clientX - layer2.getBoundingClientRect().left,
            lowerLeftY:
              layer2.getBoundingClientRect().height -
              addWidths(
                event.clientY - layer2.getBoundingClientRect().top,
                height
              ),
            upperRightX: addWidths(
              event.clientX - layer2.getBoundingClientRect().left,
              width
            ),
            upperRightY:
              layer2.getBoundingClientRect().height -
              event.clientY -
              layer2.getBoundingClientRect().top,

            feildName,
            sequence,
            xCreated,
            yCreated,
            checkwidth,
            checkheight,
          },
        ]);
        setUpdatedFields([
          ...updatedFields,
          {
            type: createFeild.type,
            x: event.clientX - layer2.getBoundingClientRect().left,
            y: event.clientY - layer2.getBoundingClientRect().top,
            page_number: index + 1,
            lowerLeftX: event.clientX - layer2.getBoundingClientRect().left,
            lowerLeftY:
              layer2.getBoundingClientRect().height -
              addWidths(
                event.clientY - layer2.getBoundingClientRect().top,
                height
              ),
            upperRightX: addWidths(
              event.clientX - layer2.getBoundingClientRect().left,
              width
            ),
            upperRightY:
              layer2.getBoundingClientRect().height -
              event.clientY -
              layer2.getBoundingClientRect().top,

            feildName,
            sequence,
            xCreated,
            yCreated,
            checkwidth,
            checkheight,
          },
        ]);
      }
    }
    setCreateFeild(null);
    setAddFeildClicked(false);
  };

  const handleDeleteFeild = (feilddata: any) => {
    const updatedFeildsall = fields.filter((str: any) => str !== feilddata);
    setFields(updatedFeildsall);

    const updatedFeildsNew = fields.filter(
      (str: any) => str.feildName !== feilddata.feildName
    );
    setUpdatedFields(updatedFeildsNew);
  };

  const handleEditFeild = (feildeditdata: any) => {
    setEditFeildData(feildeditdata);

    const filterFeildData = updatedFields.filter(
      (a: any) => a.feildName === feildeditdata.feildName
    );
    setdragFeildData(filterFeildData[0]);

    setopenDrawer(true);
  };

  const handleCloseDrawer = () => {
    setopenDrawer(false);
  };

  const handleChangeFeildName = (fieldName: any, fieldData: any) => {
    setEditFeildData({ ...editFeildData, feildName: fieldName });
  };

  const handleSaveEdited = () => {
    const updatedFields = fields.filter(
      (field: any) =>
        !(field.x === editFeildData.x && field.y === editFeildData.y)
    );

    const sortedData = [...updatedFields, editFeildData];
    sortedData.sort((a, b) => {
      return a.sequence - b.sequence;
    });
    setFields(sortedData);

    const updatedFieldsLatest = updatedFields.filter(
      (field: any) =>
        !(field.x === editFeildData.x && field.y === editFeildData.y)
    );

    const sortedDataLatest = [...updatedFieldsLatest, editFeildData];
    sortedData.sort((a, b) => {
      return a.sequence - b.sequence;
    });
    setUpdatedFields(sortedDataLatest);
    setopenDrawer(false);
    // console.log(updatedFields);
  };

  const handleScrollPosition = (felData: any) => {
    previewRef.current?.children[selectedIndex]?.scrollIntoView();
    window.scrollTo({ top: felData.y - 100, behavior: "smooth" });
  };
  const handlePreviewScrollPosition = (index: any) => {};

  const handleSaveChanges = () => {
    const convertedData: any = {};

    for (const fieldName in updatedFields) {
      const field = updatedFields[fieldName];
      const pageNumber = field.page_number;
      const FeildName = field.feildName;

      if (!convertedData[pageNumber]) {
        // convertedData[pageNumber] = [];
        convertedData[pageNumber] = {};
      }

      // convertedData[pageNumber].push({ [FeildName]: field });
      convertedData[pageNumber][FeildName] = field;
    }

   // console.log("convertedData", convertedData);
  };

  const handleBackClick = () =>{
    dispatch(setselectedfile([]));
    navigate("/dashboard/pdfannotator");
  }

  return (
    <div className={uploadStyles.wrapper}>
      <div className={uploadStyles.topContainer}>
        <div className={uploadStyles.topLeftContainer}>
          <div className={uploadStyles.backButton} onClick={handleBackClick}>
            <ArrowBackIosOutlinedIcon style={{ fontSize: "14px" }} />
          </div>
          <DescriptionOutlinedIcon />
          <p className={uploadStyles.fileName}>Loremipsum.pdf</p>
        </div>
        <div className={uploadStyles.topRightContainer}>
          <div>
            <Button
              fullWidth
              startIcon={<TitleOutlinedIcon style={{ color: "#5A6ACF" }} />}
              variant="outlined"
              onClick={() => addField("text", coordinatesX, coordinatesY)}
              sx={{
                textTransform: "none",
                color: "#5A6ACF",
                fontSize: "12px",
                fontWeight: 600,
              }}
            >
              Add Text Field
            </Button>
          </div>
          <div>
            <Button
              fullWidth
              startIcon={<ImageOutlinedIcon style={{ color: "#5A6ACF" }} />}
              variant="outlined"
              onClick={() => addField("image", coordinatesX, coordinatesY)}
              sx={{
                textTransform: "none",
                color: "#5A6ACF",
                fontSize: "12px",
                fontWeight: 600,
              }}
            >
              Add Image
            </Button>
          </div>
          <div>
            <Button
              fullWidth
              startIcon={<CheckBoxOutlinedIcon style={{ color: "#5A6ACF" }} />}
              variant="outlined"
              onClick={() => addField("checkbox", coordinatesX, coordinatesY)}
              sx={{
                textTransform: "none",
                color: "#5A6ACF",
                fontSize: "12px",
                fontWeight: 600,
              }}
            >
              Add Checkbox
            </Button>
          </div>
          <div>
            <Button
              fullWidth
              startIcon={
                <RadioButtonCheckedOutlinedIcon style={{ color: "#5A6ACF" }} />
              }
              variant="outlined"
              onClick={() => addField("radio", coordinatesX, coordinatesY)}
              sx={{
                textTransform: "none",
                color: "#5A6ACF",
                fontSize: "12px",
                fontWeight: 600,
              }}
            >
              Add Radio
            </Button>
          </div>
          <div>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                boxShadow: "none",
                background: "#5A6ACF",
                fontSize: "12px",
                marginLeft: "20px",
                fontWeight: 600,
                "&:hover": {
                  boxShadow: "none",
                  background: "#5A6ACF",
                },
              }}
              onClick={handleSaveChanges}
            >
              Save Changes
            </Button>
          </div>
          <div>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                boxShadow: "none",
                background: "#5A6ACF",
                fontSize: "12px",

                fontWeight: 600,

                "&:hover": {
                  boxShadow: "none",
                  background: "#5A6ACF",
                },
              }}
            >
              Download
            </Button>
          </div>
        </div>
      </div>

      <div className={uploadStyles.mainWrapper}>
        <div className={uploadStyles.previewSection}>
          {/* <p className={uploadStyles.coordinatesText}>Preview</p> */}

          <div className={uploadStyles.imagePreviewSection}>
            {selectedFile.length > 0 && (
              <div style={{ marginBottom: "40px" }}>
                {selectedFile.map((a: any, index: any) => (
                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      margin: "4px 0px",
                    }}
                  >
                    <img
                      src={URL.createObjectURL(a)}
                      className={uploadStyles.imagePreviewimg}
                      style={{
                        border:
                          index === selectedIndex ? "1px solid #B9B9B9" : "",
                        filter:
                          index === selectedIndex
                            ? "brightness(80%)"
                            : "brightness(100%)",
                      }}
                      onClick={(event) => {
                        setSelectedIndex(index);
                        handleSaveChanges();
                        window.scrollTo({ top: 0, behavior: "smooth" });
                      }}
                    />
                    <p
                      style={{
                        // position: "absolute",
                        width: "30px",
                        height: "30px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        // background: "#80808042",
                        // borderRadius: "50%",
                      }}
                    >
                      {index + 1}
                    </p>
                  </div>
                ))}
              </div>
            )}
            {selectedFile.length === 0 && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  margin: "auto",
                  height: "40vh",
                }}
              >
                <p
                  style={{
                    fontSize: "12px",
                    fontWeight: 600,
                    margin: "20px 0px",
                  }}
                >
                  Please select the file
                </p>
              </div>
            )}
          </div>
        </div>
        <div className={uploadStyles.center}>
          <div className={uploadStyles.centerSection}>
            <div className={uploadStyles.imageSection}>
              {/* {selectedFile.length > 0 && (
            <div
              style={{
                color: "white",
                width: "max-content",
                position: "fixed",
                zIndex: 1000,
                top: "5px",
                left: "570px",
              }}
            >
              <IconButton
                size="small"
                sx={{ color: "red" }}
                onClick={() => {
                  setSelectedFile([]);
                  setFields([]);
                  setUpdatedFields([]);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </div>
          )} */}

              {selectedFile.length > 0 && (
                <div>
                  <img
                    src={URL.createObjectURL(
                      selectedFile && selectedFile[selectedIndex]
                    )}
                    className={uploadStyles.image}
                    onClick={(event) => {
                      handleClick(event, selectedIndex);
                    }}
                    style={{
                      cursor: addFeildClicked ? "crosshair" : "default",
                    }}
                    ref={previewRef}
                  />
                </div>
              )}

              {fields.map((field: any, index: any) => (
                <>
                  {selectedIndex === field.page_number - 1 && (
                    <Draggable
                      handle="strong"
                      {...dragHandlers}
                      onStop={(e: any, data) => {
                        const newX = field.xCreated + data.x;
                        const newY = field.yCreated + data.y;
                        const updatedFieldsnew = updatedFields.map(
                          (item: any) =>
                            item.feildName === field.feildName
                              ? {
                                  ...item,
                                  lowerLeftX: newX,
                                  lowerLeftY:
                                    layer2.getBoundingClientRect().height -
                                    addWidths(newY, item.height),
                                  upperRightX: addWidths(newX, item.width),
                                  upperRightY:
                                    layer2.getBoundingClientRect().height -
                                    newY,
                                  x: newX,
                                  y: newY,
                                }
                              : item
                        );

                        setUpdatedFields(updatedFieldsnew);
                      }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          left: field.x,
                          top: field.y,
                          zIndex: 100,
                          background: "transparent",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "3px",
                        }}
                      >
                        <strong
                          className="cursor"
                          style={{
                            cursor: "move",
                            position: "absolute",
                            zIndex: 120,
                          }}
                        >
                          <div style={{ textAlign: "center" }}>&#10021;</div>
                        </strong>
                        {field.type === "text" && (
                          <>
                            <p
                              style={{
                                fontSize: "12px",
                                margin: "5px 0px",
                                position: "absolute",
                                top: "-25px",
                                color: "blue",
                                width: "max-content",
                              }}
                            >
                              {field.feildName}
                            </p>
                            <textarea
                              id={`text_area${index + 1}`}
                              readOnly
                              onClick={(event: any) => {
                                const newWidth = event.target.style.width;
                                const newHeight = event.target.style.height;

                                const updatedFieldsnew = updatedFields.map(
                                  (item: any) =>
                                    item.feildName === field.feildName
                                      ? {
                                          ...item,
                                          lowerLeftY:
                                            layer2.getBoundingClientRect()
                                              .height -
                                            addWidths(
                                              item.lowerLeftY,
                                              newHeight
                                            ),
                                          upperRightY:
                                            item.upperRightY - newHeight,
                                          upperRightX: addWidths(
                                            item.lowerLeftX,
                                            newWidth
                                          ),
                                          width: newWidth,
                                          height: newHeight,
                                        }
                                      : item
                                );

                                setUpdatedFields(updatedFieldsnew);
                                const updatedFieldsnewDraged = fields.map(
                                  (item: any) =>
                                    item.feildName === field.feildName
                                      ? {
                                          ...item,

                                          lowerLeftY:
                                            layer2.getBoundingClientRect()
                                              .height -
                                            addWidths(
                                              item.lowerLeftY,
                                              newHeight
                                            ),
                                          upperRightX: addWidths(
                                            item.lowerLeftX,
                                            newWidth
                                          ),

                                          width: newWidth,
                                          height: newHeight,
                                        }
                                      : item
                                );

                                setFields(updatedFieldsnewDraged);
                              }}
                              style={{
                                resize: "both",
                                minHeight: "10px",
                                minWidth: "16px",
                                borderRadius: "3px",
                                border: "1px dashed blue",
                                background: "#80808042",
                              }}
                            />
                          </>
                        )}
                        {field.type === "image" && (
                          <>
                            {" "}
                            <p
                              style={{
                                fontSize: "12px",
                                margin: "5px 0px",
                                position: "absolute",
                                top: "-25px",
                                color: "blue",
                                width: "max-content",
                              }}
                            >
                              {field.feildName}
                            </p>
                            <textarea
                              id={`text_area${index + 1}`}
                              readOnly
                              onClick={(event: any) => {
                                const newWidth = event.target.style.width;
                                const newHeight = event.target.style.height;
                                const updatedFieldsnew = updatedFields.map(
                                  (item: any) =>
                                    item.feildName === field.feildName
                                      ? {
                                          ...item,
                                          lowerLeftY:
                                            layer2.getBoundingClientRect()
                                              .height -
                                            addWidths(
                                              item.lowerLeftY,
                                              newHeight
                                            ),
                                          upperRightX: addWidths(
                                            item.lowerLeftX,
                                            newWidth
                                          ),
                                          width: newWidth,
                                          height: newHeight,
                                        }
                                      : item
                                );

                                setUpdatedFields(updatedFieldsnew);
                                const updatedFieldsnewDraged = fields.map(
                                  (item: any) =>
                                    item.feildName === field.feildName
                                      ? {
                                          ...item,
                                          lowerLeftY:
                                            layer2.getBoundingClientRect()
                                              .height -
                                            addWidths(
                                              item.lowerLeftY,
                                              newHeight
                                            ),
                                          upperRightX: addWidths(
                                            item.lowerLeftX,
                                            newWidth
                                          ),
                                          width: newWidth,
                                          height: newHeight,
                                        }
                                      : item
                                );

                                setFields(updatedFieldsnewDraged);
                              }}
                              style={{
                                resize: "both",
                                minHeight: "20px",
                                minWidth: "50px",
                                borderRadius: "3px",
                                border: "1px dashed blue",
                                background: "rgb(211 211 211 / 96%)",
                              }}
                            />
                          </>
                        )}
                        {field.type === "checkbox" && (
                          <>
                            {" "}
                            <p
                              style={{
                                fontSize: "12px",
                                margin: "5px 0px",
                                position: "absolute",
                                top: "-25px",
                                color: "blue",
                                width: "max-content",
                              }}
                            >
                              {field.feildName}
                            </p>
                            <input
                              type="checkbox"
                              defaultChecked
                              style={{
                                width: "10px",
                                height: "10px",
                                color: "blue",
                              }}
                            />
                          </>
                        )}
                        {field.type === "radio" && (
                          <>
                            {" "}
                            <p
                              style={{
                                fontSize: "12px",
                                margin: "5px 0px",
                                position: "absolute",
                                top: "-25px",
                                color: "blue",
                                width: "max-content",
                              }}
                            >
                              {field.feildName}
                            </p>
                            <input
                              type="radio"
                              defaultChecked
                              style={{ width: "10px", height: "10px" }}
                            />
                          </>
                        )}
                        <IconButton
                          sx={{ position: "absolute", right: "-30px" }}
                          size="small"
                          onClick={() => {
                            handleDeleteFeild(field);
                          }}
                        >
                          {" "}
                          <CloseIcon />
                        </IconButton>
                      </div>
                    </Draggable>
                  )}
                </>
              ))}
            </div>
          </div>
        </div>
        <div className={uploadStyles.right}>
          <div className={uploadStyles.rightSection}>
            <p className={uploadStyles.coordinatesText}>Co-Ordinates</p>
            {fields.map((a: any, index: any) => (
              <div
                className={uploadStyles.feildDetailsSection}
                onClick={() => {
                  setSelectedIndex(a.page_number - 1);
                  handleScrollPosition(a);
                }}
              >
                <p
                  style={{
                    display: "flex",
                    gap: "3px",
                    alignItems: "center",
                    margin: "0px",
                    fontSize: "12px",
                    fontWeight: 500,
                  }}
                >
                  {mappingImages[a.type]}
                  <span>{a.feildName}</span>
                </p>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <img
                    src={PdfEditIcon}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      handleEditFeild(a);
                    }}
                  />
                  <img
                    src={PdfDeleteIcon}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      handleDeleteFeild(a);
                    }}
                  />
                </div>
              </div>
            ))}
            {fields.length === 0 && (
              <div
                style={{
                  margin: "5px",

                  alignItems: "center",

                  border: "1px solid grey",
                  borderRadius: "5px",
                  fontSize: "14px",
                  padding: "5px 3px",
                  cursor: "pointer",
                  textAlign: "center",
                }}
              >
                No Feilds Added
              </div>
            )}
            <Drawer
              anchor={"right"}
              open={openDrawer}
              onClose={handleCloseDrawer}
            >
              <div style={{ padding: "20px", width: "20vw" }}>
                <p className={uploadStyles.coordinatesText}>Feild Details</p>
                <div>
                  <TextField
                    fullWidth
                    label="Feild Name"
                    id="outlined-size-small"
                    value={editFeildData?.feildName}
                    size="small"
                    onChange={(event: any) => {
                      handleChangeFeildName(event.target.value, editFeildData);
                    }}
                  />
                </div>
                <div style={{ margin: "25px 0px" }}>
                  <TextField
                    fullWidth
                    label="Feild Type"
                    id="outlined-size-small"
                    value={editFeildData?.type}
                    size="small"
                    disabled
                  />
                </div>

                {/* <div style={{ display: "flex", gap: "10px", width: "100%" }}>
              <TextField
                fullWidth
                label="X Coordinates"
                id="outlined-size-small"
                value={dragFeildData?.x}
                size="small"
                disabled
              />
              <TextField
                fullWidth
                label="X Coordinates"
                id="outlined-size-small"
                value={dragFeildData?.y}
                size="small"
                disabled
              />
            </div> */}
                <Stack>
                  <Button
                    variant="contained"
                    sx={{ margin: "30px auto", width: "80%" }}
                    onClick={handleSaveEdited}
                  >
                    Save
                  </Button>
                </Stack>
              </div>
            </Drawer>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PDFAnnotations;
