import * as Yup from "yup";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const signUpSchema = Yup.object({
  name: Yup.string()
    .min(2)
    .max(50)
    .required("Please enter your name")
    .matches(/^(?!.*\s{2,}).*$/, "Consecutive spaces are not allowed"),
  email: Yup.string().email().required("Please enter your email"),
  password: Yup.string().min(6).required("Please enter your password"),
  number: Yup.string()
    .required("Mobile Number is Required")
    .max(10)
    .min(8)
    .matches(/^[0-9 ]*$/, "Enter Valid Name"),
  companyname: Yup.string()
    .required("Please enter your Company Name")
    .matches(/^(?!.*\s{2,}).*$/, "Consecutive spaces are not allowed"),
});
export const signinSchema = Yup.object({
  email: Yup.string(),
  password: Yup.string().min(6).required("Please enter your password"),
});

export const forgotpwSchema = Yup.object({
  name: Yup.string()
    .min(2)
    .max(50)
    .required("Please enter your email or phone number"),
});

export const confirmpwSchema = Yup.object({
  password: Yup.string()
    .required("Password is required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,15}$/,
      "Password must have one uppercase, one lowercase, one number, one special symbol (@$!%*?&), and be 8 to 15 length"
    ),
  // confirm_password: Yup.string().oneOf(
  //   [Yup.ref("password"), null],
  //   "Passwords must match"
  // ),
});

export const UserAddScheme = Yup.object().shape({
  mobile: Yup.string().required("Mobile is Required").max(10).min(10),
  email: Yup.string().required("Email is Required").email(),
  full_name: Yup.string()
    .required("Name is Required")
    .matches(/^[a-zA-Z ]*$/, "Enter Valid Name")
    .matches(/^(?!.*\s{2,}).*$/, "Consecutive spaces are not allowed"),
  password: Yup.string()
    .required("Password is Required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,15}$/,
      "Password must have one uppercase, one lowercase, one number, one special symbol (@$!%*?&), and be 8 to 15 length"
    ),
  // is_admin: Yup.bool().required("Mention whether User is Admin or Not"),
  is_active: Yup.bool().required("Mention whether User is Active or Not"),
});

export const UserEditScheme = Yup.object().shape({
  mobile: Yup.string().required("Mobile is Required").max(10).min(10),
  email: Yup.string().required("Email is Required"),
  full_name: Yup.string()
    .required("Name is Required")
    .matches(/^[a-zA-Z ]*$/, "Enter Valid Name")
    .matches(/^(?!.*\s{2,}).*$/, "Consecutive spaces are not allowed"),
  is_admin: Yup.bool().required("Mention whether User is Admin or Not"),
  is_active: Yup.bool().required("Mention whether User is Active or Not"),
});

export const UserDeleteScheme = Yup.object().shape({
  user_id: Yup.string().required("UserId is Required"),
  deleted_reason: Yup.string()
    .required("Email is Required")
    .max(200)
    .matches(/^(?!.*\s{2,}).*$/, "Consecutive spaces are not allowed"),
});

export const TodayFollowUpScheme = Yup.object().shape({
  remarks: Yup.string()
    .required("Remarks is Required")
    .matches(/^(?!.*\s{2,}).*$/, "Consecutive spaces are not allowed"),
  date_time: Yup.date()
    .required("Next Followup Date is a required field")
    .min(new Date(), "Date and time must be more than present"),
  status: Yup.string().required("Please select an option"),
});
export const AddFollowUpScheme = Yup.object().shape({
  remarks: Yup.string()
    .required("Remarks is Required")
    .matches(/^(?!.*\s{2,}).*$/, "Consecutive spaces are not allowed"),
  update_date: Yup.date()
    .required(" Date is a Required")
    .min(new Date(), "Date and time must be more than present"),
  status: Yup.string().required("Please select an option"),
});
export const EditFollowUpHistoryScheme = Yup.object().shape({
  remarks: Yup.string()
    .required("Remarks is Required")
    .matches(/^(?!.*\s{2,}).*$/, "Consecutive spaces are not allowed"),
  //  date_time: Yup.string().required("Next Followup Date is a required field"),
  status: Yup.string().required("Please select an option"),
});

export const TariffAddScheme = Yup.object().shape({
  partnerId: Yup.string()
    .required("Partner Id is Required")
    .max(10)
    .matches(/^(?!.*\s{2,}).*$/, "Consecutive spaces are not allowed"),
  equityDelivery: Yup.string()
    .required("Equity Delivery is Required")
    .matches(/^(?!.*\s{2,}).*$/, "Consecutive spaces are not allowed"),
  partnerName: Yup.string()
    .required("Partner Name is Required")
    .matches(/^[a-zA-Z ]*$/, "Enter Valid Name")
    .matches(/^(?!.*\s{2,}).*$/, "Consecutive spaces are not allowed"),
  equityIntraday: Yup.string()
    .required("Equity Intraday is Required")
    .matches(/^(?!.*\s{2,}).*$/, "Consecutive spaces are not allowed"),
  futures: Yup.string()
    .required("Futures is Required")
    .matches(/^(?!.*\s{2,}).*$/, "Consecutive spaces are not allowed"),
  options: Yup.string()
    .required("Options is Required")
    .matches(/^(?!.*\s{2,}).*$/, "Consecutive spaces are not allowed"),
  brokerageTemplate: Yup.object().required(
    "Brokerage brokerageTemplate is Required"
  ),
});
export const AddBrokerageSchema = Yup.object().shape({
  name: Yup.string()
    .required("Brokerage Name is Required")
    // .matches(/^[a-zA-Z ]*$/, "Enter Valid Name")
    .matches(/^(?!.*\s{2,}).*$/, "Consecutive spaces are not allowed"),
  description: Yup.string()
    .required("Description is Required")
    .matches(/^(?!.*\s{2,}).*$/, "Consecutive spaces are not allowed"),
});

export const TariffDeleteScheme = Yup.object().shape({
  // user_id: Yup.string().required("UserId is Required"),
  deleted_reason: Yup.string()
    .required("Delete Reason is Required")
    .max(200)
    .matches(/^(?!.*\s{2,}).*$/, "Consecutive spaces are not allowed"),
});

export const addbankaccountschema = Yup.object({
  bankName: Yup.string()
    .required("Bank Name is required")
    .matches(/^(?!.*\s{2,}).*$/, "Consecutive spaces are not allowed"),
  priority: Yup.string().required("Priority is required"),
  ifsc: Yup.string().required("IFSC Code is required"),
  // micr: Yup.string().required("MICR Code is required"),
  accountnumber: Yup.string().required("Account Number is required"),
});

export const addnomineeschema = Yup.object({
  nomineeId: Yup.string(),
  fullname: Yup.string().required("Name is required"),
  dob: Yup.string().required("dob is required"),
  number: Yup.string()
    .required("Mobile Number is Required")
    .max(10)
    .min(8)
    .matches(/^[0-9 ]*$/, "Enter Valid number"),
  relationship: Yup.string().required("relation is required"),
  share: Yup.number().required("share is required"),
  email: Yup.string(),
  address: Yup.string(),
  pincode: Yup.string(),
  city: Yup.string(),
  state: Yup.string(),
  country: Yup.string(),
  gname: Yup.string(),
  gdob: Yup.string(),
  gemail: Yup.string(),
  gnumber: Yup.string(),
  grelation: Yup.string(),
  gpincode: Yup.string(),
  gaddress: Yup.string(),
  gstate: Yup.string(),
  gcity: Yup.string(),
  gcountry: Yup.string(),
});
export const addVendorschema = Yup.object({
  email: Yup.string().email().required("Email is Required"),
  mobilenumber: Yup.string()
    .required("Mobile Number is Required")
    .max(10)

    .matches(/^[0-9 ]*$/, "Enter Valid Name"),
  introducercode: Yup.string().required("Introducer Code is Required"),

  pannumber: Yup.string()
    .required("PanNumber is Required")
    .max(10)
    .matches(/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/, "Enter Valid PanNumber")
    .transform((value) => value && value.toUpperCase()),
  bankName: Yup.string().required("Bank Name is required"),
  branch: Yup.string(),
  ifsc: Yup.string().required("IFSC Code is required"),
  accountnumber: Yup.string()
    .required("Account Number is Required")
    .min(6)
    .max(18)
    .matches(/^[a-zA-Z0-9]*$/, "Enter Valid Account Number")
    .transform((value) => value && value.toUpperCase()),
  address1: Yup.string().required("Address1 is required"),
  address2: Yup.string(),
  address3: Yup.string(),
  pincode: Yup.string()
    .required("Pincode is Required")
    .max(6)
    .matches(/^[0-9 ]*$/, "Enter Valid Name"),
  country: Yup.string().required("Country is required"),
});
const isNomineeBelow18 = (nomineeDateOfBirth) => {
  // Implement your logic to check if the nominee is below 18 years old
  const dob = new Date(nomineeDateOfBirth);
  const today = new Date();
  const age = today.getFullYear() - dob.getFullYear();

  // console.log("age", age);
  return age;
};

const isGuardianRequired = (relationShip) => {
  // Implement your logic to check if guardian is required based on the relationship
  const requiredRelationships = [
    "Son",
    "Daughter",
    "Grand son",
    "Grand daughter",
    "Others",
    "Brother",
    "Sister",
  ];
  return (
    relationShip.length > 0 &&
    requiredRelationships.includes(relationShip[0].value)
  );
};

export const schema = Yup.object().shape({
  nominees: Yup.array().of(
    Yup.object().shape({
      nomineeFullName: Yup.string()
        .required("Nominee Name is Required")
        .max(50)
        .matches(/^[a-zA-Z ]*$/, "Enter Valid Name")
        .min(3, "Minimum 3 characters is required")
        .matches(/^(?!.*\s{2,}).*$/, "Consecutive spaces are not allowed")
        .transform((value) => value && value.toUpperCase()),

      nomineeEmail: Yup.string()
        .required("Email is Required")
        .max(50)
        .matches(
          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
          "Enter Valid Email"
        ),

      nomineeDateofBirth: Yup.string()
        .required("Nominee Date of Birth is required")
        .test({
          name: "valid-age",
          message: "Age must be between 1 and 100",
          test: function (value) {
            if (!value) return true; // Let the required validation handle empty values

            const currentDate = new Date();
            const dob = new Date(value);

            if (isNaN(dob)) {
              return this.createError({
                message: "Enter Valid Date", // Custom error message for invalid date
                path: "nomineeDateofBirth", // Field name
              });
            }

            const ageInYears =
              (currentDate - dob) / (365.25 * 24 * 60 * 60 * 1000); // Age in years

            return ageInYears >= 0 && ageInYears <= 100;
          },
        }),

      nomineeMobileNumber: Yup.string()
        .required("Mobile Number is Required")
        .max(10)

        .matches(/^[0-9]{10}$/, "Enter Valid Number"),

      percentageofShare: Yup.string()
        .required("Percentage is Required")
        .max(3)
        .matches(/^[0-9]+%*$/, "Enter Valid Percentage"),
      nomineeAddress: Yup.string()
        .required("Address is Required")
        .min(3, "Minimum 3 characters is required"),

      nomineeAddressPinCode: Yup.string()
        .required("Pincode is Required")
        .max(6)
        .min(3, "Minimum 3 characters is required")
        .matches(/^(?!.*\s{2,}).*$/, "Consecutive spaces are not allowed"),
      nomineeAddressCity: Yup.string()
        .required("City is Required")
        .min(3, "Minimum 3 characters is required")
        .matches(/^(?!.*\s{2,}).*$/, "Consecutive spaces are not allowed")
        .max(50),
      nomineeAddressState: Yup.object().required("State is Required"),

      relationShip: Yup.object().required("Please select an option"),
      relationshipIfOthers: Yup.string().when(
        "relationShip",
        (relationShip, scheme) => {
          // console.log("relationShip", relationShip);
          if (
            relationShip.length &&
            relationShip[0] &&
            relationShip[0].value === "Others"
          ) {
            return schema
              .required("Relationship is Required")
              .matches(/^[a-zA-Z ]*$/, "Enter Valid Relation")
              .max(50)
              .min(3, "Minimum 3 characters is required")
              .matches(/^(?!.*\s{2,}).*$/, "Consecutive spaces are not allowed")
              .transform((value) => value && value.toUpperCase());
          }

          return schema;
        }
      ),

      gaurdianFullName: Yup.string()
        .matches(/^[a-zA-Z ]*$/, "Enter Valid Name")
        .max(50)
        .when(
          ["relationShip", "nomineeDateofBirth"],
          (relationShip, schema) => {
            //  console.log("relationShip", relationShip);
            if (
              isNomineeBelow18(relationShip[1]) < 18 &&
              isGuardianRequired(relationShip)
            ) {
              return schema
                .required("Please Enter Gaurdian Name")
                .min(3, "Minimum 3 characters is required")
                .matches(
                  /^(?!.*\s{2,}).*$/,
                  "Consecutive spaces are not allowed"
                )
                .matches(/^[a-zA-Z ]*$/, "Enter Valid Relation");
              // .transform((value: any) => value && value.toUpperCase());
            }
          }
        ),
      gaurdianDateofBirth: Yup.string().when(
        ["relationShip", "nomineeDateofBirth"],
        (relationShip, schema) => {
          // console.log("relationShip", relationShip);
          if (
            isNomineeBelow18(relationShip[1]) < 18 &&
            isGuardianRequired(relationShip)
          ) {
            return schema
              .required("Gaurdian Date of Birth is a required")
              .test({
                name: "valid-age",
                message: "Age must be between 1 and 100",
                test: function (value) {
                  if (!value) return true; // Let the required validation handle empty values

                  const currentDate = new Date();
                  const dob = new Date(value);

                  if (isNaN(dob)) {
                    return this.createError({
                      message: "Enter Valid Date", // Custom error message for invalid date
                      path: "gaurdianDateofBirth", // Field name
                    });
                  }

                  const ageInYears =
                    (currentDate - dob) / (365.25 * 24 * 60 * 60 * 1000); // Age in years

                  return ageInYears >= 0 && ageInYears <= 100;
                },
              });
          }
        }
      ),

      gaurdianEmail: Yup.string()
        .matches(
          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
          "Enter Valid Email"
        )
        .max(50)
        .when(
          ["relationShip", "nomineeDateofBirth"],
          (relationShip, schema) => {
            // console.log("relationShip", relationShip);
            if (
              isNomineeBelow18(relationShip[1]) < 18 &&
              isGuardianRequired(relationShip)
            ) {
              return schema.required("Gaurdian Email is a required");
            }
          }
        ),

      gaurdianMobileNumber: Yup.string()
        .matches(/^[0-9]{10}$/, "Enter Valid Number")
        .when(
          ["relationShip", "nomineeDateofBirth"],
          (relationShip, schema) => {
            // console.log("relationShip", relationShip);
            if (
              isNomineeBelow18(relationShip[1]) < 18 &&
              isGuardianRequired(relationShip)
            ) {
              return schema.required("Gaurdian PhoneNumber is a required");
            }
          }
        ),

      gaurdianRelationWithNominee: Yup.object().when(
        ["relationShip", "nomineeDateofBirth"],
        (relationShip, schema) => {
          // console.log("relationShip", relationShip);
          if (
            isNomineeBelow18(relationShip[1]) < 18 &&
            isGuardianRequired(relationShip)
          ) {
            return schema.required(
              "Gaurdian Relation With Nominee is a required"
            );
          }
        }
      ),
      gaurdianAddress: Yup.string().when(
        ["relationShip", "nomineeDateofBirth"],
        (relationShip, schema) => {
          // console.log("relationShip", relationShip);
          if (
            isNomineeBelow18(relationShip[1]) < 18 &&
            isGuardianRequired(relationShip)
          ) {
            return schema
              .required("Gaurdian Address is required")
              .min(3, "Minimum 3 characters is required")
              .max(250);
          }
        }
      ),
      gaurdianAddressPinCode: Yup.string().when(
        ["relationShip", "nomineeDateofBirth"],
        (relationShip, schema) => {
          // console.log("relationShip", relationShip);
          if (
            isNomineeBelow18(relationShip[1]) < 18 &&
            isGuardianRequired(relationShip)
          ) {
            return schema
              .required("Gaurdian PinCode is required")
              .min(3, "Minimum 3 characters is required")
              .matches(/^(?!.*\s{2,}).*$/, "Consecutive spaces are not allowed")
              .max(6);
          }
        }
      ),
      gaurdianAddressCity: Yup.string().when(
        ["relationShip", "nomineeDateofBirth"],
        (relationShip, schema) => {
          // console.log("relationShip", relationShip);
          if (
            isNomineeBelow18(relationShip[1]) < 18 &&
            isGuardianRequired(relationShip)
          ) {
            return schema
              .required("Gaurdian City is required")
              .min(3, "Minimum 3 characters is required")
              .matches(/^(?!.*\s{2,}).*$/, "Consecutive spaces are not allowed")
              .max(50);
          }
        }
      ),
      gaurdianAddressState: Yup.object().when(
        ["relationShip", "nomineeDateofBirth"],
        (relationShip, schema) => {
          // console.log("relationShip", relationShip);
          if (
            isNomineeBelow18(relationShip[1]) < 18 &&
            isGuardianRequired(relationShip)
          ) {
            return schema.required("Gaurdian State is required");
          }
        }
      ),

      nomineeIDProof: Yup.string().notRequired(),
      nomineeIDProofNo: Yup.string()
        .notRequired()
        .when("nomineeIDProof", (nomineeIDProof, schema) => {
          if (nomineeIDProof[0] === "Aadhaar Card")
            return schema
              .notRequired()
              .length(12, "Enter Valid Aadhaar Number")
              .matches(/^([0-9])/, "Enter Valid Aadhaar Number")
              .transform((value) => value && value.toUpperCase());

          if (nomineeIDProof[0] === "Pan Card")
            return schema
              .notRequired()
              .length(10, "Enter Valid Pan Number")
              .matches(
                /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
                "Enter Valid Pan Number"
              )
              .transform((value) => value && value.toUpperCase());

          return schema;
        }),
      // nomineePanCardNo: Yup.string().when("nomineeIDProof", {
      //   is: "panCardNo",
      //   then(schema) {
      //     return schema.required(" Nominee ID Number is a required");
      //   },
      // }),
    })
  ),
});
