import React, { useEffect, useState } from "react";
import DataTableComponent from "../../components/DataTableComponent/DataTableComponent";
import {
  Button,
  Chip,
  CircularProgress,
  FormControl,
  Icon,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import { Helmet } from "react-helmet";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import {
  AssignClientAPICall,
  ListUsersAPICall,
  permanentDeleteClientAPICall,
  GetRekycDashboardAPICall,
  GetRekycStagesAPICall,
  GetRekycclientLogsDataAPICall,
  RekycAssignUserAPICall,
  AddClientAPICall,
  RekycpermanentDeleteClientAPICall,
} from "../../Utils/APICalls";
import {
  IClientsData,
  IRekycAssignUser,
  IRekycDashboardData,
} from "../../Utils/dtos";
import Tooltip from "@mui/material/Tooltip";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import stagesData from "../../Utils/rekycstages.json";
import SucessSnackBar from "../../components/SnackBar/SuccessSnackBar";
import HistoryIcon from "@mui/icons-material/History";
import dayjs, { Dayjs } from "dayjs";
import { REKYCADMINLOGINLINK } from "../../Utils/constants";
import DataTableComponentRekyc from "../../components/DataTableComponent Rekyc/DataTableComponentRekyc";
import RekycClientDetailspopup from "../../components/Modals/RekycClientDetailsPopUp/RekycClientDetailspopup";
import RekycClientLogsModal from "../../components/Modals/RekycClientLogsPopUpModal/RekycClientlogsPopUpModal";
import RekycAssignUser from "../../components/Modals/RekycAssignUser/RekycAssignUser";
import classes from "./NewKyc.module.scss";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import FiltersRekyc from "../../components/FiltersRekyc/FiltersRekyc";
import { confirmAlert } from "react-confirm-alert";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import ErrorSnackBar from "../../components/SnackBar/ErrorSnackBar";
import Close from "@mui/icons-material/Close";
import AddCircleOutline from "@mui/icons-material/AddCircleOutline";
import AssignUser from "../../components/Modals/AssignUser/AssignUser";

const titletextStyle = {
  fontSize: "14px",
  fontWeight: "bold",
  color: "#0F1419",
  cursor: "pointer",
};
const contenttextStyle = { fontSize: "12px", color: "#555555" };

const Data: any = stagesData;

const mappingValues: any = {
  All: "all",
  "Non Esigned": "non_esigned",
  "Approval Pending": "approval_pending",
  Rejected: "rejected",
  Approved: "approved",
  Deleted: "deleted",
  "Account Opened": "account_opened",
};
const mappingStagesData: any = {
  pan: "Pan details",
  aadhaar: "KRA verification or Digi-locker",
  nominee: "Nominee ",
  esign: "Esign section",

  update_profile: "Personal details",
  bank: "Bank details",
  ipv: "Identification verification",
  upload_document: "Upload documents",
};

export default function ReKYCPage() {
  const [data, setdata] = useState([]);
  const [loading, setloading] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedTab, setselectedTab] = useState<string>("All");
  const [totalRows, setTotalRows] = useState(5);
  const [perPage, setPerPage] = useState(20);
  const [rekycClientDetailspopup, setclientDetailsPopup] = useState({
    status: false,
    data: "",
  });

  const [clientLogsPopup, setclientLogsPopup] = useState({
    status: false,
    data: {
      DeletedTS: "",
      StartedTS: "",
      ApprovedTS: "",
      RejectedTS: "",
      CompletedTS: "",
      DigilockerTS: "",
      stage_ipv_TS: "",
      EmailVerifiedAt: "",
      MobileVerifiedAt: "",
      stage_nominee_TS: "",
      stage_pdf_esigner_TS: "",
      stage_update_profile_TS: "",
      stage_upload_document_TS: "",
    },
  });
  const [clientId, setClientId] = useState("");
  const [userName, setUserName] = useState("");
  const [clientMessage, setClientMessage] = useState("");
  const [stagesData, setStagesData] = useState<any>();
  const [users, setusers] = useState<any>();
  const [searchData, setSearchData] = useState("");
  const [stageFilterData, setStageFilterData] = useState("");
  const [userFilterData, setUserFilterData] = useState("");
  const [rejectedReason, setRejectedReason] = useState("");
  const [deletedReason, setDeletedReason] = useState("");
  const [pagenumber, setPageNumber] = useState(1);
  const [paginationCount, setpaginationCount] = useState(1);
  const [recordsCount, setRecordsCount] = useState(0);
  const [stageName, setStageName] = useState<any>("all");
  const [sortactivity, setsortactivity] = useState<any>("DESC");
  const [clientDeleted, setClientDeleted] = useState(false);
  const [clientCompleted, setClientCompleted] = useState(false);

  const [stageFilter, setStageFilter] = useState<any>("");
  const [userId, setUserId] = useState<any>("");
  const [openFilters, setOpenFilters] = useState(false);
  const [displayClientAdd, setDisplayClientAdd] = useState(false);
  const [clientCodeEntered, setClientcodeEntered] = useState("");
  const [addLoading, setAddLoading] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");

  const isAdmin = sessionStorage.getItem("isAdmin");

  function formatDateChange(inputDate: any) {
    const dateParts = inputDate.split("-"); // Split the input date into parts
    const formattedDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`; // Rearrange the parts in the desired format
    return formattedDate;
  }

  const columns = [
    {
      name: <b style={titletextStyle}>SrNo</b>,
      selector: "srno",
      sortable: true,
      width: "60px",
      cell: (row: any, index: any) => (
        <b style={contenttextStyle}>{index + 1 + paginationCount * 20 - 20}</b>
      ),
    },

    {
      name: <b style={titletextStyle}>PAN</b>,
      selector: "pan_no",
      sortable: true,
      width: "100px",
      cell: (row: any) => (
        <Tooltip title={!row.is_completed ? "Click to Login" : ""}>
        <b
          style={{ fontSize: "12px", cursor: "pointer" }}
          onClick={() => {
            if (!row.is_completed) {
              handleAdminLogintoClientAccount(row);
            }
          }}
        >
          {row.pan_no}
        </b>
        </Tooltip>
      ),
    },
    {
      name: <b style={titletextStyle}>Name</b>,
      selector: "client_name",
      sortable: true,

      cell: (row: any) => <b style={contenttextStyle}>{row.client_name}</b>,
    },
    {
      name: <b style={titletextStyle}>CCode</b>,
      selector: "client_code",
      sortable: true,
      width: "80px",
      cell: (row: any) => <b style={contenttextStyle}>{row.client_code}</b>,
    },
    {
      name: <b style={titletextStyle}>Email</b>,
      selector: "email",
      width: "200px",
      sortable: true,
      cell: (row: any) => <b style={contenttextStyle}>{row.email}</b>,
    },
    {
      name: <b style={titletextStyle}>Mobile</b>,
      selector: "mobile",
      sortable: true,
      width: "105px",
      cell: (row: any) => <b style={contenttextStyle}>{row.mobile}</b>,
    },
    {
      name: (
        <b
          style={titletextStyle}
          // onClick={() => {
          //   if(activitySort === "ASC"){
          //     setactivitySort("DESC")
          //   } else if(activitySort === "DESC"){
          //     setactivitySort("ASC")
          //   }
          // }}
        >
          Stage
        </b>
      ),
      selector: "current_stage",
      sortable: true,
      width: "100px",
      cell: (row: any) => (
        <b style={contenttextStyle}>
          {row?.rejected_reason ? (
            <>
              <span style={{ color: "red" }}>Rej-</span>
              {Data.hasOwnProperty(row.current_stage) &&
                Data[row.current_stage]}
            </>
          ) : (
            Data.hasOwnProperty(row.current_stage) && Data[row.current_stage]
          )}
        </b>
      ),
    },
    // {
    //   name: <b style={titletextStyle}>Source</b>,
    //   selector: "sources",
    //   sortable: true,
    //   width: "80px",
    //   cell: (row: any) => <b style={contenttextStyle}>{row.sources}</b>,
    // },

    // {
    //   name: (
    //     <b
    //       style={titletextStyle}
    //       onClick={() => {
    //         if (sortactivity === "ASC") {
    //           setsortactivity("DESC");
    //         } else if (sortactivity === "DESC") {
    //           setsortactivity("ASC");
    //         }
    //       }}
    //     >
    //       Activity
    //     </b>
    //   ),
    //   selector: "pending_days",
    //   sortable: false,

    //   cell: (row: any) => <b style={contenttextStyle}>{row.pending_days}</b>,
    // },
    {
      name: <b style={titletextStyle}>User</b>,
      selector: "handling_user",
      sortable: true,
      width: "100px",
      cell: (row: any) => (
        <>
          {row.handling_user === "" ? (
            <Tooltip title="Assign User ">
              <IconButton
                onClick={() => {
                  handleAssign(row);
                }}
              >
                <PersonIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <b
              style={{ fontSize: "12px", cursor: "pointer" }}
              onClick={() => {
                handleAssign(row);
              }}
            >
              {row.handling_user}
            </b>
          )}
        </>
      ),
    },
    {
      name: <b style={titletextStyle}>Actions</b>,
      selector: "spamReportRate",
      sortable: false,
      allowOverflow: true,
      button: true,
      width: "160px",
      cell: (row: any) => (
        <b style={contenttextStyle}>
          {/* {mappingValues[selectedTab] === "approved" ? (
            <Tooltip title="KRA Upload">
              <IconButton
                onClick={() => {
                  handleViewClientDetails(row);
                }}
              >
                <CloudUploadIcon />
              </IconButton>
            </Tooltip>
          ) : (
            ""
          )} */}

          <Tooltip title="View Details">
            <IconButton
              onClick={() => {
                handleViewClientDetails(row);
              }}
            >
              <RemoveRedEyeOutlinedIcon style={{ color: "#383838" }} />
            </IconButton>
          </Tooltip>

          <Tooltip title="Logs">
            <IconButton
              onClick={() => {
                handleClientLogs(row);
              }}
            >
              <HistoryIcon />
            </IconButton>
          </Tooltip>
        </b>
      ),
    },
  ];
  const columnsForDeletedAndRejected = [
    {
      name: <b style={titletextStyle}>SrNo</b>,
      selector: "srno",
      sortable: true,
      width: "60px",
      cell: (row: any, index: any) => (
        <b style={contenttextStyle}>{index + 1 + paginationCount * 20 - 20}</b>
      ),
    },

    {
      name: <b style={titletextStyle}>PAN</b>,
      selector: "pan_no",
      sortable: true,
      cell: (row: any) => (
        <Tooltip title={!row.is_completed ? "Click to Login" : ""}>
        <b
          style={{ fontSize: "12px", cursor: "pointer" }}
          onClick={() => {
            if (!row.is_completed) {
              handleAdminLogintoClientAccount(row);
            }
          }}
        >
          {row.pan_no}
        </b>
        </Tooltip>
      ),
    },
    {
      name: <b style={titletextStyle}>Name</b>,
      selector: "client_name",
      sortable: true,

      cell: (row: any) => <b style={contenttextStyle}>{row.client_name}</b>,
    },
    {
      name: <b style={titletextStyle}>CCode</b>,
      selector: "client_code",
      sortable: true,
      width: "80px",
      cell: (row: any) => <b style={contenttextStyle}>{row.client_code}</b>,
    },
    {
      name: <b style={titletextStyle}>Email</b>,
      selector: "email",
      width: "200px",
      sortable: true,
      cell: (row: any) => <b style={contenttextStyle}>{row.email}</b>,
    },
    {
      name: <b style={titletextStyle}>Mobile</b>,
      selector: "mobile",
      sortable: true,
      width: "110px",
      cell: (row: any) => <b style={contenttextStyle}>{row.mobile}</b>,
    },
    {
      name: <b style={titletextStyle}>Stage</b>,
      selector: "current_stage",
      sortable: true,
      // width: "80px",
      cell: (row: any) => (
        <b style={contenttextStyle}>
          {row?.rejected_reason ? (
            <>
              <span style={{ color: "red" }}>Rej-</span>
              {Data.hasOwnProperty(row.current_stage) &&
                Data[row.current_stage]}
            </>
          ) : (
            Data.hasOwnProperty(row.current_stage) && Data[row.current_stage]
          )}
          {/* {Data.hasOwnProperty(row.current_stage) && Data[row.current_stage]} */}
        </b>
      ),
    },
    {
      name: <b style={titletextStyle}>User</b>,
      selector: "handling_user",
      sortable: true,
      width: "100px",
      cell: (row: any) => (
        <>
          {row.handling_user === "" ? (
            <Tooltip title="Assign User ">
              <IconButton
                onClick={() => {
                  handleAssign(row);
                }}
              >
                <PersonIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <b
              style={{ fontSize: "12px", cursor: "pointer" }}
              onClick={() => {
                handleAssign(row);
              }}
            >
              {row.handling_user}
            </b>
          )}
        </>
      ),
    },

    {
      name: <b style={titletextStyle}>Reason</b>,
      selector: "reason",
      sortable: true,

      cell: (row: any) => (
        <b style={contenttextStyle}>
          {(row.rejected_reason && row.rejected_reason) ||
            (!row.rejected_reason && row.deleted_reason)}
        </b>
      ),
    },

    {
      name: <b style={titletextStyle}>Actions</b>,
      selector: "spamReportRate",
      sortable: false,
      allowOverflow: true,
      button: true,
      width: "130px",
      cell: (row: any) => (
        <b style={contenttextStyle}>
          {mappingValues[selectedTab] === "approved" ? (
            <Tooltip title="Upload">
              <IconButton
                onClick={() => {
                  handleViewClientDetails(row);
                }}
              >
                <CloudUploadIcon />
              </IconButton>
            </Tooltip>
          ) : (
            ""
          )}
          <Tooltip title="View Details">
            <IconButton
              onClick={() => {
                handleViewClientDetails(row);
                if (row.rejected_reason) {
                  setRejectedReason(row.rejected_reason);
                }
                if (row.deleted_reason) {
                  setDeletedReason(row.deleted_reason);
                }
              }}
            >
              <RemoveRedEyeOutlinedIcon style={{ color: "#383838" }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Logs">
            <IconButton
              onClick={() => {
                handleClientLogs(row);
              }}
            >
              <HistoryIcon />
            </IconButton>
          </Tooltip>
        </b>
      ),
    },
  ];

  useEffect(() => {
    GetRekycStagesAPICall()
      .then((response: any) => {
        const responseData = response.data;
        setStagesData(responseData.data.stages);
      })
      .catch((error: any) => {
        //  console.log("error", error);
      });

    ListUsersAPICall()
      .then((response: any) => {
        const responseData = response.data;
        setusers(responseData.data.data);
        // console.log(responseData.data.user_list);
      })
      .catch((error: any) => {});
  }, []);

  useEffect(() => {
    setRecordsCount(0);
    setdata([]);
    const delayDebounceFn = setTimeout(() => {
      GetRekycDashboard();
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [
    selectedTab,
    searchData,
    stageFilterData,
    userId,
    stageName,
    pagenumber,
    sortactivity,
  ]);

  const GetRekycDashboard = () => {
  
    setloading(true);
    let obj: any = {
      page_no: pagenumber,
      page_size: perPage,
      query: searchData ,
      type: stageName !== "" ? stageName : mappingValues[selectedTab],
      filter_user: userId ? userId?.full_name : "",
      filter_stage: stageFilterData,
      sort_activity: "",
    };
    GetRekycDashboardAPICall(obj)
      .then((response: any) => {
        // console.log("rekycDashboard", response.data.data);

        setdata(response.data.data.data ? response.data.data.data : []);

        setTotalRows(Math.ceil(response.data.data.total_count / 20));
        setRecordsCount(
          response.data.data.total_count ? response.data.data.total_count : 0
        );
        setloading(false);
      })
      .catch((error: any) => {
        // console.log("error", error);
        setloading(false);
      });
  };

  const handleClientLogs = (data: any) => {
    if (data.handling_user === "") {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="confitmalertUI">
              <PersonIcon />
              <p>Please assign an user</p>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <button
                  onClick={() => {
                    onClose();
                  }}
                  className="confitmbutton"
                >
                  OK
                </button>
              </div>
            </div>
          );
        },
      });
      // window.alert("Please Asign User");
    } else {
      //  console.log(data.logs);
      setclientLogsPopup({
        status: true,
        data: data.logs,
      });
    }
  };
  const handleCloseClientLogsPopup = () => {
    setclientLogsPopup({
      status: false,
      data: {
        DeletedTS: "",
        StartedTS: "",
        ApprovedTS: "",
        RejectedTS: "",
        CompletedTS: "",
        DigilockerTS: "",
        stage_ipv_TS: "",
        EmailVerifiedAt: "",
        MobileVerifiedAt: "",
        stage_nominee_TS: "",
        stage_pdf_esigner_TS: "",
        stage_update_profile_TS: "",
        stage_upload_document_TS: "",
      },
    });
  };

  const handleSearchChange = (data: any) => {
    setSearchData(data);
    setPageNumber(1);
    setpaginationCount(1);
    // getClients(1, perPage, data);
  };

  const handleStageChange = (data: any) => {
    setPageNumber(1);
    setpaginationCount(1);
    setStageFilterData(data);

    // getClients(1, perPage, data);
  };
  const handleUserChange = (data: any) => {
    setPageNumber(1);
    setUserFilterData(data);
    setpaginationCount(1);
    // getClients(1, perPage, data);
  };

  const handleAssign = (data: any) => {
    if (isAdmin === "false") {
      const obj: any = {
        client_id: data._id,
        admin_id: sessionStorage.getItem("userId"),
      };
      AssignClientAPICall(obj).then((response: any) => {
        //  console.log("response", response);
        alert("Assigned to me");
      });
    } else {
      setOpen(true);
      setClientId(data._id);
    }
  };

  const handleViewClientDetails = (data: any) => {
    // console.log("clientData", data);
    if (data.handling_user === "") {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="confitmalertUI">
              <PersonIcon />
              <p>Please assign an user</p>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <button
                  onClick={() => {
                    onClose();
                  }}
                  className="confitmbutton"
                >
                  OK
                </button>
              </div>
            </div>
          );
        },
      });
    } else {
      setclientDetailsPopup({
        status: true,
        data: data._id,
      });
      setClientId(data._id);
      setClientDeleted(data.is_deleted);
      setClientCompleted(data.is_completed);
    }
  };
  const handleAdminLogintoClientAccount = (data: any) => {
    if (!data.completed_ts) {
      window.open(
        `${REKYCADMINLOGINLINK}?client_id=${
          data._id
        }&user_id=${sessionStorage.getItem("userId")}`,
        "_blank"
      );
    }
  };

  const handleCloseClientDetailsPopup = (data: any) => {
    sessionStorage.removeItem(
      `${rekycClientDetailspopup.data}_aadhar_client_photo`
    );
    sessionStorage.removeItem(
      `${rekycClientDetailspopup.data}_webcam_client_photo`
    );

    sessionStorage.removeItem(`${rekycClientDetailspopup.data}_income_proof`);
    sessionStorage.removeItem(`${rekycClientDetailspopup.data}_e_sign_pdf`);

    sessionStorage.removeItem(`${rekycClientDetailspopup.data}_pancard`);

    //  console.log("data", data);
    setclientDetailsPopup({
      status: false,
      data: "",
    });

    // getClients(1, perPage, "", "", "", "", "");

    if (data === "Deleted") {
      GetRekycDashboard();
      setClientMessage("Client Deleted Successfully");
      setTimeout(() => {
        setClientMessage("");
      }, 5000);
    } else if (data === "Client Approved") {
      GetRekycDashboard();
      setClientMessage("Client Approved Successfully");
      setTimeout(() => {
        setClientMessage("");
      }, 5000);
    } else if (data === "Client Rejected") {
      GetRekycDashboard();
      setClientMessage("Client Rejected Successfully");
      setTimeout(() => {
        setClientMessage("");
      }, 5000);
    } else {
      setClientMessage(""); // Reset the message for other cases
    }
  };

  const handlePermanentDeleteClient = () => {
    //  console.log("data", data);
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="confitmalertUI">
            <DeleteOutlineRoundedIcon />
            <p>Are you sure, client will be permanently deleted</p>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "20px",
              }}
            >
              <button
                onClick={() => {
                  onClose();
                }}
                className="confitmbutton"
              >
                No
              </button>
              <button
                onClick={() => {
                  onClose();
                  let reqObj: any = {
                    user_id: clientId,
                  };

                  RekycpermanentDeleteClientAPICall(reqObj)
                    .then((response: any) => {
                      const responseData = response.data;
                      GetRekycDashboard();
                      setClientMessage("Client Deleted Successfully");
                      setclientDetailsPopup({
                        status: false,
                        data: "",
                      });
                      setTimeout(() => {
                        setClientMessage("");
                      }, 5000);
                    })
                    .catch((error) => {
                      if (error.response.data.message) {
                        seterrorMessage(error.response.data.message);
                        setTimeout(() => {
                          seterrorMessage("");
                        }, 5000);
                      }
                    });
                }}
                className="confitmbutton"
              >
                Yes
              </button>
            </div>
          </div>
        );
      },
    });
  };

  const handleAssignePopUpClose = () => {
    setOpen(false);
  };

  const handleAssigneID = (data: any) => {
    setOpen(false);

    const obj: IRekycAssignUser = {
      client_id: clientId,
      admin_id: data,
    };

  
    RekycAssignUserAPICall(obj)
      .then((response: any) => {
        // alert(`Assigned to me ${response.data.data.User_name}`);
        setUserName(response.data.data.User_name);
        GetRekycDashboard();
      })
      .catch((error: any) => {});
  };

  const handleSubmitDetailsPopup = (data: any) => {
    //  console.log("data", data);
  };

  const handlePageChange = (value: any, page: number) => {
    // getClients(page, perPage, "", "", "", "", "");
    setpaginationCount(page);
    setPageNumber(page);
  };

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setPerPage(newPerPage);
    GetRekycDashboard();
  };
  const handleSelectChange = (event: SelectChangeEvent) => {
    setpaginationCount(1);
    setStageName(event.target.value as string);

    setPageNumber(1);
  };
  const handleClearFilters = () => {
    setSearchData("");
    setUserId("");
    setUserFilterData("");
    setStageFilter("");
    setStageFilterData("");
  };
  const handleCloseFilters = () => {
    setOpenFilters(false);
  };
  const handleSumbitAddclient = () => {
    let obj: any = {
      client_code: clientCodeEntered,
    };
    setAddLoading(true);
    AddClientAPICall(obj)
      .then((response: any) => {
        setAddLoading(false);
        setClientcodeEntered("");
        setClientMessage("Client Added Successfully");
        GetRekycDashboard();
        setTimeout(() => {
          setClientMessage("");
        }, 5000);
      })
      .catch((error: any) => {
        setAddLoading(false);
        if (error.response.data.message) {
          seterrorMessage(error.response.data.message);
          setTimeout(() => {
            seterrorMessage("");
          }, 5000);
        }
      });
  };

  return (
    <div style={{ marginTop: "6px", width: "100%" }}>
      <Helmet>
        <title>Re KYC </title>
        <meta name="New KYC" content="New KYC" />
      </Helmet>
      <ErrorSnackBar message={errorMessage} />
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "space-between",
          position: "relative",
        }}
      >
        <div className={classes.category}>
          <FormControl sx={{ width: "230px" }} size="small">
            <InputLabel id="demo-simple-select-label">Category</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={stageName}
              name="selectStage"
              label="Category"
              onChange={handleSelectChange}
            >
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="non_esigned">Non Esigned</MenuItem>
              <MenuItem value="approval_pending">Approval Pending</MenuItem>
              <MenuItem value="rejected">Rejected</MenuItem>
              <MenuItem value="approved">Approved</MenuItem>
              <MenuItem value="deleted">Deleted</MenuItem>
              <MenuItem value="account_opened">Account Opened</MenuItem>
            </Select>
          </FormControl>
        </div>

        <div className={classes.chipsSection}>
          {/* <p className={classes.appliedFilters}>Applied Filters</p> */}

          {stageFilter && (
            <div className={classes.chips}>
              Stage
              <div>
                <Chip
                  label={mappingStagesData[stageFilter]}
                  variant="outlined"
                  size="small"
                  onDelete={() => {
                    setStageFilter("");
                    setStageFilterData("");
                  }}
                />
              </div>
            </div>
          )}
          {userId && (
            <div className={classes.chips}>
              User
              <div>
                <Chip
                  label={userId?.full_name}
                  variant="outlined"
                  size="small"
                  onDelete={() => {
                    setUserId("");
                    setUserFilterData("");
                  }}
                />
              </div>
            </div>
          )}
        </div>

        <div className={classes.emailListHeader}>
          <Button
            variant="contained"
            onClick={handleClearFilters}
            sx={{
              background: "#8B93FF",
              boxShadow: "none",
              textTransform: "none",
              "&:hover": {
                background: "#8B93FF",
                boxShadow: "none",
              },
            }}
          >
            Clear
          </Button>
          <Button
            variant="contained"
            sx={{
              background: "#8B93FF",
              boxShadow: "none",
              textTransform: "none",
              "&:hover": {
                background: "#8B93FF",
                boxShadow: "none",
              },
            }}
            onClick={() => {
              setDisplayClientAdd(true);
            }}
            startIcon={<AddCircleOutline />}
          >
            Client
          </Button>
          <Stack
            style={{
              position: "absolute",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              top: "0px",
              zIndex: 100,

              background: "white",
            }}
          >
            {displayClientAdd && (
              <TextField
                value={clientCodeEntered?.toUpperCase()}
                onChange={(event: any) => {
                  setClientcodeEntered(event.target.value.toUpperCase());
                }}
                variant="outlined"
                size="small"
                placeholder="Client Code"
                sx={{
                  marginRight: "0px",
                  marginBottom: "15px",
                  marginLeft: "0px",
                  height: "40px",
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button
                        variant="contained"
                        sx={{
                          height: "25px",
                          textTransform: "none",
                          background: "#8B93FF",
                          "&:hover": { background: "#8B93FF" },
                        }}
                        size="small"
                        disabled={
                          clientCodeEntered === "" ||
                          clientCodeEntered?.length < 4
                        }
                        onClick={handleSumbitAddclient}
                      >
                        {addLoading ? (
                          <CircularProgress
                            style={{
                              height: "20px",
                              width: "20px",
                              color: "white",
                            }}
                          />
                        ) : (
                          "Submit"
                        )}
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />
            )}
            {displayClientAdd && (
              <IconButton
                sx={{
                  marginLeft: "0px",
                  marginBottom: "15px",
                }}
                onClick={() => {
                  setClientcodeEntered("");
                  setDisplayClientAdd(false);
                }}
              >
                <Close sx={{ color: "grey" }} />
              </IconButton>
            )}
          </Stack>

          <div className={classes.searchInput}>
            <div className={classes.searchIcon}>
              <SearchIcon style={{ color: "#8B93FF" }} />
            </div>
            <input
              type="text"
              className={classes.inputBox}
              placeholder="Search...."
              value={searchData}
              onChange={(e) => {
                handleSearchChange(e.target.value);
              }}
            />
          </div>

          <Button
            variant="outlined"
            startIcon={<FilterListIcon style={{ color: "#8B93FF" }} />}
            onClick={() => setOpenFilters(true)}
            sx={{
              textTransform: "none",
              color: "#000",
              border: "1px solid #DDDCE1",
              "&:hover": {
                color: "#000",
                border: "1px solid #DDDCE1",
              },
            }}
          >
            Filters
          </Button>
          {openFilters && (
            <FiltersRekyc
              stagesData={stagesData}
              stageFilter={stageFilter}
              setStageFilter={setStageFilter}
              handleStageChange={handleStageChange}
              userId={userId}
              isAdmin={isAdmin}
              setUserId={setUserId}
              handleUserChange={handleUserChange}
              users={users}
              handleCloseFilters={handleCloseFilters}
            />
          )}
        </div>
      </div>

      <div>
        <DataTableComponentRekyc
          columns={
            stageName === "deleted" ||
            stageName === "rejected"
              ? columnsForDeletedAndRejected
              : columns
          }
          // columns={columns}
          data={data}
          loading={loading}
          pagination={true}
          paginationServer={true}
          handlePerRowsChange={handlePerRowsChange}
          handlePageChange={handlePageChange}
          totalRows={totalRows}
          selectedTab={mappingValues[selectedTab]}
          paginationCount={paginationCount}
          recordsCount={recordsCount}
        />
      </div>
      {rekycClientDetailspopup.status && (
        <RekycClientDetailspopup
          data={rekycClientDetailspopup.data}
          handleSubmitData={handleSubmitDetailsPopup}
          open={rekycClientDetailspopup.status}
          handleClose={handleCloseClientDetailsPopup}
          rejectedReason={rejectedReason}
          deletedReason={deletedReason}
          selectedTabNew={mappingValues[selectedTab]}
          handlePermanentDeleteClient={handlePermanentDeleteClient}
          clientDeleted={clientDeleted}
          clientCompleted={clientCompleted}
          stageName={stageName}
        />
      )}

      {clientLogsPopup.status && (
        <RekycClientLogsModal
          open={clientLogsPopup.status}
          handleClose={handleCloseClientLogsPopup}
          handleSubmitData={undefined}
          data={clientLogsPopup.data}
        />
      )}

      <SucessSnackBar message={clientMessage} />
      <AssignUser
        open={open}
        handleClose={handleAssignePopUpClose}
        assigneID={handleAssigneID}
        userData={users}
      />
    </div>
  );
}
