import React, { useEffect, useState } from "react";
import classes from "./GeneralBoard.module.scss";
import SouthEastIcon from "@mui/icons-material/SouthEast";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DeliveryBarChart from "../../components/DeliveryBarChart/DeliveryBarChart";
import EmailDataChart from "../../components/EmailDataChart/EmailDataChart";
import PerformanceDataChart from "../../components/PerformanceDataChart/PerformanceDataChart";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Box, Grid, Paper } from "@mui/material";
import DataTableComponent from "../../components/DataTableComponent/DataTableComponent";
import { GetDashBoardDataAPICall } from "../../Utils/APICalls";
import RocketLaunchOutlinedIcon from "@mui/icons-material/RocketLaunchOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import DoNotDisturbOutlinedIcon from "@mui/icons-material/DoNotDisturbOutlined";
import OfflinePinOutlinedIcon from "@mui/icons-material/OfflinePinOutlined";
import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import PersonAddOutlinedIcon from "@mui/icons-material/PersonAddOutlined";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import UserDataChart from "../../components/UserDataChart/UserDataChart";
import KRADataChart from "../../components/KRADataChart/KRADataChart";

const DataOverall = [
  {
    title: "Overall Leads",
    value: "Overall_leads",
    icon: <RocketLaunchOutlinedIcon style={{ color: "#5D5FEF" }} />,
  },
  {
    title: "Overall Esigned",
    value: "Overall_esigned",
    icon: <LockOutlinedIcon style={{ color: "#5D5FEF" }} />,
  },
  {
    title: "Overall Approved",
    value: "Overall_approved",
    icon: <FactCheckOutlinedIcon style={{ color: "#5D5FEF" }} />,
  },
  {
    title: "Non Esigned",
    value: "NonEsigned",
    icon: <LockOpenOutlinedIcon style={{ color: "#5D5FEF" }} />,
  },
  {
    title: "Approval Pending",
    value: "ApprovalPending",
    icon: <InfoOutlinedIcon style={{ color: "#5D5FEF" }} />,
  },
  {
    title: "Rejected",
    value: "Rejected",
    icon: <DoNotDisturbOutlinedIcon style={{ color: "#5D5FEF" }} />,
  },
  {
    title: "Approved",
    value: "Approved",
    icon: <OfflinePinOutlinedIcon style={{ color: "#5D5FEF" }} />,
  },
  {
    title: "KRA Existing",
    value: "KRA_existing",
    icon: <Person2OutlinedIcon style={{ color: "#5D5FEF" }} />,
  },
  {
    title: "KRA Modified",
    value: "KRA_modified",
    icon: <AccountCircleOutlinedIcon style={{ color: "#5D5FEF" }} />,
  },
  {
    title: "KRA New",
    value: "KRA_new",
    icon: <PersonAddOutlinedIcon style={{ color: "#5D5FEF" }} />,
  },
];
const Data = [
  {
    title: "Todays Leads",
    value: "Todays_leads",
    icon: <RocketLaunchOutlinedIcon style={{ color: "#5D5FEF" }} />,
  },
  {
    title: "Todays Esigned",
    value: "Todays_esigned",
    icon: <LockOutlinedIcon style={{ color: "#5D5FEF" }} />,
  },
  {
    title: "Todays Approved",
    value: "Todays_approved",
    icon: <FactCheckOutlinedIcon style={{ color: "#5D5FEF" }} />,
  },
  {
    title: "Non Esigned",
    value: "NonEsigned",
    icon: <LockOpenOutlinedIcon style={{ color: "#5D5FEF" }} />,
  },
  {
    title: "Approval Pending",
    value: "ApprovalPending",
    icon: <InfoOutlinedIcon style={{ color: "#5D5FEF" }} />,
  },
  {
    title: "Rejected",
    value: "Rejected",
    icon: <DoNotDisturbOutlinedIcon style={{ color: "#5D5FEF" }} />,
  },
  {
    title: "Approved",
    value: "Approved",
    icon: <OfflinePinOutlinedIcon style={{ color: "#5D5FEF" }} />,
  },
  {
    title: "KRA Existing",
    value: "KRA_existing",
    icon: <Person2OutlinedIcon style={{ color: "#5D5FEF" }} />,
  },
  {
    title: "KRA Modified",
    value: "KRA_modified",
    icon: <AccountCircleOutlinedIcon style={{ color: "#5D5FEF" }} />,
  },
  {
    title: "KRA New",
    value: "KRA_new",
    icon: <PersonAddOutlinedIcon style={{ color: "#5D5FEF" }} />,
  },
];

const GeneralBoard = () => {
  const [bgColor, setbgColor] = useState("rgb(235, 231, 231)");
  const [dashBoardData, setDashboarddata] = useState<any>({
    Todays_leads: "0",
    Todays_esigned: "0",
    Todays_approved: "0",
    Approved: "0",
    Rejected: "0",
    NonEsigned: "0",
    ApprovalPending: "0",
    KRA_existing: "0",
    KRA_modified: "0",
    KRA_new: "0",
  });
  const [dashBoardDataOverall, setDashboarddataOverall] = useState<any>({
    Overall_leads: "0",
    Overall_esigned: "0",
    Overall_approved: "0",
    Approved: "0",
    Rejected: "0",
    NonEsigned: "0",
    ApprovalPending: "0",
    KRA_existing: "0",
    KRA_modified: "0",
    KRA_new: "0",
  });

  const [tab, setTab] = useState(1);
  function activeTab(tabNumber: any) {
    setTab(tabNumber);
  }

  useEffect(() => {
    GetDashBoardDataAPICall()
      .then((response: any) => {
        const responseData = response.data;
        // console.log(responseData.data);
        setDashboarddata({
          Todays_leads: responseData.data.today.Todays_leads,
          Todays_esigned: responseData.data.today.Todays_esigned,
          Todays_approved: responseData.data.today.Todays_approved,
          Approved: responseData.data.today.Approved,
          Rejected: responseData.data.today.Rejected,
          NonEsigned: responseData.data.today.NonEsined,
          ApprovalPending: responseData.data.today.ApprovalPending,
          KRA_existing: responseData.data.today.KRA_existing,
          KRA_modified: responseData.data.today.KRA_modified,
          KRA_new: responseData.data.today.KRA_new,
        });
        setDashboarddataOverall({
          Overall_leads: responseData.data.Overall.Overall_leads,
          Overall_esigned: responseData.data.Overall.Overall_esigned,
          Overall_approved: responseData.data.Overall.Overall_approved,
          Approved: responseData.data.Overall.Approved,
          Rejected: responseData.data.Overall.Rejected,
          NonEsigned: responseData.data.Overall.NonEsined,
          ApprovalPending: responseData.data.Overall.ApprovalPending,
          KRA_existing: responseData.data.Overall.KRA_existing,
          KRA_modified: responseData.data.Overall.KRA_modified,
          KRA_new: responseData.data.Overall.KRA_new,
        });
      })

      .catch((error) => {
        // console.log(error);
      });
  }, []);

  const SelectedData = tab === 1 ? Data : DataOverall;
  const SelectedDashboardData =
    tab === 1 ? dashBoardData : dashBoardDataOverall;

  console.log("SelectedDashboardData", SelectedDashboardData);

  return (
    <>
      <div className={classes.tabsWrapper}>
        <div className={classes.tabsCatagory}>
          <div
            className={classes.tabsItem}
            onClick={() => activeTab(1)}
            style={{
              backgroundColor: tab === 1 ? "#8B93FF" : "",
              color: tab == 1 ? "white" : "",
            }}
          >
            Today Leads
          </div>
          <div
            className={classes.tabsItem}
            onClick={() => activeTab(2)}
            style={{
              backgroundColor: tab === 2 ? "#8B93FF" : "",
              color: tab == 2 ? "white" : "",
            }}
          >
            Overall Leads
          </div>
        </div>
      </div>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          {SelectedData.map((a: any) => (
            <Grid item xs={6} md={3}>
              <div className={classes.data1}>
                <div className={classes.data1Left}>{a.icon}</div>
                <div className={classes.data1Right}>
                  <div className={classes.dataTitle}>{a.title}</div>
                  <div className={classes.dataInfo}>
                    <div className={classes.dataNumInfo}>
                      {SelectedDashboardData[a.value]}
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
          ))}
        </Grid>
      </Box>
      {/* <p className={classes.analytics}>Analytics</p>

      <Box sx={{ flexGrow: 1 }} mb={4}>
        <Grid container spacing={4}>
          <Grid item xs={6} md={7}>
            <div className={classes.performanceData}>
              <div className={classes.pdTitle}>Lead Generation</div>

              <div className={classes.edPlot}>
                <PerformanceDataChart />
              </div>
            </div>
          </Grid>
          <Grid item xs={6} md={5}>
            <div className={classes.emailData}>
              <div className={classes.edTitle}>Esign</div>

              <div className={classes.edPlot}>
                <UserDataChart />
              </div>
              <div className={classes.edDatakey}>
                <div className={classes.edDatakey1}>
                  {" "}
                  <div className={classes.edDatakeyCircle1}></div>{" "}
                  <div className={classes.edDatakeyTitle}>Category 1</div>
                </div>
                <div className={classes.edDatakey2}>
                  <div className={classes.edDatakeyCircle2}></div>{" "}
                  <div className={classes.edDatakeyTitle}>Category 2</div>
                </div>
                <div className={classes.edDatakey3}>
                  <div className={classes.edDatakeyCircle2}></div>{" "}
                  <div className={classes.edDatakeyTitle}>Category 3</div>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={6} md={7}>
            <div className={classes.emailData}>
              <div className={classes.edTitle}>Esign</div>

              <div className={classes.edPlot}>
                <EmailDataChart />
              </div>
            </div>
          </Grid>
          <Grid item xs={6} md={5}>
            <div className={classes.emailDataKRA}>
              <div className={classes.edTitle}>KRA</div>

              <div className={classes.edPlot}>
                <KRADataChart />
              </div>
              <div className={classes.edDatakey} style={{height:'30px'}}>
                <div className={classes.edDatakey1}>
                  {" "}
                  <div className={classes.edDatakeyCircle1}></div>{" "}
                  <div className={classes.edDatakeyTitle}>New</div>
                </div>
                <div className={classes.edDatakey2}>
                  <div className={classes.edDatakeyCircle2}></div>{" "}
                  <div className={classes.edDatakeyTitle}>Existing</div>
                </div>
                <div className={classes.edDatakey3}>
                  <div className={classes.edDatakeyCircle2}></div>{" "}
                  <div className={classes.edDatakeyTitle}>Modified</div>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </Box> */}
    </>
  );
};

export default GeneralBoard;
