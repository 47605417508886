import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Tabs from "../../components/Tabs/Tabs";
import { IconButton, Stack, Tooltip } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DataTableComponent from "../../components/DataTableComponent/DataTableComponent";
import FollowUpsModal from "../../components/Modals/FollowUps/FollowUpsModal";
import {
  IClientsData,
  IEditFollowUpDate,
  IInsertFollowUpHistory,
} from "../../Utils/dtos";
import {
  AssignClientAPICall,
  GetAdminFollowupsAPICall,
  GetStagesAPICall,
  ListUsersAPICall,
  editFollowUpDateAPICall,
  insertFollowUpHistoryAPICall,
  permanentDeleteClientAPICall,
} from "../../Utils/APICalls";
import stagesData from "../../Utils/stages.json";
import AddIcCallIcon from "@mui/icons-material/AddIcCall";
import AssignUser from "../../components/Modals/AssignUser/AssignUser";
import EditIcon from "@mui/icons-material/Edit";
import FollowUpDateChange from "../../components/Modals/FollowUps/FollowUpDateChange";
import dayjs, { Dayjs } from "dayjs";
import SucessSnackBar from "../../components/SnackBar/SuccessSnackBar";
import RefreshIcon from "@mui/icons-material/Refresh";
import { ADMINLOGINLINK } from "../../Utils/constants";
import ClientDetailspopup from "../../components/Modals/ClientDetailspopup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { confirmAlert } from "react-confirm-alert";

const titletextStyle = {
  fontSize: "14px",
  fontWeight: "bold",
  color: "#8C8B8F",
};
const contenttextStyle = { fontSize: "12px" };
const mappingValues: any = {
  Today: "today",
  Missed: "missed",
  Upcoming: "upcoming",
  Completed: "completed",
};
const Data: any = stagesData;
export default function FollowupsPage() {
  const [data, setdata] = useState([]);
  const [loading, setloading] = useState(false);
  const [selectedTab, setselectedTab] = useState<string>("Today");
  const [clientFollowUpsPopup, setclientFollowUpsPopup] = useState({
    status: false,
    data: "",
  });
  const [stagesData, setStagesData] = useState<any>();
  const [users, setusers] = useState<any>();
  const [totalRows, setTotalRows] = useState(5);
  const [perPage, setPerPage] = useState(20);
  const [clientId, setClientId] = useState("");
  const [open, setOpen] = useState(false);
  const [openDateModal, setOpenDateModal] = useState(false);
  const [followDate, setFollowDate] = useState<any>();
  const [clientFollowUpId, setClientFollowUpId] = useState("");
  const [searchData, setSearchData] = useState("");
  const [stageFilterData, setStageFilterData] = useState("");
  const [userFilterData, setUserFilterData] = useState("");
  const [paginationCount, setpaginationCount] = useState(1);
  const [pagenumber, setPageNumber] = useState(1);
  const [dateRangeFilterData, setDateRangeFilterData] = useState<any>({
    fromDate: dayjs().subtract(1, "month"),
    toDate: dayjs().add(1, "month"),
  });
  const [recordsCount, setRecordsCount] = useState(0);
  const [message, setMessage] = useState("");
  const [clientDetailsPopup, setclientDetailsPopup] = useState({
    status: false,
    data: "",
  });
  const [clientTimeStamp, setClientTimeStamp] = useState<any>({
    MobileVerifiedTS: "",
    EmailVerifiedTS: "",
  });
  const [rejectedReason, setRejectedReason] = useState("");
  const [deletedReason, setDeletedReason] = useState("");
  const [tableRefreshFilters, settablerefreshfilters] = useState("");
  const [sortactivity, setsortactivity] = useState<any>("DESC");
  const [rotate, setRotate] = useState(false);

  const formatDate = (date: any) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hour = String(date.getHours()).padStart(2, "0");
    const minute = String(date.getMinutes()).padStart(2, "0");
    const second = String(date.getSeconds()).padStart(2, "0");
    return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
  };

  function convertDateFormat(inputDate: any) {
    const options: any = {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };

    const utcDate = new Date(inputDate);

    const localDate = new Date(
      utcDate.getTime() + utcDate.getTimezoneOffset() * 60000
    );

    const formattedDate = `${localDate.getUTCDate()}/${(
      localDate.getUTCMonth() + 1
    )
      .toString()
      .padStart(
        2,
        "0"
      )}/${localDate.getUTCFullYear()}, ${localDate.toLocaleString(
      "en-US",
      options
    )}`;

    return formattedDate;
  }
  function convertDateFormatToDatePicker(inputDate: any) {
    const options: any = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };

    const utcDate = new Date(inputDate);

    const localDate = new Date(
      utcDate.getTime() + utcDate.getTimezoneOffset() * 60000
    );
    const formattedDate = localDate.toLocaleString("en-US", options);

    return formattedDate;
  }

  function formatDatePayload(dateString: any) {
    // Create a new Date object from the provided date string
    const date = new Date(dateString);

    // Extract the year, month, and day from the Date object
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-indexed, so we add 1
    const day = String(date.getDate()).padStart(2, "0");

    // Concatenate the year, month, and day with a hyphen separator to get the desired format
    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
  }
  function convertUTCToIST(utcDateStr: any) {
    // Create a new Date object from the UTC date string
    const utcDate = new Date(utcDateStr);

    // Get the UTC time in milliseconds since January 1, 1970
    const utcTime = utcDate.getTime();

    // Add the offset for IST (5 hours 30 minutes) in milliseconds
    const istOffset = 5.5 * 60 * 60 * 1000;

    // Create a new date object with IST time
    const istDate = new Date(utcTime + istOffset);

    // Format the IST date to match the required format
    const year = istDate.getFullYear();
    const month = String(istDate.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(istDate.getDate()).padStart(2, "0");
    const hours = String(istDate.getHours()).padStart(2, "0");
    const minutes = String(istDate.getMinutes()).padStart(2, "0");
    const seconds = String(istDate.getSeconds()).padStart(2, "0");
    const milliseconds = String(istDate.getMilliseconds()).padStart(3, "0");

    // Return the formatted date string in the desired format
    return convertToFormattedDateTimetoTextField(istDate);
  }

  const convertToFormattedDateTimetoTextField = (dateTimeString: any) => {
    const dateTime = new Date(dateTimeString);

    const day = dateTime.getDate();
    const month = dateTime.getMonth() + 1; // Months are zero-indexed
    const year = dateTime.getFullYear();

    let hours = dateTime.getHours();
    let minutes = dateTime.getMinutes();
    const period = hours >= 12 ? "PM" : "AM";

    // Convert to 12-hour format
    if (hours > 12) {
      hours -= 12;
    } else if (hours === 0) {
      hours = 12;
    }

    return `${day < 10 ? "0" : ""}${day}-${
      month < 10 ? "0" : ""
    }${month}-${year} ${hours}:${minutes < 10 ? "0" : ""}${minutes} ${period}`;
  };
  const columns = [
    {
      name: <b style={titletextStyle}>SrNo</b>,
      selector: "srno",
      sortable: false,
      width: "60px",
      cell: (row: any, index: any) => (
        <b style={contenttextStyle}>{index + 1 + paginationCount * 20 - 20}</b>
      ),
    },
    {
      name: <b style={titletextStyle}>AppNo</b>,
      selector: "appno",
      sortable: false,
      width: "105px",
      cell: (row: any) => (
        <Tooltip title="Click to Login">
          <b
            style={{ fontSize: "12px", cursor: "pointer" }}
            onClick={() => {
              handleAdminLogintoClientAccount(row);
            }}
          >
            {row.appno}
          </b>
        </Tooltip>
      ),
    },
    {
      name: <b style={titletextStyle}>Name</b>,
      selector: "client_name",
      sortable: false,
      // width: "150px",
      cell: (row: any) => (
        <b
          style={{ fontSize: "12px" }}
          onClick={() => {
            // handleAdminLogintoClientAccount(row);
          }}
        >
          {row.client_name}
        </b>
      ),
    },
    {
      name: <b style={titletextStyle}>PAN</b>,
      selector: "pan_no",
      sortable: false,
      width: "110px",
      cell: (row: any) => <b style={contenttextStyle}>{row.pan_no}</b>,
    },
    {
      name: <b style={titletextStyle}>CCode</b>,
      selector: "client_code",

      sortable: false,
      cell: (row: any) => <b style={contenttextStyle}>{row.client_code}</b>,
    },

    {
      name: <b style={titletextStyle}>Email</b>,
      selector: "email",
      width: "200px",
      sortable: false,
      cell: (row: any) => <b style={contenttextStyle}>{row.email}</b>,
    },
    {
      name: <b style={titletextStyle}>Mobile</b>,
      selector: "mobile",
      sortable: false,
      // width: "120px",
      cell: (row: any) => <b style={contenttextStyle}>{row.mobile}</b>,
    },

    {
      name: <b style={titletextStyle}>Stage</b>,
      selector: "current_stage",
      sortable: false,
      // width: "80px",
      cell: (row: any) => (
        <b style={contenttextStyle}>
          {row.is_rejected ? (
            <>
              <span style={{ color: "red" }}>Rej-</span>
              {Data.hasOwnProperty(row.current_stage) &&
                Data[row.current_stage]}
            </>
          ) : (
            Data.hasOwnProperty(row.current_stage) && Data[row.current_stage]
          )}
        </b>
        // <b style={contenttextStyle}>
        //   {Data.hasOwnProperty(row.current_stage) && Data[row.current_stage]}
        // </b>
      ),
    },
    {
      name: <b style={titletextStyle}>Source</b>,
      selector: "sources",
      sortable: true,
      width: "80px",
      cell: (row: any) => <b style={contenttextStyle}>{row.source}</b>,
    },
    {
      name: (
        <b
          style={titletextStyle}
          onClick={() => {
            // console.log("S Id");
          }}
        >
          S Id
        </b>
      ),
      selector: "source_id",
      sortable: false,
      cell: (row: any) => <b style={contenttextStyle}>{row.source_id}</b>,
      // width: "100px",
    },
    // {
    //   name: (
    //     <b
    //       style={{ ...titletextStyle, display: "flex", alignItems: "center" }}
    //       onClick={() => {
    //         setRotate(!rotate);
    //         if (sortactivity === "ASC") {
    //           setsortactivity("DESC");
    //         } else if (sortactivity === "DESC") {
    //           setsortactivity("ASC");
    //         }
    //       }}
    //     >
    //       Activity{" "}
    //       <ArrowDropDownIcon
    //         sx={{
    //           transform: rotate ? "rotate(180deg)" : "",
    //           transition: "0.5s ease",
    //         }}
    //       />
    //     </b>
    //   ),
    //   selector: "pending_days",
    //   sortable: false,

    //   cell: (row: any) => <b style={contenttextStyle}>{row.pending_days}</b>,
    // },

    {
      name: <b style={titletextStyle}>User</b>,
      selector: "handling_user",
      sortable: false,
      width: "100px",
      cell: (row: any) => (
        <>
          {row.handling_user === "" ? (
            <Tooltip title="Assign to ">
              <IconButton
                onClick={() => {
                  handleAssign(row);
                }}
              >
                <PersonIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <b
              style={{ fontSize: "12px", cursor: "pointer" }}
              onClick={() => {
                handleAssign(row);
              }}
            >
              {row.handling_user}
            </b>
          )}
        </>
      ),
    },
    {
      name: <b style={titletextStyle}>Followup Count</b>,
      selector: "followup_count",
      sortable: false,
      // width: "80px",
      cell: (row: any) => <b style={contenttextStyle}>{row.followup_count}</b>,
    },
    {
      name: <b style={titletextStyle}>Followup Date</b>,
      selector: "followup_date",
      sortable: false,
      // width: "80px",
      cell: (row: any) => (
        <b style={contenttextStyle}>
          {row.followup_date === "" ? "" : convertDateFormat(row.followup_date)}
        </b>
      ),
      // cell: (row: any) => {
      //   const followUpDate = new Date(row.followup_date);

      //   const formattedDate = followUpDate.toLocaleString("en-US", {
      //     year: "numeric",
      //     month: "2-digit",
      //     day: "2-digit",
      //     hour: "2-digit",
      //     minute: "2-digit",
      //     hour12: true,
      //   });
      //   console.log("followUpDate",followUpDate , formattedDate)
      //   return (
      //     <b style={{ fontSize: "12px" }}>
      //       { formattedDate}
      //     </b>
      //   );
      // },
    },
    {
      name: <b style={titletextStyle}>Actions</b>,
      selector: "spamReportRate",
      sortable: false,
      allowOverflow: true,
      button: true,
      width: "120px",
      cell: (row: any) => (
        <b style={contenttextStyle}>
          <Tooltip title="View Details">
            <IconButton
              onClick={() => {
                handleViewClientDetailsPopUp(row);
                if (row.rejected_reason) {
                  setRejectedReason(row.rejected_reason);
                }
                if (row.deleted_reason) {
                  setDeletedReason(row.deleted_reason);
                }
              }}
            >
              <VisibilityIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="View Details">
            <IconButton
              onClick={() => {
                sessionStorage.setItem("followtype", selectedTab);
                handleViewClientDetails(row);
              }}
            >
              <AddIcCallIcon />
            </IconButton>
          </Tooltip>
        </b>
      ),
    },
  ];

  const handleAdminLogintoClientAccount = (data: any) => {
    //  console.log(data);
    // window.open(
    //   `http://localhost:3000?client_id=${
    //     data.client_id
    //   }&user_id=${sessionStorage.getItem("userId")}`,
    //   "_blank"
    // );
    //console.log("data", data.completed_ts);
    if (!data.completed_ts) {
      window.open(
        `${ADMINLOGINLINK}?client_id=${
          data._id
        }&user_id=${sessionStorage.getItem("userId")}`,
        "_blank"
      );
    }
  };
  const convertToFormattedDate = (dateString: any) => {
    const dateTime = dateString;

    const day = dateTime.getDate();
    const month = dateTime.getMonth() + 1; // Months are zero-indexed
    const year = dateTime.getFullYear();

    let hours = dateTime.getHours();
    let minutes = dateTime.getMinutes();
    const period = hours >= 12 ? "PM" : "AM";

    // Convert to 12-hour format
    if (hours > 12) {
      hours -= 12;
    } else if (hours === 0) {
      hours = 12;
    }

    return `${day < 10 ? "0" : ""}${day}-${
      month < 10 ? "0" : ""
    }${month}-${year} ${hours}:${minutes < 10 ? "0" : ""}${minutes} ${period}`;
  };

  useEffect(() => {
    GetStagesAPICall()
      .then((response: any) => {
        const responseData = response.data;
        // console.log("responseData", responseData);
        setStagesData(responseData.data.stage_list);
      })
      .catch((error: any) => {
        // console.log("error", error);
      });

    ListUsersAPICall()
      .then((response: any) => {
        const responseData = response.data;
        setusers(responseData.data.data);
        //  console.log(responseData.data.user_list);
      })
      .catch((error: any) => {});
  }, []);

  // useEffect(() => {
  //   setdata([]);
  //   getFollowUps(1, perPage, "", "", "", "", "");
  // }, [selectedTab]);
  useEffect(() => {
    setloading(true);
    setRecordsCount(0);
    const delayDebounceFn = setTimeout(() => {
      // console.log("searchData", searchData);

      getFollowUps(
        pagenumber,
        perPage,
        searchData,
        userFilterData,
        dateRangeFilterData.fromDate === ""
          ? ""
          : formatDatePayload(dateRangeFilterData.fromDate),
        dateRangeFilterData.toDate === ""
          ? ""
          : formatDatePayload(dateRangeFilterData.toDate),
        stageFilterData,
        sortactivity
      );
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [
    selectedTab,
    searchData,
    stageFilterData,
    userFilterData,
    dateRangeFilterData,
    pagenumber,
    sortactivity,
  ]);
  const getFollowUps = (
    page: number,
    perpage: number,
    query: any,
    filter_user: any,
    filter_date_from: any,
    filter_date_to: any,
    filter_stage: any,
    sortactivity: any
  ) => {
    setloading(true);
    const reqObj: any = {
      page_no: page,
      page_size: perpage,
      query: query,
      type: mappingValues[selectedTab],
      filter_user: filter_user,
      filter_date_from:
        // mappingValues[selectedTab] === "upcoming" ||
        mappingValues[selectedTab] === "completed" ? "" : filter_date_from,
      filter_date_to:
        // mappingValues[selectedTab] === "upcoming" ||
        mappingValues[selectedTab] === "completed" ? "" : filter_date_to,
      filter_stage: filter_stage,
      sort_activity: sortactivity,
    };
    GetAdminFollowupsAPICall(reqObj)
      .then((response: any) => {
        setloading(false);
        const responseData = response.data;
        setdata(responseData.data.data);
        // console.log(responseData.data);

        setTotalRows(Math.ceil(responseData.data.total_count / 20));
        if (responseData.data.total_count) {
          setRecordsCount(responseData.data.total_count);
        } else {
          setRecordsCount(0);
        }
      })
      .catch((error) => {
        setloading(false);
      });
  };

  const handleUpdateFollowUpDate = (data: any) => {
    if (data.handling_user === "") {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="confitmalertUI">
              <PersonIcon />
              <p>Please assign an user</p>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <button
                  onClick={() => {
                    onClose();
                  }}
                  className="confitmbutton"
                >
                  OK
                </button>
              </div>
            </div>
          );
        },
      });
      // window.alert("Please Asign User");
    } else {
      setOpenDateModal(true);
      // console.log("rowdata", data);
      setClientId(data._id);
      setClientFollowUpId(data.follow_up_id);
      setFollowDate(convertDateFormatToDatePicker(data.followup_date));
    }
  };
  const handleCloseDatepopUp = () => {
    setOpenDateModal(false);
  };

  // const handleUpdateDate = (data: any) => {
  //   setOpenDateModal(false);
  //   console.log("data", data);
  // };
  const handleEditFollowUpDate = (values: any) => {
    setOpenDateModal(false);

    const originalDate = new Date(values.update_date.$d);

    // Step 2: Get the individual components (year, month, day, hour, minute, second) from the Date object
    const year = originalDate.getFullYear();
    const month = String(originalDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(originalDate.getDate()).padStart(2, "0");
    const hour = String(originalDate.getHours()).padStart(2, "0");
    const minute = String(originalDate.getMinutes()).padStart(2, "0");
    const second = String(originalDate.getSeconds()).padStart(2, "0");

    // Step 3: Create a formatted date string in the desired format "YYYY-MM-DD HH:mm:ss"
    const formattedDateString = `${year}-${month}-${day} ${hour}:${minute}:${second}`;
    // console.log("formattedDateString", formattedDateString);

    // const reqObj: IEditFollowUpDate = {
    //   follow_up_id: clientFollowUpId,
    //   follow_up_TS: formattedDateString,
    //   follow_up_status: "not_completed",
    // };

    const reqObj: IInsertFollowUpHistory = {
      user_id: clientId,
      status: values.status,
      remarks: values.remarks,

      next_followup_date: convertToFormattedDate(
        new Date(values.update_date.$d)
      ),
    };

    insertFollowUpHistoryAPICall(reqObj)
      .then((response: any) => {
        const responseData = response.data;
        //  console.log(responseData);
        // getFollowUps();
        // getFollowUps(1, perPage, "", "", "", "", "");
        getFollowUps(
          pagenumber,
          perPage,
          searchData,
          userFilterData,
          formatDatePayload(dateRangeFilterData.fromDate),
          formatDatePayload(dateRangeFilterData.toDate),
          stageFilterData,
          sortactivity
        );
        setMessage("Successfully Updated");
        setTimeout(() => {
          setMessage(" ");
        }, 5000);
      })
      .catch((error: any) => {
        // setLoading(false);
        // console.log("error", error);
      });

    getFollowUps(
      pagenumber,
      perPage,
      searchData,
      userFilterData,
      formatDatePayload(dateRangeFilterData.fromDate),
      formatDatePayload(dateRangeFilterData.toDate),
      stageFilterData,
      sortactivity
    );
  };

  const handleAssign = (data: any) => {
    setOpen(true);
    setClientId(data._id);
  };

  const handleViewClientDetails = (data: any) => {
    setclientFollowUpsPopup({
      status: true,

      data: data._id,
    });
    setFollowDate(data.followup_date);
  };

  const handleChangeTab = (tab: any) => {
    setselectedTab(tab);
  };

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setPerPage(newPerPage);
  };

  const handlePageChange = (value: any, page: number) => {
    // getFollowUps(page, perPage, "", "", "", "", "");
    setpaginationCount(page);
    setPageNumber(page);
  };
  const handleSearchChange = (data: any) => {
    setSearchData(data);
    setPageNumber(1);
    setpaginationCount(1);
    // getFollowUps(1, perPage, data);
  };
  const handleSubmitDetailsPopup = (data: any) => {};

  const handleCloseClientDetailsPopup = (data: any) => {
    // console.log("data", data);
    setclientFollowUpsPopup({
      status: false,
      data: "",
    });
    // getFollowUps(1, perPage, "", "", "", "", "");
    getFollowUps(
      pagenumber,
      perPage,
      searchData,
      userFilterData,
      formatDatePayload(dateRangeFilterData.fromDate),
      formatDatePayload(dateRangeFilterData.toDate),
      stageFilterData,
      sortactivity
    );
  };
  const handleAssignePopUpClose = () => {
    setOpen(false);
  };
  const handleUserChange = (data: any) => {
    setUserFilterData(data);
    setPageNumber(1);
    setpaginationCount(1);
    // getClients(1, perPage, data);
  };
  const handleStageChange = (data: any) => {
    setPageNumber(1);
    setpaginationCount(1);
    setStageFilterData(data);
    // getClients(1, perPage, data);
  };
  const handleDateRangeChange = (data: any) => {
    setDateRangeFilterData({
      fromDate: !data[0].$d ? "" : formatDate(data[0].$d),
      toDate: !data[1].$d ? "" : formatDate(data[1].$d),
    });
  };

  const handleAssigneID = (data: any) => {
    setOpen(false);

    const obj: any = {
      client_id: clientId,
      admin_id: data,
    };
    AssignClientAPICall(obj)
      .then((response: any) => {
        // alert(`Assigned to me ${response.data.data.User_name}`);
        // setUserName(response.data.data.User_name);
        // getFollowUps(1, perPage, "", "", "", "", "");
        getFollowUps(
          pagenumber,
          perPage,
          searchData,
          userFilterData,
          formatDatePayload(dateRangeFilterData.fromDate),
          formatDatePayload(dateRangeFilterData.toDate),
          stageFilterData,
          sortactivity
        );
      })
      .catch((error: any) => {});
  };
  const handleRefreshData = () => {
    // getFollowUps(1, perPage, "", "", "", "", "");
    setPageNumber(1);
    setpaginationCount(1);
    getFollowUps(
      pagenumber,
      perPage,
      searchData,
      userFilterData,
      formatDatePayload(dateRangeFilterData.fromDate),
      formatDatePayload(dateRangeFilterData.toDate),
      stageFilterData,
      sortactivity
    );
  };
  const handleCloseClientDetailsPopupModal = (data: any) => {
    sessionStorage.removeItem(`${clientDetailsPopup.data}_aadhaar`);
    sessionStorage.removeItem(`${clientDetailsPopup.data}_ipv`);
    sessionStorage.removeItem(`${clientDetailsPopup.data}_signature`);
    sessionStorage.removeItem(`${clientDetailsPopup.data}_pancard`);
    sessionStorage.removeItem(`${clientDetailsPopup.data}_income_proof`);
    sessionStorage.removeItem(`${clientDetailsPopup.data}_e_sign_pdf`);
    sessionStorage.removeItem(`${clientDetailsPopup.data}_kra_upload`);
    sessionStorage.removeItem(`${clientDetailsPopup.data}_bank_proof`);
    sessionStorage.removeItem(`${clientDetailsPopup.data}_nominee_proof1`);
    sessionStorage.removeItem(`${clientDetailsPopup.data}_nominee_proof2`);
    sessionStorage.removeItem(`${clientDetailsPopup.data}_nominee_proof3`);
    sessionStorage.removeItem(`${clientDetailsPopup.data}_cmr`);
    sessionStorage.removeItem(`${clientDetailsPopup.data}_e_sign_pdf`);
    //  console.log("data", data);
    setclientDetailsPopup({
      status: false,
      data: "",
    });
  };

  const handlePermanentDeleteClient = () => {
    //  console.log("data", data);
    if (window.confirm("Are you sure, client will be permanently deleted")) {
      let reqObj: any = {
        user_id: clientId,
      };

      permanentDeleteClientAPICall(reqObj)
        .then((response: any) => {
          const responseData = response.data;

          // setClientMessage("Client Deleted Successfully");
          setclientDetailsPopup({
            status: false,
            data: "",
          });
          // setTimeout(() => {
          //   setClientMessage("");
          // }, 5000);
        })
        .catch((error) => {});
    }
  };
  const handleViewClientDetailsPopUp = (data: any) => {
    // console.log("clientData", data);
    if (data.handling_user === "") {
      window.alert("Please Asign User");
    } else {
      setclientDetailsPopup({
        status: true,
        data: data._id,
      });
      setClientId(data._id);
      setClientTimeStamp({
        MobileVerifiedTS: null,
        EmailVerifiedTS: null,
      });
    }
  };

  return (
    <div>
      <Helmet>
        <title>Follow up </title>
        <meta name="Follow up" content="Follow up" />
      </Helmet>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Tabs
          labels={["Today", "Missed", "Upcoming", "Completed"]}
          onChangeTab={handleChangeTab}
          selectedTab={selectedTab}
          recordsCount={recordsCount}
          calledScreen={"NewKyc"}
        />
        <Tooltip title="Refresh Table Data">
          <IconButton
            sx={{
              marginRight: "20px",
              height: "40px",
              width: "40px",
              border: "1px solid grey",
            }}
            onClick={handleRefreshData}
          >
            <RefreshIcon sx={{ color: "grey" }} />
          </IconButton>
        </Tooltip>
      </Stack>
      <DataTableComponent
        // columns={
        //   mappingValues[selectedTab] === "completed"
        //     ? columnsForCompleted
        //     : columns
        // }
        columns={columns}
        data={data}
        loading={loading}
        pagination={true}
        paginationServer={true}
        handlePerRowsChange={handlePerRowsChange}
        handlePageChange={handlePageChange}
        totalRows={totalRows}
        selectedTab={mappingValues[selectedTab]}
        paginationCount={paginationCount}
        recordsCount={recordsCount}
      />
      <SucessSnackBar message={message} />
      {clientFollowUpsPopup.status && (
        <FollowUpsModal
          data={clientFollowUpsPopup.data}
          handleSubmitData={handleSubmitDetailsPopup}
          open={clientFollowUpsPopup.status}
          handleClose={handleCloseClientDetailsPopup}
          followDate={followDate}
        />
      )}
      {clientDetailsPopup.status && (
        <ClientDetailspopup
          data={clientDetailsPopup.data}
          handleSubmitData={() => {}}
          open={clientDetailsPopup.status}
          handleClose={handleCloseClientDetailsPopupModal}
          rejectedReason={rejectedReason}
          deletedReason={deletedReason}
          selectedTabNew={mappingValues[selectedTab]}
          handlePermanentDeleteClient={handlePermanentDeleteClient}
          clientTimeStamp={clientTimeStamp}
        />
      )}
      <FollowUpDateChange
        openDateModal={openDateModal}
        handleUpdateDate={handleEditFollowUpDate}
        handleCloseDatepopUp={handleCloseDatepopUp}
        followDate={followDate}
      />

      <AssignUser
        open={open}
        handleClose={handleAssignePopUpClose}
        assigneID={handleAssigneID}
        userData={users}
      />
      <SucessSnackBar message="" />
    </div>
  );
}
