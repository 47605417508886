import { useState } from "react";
import classes from "./Mainboard.module.scss";
import Topbar from "../Topbar/Topbar";
import GeneralBoard from "../../GeneralBoard/GeneralBoard";
import { useSelector } from "react-redux";
import Campaigns from "../../Campaigns/Campaigns";
import SubscribersList from "../../SubscribersList/SubscribersList";
import Template from "../../Template/Template";
import CreateCampaigns from "../../CreateCampaigns/CreateCampaigns";
import { Interface } from "readline";

interface Props {
  children: any;
}
const Mainboard = ({ children }: Props) => {
  return (
    <>
      <div className={classes.wrapper}>
        <Topbar />
        <div className={classes.dataBoardWrapper}>{children}</div>
      </div>
    </>
  );
};

export default Mainboard;
