import React, { useState } from "react";
import { Formik, Form, useFormikContext } from "formik";
import * as Yup from "yup";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import Button from "@mui/joy/Button";
const schema = Yup.object().shape({
  reasonForRejection: Yup.string().required("Reason for Rejection is Required"),
});

interface props {
  stagesData: any;
  handleRejectClient: any;
  disabled: any;
  rejectLoading?: any;
}

export default function BankUpdateReject({
  stagesData,
  handleRejectClient,
  disabled,
  rejectLoading,
}: props) {
  const [stageName, setStageName] = useState<any>();

  const InitialValues = {
    reasonForRejection: "",
  };

  return (
    <div>
      <Formik
        validationSchema={schema}
        initialValues={InitialValues}
        onSubmit={(values: any) => {
          handleRejectClient(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,

          setFieldValue,
        }) => {
          // console.log("errors", errors)
          return (
            <form noValidate onSubmit={handleSubmit}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div>
                  <TextField
                    value={values.reasonForRejection}
                    disabled={disabled}
                    name="reasonForRejection"
                    onChange={handleChange}
                    label="Reason for Rejection"
                    size="small"
                    sx={{ marginRight: "20px", width: "300px" }}
                    error={
                      touched.reasonForRejection &&
                      Boolean(errors.reasonForRejection)
                    }
                  />
                </div>

                <div>
                  <Button
                    type="submit"
                    sx={{
                      background: "#FF5A5A",
                      color: "white",
                      width: "160px",
                      borderRadius: "4px",
                      marginLeft: "20px",
                      "&:hover": {
                        background: "#FF5A5A",
                        color: "white",
                      },
                    }}
                    disabled={disabled}
                    loading={rejectLoading}
                  >
                    Reject
                  </Button>
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
}
