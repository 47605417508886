import {
  Button,
  CircularProgress,
  IconButton,
  Stack,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { GetNRIClientDocumentsAPICall, GetPartnerDocumentsAPICall } from "../../Utils/APICalls";
import { IClientsDocuments } from "../../Utils/dtos";
import DownloadIcon from "@mui/icons-material/Download";
import axios from "axios";
import VisibilityIcon from "@mui/icons-material/Visibility";

interface Props {
  data: string;
  docType: string;
}

// Function to get data from API or localStorage
const getCachedData = (data: string, docType: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    // Check if the data exists in localStorage
    const cachedData = sessionStorage.getItem(`${data}_${docType}`);
    if (cachedData) {
      resolve(JSON.parse(cachedData));
    } else {
      // Data not found in localStorage, make API call
      const obj: IClientsDocuments = {
        user_id: data,
        doc_type: docType,
      };
      GetNRIClientDocumentsAPICall(obj)
        .then((response: any) => {
          // Save the API response to localStorage
          sessionStorage.setItem(
            `${data}_${docType}`,
            JSON.stringify(response.data)
          );
          resolve(response.data);
        })
        .catch((error: any) => {
          reject(error);
        });
    }
  });
};

export default function NRIBase64toImage({ data, docType }: Props) {
  const [imageUrl, setImageUrl] = useState<any>(null);
  const [rotationAngle, setRotationAngle] = useState(0);
  const [documentData, setDocumentData] = useState("");
  const [displayPreview, setDisplayPreview] = useState(true);
  const [circularprogress, setCircularProgress] = useState(true);
  const [incomeProofType, setIncomeProofType] = useState("");
  const [pdfFile, setPdfFile] = useState<any>({
    pdf: false,
    data: "",
  });

 // console.log("docType", docType);
  const getDetails = () => {
    setCircularProgress(true);
    getCachedData(data, docType)
      .then((response: any) => {
        //  console.log("dd", response.client_data.url);

        if (response.client_data === "") {
          setDisplayPreview(false);
        } else {
          setDisplayPreview(true);
        }
        if (response.client_data.DocTypeRef) {
          setIncomeProofType(response.client_data.DocTypeRef);
        }

        if (response.client_data.DocumentFormat !== "application/pdf") {
          setDocumentData(response.client_data.url);
          setPdfFile({
            pdf: false,
            data: "",
          });
        } else if (response.client_data.DocumentFormat === "application/pdf") {
          setPdfFile({
            pdf: true,
            data: response.client_data.url,
          });
        }

        setCircularProgress(false);
      })
      .catch((error: any) => {
        //  console.log("error", error);
        setCircularProgress(false);
        setDisplayPreview(false);
      });
  };

  const handleDownload = () => {
    window.open(documentData, "_blank");
  };

  useEffect(() => {
    getDetails();
  }, [docType]);



  return (
    <>
      {circularprogress ? (
        <div
          style={{
            width: "600px",
            height: "60vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          {displayPreview ? (
            <>
              <Stack>
                {pdfFile.pdf === false ? (
                  <>
                    <div
                      style={{
                        width: "-webkit-fill-available",
                        height: "100%",
                      }}
                    >
                      <img
                        src={documentData}
                        style={{
                          transform: `rotate(${rotationAngle}deg)`,
                          width: "96%",
                          height: "65vh",
                          objectFit: "contain",
                        }}
                      />
                    </div>
                    <Tooltip title={imageUrl ? "Download Image" : "View Image"}>
                      <IconButton
                        sx={{
                          position: "absolute",
                          right: 10,
                          border: "1px solid grey",
                        }}
                        onClick={handleDownload}
                      >
                        {imageUrl ? (
                          <DownloadIcon sx={{ color: "grey" }} />
                        ) : (
                          <VisibilityIcon sx={{ color: "grey" }} />
                        )}
                      </IconButton>
                    </Tooltip>
                  </>
                ) : (
                  ""
                )}
              </Stack>
              {pdfFile.pdf === true ? (
                <>
                  <a href={pdfFile.data} target="_blank">
                    <Tooltip title="View File">
                      <IconButton
                        sx={{
                          position: "absolute",
                          right: 10,

                          border: "1px solid grey",
                        }}
                      >
                        <VisibilityIcon sx={{ color: "grey" }} />
                      </IconButton>
                    </Tooltip>
                  </a>
                  <div
                    style={{
                      width: "-webkit-fill-available",
                      height: "100%",
                    }}
                  >
                    {docType === "income_proof" && (
                      <p
                        style={{
                          marginTop: "0px",
                          textAlign: "center",
                          fontSize: "14px",
                          textDecoration: "underline",
                          marginBottom: "3px",
                        }}
                      >
                        {incomeProofType.replace(/_/g, " ").toUpperCase()}
                      </p>
                    )}
                    <iframe
                      src={pdfFile.data}
                      width="96%"
                      height="83%"
                      title="PDF Viewer"
                    />
                  </div>
                </>
              ) : (
                ""
              )}
            </>
          ) : (
            <div
              style={{
                width: "600px",
                height: "60vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              No Data
            </div>
          )}
        </>
      )}
    </>
  );
}
