export const AddUser = {
  mobile: "",
  email: "",
  full_name: "",
  password: "",
  // role_id:"a83c8e56-07b5-4974-a817-0fe40f2aacb3",
  is_admin: true,
  is_active: false,
};
export const AddFollowUp = {
  remarks: "",
  // date_time: null,
  status: "",
};
export const AddInitialFollowUp = {
  remarks: "",
  update_date: null,
  status: "",
};
export const EditFollowUpHistory = {
  remarks: "",
 date_time: null,
  status: "",
};

export const AddTariff = {
  partnerName: "",
  partnerId: "",
  equityDelivery: "",
  equityIntraday: "",
  futures: "",
  options: "",
  brokerageTemplate: "",

};
