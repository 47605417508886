import React, { useEffect, useState } from "react";
import classes from "./Tabs.module.scss";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

interface Props {
  labels: any;
  onChangeTab: any;
  selectedTab: any;
  clientData: any;
}

export default function TabsNRI({
  labels,
  onChangeTab,
  selectedTab,
  clientData,
}: Props) {
  const [nomineeValues, setNomineeValues] = useState("");
  const [addressValues, setAddressValues] = useState("");

  const [Labels, setLabels] = useState(labels);

  useEffect(() => {
    let AddedLabels: any = labels;
    if (clientData && clientData.Bank_Details?.pis_bank_details) {
      const updatedLabels = [
        ...labels.slice(0, 1),
        "PISBank",
        ...labels.slice(1),
      ];
      AddedLabels = updatedLabels;
    }

    if (
      clientData &&
      clientData.client_details?.OverseasAddressJson?.is_correspondance
    ) {
      const updatedLabelsAddrss = AddedLabels.filter(
        (a: any) => a !== "Correspondance"
      );
      AddedLabels = updatedLabelsAddrss;
    }

    if (
      clientData &&
      clientData.client_details?.OverseasAddressJson?.is_permanent
    ) {
      const updatedLabelsAddrsspermanent = AddedLabels.filter(
        (a: any) => a !== "Permanent"
      );
      AddedLabels = updatedLabelsAddrsspermanent;
    }

    if (
      clientData &&
      clientData?.nominee_details?.NomineeDetails?.length === 1
    ) {
      const updatedLabelsNominee1 = AddedLabels.filter(
        (a: any) => a !== "Nominee2" && a !== "Nominee3"
      );

      AddedLabels = updatedLabelsNominee1;
    }

    if (
      clientData &&
      clientData?.nominee_details?.NomineeDetails?.length === 2
    ) {
      const updatedLabelsNominee2 = AddedLabels.filter(
        (a: any) => a !== "Nominee3"
      );

      AddedLabels = updatedLabelsNominee2;
    }

    setLabels(AddedLabels);
  }, [clientData]);

  // useEffect(() => {

  // }, [addressValues]);
  // useEffect(() => {
  //   onChangeTab(nomineeValues);
  // }, [nomineeValues]);

  return (
    <div className={classes.tabsWrapper}>
      <div className={classes.tabsCatagory}>
        {Labels.map((a: any) => (
          <>
            {a !== "Nominee2" &&
              a !== "Nominee3" &&
              a !== "Permanent" &&
              a !== "Correspondance" && (
                <div
                  className={classes.tabsItem}
                  onClick={() => {
                    if (a !== "Overseas" && a !== "Nominee1") {
                      onChangeTab(a);
                      setAddressValues("");
                      setNomineeValues("");
                    } else {
                    }
                  }}
                  style={{
                    backgroundColor: selectedTab === a ? "white" : "",
                    color: selectedTab === a ? "#8056D6" : "",
                    cursor: "pointer",
                  }}
                >
                  {a !== "Nominee1" &&
                    a !== "Overseas" &&
                    a !== "Nominee2" &&
                    a !== "Nominee3" &&
                    a !== "Permanent" &&
                    a !== "Correspondance" &&
                    a}
                  {a === "Nominee1" && (
                    <FormControl sx={{ width: "120px" }} size="small">
                      <InputLabel
                        id="demo-simple-select-label"
                        style={{
                          marginTop: nomineeValues === "" ? "-10px" : "-5px",
                          marginLeft: nomineeValues === "" ? "0px" : "-25px",
                          fontSize: nomineeValues === "" ? "16px" : "14px",
                          fontWeight: nomineeValues === "" ? 600 : 400,
                          color: "black",
                        }}
                      >
                        Nominee
                      </InputLabel>
                      <Select
                        disableUnderline
                        placeholder="Nominee"
                        // inputProps={{
                        //   IconComponent: () => "",
                        // }}
                        sx={{
                          // fontSize: "17px",

                          fontWeight: 600,
                          boxShadow: "none",
                          "&:hover": {
                            color: "#8056D6",
                          },
                          ".MuiSvgIcon-root-393": {
                            visibility: "hidden",
                          },
                          ".MuiOutlinedInput-input": {
                            padding: "0px 5px !important",
                          },
                          ".MuiOutlinedInput-notchedOutline": {
                            border: 0,
                          },
                          "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              border: 0,
                            },
                          "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              border: 0,
                            },
                        }}
                        style={{
                          backgroundColor:
                            selectedTab === nomineeValues ? "white" : "",
                          color: selectedTab === nomineeValues ? "#8056D6" : "",
                          cursor: "pointer",
                        }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={nomineeValues}
                        name="selectStage"
                        label="Document type"
                        onChange={(event: any) => {
                          setNomineeValues(event.target.value);
                          onChangeTab(event.target.value);
                        }}
                      >
                        <MenuItem value={"Nominee1"}>Nominee 1</MenuItem>
                        {clientData &&
                          clientData?.nominee_details?.NomineeDetails?.length >
                            1 && (
                            <MenuItem value={"Nominee2"}>Nominee 2</MenuItem>
                          )}

                        {clientData &&
                          clientData?.nominee_details?.NomineeDetails?.length >
                            2 && (
                            <MenuItem value={"Nominee3"}>Nominee 3</MenuItem>
                          )}
                      </Select>
                    </FormControl>
                  )}
                  {a === "Overseas" && (
                    <FormControl sx={{ width: "170px" }} size="small">
                      <InputLabel
                        id="demo-simple-select-label"
                        style={{
                          marginTop: addressValues === "" ? "-10px" : "-5px",
                          marginLeft: addressValues === "" ? "0px" : "-25px",
                          fontSize: addressValues === "" ? "16px" : "14px",
                          fontWeight: addressValues === "" ? 600 : 400,
                          color: "black",
                        }}
                      >
                        Address
                      </InputLabel>
                      <Select
                        placeholder=""
                        disableUnderline
                        // inputProps={{
                        //   IconComponent: () => "",
                        // }}
                        sx={{
                          fontWeight: 600,
                          boxShadow: "none",
                          "&:hover": {
                            color: "#8056D6",
                          },
                          ".MuiSvgIcon-root-393": {
                            visibility: "hidden",
                          },
                          ".MuiOutlinedInput-input": {
                            padding: "0px 5px !important",
                          },
                          ".MuiOutlinedInput-notchedOutline": {
                            border: 0,
                          },
                          "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              border: 0,
                            },
                          "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              border: 0,
                            },
                        }}
                        style={{
                          backgroundColor:
                            selectedTab === addressValues ? "white" : "",
                          color: selectedTab === addressValues ? "#8056D6" : "",
                          cursor: "pointer",
                        }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={addressValues}
                        name="selectStage"
                        label="Document type"
                        onChange={(event: any) => {
                          // console.log("event.target.value", event.target.value);

                          setAddressValues(event.target.value);

                          onChangeTab(event.target.value);
                        }}
                      >
                        <MenuItem value={"Overseas"}>Overseas</MenuItem>
                        {clientData &&
                          clientData.client_details?.OverseasAddressJson
                            ?.is_permanent === false && (
                            <MenuItem value={"Permanent"}>Permanent</MenuItem>
                          )}
                        {clientData &&
                          clientData.client_details?.OverseasAddressJson
                            ?.is_correspondance === false &&
                          clientData.client_details?.PermanentAddressJson
                            ?.is_permanent_same_as_correspondence === false && (
                            <MenuItem value={"Correspondance"}>
                              Correspondance
                            </MenuItem>
                          )}
                      </Select>
                    </FormControl>
                  )}
                </div>
              )}
          </>
        ))}
      </div>
    </div>
  );
}
