import * as React from "react";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { Formik } from "formik";
import {
  Stack,
  Fade,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  ThemeProvider,
  Container,
  FormHelperText,
  Typography,
  Box,
  Button,
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
} from "@mui/material";
import { UserAddScheme, UserEditScheme } from "../../Schemas/Schemas";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { AddUser } from "../../Utils/InitialValues";
import { IEditUser } from "../../Utils/dtos";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { GetRolesAPICall } from "../../Utils/APICalls";
import styles from "./TariffModal/TariffModal.module.scss";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(1),
  },

  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {/* {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null} */}
    </DialogTitle>
  );
}

interface Props {
  open: boolean;
  handleClose: any;

  handleSubmitData: any;
  data?: IEditUser | any;
}

export default function FormModalpopup({
  open,
  handleClose,
  handleSubmitData,
  data,
}: Props) {
  const [showPassword, setShowPassword] = React.useState(false);

  const [role, setRole] = React.useState<any>();
  const [roleData, setRoleData] = React.useState<any>();

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  // React.useEffect(() => {
  //   GetRolesAPICall()
  //     .then((response: any) => {
  //       const responseData = response.data;
  //       // console.log("responseData", response);
  //       setRoleData(responseData.data.roles_list);
  //     })
  //     .catch((error: any) => {
  //       // console.log("error", error);
  //     });
  // }, []);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  //  console.log("data", data);

  const type = data ? "edit" : "add";
  return (
    <BootstrapDialog
      onClose={(event: any) => {
        if (event.keyCode === 27) {
          handleClose();
        }
      }}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        {type === "add" ? "Add User" : "Edit User"}
      </BootstrapDialogTitle>

      <Formik
        validationSchema={data ? UserEditScheme : UserAddScheme}
        initialValues={data ? data : AddUser}
        onSubmit={(values) => {
          // console.log("values", values);
          handleSubmitData(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <>
            {/* {console.log("values", values, "errors", errors)} */}
            <form noValidate onSubmit={handleSubmit}>
              <DialogContent
                style={{
                  border: "1px solid #DDDCE1",
                  margin: "0px 20px 20px 20px",
                  borderRadius: "4px",
                  maxWidth: "unset",
                  width: "800px",
                }}
              >
                <Box component="form" noValidate>
                  <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 2 }}
                  >
                    <Grid item xs={6}>
                      <p className={styles.textfieldLabel}>Name</p>
                      <TextField
                        id="full_name"
                        fullWidth
                        placeholder="Enter Name"
                        variant="outlined"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.full_name}
                        error={touched.full_name && Boolean(errors.full_name)}
                        helperText={
                          touched.full_name && errors.full_name?.toString()
                        }
                        sx={{
                          input: {
                            color: "#344054",
                            fontSize: "14px",
                            height: "10px",
                          },
                        }}
                        inputProps={{
                          maxLength: 50,
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <p className={styles.textfieldLabel}>Mobile</p>
                      <TextField
                        id="mobile"
                        fullWidth
                        placeholder="Enter Mobile"
                        variant="outlined"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.mobile}
                        error={touched.mobile && Boolean(errors.mobile)}
                        helperText={touched.mobile && errors.mobile?.toString()}
                        inputProps={{
                          maxLength: 10,
                          inputMode: "numeric",
                          pattern: "[0-9]*",
                        }}
                        onKeyPress={(event) => {
                          const keyCode = event.keyCode || event.which;
                          const keyValue = String.fromCharCode(keyCode);
                          const isValidKey = /^\d+$/.test(keyValue);

                          if (!isValidKey) {
                            event.preventDefault();
                          }
                        }}
                        sx={{
                          input: {
                            color: "#344054",
                            fontSize: "14px",
                            height: "10px",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <p className={styles.textfieldLabel}>Email</p>
                      <TextField
                        id="email"
                        fullWidth
                        placeholder="Enter Email"
                        variant="outlined"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        error={touched.email && Boolean(errors.email)}
                        helperText={touched.email && errors.email?.toString()}
                        sx={{
                          input: {
                            color: "#344054",
                            fontSize: "14px",
                            height: "10px",
                          },
                        }}
                        inputProps={{
                          maxLength: 50,
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      {type === "add" && (
                        <>
                          <p className={styles.textfieldLabel}>Password</p>
                          <TextField
                            margin="normal"
                            fullWidth
                            name="password"
                            placeholder="Password"
                            id="password"
                            autoComplete="current-password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                            error={touched.password && Boolean(errors.password)}
                            helperText={
                              touched.password && errors.password?.toString()
                            }
                            type={showPassword ? "text" : "password"}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {showPassword ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                            sx={{
                              marginTop: "0px",
                              input: {
                                color: "#344054",
                                fontSize: "14px",
                                height: "10px",
                              },
                            }}
                            inputProps={{
                              maxLength: 30,
                            }}
                          />
                        </>
                      )}
                    </Grid>
                  </Grid>

                  <Stack
                    justifyContent={"flex-start"}
                    direction={"row"}
                    marginTop={"0px !important"}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.is_active}
                          value={values.is_active}
                          onChange={(event) => {
                            setFieldValue("is_active", event.target.checked);
                          }}
                          sx={{
                            "&.Mui-checked": {
                              color: "#8B93FF",
                            },
                          }}
                        />
                      }
                      label="Is Active"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.is_admin}
                          value={values.is_admin}
                          onChange={(event) => {
                            setFieldValue("is_admin", event.target.checked);
                          }}
                          sx={{
                            "&.Mui-checked": {
                              color: "#8B93FF",
                            },
                          }}
                        />
                      }
                      label="Is Admin"
                    />
                  </Stack>
                </Box>
                <Stack
                  justifyContent={"flex-start"}
                  direction={"row"}
                  margin={"15px 0px"}
                  gap={"10px"}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{
                      background: "#8B93FF",
                      boxShadow: "none",
                      textTransform: "none",
                      "&:hover": {
                        background: "#8B93FF",
                        boxShadow: "none",
                      },
                    }}
                  >
                    Save changes
                  </Button>
                  <Button
                    onClick={() => handleClose()}
                    sx={{
                      color: "#8B93FF",
                      boxShadow: "none",
                      textTransform: "none",
                      "&:hover": {
                        color: "#8B93FF",
                        background: "transparent",
                        boxShadow: "none",
                      },
                    }}
                  >
                    Cancel
                  </Button>
                </Stack>
              </DialogContent>
            </form>
          </>
        )}
      </Formik>
    </BootstrapDialog>
  );
}
