import React, { useEffect, useState } from "react";
import {
  Stack,
  Button,
  TextField,
  Modal,
  Fade,
  Box,
  IconButton,
} from "@mui/material";
import { FileUploader } from "react-drag-drop-files";
import ClearIcon from "@mui/icons-material/Clear";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import ErrorSnackBar from "../SnackBar/ErrorSnackBar";

const styleNew = {
  // position: "absolute" as "absolute",
  // top: "55%",
  // left: "55%",
  // transform: "translate(-50%, -50%)",
  width: "55vW",
  height: "60vH",
  bgcolor: "background.paper",

  p: 1,
  // boxShadow: 24,
  border: "1px solid #DDDCE1",
  borderRadius: "10px",
};

interface Props {
  handleFileChangeUpload: any;
  acceptedFormats: any;
  errorMessage: string;
  selectedFile: any;
}

export default function DragandDropFile({
  handleFileChangeUpload,
  acceptedFormats,
  errorMessage,
  selectedFile,
}: Props) {
  const [file, setFile] = useState<any>(null);
  const [fileErrorMessage, setFileErrorMessage] = useState("");
  const handleChange = (file: any) => {
    setFile(file);
  };

  return (
    <Stack alignItems={"center"}>
      <Box sx={styleNew}>
        <Stack
          height={"50vh"}
          justifyContent={"center"}
          alignItems={"center"}
          marginTop={"20px"}
        >
          {/* <h3> Drag & Drop Files Here</h3> */}
          <div>
            <FileUploader
              multiple={true}
              handleChange={handleFileChangeUpload}
              name="file"
              types={acceptedFormats}
              onTypeError={(err: any) => {
                setFileErrorMessage(err);

                setTimeout(() => {
                  setFileErrorMessage("");
                }, 5000);
              }}
            >
              <div
                style={{
                  border: "2px dotted #DDDCE1",
                  borderRadius: "10px",
                  width: "600px",
                  padding: "30px 0px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CloudUploadOutlinedIcon
                    style={{ fontSize: "80px", color: "rgba(0, 0, 0, 0.4)" }}
                  />
                  <p>Select a file or drag and drop here</p>
                  <p style={{ fontSize: "12px", marginTop:'10px' }}>
                    Only .{acceptedFormats} files ,File size no more than 10MB
                  </p>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    marginTop: "30px",
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      width: "160px",
                      background: "#5A6ACF",
                      textTransform: "none",
                      boxShadow: "none",
                      "&:hover": {
                        background: "#5A6ACF",
                        boxShadow: "none",
                      },
                    }}
                  >
                    Select File
                  </Button>
                </div>
              </div>
            </FileUploader>
          </div>
          <ErrorSnackBar message={fileErrorMessage} />
        </Stack>
      </Box>
    </Stack>
  );
}
