import * as React from "react";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { Formik } from "formik";
import {
  Stack,
  Fade,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  ThemeProvider,
  Container,
  FormHelperText,
  Typography,
  Box,
  Button,
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
  Grid,
} from "@mui/material";
import {
  TariffAddScheme,
  UserAddScheme,
  UserEditScheme,
} from "../../../Schemas/Schemas";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { AddTariff, AddUser } from "../../../Utils/InitialValues";
import { IEditUser } from "../../../Utils/dtos";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {
  GetBrokerageTemplatesAPICall,
  GetRolesAPICall,
} from "../../../Utils/APICalls";
import EditIcon from "@mui/icons-material/Edit";
import styles from "./TariffModal.module.scss";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(1),
  },

  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {/* {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null} */}
    </DialogTitle>
  );
}

interface Props {
  open: boolean;
  handleClose: any;
  brokeragetemplateData?: any;
  handleSubmitData: any;
  data?: IEditUser | any;
}

export default function TariffModalpopup({
  open,
  handleClose,
  handleSubmitData,
  data,
  brokeragetemplateData,
}: Props) {
  const [showPassword, setShowPassword] = React.useState(false);

  const [role, setRole] = React.useState<any>("");
  const [type, setType] = React.useState("add");
  const [roleData, setRoleData] = React.useState<any>();

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  React.useEffect(() => {
    // setRole(brokeragetemplateData[0]);
  }, [brokeragetemplateData]);
  React.useEffect(() => {
    if (data) {
      setType("view");
      setRole(data.brokerageTemplate);
    } else {
      setType("add");
      setRole("");
    }
  }, [data, brokeragetemplateData]);

  const handleEditClick = () => {
    setType("edit");
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const AddTariffData = {
    partnerName: "",
    partnerId: "",
    equityDelivery: "",
    equityIntraday: "",
    futures: "",
    options: "",
    brokerageTemplate: role,
  };

  //  console.log("role", data);

  //   const type = data ? "view" : "add";

  console.log("role", role);

  return (
    <BootstrapDialog
      //   onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      onClose={(event: any) => {
        if (event.keyCode === 27) {
          handleClose();
        }
      }}
    >
      <BootstrapDialogTitle
        id="customized-dialog-title"
        onClose={() => {
          handleClose();
          setRole("");
        }}
      >
        {type === "add"
          ? "Add Tariff"
          : type === "edit"
          ? "Edit Tariff"
          : "View Tariff"}
        {/* {type === "view" && (
          <Tooltip title="Edit Details">
            <IconButton onClick={handleEditClick}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        )} */}
      </BootstrapDialogTitle>

      <Formik
        validationSchema={TariffAddScheme}
        initialValues={data ? data : AddTariffData}
        onSubmit={(values) => {
          //   console.log("values", values);
          handleSubmitData(values);
          setRole("");
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <>
            {/* {console.log("values", values, "errors", errors)} */}
            <form noValidate onSubmit={handleSubmit}>
              <DialogContent
                style={{
                  border: "1px solid #DDDCE1",
                  margin: "0px 20px 20px 20px",
                  borderRadius: "4px",
                  maxWidth: "unset",
                  width: "800px",
                }}
              >
                <Box component="form" noValidate>
                  <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 2 }}
                  >
                    <Grid item xs={6}>
                      <p className={styles.textfieldLabel}>Partner Name</p>
                      <TextField
                        id="partnerName"
                        fullWidth
                        disabled={type === "view"}
                        placeholder="Enter Partner Name"
                        variant="outlined"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.partnerName}
                        error={
                          touched.partnerName && Boolean(errors.partnerName)
                        }
                        helperText={
                          touched.partnerName && errors.partnerName?.toString()
                        }
                        sx={{
                          input: {
                            color: "#344054",
                            fontSize: "14px",
                            height: "10px",
                          },
                        }}
                        inputProps={{ maxLength: 100 }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <p className={styles.textfieldLabel}>Partner Id</p>
                      <TextField
                        id="partnerId"
                        fullWidth
                        disabled={type === "view" || type === "edit"}
                        placeholder="Enter Partner Id"
                        variant="outlined"
                        onChange={(e) => {
                          const uppercasedValue = e.target.value.toUpperCase();
                          handleChange({
                            target: {
                              id: "partnerId",
                              value: uppercasedValue,
                            },
                          });
                        }}
                        onBlur={handleBlur}
                        value={values.partnerId}
                        error={touched.partnerId && Boolean(errors.partnerId)}
                        helperText={
                          touched.partnerId && errors.partnerId?.toString()
                        }
                        sx={{
                          input: {
                            color: "#344054",
                            fontSize: "14px",
                            height: "10px",
                          },
                        }}
                        inputProps={{ maxLength: 100 }}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <p className={styles.textfieldLabel}>Equity Delivery</p>
                      <TextField
                        id="equityDelivery"
                        fullWidth
                        disabled={type === "view" || role?.Name === "ONLINE"}
                        placeholder="Enter Equity Delivery"
                        variant="outlined"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.equityDelivery}
                        error={
                          touched.equityDelivery &&
                          Boolean(errors.equityDelivery)
                        }
                        helperText={
                          touched.equityDelivery &&
                          errors.equityDelivery?.toString()
                        }
                        inputProps={{
                          maxLength: 100, // Set the maximum character limit
                        }}
                        sx={{
                          input: {
                            color: "#344054",
                            fontSize: "14px",
                            height: "10px",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <p className={styles.textfieldLabel}>Equity IntraDay</p>
                      <TextField
                        id="equityIntraday"
                        fullWidth
                        disabled={type === "view" || role?.Name === "ONLINE"}
                        placeholder="Enter Equity IntraDay"
                        variant="outlined"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.equityIntraday}
                        error={
                          touched.equityIntraday &&
                          Boolean(errors.equityIntraday)
                        }
                        helperText={
                          touched.equityIntraday &&
                          errors.equityIntraday?.toString()
                        }
                        inputProps={{
                          maxLength: 100, // Set the maximum character limit
                        }}
                        sx={{
                          input: {
                            color: "#344054",
                            fontSize: "14px",
                            height: "10px",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <p className={styles.textfieldLabel}>Futures</p>
                      <TextField
                        id="futures"
                        fullWidth
                        disabled={type === "view" || role?.Name === "ONLINE"}
                        placeholder="Enter Futures"
                        variant="outlined"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.futures}
                        error={touched.futures && Boolean(errors.futures)}
                        helperText={
                          touched.futures && errors.futures?.toString()
                        }
                        inputProps={{
                          maxLength: 100,
                        }}
                        sx={{
                          input: {
                            color: "#344054",
                            fontSize: "14px",
                            height: "10px",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <p className={styles.textfieldLabel}>Options</p>
                      <TextField
                        id="options"
                        fullWidth
                        disabled={type === "view" || role?.Name === "ONLINE"}
                        placeholder="Enter Options"
                        variant="outlined"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.options}
                        error={touched.options && Boolean(errors.options)}
                        helperText={
                          touched.options && errors.options?.toString()
                        }
                        inputProps={{
                          maxLength: 100, // Set the maximum character limit
                        }}
                        sx={{
                          input: {
                            color: "#344054",
                            fontSize: "14px",
                            height: "10px",
                          },
                        }}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <p className={styles.textfieldLabel}>
                        Brokerage template
                      </p>
                      <FormControl fullWidth>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={role}
                          displayEmpty
                          disabled={type === "view"}
                          name="brokerageTemplate"
                          renderValue={
                            role !== ""
                              ? undefined
                              : () => {
                                  return (
                                    <p
                                      style={{
                                        margin: 0,
                                        fontWeight: 300,
                                        color: "#adadb1",
                                        fontSize: "13px",
                                      }}
                                    >
                                      Select Brokerage template
                                    </p>
                                  );
                                }
                          }
                          error={
                            touched.brokerageTemplate &&
                            Boolean(errors.brokerageTemplate)
                          }
                          onChange={(event: any) => {
                            //console.log(event.target.value.Name);
                            setFieldValue(
                              "brokerageTemplate",
                              event.target.value
                            );
                            if (event.target.value.name === "ONLINE") {
                              setFieldValue("equityDelivery", "Zero Brokerage");
                              setFieldValue(
                                "equityIntraday",
                                "Rs. 20 per executed order or 0.03% of Turnover whichever is lower"
                              );
                              setFieldValue(
                                "futures",
                                "Rs. 20 per executed order or 0.03% of Turnover whichever is lower"
                              );
                              setFieldValue(
                                "options",
                                "Rs. 20 per executed order"
                              );
                            } else {
                              setFieldValue(
                                "equityDelivery",
                                values.equityDelivery
                              );
                              setFieldValue(
                                "equityIntraday",
                                values.equityIntraday
                              );
                              setFieldValue("futures", values.futures);
                              setFieldValue("options", values.options);
                            }
                            setRole(event.target.value);
                          }}
                          sx={{ height: "40px" }}
                        >
                          {brokeragetemplateData.map((item: any) => (
                            <MenuItem key={item.name} value={item}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>
                <Stack
                  justifyContent={"flex-start"}
                  direction={"row"}
                  margin={"15px 0px"}
                  gap={"10px"}
                >
                  {type !== "view" && (
                    <>
                      {values.partnerName &&
                      values.partnerId &&
                      values.equityDelivery &&
                      values.equityIntraday &&
                      values.futures &&
                      values.options &&
                      role ? (
                        <Button
                          type="submit"
                          variant="contained"
                          sx={{
                            background: "#8B93FF",
                            boxShadow: "none",
                            textTransform: "none",
                            "&:hover": {
                              background: "#8B93FF",
                              boxShadow: "none",
                            },
                          }}
                        >
                          Save changes
                        </Button>
                      ) : (
                        <Button
                          type="submit"
                          variant="contained"
                          disabled
                          sx={{
                            background: "#8B93FF",
                            boxShadow: "none",
                            textTransform: "none",
                            "&:hover": {
                              background: "#8B93FF",
                              boxShadow: "none",
                            },
                          }}
                        >
                          Save changes
                        </Button>
                      )}
                    </>
                  )}
                  <Button
                    onClick={() => handleClose()}
                    sx={{
                      color: "#8B93FF",
                      boxShadow: "none",
                      textTransform: "none",
                      "&:hover": {
                        color: "#8B93FF",
                        background: "transparent",
                        boxShadow: "none",
                      },
                    }}
                  >
                    Cancel
                  </Button>
                </Stack>
              </DialogContent>
            </form>
          </>
        )}
      </Formik>
    </BootstrapDialog>
  );
}
