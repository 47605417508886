import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Stack,
  Fade,
  InputLabel,
  Box,
  IconButton,
  Modal,
  Button,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import styles from "./AssignUser.module.scss";

import { ListUsersAPICall } from "../../../Utils/APICalls";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(1),
  },

  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
    </DialogTitle>
  );
}

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

interface Props {
  open: boolean;
  handleClose: any;
  assigneID: any;
  userData?: any;
}

export default function AssignUser({
  open,
  handleClose,
  assigneID,
  userData,
}: Props) {
  const [userId, setUserId] = useState("");
  const [users, setusers] = useState<any>();
  // useEffect(() => {
  //   ListUsersAPICall()
  //     .then((response: any) => {
  //       const responseData = response.data;
  //       setusers(responseData.data.user_list);
  //       // console.log(responseData.data.user_list);
  //     })
  //     .catch((error: any) => {});
  // }, []);

  const handleChange = (event: SelectChangeEvent) => {
    setUserId(event.target.value as string);
    // console.log(event.target);
  };

  const handleAssignClick = () => {
    assigneID(userId);
  };
  const close = (e: any) => {
    if (e.keyCode === 27) {
      handleClose();
    }
  };

  return (
    <div>
      <BootstrapDialog
        //   onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        onClose={(event: any) => {
          if (event.keyCode === 27) {
            handleClose();
          }
        }}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => {
            handleClose();
          }}
        >
          Assign User
        </BootstrapDialogTitle>

        <DialogContent
          style={{
            border: "1px solid #DDDCE1",
            margin: "0px 20px 20px 20px",
            borderRadius: "4px",
            maxWidth: "unset",
            width: "500px",
          }}
        >
          <Box component="form" noValidate>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 2 }}
            >
              <Grid item xs={12}>
                <p className={styles.textfieldLabel}>Select user</p>
                <FormControl sx={{ width: "100%" }}>
                  <Select
                    displayEmpty
                    renderValue={
                      userId !== ""
                        ? undefined
                        : () => {
                            return (
                              <p
                                style={{
                                  margin: 0,
                                  fontWeight: 300,
                                  color: "#adadb1",
                                  fontSize: "13px",
                                }}
                              >
                                Select user
                              </p>
                            );
                          }
                    }
                    sx={{ height: "40px" }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={userId}
                    label=""
                    onChange={handleChange}
                  >
                    {userData &&
                      userData.map((data: any) => {
                        return (
                          <MenuItem value={data.admin_id} key={data.full_name}>
                            {data.full_name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
          <Stack
            justifyContent={"flex-start"}
            direction={"row"}
            margin={"15px 0px"}
            gap={"10px"}
          >
            <>
              <Button
                onClick={handleAssignClick}
                variant="contained"
                sx={{
                  background: "#8B93FF",
                  boxShadow: "none",
                  textTransform: "none",
                  "&:hover": {
                    background: "#8B93FF",
                    boxShadow: "none",
                  },
                }}
              >
                Assign
              </Button>
            </>

            <Button
              onClick={() => handleClose()}
              sx={{
                color: "#8B93FF",
                boxShadow: "none",
                textTransform: "none",
                "&:hover": {
                  color: "#8B93FF",
                  background: "transparent",
                  boxShadow: "none",
                },
              }}
            >
              Cancel
            </Button>
          </Stack>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
