import {
  Button,
  CircularProgress,
  IconButton,
  Stack,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { GetDocumentsSegmentUpdateAPICall } from "../../Utils/APICalls";
import { IGetDocumentEmailMobileUpdate } from "../../Utils/dtos";
import DownloadIcon from "@mui/icons-material/Download";
import axios from "axios";
import VisibilityIcon from "@mui/icons-material/Visibility";

interface Props {
  data: string;
  docType: string;
}

// Function to get data from API or localStorage
const getCachedData = (data: string, docType: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    const obj: any = {
      user_id: data,
      doc_type: docType,
    };
    GetDocumentsSegmentUpdateAPICall(obj)
      .then((response: any) => {
        // Save the API response to localStorage
        sessionStorage.setItem(
          `${data}_${docType}`,
          JSON.stringify(response.data)
        );
        resolve(response.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
};

export default function SegmentUpdateBase64ToImage({ data, docType }: Props) {
  const [imageUrl, setImageUrl] = useState<any>(null);
  const [rotationAngle, setRotationAngle] = useState(0);
  const [documentData, setDocumentData] = useState("");
  const [displayPreview, setDisplayPreview] = useState(true);
  const [circularprogress, setCircularProgress] = useState(true);
  const [incomeProofType, setIncomeProofType] = useState("");
  const [pdfFile, setPdfFile] = useState<any>({
    pdf: false,
    data: "",
  });

  const getDetails = () => {
    setCircularProgress(true);
    getCachedData(data, docType)
      .then((response: any) => {
        // console.log("responseimage", response.client_data.url.includes(".pdf"));
        if (response.data.url === "") {
          setDisplayPreview(false);
        } else {
          setDisplayPreview(true);
        }

        if (response.data.url.includes(".pdf")) {
          setPdfFile({
            pdf: true,
            data: response.data.url,
          });
        } else {
          setDocumentData(response.data.url);
          setPdfFile({
            pdf: false,
            data: "",
          });
        }

        setCircularProgress(false);
      })
      .catch((error: any) => {
        //  console.log("error", error);
        setCircularProgress(false);
        setDisplayPreview(false);
      });
  };

  // const handleDownload = () => {
  //   const link = document.createElement("a");
  //   link.href = imageUrl ? imageUrl : documentData;
  //   link.download = "image.jpg"; // You can set the desired filename here
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // };

  const handleDownload = () => {
    // if (imageUrl.startsWith("data:image/")) {
    //   const link = document.createElement("a");
    //   link.href = imageUrl;
    //   link.download = "image.jpg"; // You can set the desired filename here
    //   document.body.appendChild(link);
    //   link.click();
    //   document.body.removeChild(link);
    // } else {
    window.open(documentData, "_blank");
    // }
  };

  useEffect(() => {
    getDetails();
  }, [docType]);

  const rotateLeft = () => {
    setRotationAngle(rotationAngle - 90);
  };

  const rotateRight = () => {
    setRotationAngle(rotationAngle + 90);
  };

  const convertBase64ToImage = (base64String: any) => {
    setImageUrl(base64String);
  };

  return (
    <>
      {circularprogress ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div style={{ height: "100%" }}>
          {displayPreview ? (
            <div style={{ position: "relative", height: "100%" }}>
              {pdfFile.pdf === false && (
                <div style={{ height: "100%" }}>
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <img
                      src={documentData}
                      style={{
                        transform: `rotate(${rotationAngle}deg)`,
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                    />
                  </div>
                  <Tooltip title={imageUrl ? "Download Image" : "View Image"}>
                    <IconButton
                      sx={{
                        position: "absolute",
                        right: 0,
                        top: 0,
                        // border: "1px solid grey",
                      }}
                      onClick={handleDownload}
                    >
                      {imageUrl ? (
                        <DownloadIcon sx={{ color: "grey" }} />
                      ) : (
                        <VisibilityIcon sx={{ color: "grey" }} />
                      )}
                    </IconButton>
                  </Tooltip>
                </div>
              )}

              {pdfFile.pdf === true && (
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <a href={pdfFile.data} target="_blank">
                    <Tooltip title="View File">
                      <IconButton
                        sx={{
                          position: "absolute",
                          right: 0,
                          top: 0,

                          // border: "1px solid grey",
                        }}
                      >
                        <VisibilityIcon sx={{ color: "grey" }} />
                      </IconButton>
                    </Tooltip>
                  </a>
                  <div
                    style={{
                      width: "100%",
                      height: "96%",
                    }}
                  >
                    {docType === "income_proof" && (
                      <p
                        style={{
                          marginTop: "0px",
                          textAlign: "center",
                          fontSize: "14px",
                          textDecoration: "underline",
                          marginBottom: "3px",
                        }}
                      >
                        {incomeProofType.replace(/_/g, " ").toUpperCase()}
                      </p>
                    )}
                    <iframe
                      src={pdfFile.data}
                      width="100%"
                      height="100%"
                      title="PDF Viewer"
                    />
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              No Data
            </div>
          )}
        </div>
      )}
    </>
  );
}
