import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import classes from "./ProfileDetails.module.scss";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import Grid from "@mui/material/Grid";
import {
  clientAddBankApi,
  clientAddNomineeApi,
  clientAdminTableApi,
  clientEditApi,
} from "../../Utils/APICalls";
import { setProfile } from "../../Store/Project/ProjectSlice";
import { useDispatch, useSelector } from "react-redux";
import { Toaster, toast } from "react-hot-toast";
import { Avatar, TextField } from "@mui/material";
import checkboxdone from "../../assets/Images/CheckBoxDone.svg";
import checkboxempty from "../../assets/Images/CheckBox.svg";
import AddIcon from "../../assets/Images/ADDIcon.svg";
import AddBankDetailsModal from "../../components/Modals/AddBankDetails/AddBankModal";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AddNominee from "../../components/Modals/AddNominee/AddNominee";
import NomineePage from "../../components/NomineePage/NomineePage";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Autocomplete from "@mui/material/Autocomplete";
import { bool } from "yup";

const mappingValues: any = {
  "User Details": "User Details",
  "Roles&Permissions": "Roles&Permissions",
  "More Information": "More Information",
  Settings: "Settings",
  Connections: "Connections",
};
const StatusmappingValues: any = {
  A: "Active",
  T: "Terminated",
  F: "Freezed",
  "": "-",
};

const DDPIOPT: any = ["True", "False"];
const POAOPT: any = ["True", "False"];
const AccountStatus = ["Active", "Freeze", "Terminate"];

export default function ProfileDetails() {
  const navigate = useNavigate();
  const [edit, setedit] = useState(false);
  const [selectedTab, setselectedTab] = useState<string>("User Details");
  const [openAddBankModal, setopenAddBankModal] = useState(false);
  const [openAddNomineeModal, setopenAddNomineeModal] = useState(false);
  const [openAddNominee, setopenAddNominee] = useState(false);

  const [nomineeId, setnomineeId] = useState(1);

  const [micrnumber, setmicrnumber] = useState("");
  const [profile, setProfiledata] = useState<any>({
    client_code: "",
    pan_no: "",
    mobile_no: "",
    email_id: "",
    client_status: {
      account_status: "",
      reason: "",
      account_status_change_reason: "",
      ac_status_logs: {},
    },
    UCC: {
      NSEUccUpload: "",
      NSEUccDate: "",
      BSEUccUpload: "",
      BSEUccDate: "",
      NSEFOUccUpload: "",
      NSEFOUccDate: "",
      BSEFOUccUpload: "",
      BSEFOUccDate: "",
      NSECDUccUpload: "",
      NSECDUccDate: "",
      NSECOMUccUpload: "",
      NSECOMUccDate: "",
      MCXUccUpload: "",
      MCXUccDate: "",
    },
    client_info: {
      client_type: "",
      client_name: "",
      first_name: "",
      middle_name: "",
      last_name: "",
      gender: "",
      dob: "",
      marital_status: "",
      residential: "",
      occupation: "",
      profile_name: "",
      last_traded_date: "",
      landline: "",
      mother_name: "",
      closed_date: "",
      income_status: "",
      income_date: "",
      risk_type: "",
      boid: "",
      ckyc_no: "",
      dp_holding: "",
      pan_no: "",
      account_open_date: "",
      application_type: "",
      enroll_date: "",
    },
    client_nominee: [
      {
        nominee_id: "",
        full_name: "",
        email: "",
        dob: "",
        mobile: "",
        percentage_share: "",
        relationship: "",
        other_relationship: "",
        guardian: {
          fullname: "",
          dob: "",
          email: "",
          mobile: "",
          relation_with_nominee: "",
          address: "",
          pincode: "",
          city: "",
          state: "",
          country: "",
        },
        id_proof: {
          type: "",
          details: "",
          id_proof_b64: "",
        },
        address: "",
        city: "",
        pincode: "",
        state: "",
        country: "",
      },
    ],
    poa: {
      status: "",
      id: "",
      date: "",
    },
    relative_client: {
      mobile: "",
      relation: "",
      email: "",
    },
    ddpi: {
      status: "",
    },
    nri: {
      fatca_consent: "",
      custodian: "",
    },
    address_details: {
      address: "",
      city: "",
      state: "",
      pincode: "",
      country: "",
      is_primary: "",
    },
    bank_details: [
      {
        priority: "",
        name: "",
        account_no: "",
        ifsc: "",
        micr: "",
      },
    ],
    organisation: {
      gstin: "",
      company_name: "",
      gst_register_date: "",
    },
    referral: {
      introducer: {
        id: "",
        remisier_name: "",
      },
      client: "",
    },
    miscellaneous: {
      crd_number: "",
      it_client: "",
      margin_funded: "",
      employee_code: "",
      kyc_application_no: "",
    },
    client_added_ts: "",
    client_modified_ts: "",
  });

  const [tabLabels, setTabLabels] = useState([
    "User Details",
    "Roles&Permissions",
    "More Information",
    "Settings",
    "Connections",
  ]);
  const dispatch = useDispatch();
  // const profile = useSelector((state: any) => state.project.profile);
  const handleChangeTab = (tab: any) => {
    setselectedTab(tab);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    GetClientDetails();

    if (profile.client_nominee.length === 0) {
      setnomineeId(1);
    } else if (profile.client_nominee.length === 1) {
      setnomineeId(2);
    } else if (profile.client_nominee.length === 2) {
      setnomineeId(3);
    }
  }, []);

  const GetClientDetails = () => {
    const id = sessionStorage.getItem("id");

    clientAdminTableApi(0, 10, id)
      .then((res) => {
        // console.log(res.data.total_pages);
        dispatch(setProfile(res.data.data));
        setProfiledata(res.data.data.client_details[0]);
      })
      .catch((res) => {
        toast.error("User Invalid");
      });
  };

  const handleOpenAddBankModal = () => {
    setopenAddBankModal(true);
  };

  const handleCloseOpenAddBankModal = () => {
    setopenAddBankModal(false);
  };

  const handleCloseNomineeModal = () => {
    setopenAddNomineeModal(false);
  };
  const handleSubmitAddedBankDetails = (BankValues: any) => {
    const reqbody = {
      client_code: profile.client_code,
      bank_details: [
        {
          name: BankValues.bankName,
          account_no: BankValues.accountnumber,
          ifsc: BankValues.ifsc,
          micr: micrnumber,
          priority: BankValues.priority,
        },
      ],
    };
    clientAddBankApi(reqbody)
      .then((res) => {
        GetClientDetails();
        toast.success(res.data.message);
      })
      .catch(() => {});
  };

  const handleSubmitNomineeDetails = (values: any) => {
    const existingNominees = profile.client_nominee || [];

    const newNominee = {
      nominee_id: "",
      full_name: values.fullname,
      email: values.email,
      dob: values.dob,
      mobile: values.number,
      percentage_share: values.share,
      relationship: values.relationship,
      other_relationship: "",
      guardian: {
        fullname: values.gname,
        dob: values.gdob,
        email: values.gemail,
        mobile: values.gmobile,
        relation_with_nominee: values.grelation,
        address: values.gaddress,
        pincode: values.gpincode,
        city: values.gcity,
        state: values.gstate,
        country: values.gcountry,
      },
      id_proof: {
        type: "",
        details: "",
        id_proof_b64: "",
      },
      address: values.address,
      city: values.city,
      pincode: values.pincode,
      state: values.state,
      country: values.country,
    };

    const nomineeDetails = [...existingNominees, newNominee];

    const reqbody = {
      client_code: profile.client_code,
      nominee_details: nomineeDetails,
    };

    clientAddNomineeApi(reqbody)
      .then((res) => {
        GetClientDetails();
        toast.success(res.data.message);
      })
      .catch(() => {});
  };

  function formatDate(inputDate: any) {
    // Parse the input date string
    const dateObject = new Date(inputDate);

    // Get day, month, and year components
    const day = dateObject.getDate();
    const month = dateObject.getMonth() + 1; // Month is zero-indexed
    const year = dateObject.getFullYear();

    // Format the date as DD-MM-YYYY
    const formattedDate = `${day < 10 ? "0" : ""}${day}-${
      month < 10 ? "0" : ""
    }${month}-${year}`;

    return formattedDate;
  }

  const editClientDetails = () => {
    const reqbody = {
      client_code: profile.client_code,
      pan_no: profile.pan_no,
      mobile_no: profile.mobile_no,
      email_id: profile.email_id,
      client_status: {
        account_status: profile.client_status.account_status,
        reason: profile.client_status.reason,
      },
      UCC: {
        NSEUccUpload: profile.UCC.NSEUccUpload,
        NSEUccDate: profile.UCC.NSEUccDate,
        BSEUccUpload: profile.UCC.BSEUccUpload,
        BSEUccDate: profile.UCC.BSEUccDate,
        NSEFOUccUpload: profile.UCC.NSEFOUccUpload,
        NSEFOUccDate: profile.UCC.NSEFOUccDate,
        BSEFOUccUpload: profile.UCC.BSEFOUccUpload,
        BSEFOUccDate: profile.UCC.BSEFOUccDate,
        NSECDUccUpload: profile.UCC.NSECDUccUpload,
        NSECDUccDate: profile.UCC.NSECDUccDate,
        NSECOMUccUpload: profile.UCC.NSECOMUccUpload,
        NSECOMUccDate: profile.UCC.NSECOMUccDate,
        MCXUccUpload: profile.UCC.MCXUccUpload,
        MCXUccDate: profile.UCC.MCXUccDate,
      },
      client_info: {
        client_type: profile.client_info.client_type,
        client_name: profile.client_info.client_name,
        first_name: profile.client_info.first_name,
        middle_name: profile.client_info.middle_name,
        last_name: profile.client_info.last_name,
        gender: profile.client_info.gender,
        dob: profile.client_info.dob,
        marital_status: profile.client_info.marital_status,
        residential: profile.client_info.residential,
        occupation: profile.client_info.occupation,
        profile_name: profile.client_info.profile_name,
        last_traded_date: profile.client_info.last_traded_date,
        landline: profile.client_info.landline,
        mother_name: profile.client_info.mother_name,
        closed_date: profile.client_info.closed_date,
        income_status: profile.client_info.income_status,
        income_date: profile.client_info.income_date,
        risk_type: profile.client_info.risk_type,
        boid: profile.client_info.boid,
        ckyc_no: profile.client_info.ckyc_no.toString(),
        dp_holding: profile.client_info.dp_holding,
        pan_no: profile.client_info.pan_no,
        account_open_date: profile.client_info.account_open_date,
        application_type: profile.client_info.application_type,
        enroll_date: profile.client_info.enroll_date,
      },

      poa: {
        status: profile.poa.status,
        id: profile.poa.id,
        date: profile.poa.date,
      },
      ddpi: {
        status: profile.ddpi.status,
      },
      relative_client: {
        mobile: profile.relative_client.mobile,
        relation: profile.relative_client.relation,
        email: profile.relative_client.email,
      },
      nri: {
        fatca_consent: profile.nri.fatca_consent,
        custodian: profile.nri.custodian,
      },
      address_details: {
        address: profile.address_details.address,
        city: profile.address_details.city,
        state: profile.address_details.state,
        pincode: profile.address_details.pincode,
        country: profile.address_details.country,
        is_primary: profile.address_details.is_primary,
      },
      organisation: {
        gstin: profile.organisation.gstin,
        company_name: profile.organisation.company_name,
        gst_register_date: profile.organisation.gst_register_date,
      },
      referral: {
        introducer: {
          id: profile.referral.introducer.id,
          remisier_name: profile.referral.introducer.remisier_name,
        },
        client: profile.referral.client,
      },
      miscellaneous: {
        crd_number: profile.miscellaneous.crd_number,
        it_client: profile.miscellaneous.it_client,
        margin_funded: profile.miscellaneous.margin_funded,
        employee_code: "",
        kyc_application_no: profile.miscellaneous.kyc_application_no,
      },
    };

    clientEditApi(reqbody)
      .then((res) => {
        toast.success(res.data.message);
        GetClientDetails();
      })
      .catch((err) => {
        GetClientDetails();
        toast.error(err.response.data.error);
      });
  };

  const handleSubmitNomineeDetails2 = (nominee: any) => {
    const reqbody = {
      client_code: profile.client_code,
      nominee_details: nominee,
    };

    clientAddNomineeApi(reqbody)
      .then((res) => {
        GetClientDetails();
        setopenAddNominee(false);
        toast.success(res.data.message);
      })
      .catch(() => {});
  };

  return (
    <div>
      <Toaster />

      <div className={classes.crumbs}>
        <span onClick={() => navigate("/dashboard/clientadmin")}>
          All Clients
        </span>{" "}
        &nbsp; / &nbsp;{" "}
        <span className={classes.crumbsLink}>Client Details</span>
      </div>
      <div className={classes.wrapper}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Avatar
              src=""
              sx={{
                background: "#e9e9e9",
                color: "#8b93ffb8",
                fontSize: "18px",
                fontWeight: "bolder",
              }}
            >
              {profile?.client_code.slice(0, 2)}
            </Avatar>
            <p
              style={{
                fontSize: "14px",
                fontWeight: 600,
                marginBottom: "5px",
                marginLeft: "5px",
                display: "flex",
              }}
            >
              {" " +
                profile?.client_code +
                "-" +
                profile.client_info.client_name}
            </p>
            {edit ? (
              <Tooltip
                title="Save"
                sx={{ ml: "10px" }}
                onClick={() => {
                  setedit(!edit);
                  editClientDetails();
                }}
              >
                <IconButton>
                  <SaveAsIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip
                title="Edit"
                sx={{ ml: "10px" }}
                onClick={() => {
                  setedit(!edit);
                }}
              >
                <IconButton>
                  <EditIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
          </div>
          {openAddNominee ? (
            <div
              onClick={() => {
                setopenAddNominee(false);
              }}
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "14px",
                gap: "5px",
                cursor: "pointer",
              }}
            >
              <ArrowBackIcon fontSize="small" />
              Back
            </div>
          ) : (
            <div style={{ display: "flex", gap: "15px" }}>
              {/* <div
              onClick={() => {
                setopenAddNomineeModal(true);
              }}
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "14px",
                gap: "5px",
                cursor: "pointer",
              }}
            >
              <AddCircleOutlineIcon fontSize="small" /> Add/Edit Nominee
            </div> */}
              <div
                onClick={() => {
                  // navigate("/dashboard/clients/profile/addNominee");
                  setopenAddNominee(true);
                }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "14px",
                  gap: "5px",
                  cursor: "pointer",
                }}
              >
                <AddCircleOutlineIcon fontSize="small" /> Add/Edit Nominee
              </div>
              <div
                onClick={() => {
                  setopenAddBankModal(true);
                }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "14px",
                  gap: "5px",
                  cursor: "pointer",
                }}
              >
                <AddCircleOutlineIcon fontSize="small" /> Add Bank
              </div>
              {/* <div>
              Add Nominee
              </div> */}
            </div>
          )}
        </div>
        {openAddNominee ? (
          <NomineePage
            data={profile.client_nominee}
            updateNominees={handleSubmitNomineeDetails2}
          />
        ) : (
          <>
            <div className={classes.fields} style={{ marginBottom: "5px" }}>
              <Grid
                container
                spacing={1.5}
                columns={16}
                sx={{ marginTop: "0px" }}
              >
                <Grid item xs={4}>
                  <TextField
                    id="outlined-basic"
                    label="Email"
                    value={profile.email_id}
                    onChange={(e) => {
                      if (edit) {
                        setProfiledata((prevState: any) => ({
                          ...prevState,
                          email_id: e.target.value,
                        }));
                      }
                    }}
                    variant="outlined"
                    size="small"
                    sx={{
                      input: {
                        color: "#000000",
                        fontSize: "12px",
                        fontWeight: 700,
                      },
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id="outlined-basic"
                    label="Mobile Number"
                    value={profile.mobile_no}
                    onChange={(e) => {
                      if (edit) {
                        setProfiledata((prevState: any) => ({
                          ...prevState,
                          mobile_no: e.target.value,
                        }));
                      }
                    }}
                    variant="outlined"
                    size="small"
                    sx={{
                      input: {
                        color: "#000000",
                        fontSize: "12px",
                        fontWeight: 700,
                      },
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id="outlined-basic"
                    label="Trade Code"
                    value={profile.client_code}
                    variant="outlined"
                    size="small"
                    InputProps={{ readOnly: true }}
                    sx={{
                      input: {
                        color: "#000000",
                        fontSize: "12px",
                        fontWeight: 700,
                      },
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  {edit ? (
                    <Autocomplete
                      disablePortal
                      options={AccountStatus}
                      disableClearable
                      value={
                        // StatusmappingValues[
                        //   profile.client_status.account_status
                        // ]
                        profile.client_status.account_status === "A"
                          ? "Active"
                          : profile.client_status.account_status === "F"
                          ? "Freeze"
                          : "Terminate"
                      }
                      onChange={(event: any, newValue: string | null) => {
                        if (edit) {
                          setProfiledata((prevState: any) => ({
                            ...prevState,
                            client_status: {
                              ...prevState.client_status,
                              account_status:
                                newValue === "Active"
                                  ? "A"
                                  : newValue === "Terminate"
                                  ? "T"
                                  : "F",
                            },
                          }));
                        }
                      }}
                      size="small"
                      sx={{
                        input: {
                          color: "#000000",
                          fontSize: "12px",
                          fontWeight: 700,
                        },
                      }}
                      renderInput={(params: any) => (
                        <TextField {...params} label="Account Status" />
                      )}
                    />
                  ) : (
                    <TextField
                      id="outlined-basic"
                      label="Status"
                      value={
                        profile.client_status.account_status === "A"
                          ? "Active"
                          : profile.client_status.account_status === "F"
                          ? "Freeze"
                          : "Terminate"
                      }
                      variant="outlined"
                      size="small"
                      sx={{
                        input: {
                          outline: "none",
                          color: "#000000",
                          fontSize: "12px",
                          fontWeight: 700,
                        },
                      }}
                      fullWidth
                    />
                  )}
                </Grid>
              </Grid>
            </div>
            <div className={classes.displayWrapper}>
              <div className={classes.section1Left}>
                <div className={classes.header}>Client Details</div>

                <div className={classes.fields}>
                  <Grid
                    container
                    spacing={1}
                    columns={16}
                    sx={{ marginTop: "0px" }}
                  >
                    <Grid item xs={5.5}>
                      <TextField
                        id="outlined-basic"
                        label="Accouned Open date"
                        value={
                          profile.client_info.account_open_date !== ""
                            ? formatDate(profile.client_info.account_open_date)
                            : profile.client_info.account_open_date
                        }
                        InputProps={{ readOnly: true }}
                        variant="outlined"
                        size="small"
                        sx={{
                          input: {
                            color: "#000000",
                            fontSize: "12px",
                            fontWeight: 700,
                          },
                        }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <TextField
                        id="outlined-basic"
                        label="Date of Birth"
                        value={
                          profile.client_info.dob !== ""
                            ? profile.client_info.dob.split(" ")[0]
                            : profile.client_info.dob.split(" ")[0]
                        }
                        InputProps={{ readOnly: true }}
                        variant="outlined"
                        size="small"
                        sx={{
                          input: {
                            color: "#000000",
                            fontSize: "12px",
                            fontWeight: 700,
                          },
                        }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <TextField
                        id="outlined-basic"
                        label="PAN"
                        value={profile.client_info.pan_no}
                        InputProps={{ readOnly: true }}
                        variant="outlined"
                        size="small"
                        sx={{
                          input: {
                            color: "#000000",
                            fontSize: "12px",
                            fontWeight: 700,
                          },
                        }}
                        fullWidth
                      />
                    </Grid>
                    {/* <Grid item xs={4}>
                  <TextField
                    id="outlined-basic"
                    label="Gender"
                    value={profile.user_data.gender}
                    variant="outlined"
                    size="small"
                    sx={{
                      input: {
                        color: "#000000",
                        fontSize: "12px",
                        fontWeight: 700,
                      },
                    }}
                    fullWidth
                  />
                </Grid> */}
                  </Grid>
                  <Grid
                    container
                    spacing={1.5}
                    columns={16}
                    sx={{ marginTop: "0px" }}
                  >
                    <Grid item xs={5.5}>
                      <TextField
                        id="outlined-basic"
                        label="Father Name"
                        value={
                          edit
                            ? profile.client_info.father_name
                            : profile.client_info.father_name === ""
                            ? "-"
                            : "-"
                        }
                        variant="outlined"
                        size="small"
                        sx={{
                          input: {
                            color: "#000000",
                            fontSize: "12px",
                            fontWeight: 700,
                          },
                        }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <TextField
                        id="outlined-basic"
                        label="Mother Name"
                        value={
                          edit
                            ? profile.client_info.mother_name
                            : profile.client_info.mother_name === ""
                            ? "-"
                            : profile.client_info.mother_name
                        }
                        onChange={(e) => {
                          if (edit) {
                            setProfiledata((prevState: any) => ({
                              ...prevState,
                              client_info: {
                                ...prevState.client_info,
                                mother_name: e.target.value,
                              },
                            }));
                          }
                        }}
                        variant="outlined"
                        size="small"
                        sx={{
                          input: {
                            color: "#000000",
                            fontSize: "12px",
                            fontWeight: 700,
                          },
                        }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <TextField
                        id="outlined-basic"
                        label="Gender"
                        value={profile.client_info.gender}
                        onChange={(e) => {
                          if (edit) {
                            setProfiledata((prevState: any) => ({
                              ...prevState,
                              client_info: {
                                ...prevState.client_info,
                                gender: e.target.value,
                              },
                            }));
                          }
                        }}
                        variant="outlined"
                        size="small"
                        sx={{
                          input: {
                            color: "#000000",
                            fontSize: "12px",
                            fontWeight: 700,
                          },
                        }}
                        fullWidth
                      />
                    </Grid>
                    {/* <Grid item xs={4}>
                  <TextField
                    id="outlined-basic"
                    label="Date of Birth"
                    value={profile.user_data.dob}
                    variant="outlined"
                    size="small"
                    sx={{
                      input: {
                        color: "#000000",
                        fontSize: "12px",
                        fontWeight: 700,
                      },
                    }}
                    fullWidth
                  />
                </Grid> */}
                  </Grid>

                  <Grid
                    container
                    spacing={1.5}
                    columns={16}
                    sx={{ marginTop: "0px" }}
                  >
                    <Grid item xs={5.5}>
                      <TextField
                        id="outlined-basic"
                        label="Residential Status"
                        value={profile.client_info.residential}
                        onChange={(e) => {
                          if (edit) {
                            setProfiledata((prevState: any) => ({
                              ...prevState,
                              client_info: {
                                ...prevState.client_info,
                                residential: e.target.value,
                              },
                            }));
                          }
                        }}
                        variant="outlined"
                        size="small"
                        sx={{
                          input: {
                            color: "#000000",
                            fontSize: "12px",
                            fontWeight: 700,
                          },
                        }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <TextField
                        id="outlined-basic"
                        label="DP ID"
                        value={profile.client_info.boid}
                        variant="outlined"
                        size="small"
                        sx={{
                          input: {
                            color: "#000000",
                            fontSize: "12px",
                            fontWeight: 700,
                          },
                        }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <TextField
                        id="outlined-basic"
                        label="GSTIN"
                        value={
                          edit
                            ? profile?.organisation.gstin
                            : profile?.organisation.gstin === ""
                            ? "-"
                            : profile.organisation.gstin
                        }
                        variant="outlined"
                        size="small"
                        sx={{
                          input: {
                            color: "#000000",
                            fontSize: "12px",
                            fontWeight: 700,
                          },
                        }}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={1.5}
                    columns={16}
                    sx={{ marginTop: "0px" }}
                  >
                    <Grid item xs={15.5}>
                      <TextField
                        id="outlined-basic"
                        label="Address"
                        value={
                          edit
                            ? profile.address_details.address
                            : profile.address_details.address === ""
                            ? "-"
                            : profile.address_details.address
                        }
                        onChange={(e) => {
                          if (edit) {
                            setProfiledata((prevState: any) => ({
                              ...prevState,
                              address_details: {
                                ...prevState.address_details,
                                address: e.target.value,
                              },
                            }));
                          }
                        }}
                        variant="outlined"
                        size="small"
                        sx={{
                          input: {
                            color: "#000000",
                            fontSize: "12px",
                            fontWeight: 700,
                          },
                        }}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </div>
              </div>
              <div className={classes.section1Right}>
                <div className={classes.header}>Segments</div>
                <div className={classes.fields}>
                  <Grid container spacing={1} columns={16}>
                    <Grid item xs={8}>
                      <div
                        className={classes.checkboxsection}
                        onClick={() => {
                          const updatedvalue =
                            profile.UCC.BSEUccUpload === "" ? "Y" : "";

                          if (edit) {
                            setProfiledata((prevState: any) => ({
                              ...prevState,
                              UCC: {
                                ...prevState.UCC,
                                BSEUccUpload: updatedvalue,
                              },
                            }));
                          }
                        }}
                      >
                        <p className={classes.checkboxsectionName}>
                          BSE Equity
                        </p>
                        <img
                          src={
                            profile.UCC.BSEUccUpload
                              ? checkboxdone
                              : checkboxempty
                          }
                        />
                      </div>
                    </Grid>
                    <Grid item xs={8}>
                      <div
                        className={classes.checkboxsection}
                        onClick={() => {
                          const updatedvalue =
                            profile.UCC.NSEUccUpload === "" ? "Y" : "";

                          if (edit) {
                            setProfiledata((prevState: any) => ({
                              ...prevState,
                              UCC: {
                                ...prevState.UCC,
                                NSEUccUpload: updatedvalue,
                              },
                            }));
                          }
                        }}
                      >
                        <p className={classes.checkboxsectionName}>
                          NSE Equity
                        </p>
                        <img
                          src={
                            profile.UCC.NSEUccUpload
                              ? checkboxdone
                              : checkboxempty
                          }
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} columns={16}>
                    <Grid item xs={8}>
                      <div
                        className={classes.checkboxsection}
                        onClick={() => {
                          const updatedvalue =
                            profile.UCC.NSEFOUccUpload === "" ? "Y" : "";

                          if (edit) {
                            setProfiledata((prevState: any) => ({
                              ...prevState,
                              UCC: {
                                ...prevState.UCC,
                                NSEFOUccUpload: updatedvalue,
                              },
                            }));
                          }
                        }}
                      >
                        <p className={classes.checkboxsectionName}>NSE FO</p>
                        <img
                          src={
                            profile.UCC.NSEFOUccUpload
                              ? checkboxdone
                              : checkboxempty
                          }
                        />
                      </div>
                    </Grid>
                    <Grid item xs={8}>
                      <div
                        className={classes.checkboxsection}
                        onClick={() => {
                          const updatedvalue =
                            profile.UCC.BSEFOUccUpload === "" ? "Y" : "";

                          if (edit) {
                            setProfiledata((prevState: any) => ({
                              ...prevState,
                              UCC: {
                                ...prevState.UCC,
                                BSEFOUccUpload: updatedvalue,
                              },
                            }));
                          }
                        }}
                      >
                        <p className={classes.checkboxsectionName}>BSE FO</p>
                        <img
                          src={
                            profile.UCC.BSEFOUccUpload
                              ? checkboxdone
                              : checkboxempty
                          }
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} columns={16}>
                    <Grid item xs={8}>
                      <div
                        className={classes.checkboxsection}
                        onClick={() => {
                          const updatedvalue =
                            profile.UCC.NSECDUccUpload === "" ? "Y" : "";

                          if (edit) {
                            setProfiledata((prevState: any) => ({
                              ...prevState,
                              UCC: {
                                ...prevState.UCC,
                                NSECDUccUpload: updatedvalue,
                              },
                            }));
                          }
                        }}
                      >
                        <p className={classes.checkboxsectionName}>NSE CDU</p>
                        <img
                          src={
                            profile.UCC.NSECDUccUpload
                              ? checkboxdone
                              : checkboxempty
                          }
                        />
                      </div>
                    </Grid>
                    <Grid item xs={8}>
                      <div
                        className={classes.checkboxsection}
                        onClick={() => {
                          const updatedvalue =
                            profile.UCC.NSECOMUccUpload === "" ? "Y" : "";

                          if (edit) {
                            setProfiledata((prevState: any) => ({
                              ...prevState,
                              UCC: {
                                ...prevState.UCC,
                                NSECOMUccUpload: updatedvalue,
                              },
                            }));
                          }
                        }}
                      >
                        <p className={classes.checkboxsectionName}>NSE COM</p>
                        <img
                          src={
                            profile.UCC.NSECOMUccUpload
                              ? checkboxdone
                              : checkboxempty
                          }
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} columns={16}>
                    <Grid item xs={8}>
                      <div
                        className={classes.checkboxsection}
                        onClick={() => {
                          const updatedvalue =
                            profile.UCC.MCXUccUpload === "" ? "Y" : "";

                          if (edit) {
                            setProfiledata((prevState: any) => ({
                              ...prevState,
                              UCC: {
                                ...prevState.UCC,
                                MCXUccUpload: updatedvalue,
                              },
                            }));
                          }
                        }}
                      >
                        <p className={classes.checkboxsectionName}>MCX</p>
                        <img
                          src={
                            profile.UCC.MCXUccUpload === "Y"
                              ? checkboxdone
                              : checkboxempty
                          }
                        />
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>

            <div
              className={classes.section1}
              style={{ marginTop: "5px", marginBottom: "10px" }}
            >
              <div className={classes.header}>Bank Account Details </div>
              <div className={classes.fields}>
                <Grid container spacing={1} columns={16}>
                  <Grid
                    item
                    xs={2}
                    style={{
                      background: "#F4F7F9",
                      borderRight: "1px solid #E1E2E3",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "12px",
                        fontWeight: 600,
                        textAlign: "center",
                        paddingBottom: "5px",
                      }}
                    >
                      Priority
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={5}
                    style={{
                      background: "#F4F7F9",
                      borderRight: "1px solid #E1E2E3",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "12px",
                        fontWeight: 600,
                        textAlign: "center",
                        paddingBottom: "5px",
                      }}
                    >
                      Bank Name
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    style={{
                      background: "#F4F7F9",
                      borderRight: "1px solid #E1E2E3",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "12px",
                        fontWeight: 600,
                        textAlign: "center",
                        paddingBottom: "5px",
                      }}
                    >
                      Account No
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    style={{
                      background: "#F4F7F9",
                      borderRight: "1px solid #E1E2E3",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "12px",
                        fontWeight: 600,
                        textAlign: "center",
                        paddingBottom: "5px",
                      }}
                    >
                      MICR Code
                    </div>
                  </Grid>
                  <Grid item xs={3} style={{ background: "#F4F7F9" }}>
                    <div
                      style={{
                        fontSize: "12px",
                        fontWeight: 600,
                        textAlign: "center",
                        paddingBottom: "5px",
                      }}
                    >
                      IFSC Code
                    </div>
                  </Grid>
                </Grid>
                {profile.bank_details.map((data: any, i: any) => (
                  <>
                    <Grid
                      container
                      spacing={1}
                      columns={16}
                      sx={{ marginTop: "0px" }}
                    >
                      <Grid
                        item
                        xs={2}
                        style={{ borderRight: "1px solid #E1E2E3" }}
                      >
                        <div
                          style={{
                            fontSize: "12px",
                            fontWeight: 600,
                            textAlign: "center",
                            paddingBottom: "10px",
                            textDecoration: "underline",
                          }}
                        >
                          {data.priority}
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={5}
                        style={{ borderRight: "1px solid #E1E2E3" }}
                      >
                        <div
                          style={{
                            fontSize: "12px",
                            fontWeight: 600,
                            textAlign: "center",
                            paddingBottom: "10px",
                            textDecoration: "underline",
                          }}
                        >
                          {data.name}
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        style={{ borderRight: "1px solid #E1E2E3" }}
                      >
                        <div
                          style={{
                            fontSize: "12px",
                            fontWeight: 600,
                            textAlign: "center",
                            paddingBottom: "10px",
                          }}
                        >
                          {data.account_no}
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        style={{ borderRight: "1px solid #E1E2E3" }}
                      >
                        <div
                          style={{
                            fontSize: "12px",
                            fontWeight: 600,
                            textAlign: "center",
                            paddingBottom: "10px",
                          }}
                        >
                          {data.micr}
                        </div>
                      </Grid>
                      <Grid item xs={3}>
                        <div
                          style={{
                            fontSize: "12px",
                            fontWeight: 600,
                            textAlign: "center",
                            paddingBottom: "10px",
                          }}
                        >
                          {data.ifsc}
                        </div>
                      </Grid>
                    </Grid>
                  </>
                ))}
              </div>
            </div>
            <div className={classes.displayWrapper}>
              <div className={classes.section1Left}>
                <div className={classes.header}>Nominee Details</div>
                {profile.client_nominee.map((data: any, index: any) => (
                  <div className={classes.fieldsNom}>
                    <Grid
                      container
                      spacing={1.5}
                      columns={16}
                      sx={{ marginTop: "0px" }}
                    >
                      <Grid item xs={5.5}>
                        <TextField
                          id="outlined-basic"
                          label="Full Name"
                          value={data.full_name !== "" ? data.full_name : "-"}
                          variant="outlined"
                          size="small"
                          sx={{
                            input: {
                              color: "#000000",
                              fontSize: "12px",
                              fontWeight: 700,
                            },
                          }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={5}>
                        <TextField
                          id="outlined-basic"
                          label="Date of Birth"
                          value={data.dob !== "" ? data.dob.split(" ")[0] : "-"}
                          variant="outlined"
                          size="small"
                          sx={{
                            input: {
                              color: "#000000",
                              fontSize: "12px",
                              fontWeight: 700,
                            },
                          }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={5}>
                        <TextField
                          id="outlined-basic"
                          label="Email"
                          value={data.email === "" ? "-" : data.email}
                          variant="outlined"
                          size="small"
                          sx={{
                            input: {
                              color: "#000000",
                              fontSize: "12px",
                              fontWeight: 700,
                            },
                          }}
                          fullWidth
                        />
                      </Grid>
                      {/* <Grid item xs={4}>
                  <TextField
                    id="outlined-basic"
                    label="Gender"
                    value={profile.user_data.gender}
                    variant="outlined"
                    size="small"
                    sx={{
                      input: {
                        color: "#000000",
                        fontSize: "12px",
                        fontWeight: 700,
                      },
                    }}
                    fullWidth
                  />
                </Grid> */}
                    </Grid>
                    <Grid
                      container
                      spacing={1.5}
                      columns={16}
                      sx={{ marginTop: "0px" }}
                    >
                      <Grid item xs={5.5}>
                        <TextField
                          id="outlined-basic"
                          label="Mobile No"
                          value={data.mobile === "" ? "-" : data.mobile}
                          variant="outlined"
                          size="small"
                          sx={{
                            input: {
                              color: "#000000",
                              fontSize: "12px",
                              fontWeight: 700,
                            },
                          }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={5}>
                        <TextField
                          id="outlined-basic"
                          label="Percentage Share"
                          value={
                            data.percentage_share === 0
                              ? 100
                              : data.percentage_share
                          }
                          // onChange={(e) => {
                          //   const updatedPercentage = e.target.value;
                          //   setProfiledata((prevState: any) => {
                          //     const updatedNominees = [...prevState.client_nominee];
                          //     updatedNominees[index] = {
                          //       ...updatedNominees[index],
                          //       percentage_share: updatedPercentage,
                          //     };
                          //     return {
                          //       ...prevState,
                          //       client_nominee: updatedNominees,
                          //     };
                          //   });
                          // }}
                          variant="outlined"
                          size="small"
                          sx={{
                            input: {
                              color: "#000000",
                              fontSize: "12px",
                              fontWeight: 700,
                            },
                          }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={5}>
                        <TextField
                          id="outlined-basic"
                          label="Relationship"
                          value={
                            data.relationship === "" ? "-" : data.relationship
                          }
                          variant="outlined"
                          size="small"
                          sx={{
                            input: {
                              color: "#000000",
                              fontSize: "12px",
                              fontWeight: 700,
                            },
                          }}
                          fullWidth
                        />
                      </Grid>
                      {/* <Grid item xs={4}>
                  <TextField
                    id="outlined-basic"
                    label="Date of Birth"
                    value={profile.user_data.dob}
                    variant="outlined"
                    size="small"
                    sx={{
                      input: {
                        color: "#000000",
                        fontSize: "12px",
                        fontWeight: 700,
                      },
                    }}
                    fullWidth
                  />
                </Grid> */}
                    </Grid>
                  </div>
                ))}
              </div>
              <div className={classes.section1Right}>
                <div className={classes.header}>Other</div>
                <div className={classes.fields}>
                  <Grid container spacing={1} columns={16}>
                    <Grid item xs={16}>
                      <TextField
                        id="outlined-basic"
                        label="Introducer"
                        value={
                          profile.referral.introducer.remisier_name !== ""
                            ? profile.referral.introducer.remisier_name
                            : "-"
                        }
                        variant="outlined"
                        size="small"
                        sx={{
                          input: {
                            color: "#000000",
                            fontSize: "12px",
                            fontWeight: 700,
                          },
                        }}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} columns={16} sx={{ mt: "5px" }}>
                    <Grid item xs={8}>
                      {edit ? (
                        <Autocomplete
                          disablePortal
                          options={DDPIOPT}
                          disableClearable
                          value={profile.poa.status === "F" ? "False" : "True"}
                          onChange={(event: any, newValue: string | null) => {
                            if (edit) {
                              setProfiledata((prevState: any) => ({
                                ...prevState,
                                poa: {
                                  ...prevState.poa,
                                  status: newValue === "True" ? "Y" : "F",
                                },
                              }));
                            }
                          }}
                          size="small"
                          sx={{
                            input: {
                              color: "#000000",
                              fontSize: "12px",
                              fontWeight: 700,
                            },
                          }}
                          renderInput={(params: any) => (
                            <TextField {...params} label="POA Status" />
                          )}
                        />
                      ) : (
                        <TextField
                          id="outlined-basic"
                          label="POA status"
                          value={profile.poa.status === "Y" ? "True" : "False"}
                          variant="outlined"
                          size="small"
                          sx={{
                            input: {
                              color: "#000000",
                              fontSize: "12px",
                              fontWeight: 700,
                            },
                          }}
                          fullWidth
                        />
                      )}
                    </Grid>
                    <Grid item xs={8}>
                      {edit ? (
                        <Autocomplete
                          disablePortal
                          options={DDPIOPT}
                          disableClearable
                          value={profile.ddpi.status === "N" ? "False" : "True"}
                          onChange={(event: any, newValue: string | null) => {
                            if (edit) {
                              setProfiledata((prevState: any) => ({
                                ...prevState,
                                ddpi: {
                                  ...prevState.ddpi,
                                  status: newValue === "True" ? "Y" : "N",
                                },
                              }));
                            }
                          }}
                          size="small"
                          sx={{
                            input: {
                              color: "#000000",
                              fontSize: "12px",
                              fontWeight: 700,
                            },
                          }}
                          renderInput={(params: any) => (
                            <TextField {...params} label="DDPI Status" />
                          )}
                        />
                      ) : (
                        <TextField
                          id="outlined-basic"
                          label="DDPI Status"
                          value={profile.ddpi.status === "N" ? "False" : "True"}
                          variant="outlined"
                          size="small"
                          sx={{
                            input: {
                              color: "#000000",
                              fontSize: "12px",
                              fontWeight: 700,
                            },
                          }}
                          fullWidth
                        />
                      )}
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {openAddBankModal && (
        <AddBankDetailsModal
          open={openAddBankModal}
          handleClose={handleCloseOpenAddBankModal}
          handleSubmitData={handleSubmitAddedBankDetails}
          setMicrNumber={setmicrnumber}
          data={undefined}
        />
      )}
      {openAddNomineeModal && (
        <AddNominee
          open={openAddNomineeModal}
          handleClose={handleCloseNomineeModal}
          handleSubmitData={handleSubmitNomineeDetails}
          data={undefined}
          nomineeId={nomineeId}
        />
      )}
    </div>
  );
}

{
  /* <div className={classes.fieldsBank}>
            {!profile.bank_data && (
              <p style={{ textAlign: "center", width: "100%" }}>
                No Data to display
              </p>
            )}
            {profile.bank_data &&
              profile.bank_data.map((data: any, i: any) => (
                <>
                  <div
                    className={classes.card}
                    style={{
                      borderRight: i <= 1 ? "1px solid #e1e2e3" : "none",
                    }}
                  >
                    <Grid container spacing={1} columns={16}>
                      <Grid item xs={0.5}>
                        <div className={classes.textfieldTable}>{i + 1}.</div>
                      </Grid>
                      <Grid item xs={15}>
                        <div className={classes.bankName}>{data.bank_name}</div>
                        <div className={classes.branchName}>
                          <span className={classes.bankTitle}>
                            {" "}
                            Branch Name
                          </span>
                          &nbsp; :&nbsp;M G ROAD BRANCH BANGALORE
                        </div>
                        <div className={classes.branchName}>
                          <span className={classes.bankTitle}>
                            {" "}
                            Account Number
                          </span>
                          &nbsp; :&nbsp;{data.bank_acc_number}
                        </div>
                        <div className={classes.branchName}>
                          <span className={classes.bankTitle}> MICR code</span>
                          &nbsp; :&nbsp;{data.micr}
                        </div>
                        <div className={classes.branchName}>
                          <span className={classes.bankTitle}> IFSC code</span>
                          &nbsp; :&nbsp;{data.bank_ifsc}
                        </div>
                        <div className={classes.bankCode}>
                          <div>
                            {" "}
                            <span className={classes.bankTitle}>
                              MICR code{" "}
                            </span>
                            &nbsp; :&nbsp;{data.micr}
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                  {i === profile.bank_data.length - 1 && i <= 1 && (
                    <div
                      className={classes.card}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                      onClick={handleOpenAddBankModal}
                    >
                      <img src={AddIcon} />
                    </div>
                  )}
                  {openAddBankModal && (
                    <AddBankDetailsModal
                      open={openAddBankModal}
                      handleClose={handleCloseOpenAddBankModal}
                      handleSubmitData={handleSubmitAddedBankDetails}
                      data={undefined}
                    />
                  )}
                </>
              ))}
          </div> */
}
