import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Formik } from "formik";
import {
  IApproveClientApplication,
  IClientData,
  IDeleteClient,
  IDeleteUser,
  IGeoJith,
  IKRARegister,
  IRejectClientApplication,
} from "../../Utils/dtos";
import {
  Stack,
  Fade,
  Dialog,
  DialogTitle,
  Box,
  IconButton,
  Modal,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import Button from "@mui/joy/Button";
import { js2xml } from "xml-js";
import {
  ApproveClientApplicationAPICall,
  DeletClientAPICall,
  DeletUserAPICall,
  GetClientDetailsAPICall,
  GetStagesAPICall,
  RejectClientApplicationAPICall,
  geojithAPICall,
  kraRegisterAPICall,
} from "../../Utils/APICalls";
import Styles from "./ClientDetailspopup.module.scss";
import Tabs from "../Tabs/Tabs";
import Base64ToImage from "../Base64ToImage/Base64ToImage";
import Reject from "./RejectComponent/Reject";
import ErrorSnackBar from "../SnackBar/ErrorSnackBar";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteClient from "./DeleteClient/DeleteClient";
import SucessSnackBar from "../SnackBar/SuccessSnackBar";
import CheckedBox from "../../assets/Images/CheckedBox.png";
import EmptyCheckBox from "../../assets/Images/EmptyCheckBox.png";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import { unset } from "lodash";
import GeojithResponseModalpopup from "./GeojithResponseModal/GeojithResponseModalPopUp";
import VerticalTabs from "../Tabs/TabsVeritical";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "96%",
  height: "95%",
  bgcolor: "background.paper",
  borderRadius: "20px",
  boxShadow: 24,
  p: 2,
};

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

interface Props {
  open: boolean;
  handleClose: any;
  data: string;
  rejectedReason?: any;
  deletedReason?: any;
  selectedTabNew?: string;
  stageName?: any;
}

export default function DematPopup({
  open,
  handleClose,
  data,
  rejectedReason,
  selectedTabNew,
  deletedReason,
  stageName,
}: Props) {
  // const [open, setOpen] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [clientData, setClientData] = useState<any>();
  const [clientBankData, setClientBankData] = useState<any>({
    BankName: "",
    IFSCCode: "",
    Micr: "",
    AcNo: "",
    AcType: "",
    NameAsInBank: "",
    UPIId: "",
    PennydropStatus: "",
    PennydropResponse: "",
  });
  const [KRAData, setKRAData] = useState<any>({
    KraName: "",
    KraDob: "",
    KraMob: "",
    KraAddr: "",
    KraEmail: "",
    KraFatherName: "",
    KraStatus: "",
    KraGender: "",
  });
  const [AadhaarData, setAadhaarData] = useState<any>({
    AadhaarNum: "",
    ClientAadharName: "",
    DobOnAadhaar: "",
    AadhaarAddress: "",
    AadhaarGender: "",
  });
  const [tradingPreference, setTradingPrefernce] = useState<any>([
    {
      label: "equity",
      opted: true,
    },
    {
      label: "currency",
      opted: true,
    },
    {
      label: "commodity",
      opted: true,
    },
    {
      label: "mutual_funds",
      opted: true,
    },
    {
      label: "futures_and_options",
      opted: false,
    },
  ]);
  const [selectedTab, setselectedTab] = useState<string>("Pan");
  const [successMessage, setSuccessMessage] = useState("");

  const [tabLabels, setTabLabels] = useState([
    "Pan",
    "Aadhaar",
    "Photo",
    "Signature",
    "IncomeProof",
    "BankProof",
    "Nominee Proof",
    "Esign",
  ]);
  const [docType, setDocType] = useState("pancard");

  const [tab, setTab] = useState(1);
  function activeTab(tabNumber: any) {
    setTab(tabNumber);
  }

  const name = clientData && clientData.ClientDetails.ClientName;
  const AadhaarXMLData = clientData && clientData.Aadhaar.AadhaarXml;

  const removeStages = (stages: any) => {
    const stagesToRemove = ["Terms and Conditions", "Segment Details"];

    const filteredStages = stages.filter(
      (stage: any) => !stagesToRemove.includes(stage)
    );
    return filteredStages;
  };

  useEffect(() => {
    getDetails();
  }, []);

  useEffect(() => {
    sessionStorage.removeItem(`${data}_aadhaar`);
    sessionStorage.removeItem(`${data}_webcam_client_photo`);
    sessionStorage.removeItem(`${data}_signature`);
    sessionStorage.removeItem(`${data}_pancard`);
    sessionStorage.removeItem(`${data}_income_proof`);
    sessionStorage.removeItem(`${data}_e_sign_pdf`);
    sessionStorage.removeItem(`${data}_kra_upload`);
    sessionStorage.removeItem(`${data}_nominee_proof1`);
    sessionStorage.removeItem(`${data}_nominee_proof2`);
    sessionStorage.removeItem(`${data}_nominee_proof3`);
    sessionStorage.removeItem(`${data}_cmr`);
  }, [handleClose]);

  // console.log(data);
  const isAdmin = sessionStorage.getItem("isAdmin");
  const getDetails = () => {
    const obj: any = {
      user_id: data,
    };
    GetClientDetailsAPICall(obj)
      .then((response: any) => {
        console.log("GetClientDetailsAPICall", response.data.data);
        if (response.data.data.BooleanValues.is_aadhaar_fetched === false) {
          if (
            response.data.data.BooleanValues.is_approved === true &&
            response.data.data.BooleanValues.is_aadhaar_fetched === true
          ) {
            setTabLabels([
              "Pan",
              "Photo",
              "Signature",
              "IncomeProof",
              "BankProof",
              "Nominee Proof",
              "Esign",
              "KRA",
              "CMR",
            ]);
            // setselectedTab("KRA");
            // setDocType("e_sign_extracted_pdf");
          } else {
            setTabLabels([
              "Pan",
              "Photo",
              "Signature",
              "IncomeProof",
              "BankProof",
              "Nominee Proof",
              "Esign",
              "CMR",
            ]);
          }
        } else {
          if (
            response.data.data.BooleanValues.is_approved === true &&
            response.data.data.BooleanValues.is_aadhaar_fetched === true
          ) {
            setTabLabels([
              "Pan",
              "Aadhaar",
              "Photo",
              "Signature",
              "IncomeProof",
              "BankProof",
              "Nominee Proof",
              "Esign",
              "KRA",
              "CMR",
            ]);
          } else {
            setTabLabels([
              "Pan",
              "Aadhaar",
              "Photo",
              "Signature",
              "IncomeProof",
              "BankProof",
              "Nominee Proof",
              "Esign",
              "CMR",
            ]);
          }
        }
        setClientData(response.data.data);
        if (response.data.data.BankDetails) {
          setClientBankData({
            BankName: response.data.data.BankDetails.BankName,
            IFSCCode: response.data.data.BankDetails.Ifsc,
            Micr: response.data.data.BankDetails.Micr,
            AcNo: response.data.data.BankDetails.BankAccNum,
            AcType: response.data.data.BankDetails.AccountType,
            NameAsInBank: response.data.data.BankDetails.CustomerNameInBank,
            UPIId: response.data.data.BankDetails.UPIId,
            PennydropStatus: "",
            PennydropResponse: "",
          });
        }

        if (response.data.data.KraDetails) {
          setKRAData({
            KraName: response.data.data.KraDetails.KraName,
            KraDob: response.data.data.KraDetails.KraDob,
            KraMob: response.data.data.KraDetails.KraMobNo,
            KraAddr: response.data.data.KraDetails.Address,
            KraEmail: response.data.data.KraDetails.KraEmail,
            KraFatherName: response.data.data.KraDetails.FatherName,
            KraStatus: response.data.data.KraDetails.KraJson.status,
            KraGender: response.data.data.KraDetails.Gender,
          });
        }
        if (response.data.data.Aadhaar) {
          setAadhaarData({
            AadhaarNum: response.data.data.Aadhaar.AadhaarNumber,
            ClientAadharName: response.data.data.Aadhaar.Name,
            DobOnAadhaar: response.data.data.Aadhaar.AadhaarJson.dateOfBirth,
            AadhaarAddress: `${
              response.data.data.Aadhaar.AadhaarJson.address.careOf
            },${" "} ${
              response.data.data.Aadhaar.AadhaarJson.address.house
            }${" "} ${
              response.data.data.Aadhaar.AadhaarJson.address.street
            }${" "} ${
              response.data.data.Aadhaar.AadhaarJson.address.locality
            }${" "} ${
              response.data.data.Aadhaar.AadhaarJson.address.locality
            }${" "} ${
              response.data.data.Aadhaar.AadhaarJson.address.district
            }${" "}${" "} ${
              response.data.data.Aadhaar.AadhaarJson.address.state
            } ${" "} ${
              response.data.data.Aadhaar.AadhaarJson.address.country
            } ${" "} ${response.data.data.Aadhaar.AadhaarJson.address.pin} `,
            AadhaarGender: response.data.data.Aadhaar.AadhaarJson.gender,
          });

          if (response.data.data.TradingPreference) {
            setTradingPrefernce(response.data.data.TradingPreference);
          }
        }
      })
      .catch((error: any) => {});
  };





  const handleChangeTab = (tab: any) => {
    //  console.log(tab);
    setselectedTab(tab);
    if (tab === "Aadhaar") {
      setDocType("aadhaar");
    }
    if (tab === "Pan") {
      setDocType("pancard");
    }
    if (tab === "Photo") {
      setDocType("ipv");
    }
    if (tab === "Signature") {
      setDocType("signature");
    }
    if (tab === "IncomeProof") {
      setDocType("income_proof");
    }
    if (tab === "Esign") {
      setDocType("e_sign_pdf");
    }
    if (tab === "KRA") {
      setDocType("e_sign_extracted_pdf");
    }
    if (tab === "BankProof") {
      setDocType("bank_proof");
    }
    if (tab === "Nominee Proof") {
      setDocType("nominee_proof1");
    }
    if (tab === "Nominee Proof2") {
      setDocType("nominee_proof2");
    }
    if (tab === "Nominee Proof3") {
      setDocType("nominee_proof3");
    }
    if (tab === "CMR") {
      setDocType("cmr");
    }
  };





  const formatDate = (dateString: any) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    const hour = date.getHours() % 12 || 12;
    const minute = date.getMinutes().toString().padStart(2, "0");
    const meridian = date.getHours() >= 12 ? "PM" : "AM";

    return `${day}-${month}-${year} ${hour}:${minute} ${meridian}`;
  };

  const handleDownloadXml = () => {
    const blob = new Blob([AadhaarXMLData], { type: "application/xml" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = `${name}.xml`;
    a.click();

    URL.revokeObjectURL(url);
  };

  const formatArrangeDOB = (inputDate: any) => {
    const parts = inputDate.split("-");
    const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
    return formattedDate;
  };

  const close = (e: any) => {
    if (e.keyCode === 27) {
      handleClose();
    }
  };

  console.log("clientData", clientData?.NomineeDetails);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        closeAfterTransition
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
        onClose={close}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className={Styles.topContainerTitle}>
              <p
                style={{
                  margin: "0px 10px",
                  fontSize: "14px",
                  fontWeight: 500,
                }}
              >
                <IconButton
                  onClick={handleClose}
                  size="small"
                  style={{
                    background: "#8B93FF",
                    color: "white",
                    borderRadius: 4,
                  }}
                >
                  <KeyboardBackspaceIcon
                    style={{
                      fontSize: "18px",
                      fontWeight: 600,
                    }}
                  />
                </IconButton>
                <span style={{ marginLeft: "10px" }}>Client Details</span>
              </p>
              <div className={Styles.title}>
                {clientData && clientData.PanDetails.FullName}
              </div>
            </div>

            <div className={Styles.wrapper}>
              <div className={Styles.leftContainer}>
                <p className={Styles.clientInfo}>Client Info</p>
                {rejectedReason &&
                  (selectedTabNew === "rejected" ||
                    selectedTabNew === "all") && (
                    <>
                      <div style={{ color: "red" }}>
                        <span style={{ fontWeight: 700 }}>
                          Rejected Reason:
                        </span>{" "}
                        <span style={{ fontSize: "14px" }}>
                          {" "}
                          {rejectedReason}
                        </span>
                      </div>
                      <Divider sx={{ borderColor: "grey", margin: "10px 0" }} />
                    </>
                  )}
                {deletedReason && selectedTabNew === "deleted" && (
                  <>
                    <div style={{ color: "red" }}>
                      <span style={{ fontWeight: 700 }}>Deleted Reason:</span>{" "}
                      <span style={{ fontSize: "14px" }}> {deletedReason}</span>
                    </div>
                    <Divider sx={{ borderColor: "grey", margin: "10px 0" }} />
                  </>
                )}

                <div className={Styles.contentdiv}>
                  <p className={Styles.title}>
                    Mobile :{" "}
                    <span className={Styles.content}>
                      {clientData && clientData.ClientDetails.MobileNumber}
                    </span>
                  </p>
                  <p className={Styles.title}>
                    Email :{" "}
                    <span className={Styles.content}>
                      {clientData && clientData.ClientDetails.Email}
                    </span>
                  </p>
                  {/* <p className={Styles.title}>
                    Type : <span className={Styles.content}>Indian</span>
                  </p> */}
                </div>
                <Divider sx={{ borderColor: "grey", margin: "10px 0" }} />
                <div className={Styles.contentdiv}>
                  <p className={Styles.title}>
                    PAN NAME :{" "}
                    <span className={Styles.content}>
                      {clientData && clientData.PanDetails.FullName}
                    </span>
                  </p>
                  <p className={Styles.title}>
                    CLIENT ID :{" "}
                    <span className={Styles.content}>
                      {clientData && clientData.ClientDetails.ClientCode}
                    </span>
                  </p>
                </div>
                <Divider sx={{ borderColor: "grey", margin: "10px 0" }} />
                <div className={Styles.contentdiv}>
                  <p className={Styles.title}>
                    Source :{" "}
                    <span className={Styles.content}>
                      {clientData && clientData.ClientDetails.Source}
                    </span>
                  </p>
                  <p className={Styles.title}>
                    Source ID :{" "}
                    <span className={Styles.content}>
                      {clientData && clientData.ClientDetails.SourceId}
                    </span>
                  </p>
                  <p className={Styles.title}>
                    SettlementPreference :{" "}
                    <span className={Styles.content}>
                      {clientData &&
                      clientData.TermsAndConditions.settlement_preference
                        ? clientData.TermsAndConditions.settlement_preference?.toUpperCase()
                        : ""}
                    </span>
                  </p>
                  {/* <p className={Styles.title}>
                    Type : <span className={Styles.content}>Indian</span>
                  </p> */}
                </div>
                <Divider sx={{ borderColor: "grey", margin: "10px 0" }} />
                <div className={Styles.contentdiv}>
                  <p className={Styles.title}>
                    PAN :{" "}
                    <span className={Styles.content}>
                      {clientData && clientData.ClientDetails.PANNumber}
                    </span>
                  </p>
                  <p className={Styles.title}>
                    DOB :{" "}
                    <span className={Styles.content}>
                      {clientData && clientData.ClientDetails.DateOfBirth}
                    </span>
                  </p>
                </div>
                <Divider sx={{ borderColor: "grey", margin: "10px 0" }} />
                <div className={Styles.contentdiv}>
                  <p className={Styles.title}>
                    Mobile Verified At :{" "}
                    <span className={Styles.content}>
                      {clientData
                        ? clientData.EkycLogs.mobile_verified_ts === ""
                          ? ""
                          : clientData.EkycLogs.mobile_verified_ts
                          ? formatDate(
                              clientData.EkycLogs.mobile_verified_ts.slice(
                                0,
                                16
                              )
                            )
                          : "-"
                        : ""}
                    </span>
                  </p>
                  <p className={Styles.title}>
                    Email Verified At :{" "}
                    <span className={Styles.content}>
                      {clientData
                        ? clientData.EkycLogs.email_verified_ts === ""
                          ? ""
                          : clientData.EkycLogs.email_verified_ts
                          ? formatDate(
                              clientData.EkycLogs.email_verified_ts.slice(0, 16)
                            )
                          : "-"
                        : ""}
                    </span>
                  </p>
                </div>
                <Divider sx={{ borderColor: "grey", margin: "10px 0" }} />

                <div className={Styles.contentdiv}>
                  <p className={Styles.title}>
                    KRA Upload At :{" "}
                    <span className={Styles.content}>
                      {clientData &&
                        clientData.ClientDetails.KrauploadedTS?.slice(0, 16)}
                    </span>
                  </p>
                </div>
                <Divider sx={{ borderColor: "grey", margin: "10px 0" }} />

                <div className={Styles.contentdiv}>
                  <p className={Styles.title}>
                    Demat No :{" "}
                    <span className={Styles.content}>
                      {clientData && clientData.ClientDetails?.DematNumber}
                    </span>
                  </p>
                  <p className={Styles.title}>
                    Proof Source :{" "}
                    <span className={Styles.content}>
                      {clientData && clientData.BooleanValues.is_kra_fetched
                        ? "KRA"
                        : "Digilocker"}
                    </span>
                  </p>
                  <p className={Styles.title}>
                    Gender :{" "}
                    <span className={Styles.content}>
                      {clientData && clientData.PersonalDetails.Gender}
                    </span>
                  </p>
                </div>
                <Divider sx={{ borderColor: "grey", margin: "10px 0" }} />
                <div className={Styles.contentdiv}>
                  <div>
                    <p
                      className={Styles.title}
                      style={{
                        // textDecoration: "underline",
                        background: "#d9e4fd",
                        // width: "max-content",
                        padding: "3px 20px",
                        borderRadius: "5px",
                        textAlign: "center",
                      }}
                    >
                      Permanent Address
                    </p>
                    <p className={Styles.title}>
                      Address :{" "}
                      <span className={Styles.content}>
                        {clientData &&
                          clientData.PersonalDetails.AddressDetails.Address}
                      </span>
                    </p>
                    <p className={Styles.title}>
                      PinCode :{" "}
                      <span className={Styles.content}>
                        {clientData &&
                          clientData.PersonalDetails.AddressDetails.Pincode}
                      </span>
                    </p>
                  </div>
                </div>
                <Divider sx={{ borderColor: "grey", margin: "10px 0" }} />
                <div className={Styles.contentdiv}>
                  <div style={{ width: "100%" }}>
                    <p
                      className={Styles.title}
                      style={{
                        // textDecoration: "underline",
                        background: "#d9e4fd",
                        width: "100%",
                        padding: "3px 20px",
                        borderRadius: "5px",
                        textAlign: "center",
                      }}
                    >
                      Trading Prefernce
                    </p>
                    <div>
                      <p className={Styles.title}>
                        Equity :{" "}
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <span className={Styles.content}>
                          {/* {tradingPreference.equity} */}
                          <img
                            src={
                              tradingPreference &&
                              tradingPreference.filter(
                                (a: any) => a.label === "equity"
                              )[0]?.opted === true
                                ? CheckedBox
                                : EmptyCheckBox
                            }
                            style={{ width: "20px" }}
                          />
                        </span>
                      </p>

                      <div>
                        <p className={Styles.title}>
                          Futures & Options :{" "}
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <span className={Styles.content}>
                            {/* {tradingPreference.futures_and_options} */}
                            <img
                              src={
                                tradingPreference &&
                                tradingPreference.filter(
                                  (a: any) => a.label === "futures_and_options"
                                )[0]?.opted === true
                                  ? CheckedBox
                                  : EmptyCheckBox
                              }
                              style={{ width: "20px" }}
                            />
                          </span>
                        </p>
                      </div>
                      <div>
                        <p className={Styles.title}>
                          Currency :{" "}
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <span className={Styles.content}>
                            {/* {tradingPreference.mutual_funds} */}
                            <img
                              src={
                                tradingPreference &&
                                tradingPreference.filter(
                                  (a: any) => a.label === "currency"
                                )[0]?.opted === true
                                  ? CheckedBox
                                  : EmptyCheckBox
                              }
                              style={{ width: "20px" }}
                            />
                          </span>
                        </p>
                      </div>
                      <div>
                        <p className={Styles.title}>
                          Commodity :{" "}
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <span className={Styles.content}>
                            {/* {tradingPreference.commodity} */}
                            <img
                              src={
                                tradingPreference &&
                                tradingPreference.filter(
                                  (a: any) => a.label === "commodity"
                                )[0]?.opted === true
                                  ? CheckedBox
                                  : EmptyCheckBox
                              }
                              style={{ width: "20px" }}
                            />
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <Divider sx={{ borderColor: "grey", margin: "10px 0" }} />
                <p
                  className={Styles.title}
                  style={{
                    // textDecoration: "underline",
                    background: "#d9e4fd",
                    // width: "max-content",
                    padding: "3px 20px",
                    borderRadius: "5px",
                    textAlign: "center",
                  }}
                >
                  Basic Info
                </p>

                <div className={Styles.contentdiv}>
                  <div style={{ width: "360px" }}>
                    <div>
                      <p className={Styles.title}>
                        Application No :{" "}
                        <span className={Styles.content}>
                          {clientData && clientData.ClientDetails.ApplicationNo}
                        </span>
                      </p>

                      <div>
                        <p className={Styles.title}>
                          Mobile No :{" "}
                          <span className={Styles.content}>
                            {clientData &&
                              clientData.ClientDetails.MobileNumber}
                          </span>
                        </p>
                      </div>
                      <div>
                        <p className={Styles.title}>
                          PAN Number :{" "}
                          <span className={Styles.content}>
                            {clientData && clientData.ClientDetails.PANNumber}
                          </span>
                        </p>
                      </div>
                      <div>
                        <p className={Styles.title}>
                          DOB :{" "}
                          <span className={Styles.content}>
                            {clientData && clientData.ClientDetails.DateOfBirth}
                          </span>
                        </p>
                      </div>
                      <div>
                        <p className={Styles.title}>
                          Maritalstatus :{" "}
                          <span className={Styles.content}>
                            {clientData &&
                              clientData.PersonalDetails.MaritalStatus}
                          </span>
                        </p>
                      </div>
                      <div>
                        <p className={Styles.title}>
                          Father/SpouseName :{" "}
                          <span className={Styles.content}>
                            {clientData &&
                              clientData.PersonalDetails.FatherName}
                          </span>
                        </p>
                      </div>
                      <div>
                        <p className={Styles.title}>
                          Location :{" "}
                          <span className={Styles.content}>
                            {clientData && clientData.Location.Latitude},
                            {clientData && clientData.Location.Longitude}
                          </span>
                        </p>
                      </div>
                      <div>
                        <p className={Styles.title}>
                          WebImage TS :{" "}
                          <span className={Styles.content}>
                            {clientData &&
                            clientData.EkycLogs.ipv_stage_completed_ts
                              ? formatDate(
                                  clientData.EkycLogs.ipv_stage_completed_ts.slice(
                                    0,
                                    16
                                  )
                                )
                              : "-"}
                          </span>
                        </p>
                      </div>
                      <div>
                        <p className={Styles.title}>
                          ResidentialStatus :{" "}
                          <span className={Styles.content}>
                            {clientData &&
                              clientData.PersonalDetails.AddressDetails
                                ?.Country}
                          </span>
                        </p>
                      </div>
                      <div>
                        <p className={Styles.title}>
                          Politically Exposed :{" "}
                          <span className={Styles.content}>
                            {clientData && clientData.PoliticalExposed
                              ? "Yes"
                              : "No"}
                          </span>
                        </p>
                      </div>
                      <div>
                        <p className={Styles.title}>
                          Trading Experience :{" "}
                          <span className={Styles.content}>
                            {clientData && clientData.TradingExperience}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div style={{ marginTop: "0px" }}>
                    <p className={Styles.title} style={{ marginLeft: "20px" }}>
                      Client Name :{" "}
                      <span className={Styles.content}>
                        {clientData && clientData.ClientDetails.ClientName}
                      </span>
                    </p>
                    <p className={Styles.title} style={{ marginLeft: "20px" }}>
                      Email :{" "}
                      <span className={Styles.content}>
                        {clientData && clientData.ClientDetails.Email}
                      </span>
                    </p>
                    {/* <p className={Styles.title} style={{ marginLeft: "20px" }}>
                      Created Time :{" "}
                      <span className={Styles.content}>12/07/2023</span>
                    </p> */}
                    <p className={Styles.title} style={{ marginLeft: "20px" }}>
                      Gender :{" "}
                      <span className={Styles.content}>
                        {clientData && clientData.PersonalDetails.Gender}
                      </span>
                    </p>
                    <p className={Styles.title} style={{ marginLeft: "20px" }}>
                      MothersName :{" "}
                      <span className={Styles.content}>
                        {clientData && clientData.PersonalDetails.MotherName}
                      </span>
                    </p>
                    <p className={Styles.title} style={{ marginLeft: "20px" }}>
                      Occupation :{" "}
                      <span className={Styles.content}>
                        {clientData &&
                          clientData.PersonalDetails.Occupation.OccupationValue}
                      </span>
                    </p>
                    <p className={Styles.title} style={{ marginLeft: "20px" }}>
                      Occupation Others :{" "}
                      <span className={Styles.content}>
                        {clientData &&
                          clientData.PersonalDetails.Occupation.OthersValue}
                      </span>
                    </p>
                    <p className={Styles.title} style={{ marginLeft: "20px" }}>
                      AnnualIncome :{" "}
                      <span className={Styles.content}>
                        {clientData && clientData.PersonalDetails.AnnualIncome}
                      </span>
                    </p>
                  </div>
                </div>

                <Divider sx={{ borderColor: "grey", margin: "10px 0" }} />

                <div className={Styles.contentdiv}>
                  <div style={{ width: "100%" }}>
                    <p
                      className={Styles.title}
                      style={{
                        // textDecoration: "underline",
                        background: "#d9e4fd",

                        padding: "3px 20px",
                        borderRadius: "5px",
                        textAlign: "center",
                      }}
                    >
                      Bank Details
                    </p>
                    <div>
                      <p className={Styles.title}>
                        BankName :{" "}
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <span className={Styles.content}>
                          {clientBankData.BankName}
                        </span>
                      </p>

                      <div>
                        <p className={Styles.title}>
                          IFSC Code :{" "}
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <span className={Styles.content}>
                            {clientBankData.IFSCCode}
                          </span>
                        </p>
                      </div>
                      <div>
                        <p className={Styles.title}>
                          Micr Code :{" "}
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <span className={Styles.content}>
                            {clientBankData.Micr}
                          </span>
                        </p>
                      </div>
                      <div>
                        <p className={Styles.title}>
                          AcNo :{" "}
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <span className={Styles.content}>
                            {clientBankData.AcNo}
                          </span>
                        </p>
                      </div>
                      <div>
                        <p className={Styles.title}>
                          AcType :{" "}
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <span className={Styles.content}>
                            {clientBankData.AcType}
                          </span>
                        </p>
                      </div>
                      <div>
                        <p className={Styles.title}>
                          Name As In Bank :{" "}
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <span className={Styles.content}>
                            {clientBankData.NameAsInBank}
                          </span>
                        </p>
                      </div>
                      <div>
                        <p className={Styles.title}>
                          UPI Id :{" "}
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <span className={Styles.content}>
                            {clientBankData.UPIId}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <Divider sx={{ borderColor: "grey", margin: "10px 0" }} />
                {AadhaarData.AadhaarNum !== "" && (
                  <>
                    {
                      clientData && (
                        // clientData.BooleanValues.is_completed === true && (
                        <p className={Styles.title}>
                          Download Aadhaar XML :{" "}
                          <Tooltip title="Aadhaar XML Download">
                            <IconButton onClick={handleDownloadXml}>
                              <ArrowCircleDownIcon />
                            </IconButton>
                          </Tooltip>
                        </p>
                      )
                      // )
                    }

                    <div className={Styles.contentdiv}>
                      <div>
                        <p
                          className={Styles.title}
                          style={{
                            // textDecoration: "underline",
                            background: "#d9e4fd",
                            // width: "max-content",
                            padding: "3px 20px",
                            borderRadius: "5px",
                            textAlign: "center",
                          }}
                        >
                          Aadhaar Details
                        </p>
                        <div>
                          <p className={Styles.title}>
                            Aadhaar Number : &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <span className={Styles.content}>
                              {AadhaarData.AadhaarNum}
                            </span>
                          </p>

                          <div>
                            <p className={Styles.title}>
                              Aadhaar DOB :{" "}
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <span className={Styles.content}>
                                {AadhaarData.DobOnAadhaar &&
                                  formatArrangeDOB(AadhaarData.DobOnAadhaar)}
                              </span>
                            </p>
                          </div>
                          <div>
                            <p className={Styles.title}>
                              Aadhaar Name :{" "}
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <span className={Styles.content}>
                                {AadhaarData.ClientAadharName}
                              </span>
                            </p>
                          </div>
                          <div>
                            <p className={Styles.title}>
                              Aadhaar Gender :{" "}
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <span className={Styles.content}>
                                {AadhaarData.AadhaarGender}
                              </span>
                            </p>
                          </div>
                          <div>
                            <p className={Styles.title}>
                              Aadhaar Address :{" "}
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <span className={Styles.content}>
                                {AadhaarData.AadhaarAddress}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <Divider sx={{ borderColor: "grey", margin: "10px 0" }} />
                  </>
                )}
                {KRAData.KraName !== "" && (
                  <>
                    <div className={Styles.contentdiv}>
                      <div>
                        <p
                          className={Styles.title}
                          style={{
                            // textDecoration: "underline",
                            background: "#d9e4fd",
                            // width: "max-content",
                            padding: "3px 20px",
                            borderRadius: "5px",
                            textAlign: "center",
                          }}
                        >
                          KRA Details
                        </p>
                        <div>
                          <p className={Styles.title}>
                            KraName :{" "}
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <span className={Styles.content}>
                              {KRAData.KraName}
                            </span>
                          </p>

                          <div>
                            <p className={Styles.title}>
                              Kra DOB :{" "}
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <span className={Styles.content}>
                                {KRAData.KraDob}
                              </span>
                            </p>
                          </div>
                          <div>
                            <p className={Styles.title}>
                              Kra FatherName :{" "}
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <span className={Styles.content}>
                                {KRAData.KraFatherName}
                              </span>
                            </p>
                          </div>
                          <div>
                            <p className={Styles.title}>
                              Kra Gender :{" "}
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <span className={Styles.content}>
                                {KRAData.KraGender}
                              </span>
                            </p>
                          </div>
                          <div>
                            <p className={Styles.title}>
                              Kra Mobile :{" "}
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <span className={Styles.content}>
                                {KRAData.KraMob}
                              </span>
                            </p>
                          </div>
                          <div>
                            <p className={Styles.title}>
                              Kra Email :{" "}
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <span className={Styles.content}>
                                {KRAData.KraEmail?.toLowerCase()}
                              </span>
                            </p>
                          </div>
                          <div>
                            <p className={Styles.title}>
                              Kra Status :{" "}
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <span className={Styles.content}>
                                {KRAData.KraStatus}
                              </span>
                            </p>
                          </div>
                          <div>
                            <p className={Styles.title}>
                              Kra Address :{" "}
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <span className={Styles.content}>
                                {KRAData.KraAddr}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <Divider sx={{ borderColor: "grey", margin: "10px 0" }} />
                  </>
                )}

                <div className={Styles.contentdiv}>
                  <div>
                    <p className={Styles.title}>Nominee Details</p>
                    {clientData &&
                      clientData.NomineeDetails &&
                      clientData.NomineeDetails.map((data: any, index: any) => {
                        return (
                          <>
                            <p
                              className={Styles.title}
                              style={{
                                // textDecoration: "underline",
                                background: "#d9e4fd",
                                // width: "max-content",
                                padding: "3px 20px",
                                borderRadius: "5px",
                                textAlign: "center",
                              }}
                            >
                              Nominee {index + 1}
                            </p>
                            <div>
                              <p className={Styles.title}>
                                Name :{" "}
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <span className={Styles.content}>
                                  {" "}
                                  {data.FullName}
                                </span>
                              </p>

                              <div>
                                <p className={Styles.title}>
                                  RelationShip :{" "}
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  <span className={Styles.content}>
                                    {data.Relationship}
                                  </span>
                                </p>
                              </div>
                              <div>
                                <p className={Styles.title}>
                                  Relation Others : &nbsp;
                                  &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;
                                  &nbsp;&nbsp;&nbsp;
                                  <span className={Styles.content}>
                                    {data.RelationshipOthers}
                                  </span>
                                </p>
                              </div>
                              <div>
                                <p className={Styles.title}>
                                  Email :{" "}
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  &nbsp;&nbsp;
                                  <span className={Styles.content}>
                                    {data.Email}
                                  </span>
                                </p>
                              </div>
                              <div>
                                <p className={Styles.title}>
                                  Mobile :{" "}
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  <span className={Styles.content}>
                                    {data.Mobile}
                                  </span>
                                </p>
                              </div>
                              <div>
                                <p className={Styles.title}>
                                  Share Percentage :{" "}
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  <span className={Styles.content}>
                                    {data.SharePercentage}
                                  </span>
                                </p>
                              </div>
                              <div>
                                <p className={Styles.title}>
                                  DOB :{" "}
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  &nbsp;&nbsp;&nbsp;
                                  <span className={Styles.content}>
                                    {data.DOB.slice(0, 10)}
                                  </span>
                                </p>
                              </div>
                              <div>
                                <p className={Styles.title}>
                                  Nominee ID Proof :{" "}
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  <span className={Styles.content}>
                                    {data.Nominee_Id_proof}
                                  </span>
                                </p>
                              </div>
                              <div>
                                <p className={Styles.title}>
                                  Nominee ID ProofNo : &nbsp;&nbsp;&nbsp;
                                  <span className={Styles.content}>
                                    {data.Nominee_Id}
                                  </span>
                                </p>
                              </div>
                              {/* <div>
                                  <p className={Styles.title}>
                                    Nominee RelationShip :{" "}
                                    <span className={Styles.content}>
                                      {data.NomineeRelationship}
                                    </span>
                                  </p>
                                </div> */}

                              <div>
                                <p className={Styles.title}>
                                  Nominee Address :{" "}
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  <span className={Styles.content}>
                                    {data.Address}
                                  </span>
                                </p>
                              </div>
                              <div>
                                <p className={Styles.title}>
                                  Pincode :{" "}
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  <span className={Styles.content}>
                                    {data.Pincode}
                                  </span>
                                </p>
                              </div>
                              <div>
                                <p className={Styles.title}>
                                  City :{" "}
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  &nbsp;&nbsp;&nbsp;
                                  <span className={Styles.content}>
                                    {data.City}
                                  </span>
                                </p>
                              </div>
                              <div>
                                <p className={Styles.title}>
                                  State :{" "}
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                  &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;
                                  <span className={Styles.content}>
                                    {data.State}
                                  </span>
                                </p>
                              </div>
                              <div>
                                <p className={Styles.title}>
                                  Guardian FullName:{" "}
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  <span className={Styles.content}>
                                    {data.GuradianFullName}
                                  </span>
                                </p>
                              </div>
                              <div>
                                <p className={Styles.title}>
                                  Guardian DOB: &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;
                                  &nbsp;&nbsp;&nbsp;&nbsp; &nbsp; &nbsp;
                                  <span className={Styles.content}>
                                    {data.GuradianDOB}
                                  </span>
                                </p>
                              </div>
                              <div>
                                <p className={Styles.title}>
                                  Guardian Email: &nbsp;
                                  &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;
                                  &nbsp;&nbsp;&nbsp;
                                  <span className={Styles.content}>
                                    {data.GuradianEmail}
                                  </span>
                                </p>
                              </div>
                              <div>
                                <p className={Styles.title}>
                                  Guardian Address: &nbsp;
                                  &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;
                                  <span className={Styles.content}>
                                    {data.GuradianAddress}
                                  </span>
                                </p>
                              </div>
                              <div>
                                <p className={Styles.title}>
                                  Guardian City: &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  <span className={Styles.content}>
                                    {data.GuradianAddressCity}
                                  </span>
                                </p>
                              </div>
                              <div>
                                <p className={Styles.title}>
                                  Guardian PinCode: &nbsp;
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  <span className={Styles.content}>
                                    {data.GuradianAddressPinCode}
                                  </span>
                                </p>
                              </div>
                              <div>
                                <p className={Styles.title}>
                                  Guardian State: &nbsp;
                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  <span className={Styles.content}>
                                    {data.GuradianAddressState}
                                  </span>
                                </p>
                              </div>
                              <div>
                                <p className={Styles.title}>
                                  Guardian Relation : &nbsp;
                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                  <span className={Styles.content}>
                                    {data.GuradianRealtionWithNominee}
                                  </span>
                                </p>
                              </div>
                            </div>
                          </>
                        );
                      })}
                  </div>
                </div>
              </div>

              <div className={Styles.rightContainer} style={{ width: "75%" }}>
                <div style={{ width: "20%" }} className={Styles.tabsContainer}>
                  <VerticalTabs
                    labels={tabLabels}
                    onChangeTab={handleChangeTab}
                    selectedTab={selectedTab}
                  />
                </div>
                <div
                  style={{
                    width: "80%",
                    border: "1px solid #DDDCE1",
                    height: "100%",
                    borderRadius: "4px",
                    position: "relative",
                  }}
                >
                  {selectedTab.includes("Nominee") && (
                    <div className={Styles.tabsWrapper}>
                      <div className={Styles.tabsCatagory}>
                        {clientData?.NomineeDetails.length > 0 && (
                          <div
                            className={Styles.tabsItem}
                            onClick={() => {
                              activeTab(1);
                              handleChangeTab("Nominee Proof");
                            }}
                            style={{
                              backgroundColor: tab === 1 ? "#8B93FF" : "",
                              color: tab == 1 ? "white" : "",
                            }}
                          >
                            Nominee1
                          </div>
                        )}
                        {clientData?.NomineeDetails.length > 1 && (
                          <div
                            className={Styles.tabsItem}
                            onClick={() => {
                              handleChangeTab("Nominee Proof2");
                              activeTab(2);
                            }}
                            style={{
                              backgroundColor: tab === 2 ? "#8B93FF" : "",
                              color: tab == 2 ? "white" : "",
                            }}
                          >
                            Nominee2
                          </div>
                        )}
                        {clientData?.NomineeDetails.length > 2 && (
                          <div
                            className={Styles.tabsItem}
                            onClick={() => {
                              handleChangeTab("Nominee Proof3");
                              activeTab(3);
                            }}
                            style={{
                              backgroundColor: tab === 3 ? "#8B93FF" : "",
                              color: tab == 3 ? "white" : "",
                            }}
                          >
                            Nominee3
                          </div>
                        )}
                      </div>
                    </div>
                  )}

                  <Base64ToImage data={data} docType={docType} />
                </div>
              </div>
            </div>
            <div className={Styles.buttonWrapper}>
              <SucessSnackBar message={successMessage} />
              <ErrorSnackBar message={errorMessage} />
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
