import signUp from "../../assets/Images/loginimage.svg";
import classes from "./Login.module.scss";
import { useFormik } from "formik";
import { signUpSchema, signinSchema } from "../../Schemas/Schemas";
import Button from "@mui/joy/Button";
import Input from "@mui/joy/Input";
import { Link, useNavigate } from "react-router-dom";
import { IAdminLogin } from "../../Utils/dtos";
import { AdminLoginAPICall, ProfileDetailsAPICall } from "../../Utils/APICalls";
import ErrorSnackBar from "../../components/SnackBar/ErrorSnackBar";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useState } from "react";
import { IconButton, InputAdornment, TextField } from "@mui/material";

const initialValues = {
  email: "",
  password: "",
};

const Login = () => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [showAccountNumber, setShowAccountNumber] = useState(false);
  const [loading, setLoading] = useState(false);
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues,
      validationSchema: signinSchema,
      onSubmit: (values, action) => {
        // console.log("values", values);
        // action.resetForm();
        // const requestBody = {
        //   username: values.email,
        //   password: values.password,
        // };
        let requestBody: IAdminLogin = {
          user_details: values.email,
          password: values.password,
        };
        setLoading(true);
        AdminLoginAPICall(requestBody)
          .then((res: any) => {
            //  console.log(res.data);
            const Token = res.data.data.access_token;
            sessionStorage.setItem("access-token", Token);
            
            setLoading(false);
            ProfileDetailsAPICall().then((response: any) => {
              const responseData = response.data;
              console.log("response", response.data.data)
              sessionStorage.setItem(
                "user-details",
                JSON.stringify(responseData.data)
              );
              sessionStorage.setItem(
                "userId",
                responseData.data.AdminId
              );
              sessionStorage.setItem(
                "isAdmin",
                responseData.data.IsAdmin
              );
              navigate("/dashboard/analytics");
            });
          })
          .catch((err) => {
            setLoading(false);
            if (err?.response?.data?.message) {
              setErrorMessage(err.response.data.message);

              setTimeout(() => {
                setErrorMessage("");
              }, 5000);
            } else {
              setErrorMessage("Error login");

              setTimeout(() => {
                setErrorMessage("");
              }, 5000);
            }
          });
      },
    });

  const handleClickShowAccountNumber = () =>
    setShowAccountNumber((show) => !show);

  const handleMouseDownAccountNumber = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  return (
    <>
      <div className={classes.container}>
        <div className={classes.modal}>
          <div className={classes.modalContainer}>
            <div className={classes.modalLeft}>
              <h1 className={classes.modalTitle}>EKYC ADMIN</h1>
              <h3 className={classes.modalSubTitle}>Login</h3>

              <p className={classes.modalDesc}></p>
              <div className={classes.loginData}>
                <form onSubmit={handleSubmit}>
                  <div className={classes.inputBlock}>
                    <p className={classes.label}>Enter Email/Mobile Number</p>
                    <TextField
                      name="email"
                      id="email"
                      placeholder="Email or Mobile Number"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.email && touched.email ? (
                      <p className={classes.formError}>{errors.email}</p>
                    ) : null}
                  </div>

                  <div className={classes.inputBlock}>
                    <p className={classes.label}>Enter Password</p>
                    <TextField
                      autoComplete="off"
                      name="password"
                      id="password"
                      type={showAccountNumber ? "text" : "password"}
                      placeholder="Enter Password"
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleClickShowAccountNumber}
                              onMouseDown={handleMouseDownAccountNumber}
                              edge="end"
                            >
                              {showAccountNumber ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {errors.password && touched.password ? (
                      <p className={classes.formError}>{errors.password}</p>
                    ) : null}
                  </div>
                  <div className={classes.modalButtons}>
                    <Button
                      type="submit"
                      size="lg"
                      style={{ padding: "14px 20px", background: "#8B93FF" }}
                      loading={loading}
                    >
                      Login
                    </Button>
                    <a href="#" className={classes.link}>
                      <Link
                        to={"/forgotPassword"}
                        style={{ textDecoration: "none", color: "#383838" }}
                      >
                        Forgot Password?
                      </Link>
                    </a>
                  </div>
                </form>
              </div>
            </div>
            <ErrorSnackBar message={errorMessage} />
            <div className={classes.modalRight}>
              <img src={signUp} alt="" className={classes.signupImage} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
