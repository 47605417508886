import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  IApproveClientRequestEmailMobileUpdate,
  IClientDataBankUpdate,
  IDeleteClientBankUpdate,
  IRejectApplicationBankUpdate,
} from "../../../Utils/dtos";
import {
  Stack,
  Fade,
  Box,
  IconButton,
  Modal,
  Divider,
  Tooltip,
} from "@mui/material";
import Button from "@mui/joy/Button";
import {
  GetRekycStagesAPICall,
  GetClientDataSegmentUpdateAPICall,
  GetRequestListSegmentUpdateAPICall,
  ApproveApplicationSegmentUpdateAPICall,
  RejectApplicationSegmentUpdateAPICall,
  DeleteClientSegmentUpdateAPICall,
} from "../../../Utils/APICalls";
import Styles from "./SegmentUpdateDetailspopup.module.scss";
import Tabs from "../../Tabs/Tabs";
import ErrorSnackBar from "../../SnackBar/ErrorSnackBar";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteClient from "../DeleteClient/DeleteClient";
import SucessSnackBar from "../../SnackBar/SuccessSnackBar";
import SegmentUpdateBase64ToImage from "../../SegmentUpdateBase64/SegmentUpdateBase64";
import SegmentUpdateReject from "../RejectComponent/SegmentUpdateReject";
import CheckedBox from "../../../assets/Images/CheckedBox.png";
import EmptyCheckBox from "../../../assets/Images/EmptyCheckBox.png";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import VerticalTabs from "../../Tabs/TabsVeritical";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "96%",
  height: "95%",
  bgcolor: "background.paper",
  borderRadius: "20px",
  boxShadow: 24,
  p: 2,
};

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

interface Props {
  open: boolean;
  handleClose: any;
  handleSubmitData: any;
  data: string;
  rejectedReason?: any;
  deletedReason?: any;
  selectedTabNew?: string;
  handlePermanentDeleteClient?: any;
  clientDeleted: boolean;
  stageName?: any;
  clientCompleted: boolean;
}

export default function SegmentUpdateDetailspopup({
  open,
  handleClose,
  data,
  handleSubmitData,
  rejectedReason,
  selectedTabNew,
  deletedReason,
  handlePermanentDeleteClient,
  clientDeleted,
  clientCompleted,
  stageName,
}: Props) {
  // const [open, setOpen] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [openDeletePopUp, setDeletePopUp] = useState(false);
  const [clientData, setClientData] = useState<any>();
  const [selectedTab, setselectedTab] = useState<string>("Income Proof");
  const [successMessage, setSuccessMessage] = useState("");

  const [tabLabels, setTabLabels] = useState(["Income Proof", "Esign"]);
  const [docType, setDocType] = useState("income_proof");

  const [stagesData, setStagesData] = useState<any>([]);
  const [rejectLoading, setRejectLoading] = useState(false);
  const [approveLoading, setApproveLoading] = useState(false);
  const [requestList, setRequestList] = useState([]);

  useEffect(() => {
    getDetails();

    GetRekycStagesAPICall()
      .then((response: any) => {
        const responseData = response.data;
        const sortedStages = responseData.sort(
          (a: any, b: any) => a.Sequence - b.Sequence
        );

        setStagesData(sortedStages);
      })
      .catch((error: any) => {
        //  console.log("error", error);
      });
  }, []);

  useEffect(() => {
    sessionStorage.removeItem(`${data}_e_sign_pdf`);
    sessionStorage.removeItem(`${data}_income_proof`);
  }, [handleClose]);

  // console.log(data);
  const isAdmin = sessionStorage.getItem("isAdmin");
  const getDetails = () => {
    const obj: any = {
      user_id: data,
    };
    GetClientDataSegmentUpdateAPICall(obj)
      .then((response: any) => {
        //console.log("responseclient", response.data);
        setClientData(response.data.data);
        // GetRequestList(
        //   response.data.client_data.ExistingSegmentDetails.ClientCode
        // );
      })
      .catch((error: any) => {});
  };

  const GetRequestList = (clientcode: any) => {
    let obj: any = {
      client_code: clientcode,
    };

    GetRequestListSegmentUpdateAPICall(obj)
      .then((response: any) => {
        //console.log("requestlistsegment", response.data.data[0]);
        setRequestList(response.data.data[0]);
      })
      .catch((error: any) => {});
  };

  const handleRejectClient = (reject: any) => {
    //  console.log("data", reject);

    let obj: any = {
      user_id: data,
      rejected_reason: reject.reasonForRejection,
    };
    setRejectLoading(true);
    RejectApplicationSegmentUpdateAPICall(obj)
      .then((response: any) => {
        const responseData = response.data;
        // console.log("responseData", responseData);
        setRejectLoading(false);
        handleClose("Client Rejected");
      })
      .catch((error: any) => {
        setRejectLoading(false);
        // console.log("error", error);
        if (error.response.data.message) {
          seterrorMessage(error.response.data.message);
          setTimeout(() => {
            seterrorMessage("");
          }, 5000);
        }
      });
  };
  const handleApproveClient = () => {
    let obj: any = {
      user_id: data,
    };
    setApproveLoading(true);
    ApproveApplicationSegmentUpdateAPICall(obj)
      .then((response: any) => {
        const responseData = response.data;
        //  console.log("responseData", responseData);
        setApproveLoading(false);
        handleClose("Client Approved");
      })
      .catch((error: any) => {
        // console.log("error", error);
        setApproveLoading(false);
        if (error.response.data.message) {
          seterrorMessage(error.response.data.message);
          setTimeout(() => {
            seterrorMessage("");
          }, 5000);
        }
      });
  };

  const handleChangeTab = (tab: any) => {
    //  console.log(tab);
    setselectedTab(tab);

    if (tab === "Esign") {
      setDocType("e_sign_pdf");
    }
    if (tab === "Income Proof") {
      setDocType("income_proof");
    }
  };

  const handleOpenDeletePopUp = () => {
    setDeletePopUp(true);
  };
  const handleCloseDeletepopUp = () => {
    setDeletePopUp(false);
  };

  const handleDeleteClient = (dataDelete: any) => {
    let obj: any = {
      user_id: data,
      deleted_reason: dataDelete.deleted_reason,
    };
    DeleteClientSegmentUpdateAPICall(obj)
      .then((response: any) => {
        const responseData = response.data;
        // console.log("responseData", responseData);
        setDeletePopUp(false);
        handleClose("Deleted");
        setSuccessMessage("Client Deleted Successfully");
        setTimeout(() => {
          setSuccessMessage("");
        }, 5000);
      })
      .catch((error: any) => {
        // console.log("error", error);
        setDeletePopUp(false);
        //  handleClose("Deleted");
        if (error.response.data.message) {
          seterrorMessage(error.response.data.message);
          setTimeout(() => {
            seterrorMessage("");
          }, 5000);
        }
      });
  };

  const close = (e: any) => {
    if (e.keyCode === 27) {
      handleClose();
    }
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        closeAfterTransition
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
        onClose={close}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className={Styles.topContainerTitle}>
              <p
                style={{
                  margin: "0px 10px",
                  fontSize: "14px",
                  fontWeight: 500,
                }}
              >
                <IconButton
                  onClick={handleClose}
                  size="small"
                  style={{
                    background: "#8B93FF",
                    color: "white",
                    borderRadius: 4,
                  }}
                >
                  <KeyboardBackspaceIcon
                    style={{
                      fontSize: "18px",
                      fontWeight: 600,
                    }}
                  />
                </IconButton>
                <span style={{ marginLeft: "10px" }}>Client Details</span>
              </p>
              <div className={Styles.title}>
                {clientData?.ClientDetails &&
                  clientData?.ClientDetails.ClientName}
              </div>
            </div>
            <div className={Styles.wrapper}>
              <div className={Styles.leftContainer}>
                <p className={Styles.clientInfo}>Client Info</p>
                {rejectedReason && stageName === "rejected" && (
                  <>
                    <div style={{ color: "red" }}>
                      <span style={{ fontWeight: 700 }}>Rejected Reason:</span>{" "}
                      <span style={{ fontSize: "14px" }}>
                        {" "}
                        {rejectedReason}
                      </span>
                    </div>
                    <Divider sx={{ borderColor: "grey", margin: "10px 0" }} />
                  </>
                )}
                {deletedReason && stageName === "deleted" && (
                  <>
                    <div style={{ color: "red" }}>
                      <span style={{ fontWeight: 700 }}>Deleted Reason:</span>{" "}
                      <span style={{ fontSize: "14px" }}> {deletedReason}</span>
                    </div>
                    <Divider sx={{ borderColor: "grey", margin: "10px 0" }} />
                  </>
                )}

                <div className={Styles.contentdiv}>
                  <p className={Styles.title}>
                    Mobile :{" "}
                    <span className={Styles.content}>
                      {clientData?.ClientDetails &&
                        clientData?.ClientDetails?.MobileNumber}
                    </span>
                  </p>
                  <p className={Styles.title}>
                    Email :{" "}
                    <span className={Styles.content}>
                      {clientData?.ClientDetails &&
                        clientData?.ClientDetails?.Email}
                    </span>
                  </p>
                </div>
                <Divider sx={{ borderColor: "grey", margin: "10px 0" }} />
                <div className={Styles.contentdiv}>
                  <p className={Styles.title}>
                    Client Name :{" "}
                    <span className={Styles.content}>
                      {clientData?.ClientDetails &&
                        clientData?.ClientDetails.ClientName}
                    </span>
                  </p>
                  <p className={Styles.title}>
                    CLIENT Code :{" "}
                    <span className={Styles.content}>
                      {clientData?.ClientDetails &&
                        clientData?.ClientDetails?.ClientCode}
                    </span>
                  </p>
                </div>

                <Divider sx={{ borderColor: "grey", margin: "10px 0" }} />
                <div className={Styles.contentdiv}>
                  <p className={Styles.title}>
                    Pan No :{" "}
                    <span className={Styles.content}>
                      {clientData?.ClientDetails &&
                        clientData?.ClientDetails.PANNumber}
                    </span>
                  </p>
                  {/* <p className={Styles.title}>
                    Status :{" "}
                    <span className={Styles.content}>
                      {clientData?.ExistingSegmentDetails &&
                        clientData?.ExistingSegmentDetails?.Status}
                    </span>
                  </p> */}
                </div>

                <Divider sx={{ borderColor: "grey", margin: "10px 0" }} />
                <div className={Styles.contentdiv}>
                  <p className={Styles.title}>
                    Created TS :{" "}
                    <span className={Styles.content}>
                      {clientData?.EventTimestamps &&
                        clientData?.EventTimestamps.created_ts?.slice(0, 10)}
                    </span>
                  </p>
                  <p className={Styles.title}>
                    Completed TS :{" "}
                    <span className={Styles.content}>
                      {clientData?.EventTimestamps &&
                        clientData?.EventTimestamps?.completed_ts?.slice(0, 10)}
                    </span>
                  </p>
                </div>

                <Divider sx={{ borderColor: "grey", margin: "10px 0" }} />

                <p
                  className={Styles.title}
                  style={{
                    // textDecoration: "underline",
                    background: "#d9e4fd",
                    // width: "max-content",
                    padding: "3px 20px",
                    borderRadius: "5px",
                    textAlign: "center",
                  }}
                >
                  New Segment Details
                </p>
                <div className={Styles.contentdiv}>
                  <div style={{ width: "100%" }}>
                    <div>
                      <p className={Styles.title}>
                        Equity :{" "}
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <span className={Styles.content}>
                          <img
                            src={
                              clientData?.NewDetails?.equity
                                ? CheckedBox
                                : EmptyCheckBox
                            }
                            style={{ width: "20px" }}
                          />
                        </span>
                      </p>

                      <div>
                        <p className={Styles.title}>
                          Futures & Options :{" "}
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <span className={Styles.content}>
                            <img
                              src={
                                clientData?.NewDetails?.futures_and_options
                                  ? CheckedBox
                                  : EmptyCheckBox
                              }
                              style={{ width: "20px" }}
                            />
                          </span>
                        </p>
                      </div>
                      <div>
                        <p className={Styles.title}>
                          Currency :{" "}
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <span className={Styles.content}>
                            <img
                              src={
                                clientData?.NewDetails?.currency
                                  ? CheckedBox
                                  : EmptyCheckBox
                              }
                              style={{ width: "20px" }}
                            />
                          </span>
                        </p>
                      </div>
                      <div>
                        <p className={Styles.title}>
                          Commodity :{" "}
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;
                          <span className={Styles.content}>
                            <img
                              src={
                                clientData?.NewDetails?.commodity
                                  ? CheckedBox
                                  : EmptyCheckBox
                              }
                              style={{ width: "20px" }}
                            />
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <p
                  className={Styles.title}
                  style={{
                    // textDecoration: "underline",
                    background: "#d9e4fd",
                    // width: "max-content",
                    padding: "3px 20px",
                    borderRadius: "5px",
                    textAlign: "center",
                  }}
                >
                  Existing Segment Details
                </p>
                <div className={Styles.contentdiv}>
                  <div style={{ width: "100%" }}>
                    <div>
                      <p className={Styles.title}>
                        Equity :{" "}
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <span className={Styles.content}>
                          <img
                            src={
                              clientData?.ExistingDetails?.equity
                                ? CheckedBox
                                : EmptyCheckBox
                            }
                            style={{ width: "20px" }}
                          />
                        </span>
                      </p>

                      <div>
                        <p className={Styles.title}>
                          Futures & Options :{" "}
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <span className={Styles.content}>
                            <img
                              src={
                                clientData?.ExistingDetails?.futures_and_options
                                  ? CheckedBox
                                  : EmptyCheckBox
                              }
                              style={{ width: "20px" }}
                            />
                          </span>
                        </p>
                      </div>
                      <div>
                        <p className={Styles.title}>
                          Currency :{" "}
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <span className={Styles.content}>
                            <img
                              src={
                                clientData?.ExistingDetails?.currency
                                  ? CheckedBox
                                  : EmptyCheckBox
                              }
                              style={{ width: "20px" }}
                            />
                          </span>
                        </p>
                      </div>
                      <div>
                        <p className={Styles.title}>
                          Commodity :{" "}
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;
                          <span className={Styles.content}>
                            <img
                              src={
                                clientData?.ExistingDetails?.commodity
                                  ? CheckedBox
                                  : EmptyCheckBox
                              }
                              style={{ width: "20px" }}
                            />
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <Divider sx={{ borderColor: "grey", margin: "10px 0" }} />
                <p
                  className={Styles.title}
                  style={{
                    // textDecoration: "underline",
                    background: "#d9e4fd",
                    // width: "max-content",
                    padding: "3px 20px",
                    borderRadius: "5px",
                    textAlign: "center",
                  }}
                >
                  Request List
                </p>
                <div className={Styles.contentdiv}>
                  <p className={Styles.title} style={{ width: "15%" }}>
                    Sl No
                  </p>
                  <p className={Styles.title} style={{ width: "55%" }}>
                    Futures & Options
                  </p>
                  <p className={Styles.title} style={{ width: "30%" }}>
                    Mutual Funds
                  </p>
                </div>
                {requestList.map((a: any, index: any) => (
                  <div
                    className={Styles.contentdiv}
                    style={{
                      margin: "5px 0px",
                      borderTop: "1px solid rgba(0, 0, 0, 0.12)",
                    }}
                  >
                    <p className={Styles.title} style={{ width: "15%" }}>
                      {index + 1}
                    </p>
                    <p className={Styles.title} style={{ width: "55%" }}>
                      <img
                        src={
                          a.NewSegmentDetails.fo ? CheckedBox : EmptyCheckBox
                        }
                        style={{ width: "20px" }}
                      />
                    </p>
                    <p className={Styles.title} style={{ width: "30%" }}>
                      <img
                        src={
                          a.NewSegmentDetails.mutual_funds
                            ? CheckedBox
                            : EmptyCheckBox
                        }
                        style={{ width: "20px" }}
                      />
                    </p>
                  </div>
                ))} */}
              </div>

              <div className={Styles.rightContainer} style={{ width: "75%" }}>
                <div style={{ width: "20%" }}>
                  <VerticalTabs
                    labels={tabLabels}
                    onChangeTab={handleChangeTab}
                    selectedTab={selectedTab}
                  />
                </div>
                <div
                  style={{
                    width: "80%",
                    border: "1px solid #DDDCE1",
                    height: "100%",
                    borderRadius: "4px",
                  }}
                >
                  <SegmentUpdateBase64ToImage data={data} docType={docType} />
                </div>
              </div>
            </div>
            <div className={Styles.buttonWrapper}>
              {clientData?.BooleanValues?.is_deleted === false && (
                <Tooltip title="Delete Client">
                  <IconButton
                    onClick={handleOpenDeletePopUp}
                    sx={{
                      // position: "absolute",
                      // bottom: 30,
                      border: "1px solid red",
                    }}
                  >
                    <DeleteIcon sx={{ color: "red" }} />
                  </IconButton>
                </Tooltip>
              )}
              {clientData?.BooleanValues?.is_deleted && (
                <Tooltip title="Permanent Delete Client">
                  <IconButton
                    onClick={handlePermanentDeleteClient}
                    sx={{
                      // position: "absolute",
                      // bottom: 30,
                      border: "1px solid red",
                    }}
                  >
                    <DeleteIcon sx={{ color: "red" }} />
                  </IconButton>
                </Tooltip>
              )}

              <DeleteClient
                open={openDeletePopUp}
                handleDeleteClient={handleDeleteClient}
                handleCloseDeletepopUp={handleCloseDeletepopUp}
              />
              <SucessSnackBar message={successMessage} />
              <ErrorSnackBar message={errorMessage} />

              <Stack
                direction={"row"}
                justifyContent={"space-evenly"}
                // marginTop={"15px"}
                alignItems={"center"}
                marginLeft={"20px"}
              >
                {stageName !== "deleted" &&
                  stageName !== "rejected" &&
                  clientData?.BooleanValues?.is_rejected === false &&
                  
                  clientData?.BooleanValues?.is_completed === true && (
                    <SegmentUpdateReject
                      stagesData={stagesData}
                      handleRejectClient={handleRejectClient}
                      rejectLoading={rejectLoading}
                      disabled={false}
                    />
                  )}
                {isAdmin === "true" && (
                  <div>
                    {stageName !== "deleted" &&
                      stageName !== "rejected" &&
                      clientData?.BooleanValues?.is_completed === true &&
                      clientData?.BooleanValues?.is_approved === false &&
                      clientData?.BooleanValues?.is_rejected === false &&
                      stageName !== "approved" && (
                        <Button
                          type="submit"
                          onClick={handleApproveClient}
                          loading={approveLoading}
                          // disabled={
                          //   clientData.ExistingSegmentDetails &&
                          //   clientData.ExistingSegmentDetails.IsCompleted
                          //     ? false
                          //     : true
                          // }
                          sx={{
                            background: "#3CBC49",
                            color: "white",
                            width: "160px",
                            borderRadius: "4px",
                            marginLeft: "20px",
                            "&:hover": {
                              background: "#3CBC49",
                              color: "white",
                            },
                          }}
                        >
                          Approve
                        </Button>
                      )}
                  </div>
                )}

                <ErrorSnackBar message={errorMessage} />
              </Stack>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
