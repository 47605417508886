import React, { useEffect, useState } from "react";
import DataTableComponent from "../../components/DataTableComponent/DataTableComponent";
import {
  Button,
  FormControl,
  Icon,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import RefreshIcon from "@mui/icons-material/Refresh";
import PersonIcon from "@mui/icons-material/Person";
import { Helmet } from "react-helmet";
import {
  GetPartnerdashBoardAPICall,
  AssignPartnerAPICall,
  GetPartnerStagesAPICall,
  ListUsersAPICall,
  permanentDeleteClientAPICall,
  permanentDeletePartnerAPICall,
  GetNRIStagesAPICall,
  GetNRIDashboardAPICall,
  AssignNRIClientAPICall,
  PermanentDeleteNRIClientAPICall,
} from "../../Utils/APICalls";
import { IClientsData, IPartnerAssignData } from "../../Utils/dtos";
import UserFormModalpopup from "../../components/Modals/UserFormModalpopup";
import { IClientData, IDeleteUser, IEditUser } from "../../Utils/dtos";
import Tooltip from "@mui/material/Tooltip";
import Tabs from "../../components/Tabs/Tabs";
import ClientDetailspopup from "../../components/Modals/ClientDetailspopup";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import stagesData from "../../Utils/stages.json";
import AssignUser from "../../components/Modals/AssignUser/AssignUser";
import SucessSnackBar from "../../components/SnackBar/SuccessSnackBar";
import AddIcCallIcon from "@mui/icons-material/AddIcCall";
import FollowUpsModal from "../../components/Modals/FollowUps/FollowUpsModal";
import HistoryIcon from "@mui/icons-material/History";
import { useDebounce } from "use-debounce";
import ClientLogsModal from "../../components/Modals/ClientLogsPopUpModal/ClientLogsModalPopUp";
import { DateRange } from "@mui/x-date-pickers-pro";
import dayjs, { Dayjs } from "dayjs";
import { ADMINLOGINLINK, ADMINLOGINTONRI, ADMINLOGINTOPARTNERLINK } from "../../Utils/constants";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DataTableComponentPartner from "../../components/DataTableComponentPatner/DataTableComponentPartner";
import PartnerDetailspopup from "../../components/Modals/PartnerDetailsPopUpModal/PartnerDetailsPopUp";
import PartnerLogsModal from "../../components/Modals/PartnerLogsPopUpModal/PartnerLogsPopUpModal";
import AssignUserPartner from "../../components/Modals/AssignUser/AssignUserPartner";
import DataTableComponentNRI from "../../components/DataTableComponentNRI/DataTableComponentNRI";
import NRIDetailspopup from "../../components/Modals/NRIDetailsPopUpModal/NRIDetailsPopUp";
import NRILogsModal from "../../components/Modals/NRILogsPopUpModal/NRILogsPopUpModal";
import { confirmAlert } from "react-confirm-alert";

const titletextStyle = {
  fontSize: "14px",
  fontWeight: "bold",
  color: "#8C8B8F",
  cursor: "pointer",
};
const contenttextStyle = { fontSize: "12px" };

const Data: any = stagesData;

const mappingValues: any = {
  Today: "today",
  All: "all",
  "In Progress": "non_esigned",
  "Approval Pending": "approval_pending",
  Rejected: "rejected",
  Approved: "approved",
  Deleted: "deleted",
  "Account Opened": "account_opened",
};
export default function NRIPage() {
  const [data, setdata] = useState([]);
  const [loading, setloading] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedTab, setselectedTab] = useState<string>("Today");
  const [totalRows, setTotalRows] = useState(5);
  const [perPage, setPerPage] = useState(20);
  const [clientDetailsPopup, setclientDetailsPopup] = useState({
    status: false,
    data: "",
  });
  const [clientTimeStamp, setClientTimeStamp] = useState({
    MobileVerifiedTS: "",
    EmailVerifiedTS: "",
  });
  const [clientFollowUpPopup, setclientFollowPopup] = useState({
    status: false,
    data: "",
  });
  const [clientLogsPopup, setclientLogsPopup] = useState({
    status: false,
    data: {},
  });
  const [clientId, setClientId] = useState("");
  const [userName, setUserName] = useState("");
  const [clientMessage, setClientMessage] = useState("");
  const [stagesData, setStagesData] = useState<any>();
  const [users, setusers] = useState<any>();
  const [searchData, setSearchData] = useState("");
  const [stageFilterData, setStageFilterData] = useState("");
  const [userFilterData, setUserFilterData] = useState("");
  const [rejectedReason, setRejectedReason] = useState("");
  const [deletedReason, setDeletedReason] = useState("");
  const [pagenumber, setPageNumber] = useState(1);
  const [paginationCount, setpaginationCount] = useState(1);
  const [tableRefreshFilters, settablerefreshfilters] = useState("");
  const [dateRangeFilterData, setDateRangeFilterData] = useState<any>({
    fromDate: dayjs().subtract(2, "month"),
    toDate: dayjs(new Date()),
  });

  const [recordsCount, setRecordsCount] = useState(0);
  const [stageName, setStageName] = useState<any>("");
  const [sortactivity, setsortactivity] = useState<any>("DESC");
  const [rotate, setRotate] = useState(false);
  // const [isAdmin, setIsAdmin] = useState(true);
  const isAdmin = sessionStorage.getItem("isAdmin");

  function formatDateChange(inputDate: any) {
    const dateParts = inputDate.split("-");
    const formattedDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
    return formattedDate;
  }

  const columns = [
    {
      name: <b style={titletextStyle}>SrNo</b>,
      selector: "srno",
      sortable: true,
      width: "60px",
      cell: (row: any, index: any) => (
        <b style={contenttextStyle}>{index + 1 + paginationCount * 20 - 20}</b>
      ),
    },
    {
      name: <b style={titletextStyle}>AppNo</b>,
      selector: "appno",
      sortable: true,
      width: "105px",
      cell: (row: any) => (
        <Tooltip title={!row.completed_ts ? "Click to Login" : ""}>
          <b
            style={{ fontSize: "12px", cursor: "pointer" }}
            onClick={() => {
              handleAdminLogintoClientAccount(row);
            }}
          >
            {row.appno}
          </b>
         </Tooltip>
      ),
    },

    {
      name: <b style={titletextStyle}>Name</b>,
      selector: "client_name",
      sortable: true,

      cell: (row: any) => <b style={contenttextStyle}>{row.client_name}</b>,
    },
    {
      name: <b style={titletextStyle}>PAN</b>,
      selector: "pan_no",
      sortable: true,
      width: "110px",
      cell: (row: any) => <b style={contenttextStyle}>{row.pan_no}</b>,
    },
    {
      name: <b style={titletextStyle}>Type</b>,
      selector: "account_type",
      sortable: true,
      width: "110px",
      cell: (row: any) => <b style={contenttextStyle}>{row.account_type}</b>,
    },
    {
      name: <b style={titletextStyle}>CCode</b>,
      selector: "nri_code",
      sortable: true,
      width: "80px",
      cell: (row: any) => <b style={contenttextStyle}>{row.nri_code}</b>,
    },
    {
      name: <b style={titletextStyle}>Email</b>,
      selector: "email",
      width: "200px",
      sortable: true,
      cell: (row: any) => <b style={contenttextStyle}>{row.email}</b>,
    },
    {
      name: <b style={titletextStyle}>Mobile</b>,
      selector: "mobile",
      sortable: true,
      width: "90px",
      cell: (row: any) => <b style={contenttextStyle}>{row.mobile}</b>,
    },
    {
      name: <b style={titletextStyle}>Stage</b>,
      selector: "stage",
      sortable: true,
      // width: "90px",
      cell: (row: any) => (
        <b style={contenttextStyle}>
          {row.logs?.RejectedTS ? (
            <>
              {mappingValues[selectedTab] === "all" && (
                <span style={{ color: "red" }}>Rej-</span>
              )}
              {Data.hasOwnProperty(row.current_stage) &&
                Data[row.current_stage]}
            </>
          ) : (
            Data.hasOwnProperty(row.current_stage) && Data[row.current_stage]
          )}
        </b>
      ),
    },

    {
      name: (
        <b
          style={{ ...titletextStyle, display: "flex", alignItems: "center" }}
          onClick={() => {
            setRotate(!rotate);
            if (sortactivity === "ASC") {
              setsortactivity("DESC");
            } else if (sortactivity === "DESC") {
              setsortactivity("ASC");
            }
          }}
        >
          Activity{" "}
          <ArrowDropDownIcon
            sx={{
              transform: rotate ? "rotate(180deg)" : "",
              transition: "0.5s ease",
            }}
          />
        </b>
      ),
      selector: "pending_days",
      sortable: false,
      width: "90px",
      cell: (row: any) => <b style={contenttextStyle}>{row.pending_days}</b>,
    },
    {
      name: <b style={titletextStyle}>User</b>,
      selector: "handling_user",
      sortable: true,
      // width: "100px",
      cell: (row: any) => (
        <>
          {row.assigned_user === null ? (
            <Tooltip title="Assign User ">
              <IconButton
                onClick={() => {
                  handleAssign(row);
                }}
              >
                <PersonIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <b
              style={{ fontSize: "12px", cursor: "pointer" }}
              onClick={() => {
                handleAssign(row);
              }}
            >
              {row.assigned_user}
            </b>
          )}
        </>
      ),
    },
    {
      name: <b style={titletextStyle}>Actions</b>,
      selector: "spamReportRate",
      sortable: false,
      allowOverflow: true,
      button: true,
      width: "160px",
      cell: (row: any) => (
        <b style={contenttextStyle}>
          <Tooltip title="View Details">
            <IconButton
              onClick={() => {
                handleViewClientDetails(row);
              }}
            >
              <VisibilityIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Logs">
            <IconButton
              onClick={() => {
                handleClientLogs(row);
              }}
            >
              <HistoryIcon />
            </IconButton>
          </Tooltip>
        </b>
      ),
    },
  ];
  const columnsForDeletedAndRejected = [
    {
      name: <b style={titletextStyle}>SrNo</b>,
      selector: "srno",
      sortable: true,
      width: "60px",
      cell: (row: any, index: any) => (
        <b style={contenttextStyle}>{index + 1 + paginationCount * 20 - 20}</b>
      ),
    },
    {
      name: <b style={titletextStyle}>AppNo</b>,
      selector: "appno",
      sortable: true,
      width: "105px",
      cell: (row: any) => (
        <b
          style={{ fontSize: "12px", cursor: "pointer" }}
          onClick={() => {
            if (mappingValues[selectedTab] !== "deleted") {
              handleAdminLogintoClientAccount(row);
            }
          }}
        >
          {row.appno}
        </b>
      ),
    },

    {
      name: <b style={titletextStyle}>Name</b>,
      selector: "client_name",
      sortable: true,

      cell: (row: any) => <b style={contenttextStyle}>{row.client_name}</b>,
    },
    {
      name: <b style={titletextStyle}>PAN</b>,
      selector: "pan_no",
      sortable: true,

      cell: (row: any) => <b style={contenttextStyle}>{row.pan_no}</b>,
    },
    {
      name: <b style={titletextStyle}>Type</b>,
      selector: "account_type",
      sortable: true,
      width: "110px",
      cell: (row: any) => <b style={contenttextStyle}>{row.account_type}</b>,
    },
    {
      name: <b style={titletextStyle}>PCode</b>,
      selector: "client_code",
      sortable: true,
      width: "80px",
      cell: (row: any) => <b style={contenttextStyle}>{row.introducer_code}</b>,
    },
    {
      name: <b style={titletextStyle}>Email</b>,
      selector: "email",
      width: "200px",
      sortable: true,
      cell: (row: any) => <b style={contenttextStyle}>{row.email}</b>,
    },
    {
      name: <b style={titletextStyle}>Mobile</b>,
      selector: "mobile",
      sortable: true,
      width: "110px",
      cell: (row: any) => <b style={contenttextStyle}>{row.mobile}</b>,
    },
    {
      name: <b style={titletextStyle}>Stage</b>,
      selector: "stage",
      sortable: true,
      // width: "80px",
      cell: (row: any) => (
        <b style={contenttextStyle}>
          {Data.hasOwnProperty(row.current_stage) && Data[row.current_stage]}
        </b>
      ),
    },

    {
      name: (
        <b
          style={titletextStyle}
          onClick={() => {
            if (sortactivity === "ASC") {
              setsortactivity("DESC");
            } else if (sortactivity === "DESC") {
              setsortactivity("ASC");
            }
          }}
        >
          Activity
        </b>
      ),
      selector: "pending_days",
      sortable: false,
      // width: "90px",
      cell: (row: any) => <b style={contenttextStyle}>{row.pending_days}</b>,
    },
    {
      name: <b style={titletextStyle}>User</b>,
      selector: "handling_user",
      sortable: true,
      // width: "100px",
      cell: (row: any) => (
        <>
          {row.assigned_user === null ? (
            <Tooltip title="Assign User ">
              <IconButton
                onClick={() => {
                  handleAssign(row);
                }}
              >
                <PersonIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <b
              style={{ fontSize: "12px", cursor: "pointer" }}
              onClick={() => {
                handleAssign(row);
              }}
            >
              {row.assigned_user}
            </b>
          )}
        </>
      ),
    },

    {
      name: <b style={titletextStyle}>Reason</b>,
      selector: "reason",
      sortable: true,

      cell: (row: any) => (
        <b style={contenttextStyle}>
          {(row.rejected_reason && row.rejected_reason) ||
            (!row.rejected_reason && row.deleted_reason)}
        </b>
      ),
    },

    {
      name: <b style={titletextStyle}>Actions</b>,
      selector: "spamReportRate",
      sortable: false,
      allowOverflow: true,
      button: true,
      width: "130px",
      cell: (row: any) => (
        <b style={contenttextStyle}>
          <Tooltip title="View Details">
            <IconButton
              onClick={() => {
                handleViewClientDetails(row);
                if (row.rejected_reason) {
                  setRejectedReason(row.rejected_reason);
                }
                if (row.deleted_reason) {
                  setDeletedReason(row.deleted_reason);
                }
              }}
            >
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Logs">
            <IconButton
              onClick={() => {
                handleClientLogs(row);
              }}
            >
              <HistoryIcon />
            </IconButton>
          </Tooltip>

          {/* <Tooltip title={row.handling_user === "" ? "Asign User" : "FollowUp"}>
            <IconButton
              onClick={() => {
                handleFollowUpData(row);
              }}
              // disabled={row.handling_user === ""}
            >
              <AddIcCallIcon
                sx={{ fill: row.followup_date === null ? "red" : "" }}
              />
            </IconButton>
          </Tooltip> */}
        </b>
      ),
    },
  ];
  const formatDate = (date: any) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hour = String(date.getHours()).padStart(2, "0");
    const minute = String(date.getMinutes()).padStart(2, "0");
    const second = String(date.getSeconds()).padStart(2, "0");
    return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
  };
  //  console.log("columns", columns);

  function formatDatePayload(dateString: any) {
    // Create a new Date object from the provided date string
    const date = new Date(dateString);

    // Extract the year, month, and day from the Date object
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-indexed, so we add 1
    const day = String(date.getDate()).padStart(2, "0");

    // Concatenate the year, month, and day with a hyphen separator to get the desired format
    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
  }

  useEffect(() => {
    GetNRIStagesAPICall()
      .then((response: any) => {
        const responseData = response.data;
        // console.log("responseData", responseData);
        setStagesData(responseData.data.stage_list);
      })
      .catch((error: any) => {
        //  console.log("error", error);
      });

    ListUsersAPICall()
      .then((response: any) => {
        const responseData = response.data;
        setusers(responseData.data.user_list);
        // console.log(responseData.data.user_list);
      })
      .catch((error: any) => {});
  }, []);

  useEffect(() => {
    setStageName("");
  }, [selectedTab]);

  useEffect(() => {
    setloading(true);
    setRecordsCount(0);
    const delayDebounceFn = setTimeout(() => {
      setdata([]);
      getClients(
        pagenumber,
        perPage,
        searchData,
        userFilterData,
        dateRangeFilterData.fromDate === ""
          ? ""
          : formatDatePayload(dateRangeFilterData.fromDate),
        dateRangeFilterData.toDate === ""
          ? ""
          : formatDatePayload(dateRangeFilterData.toDate),
        stageFilterData,
        stageName
      );
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [
    selectedTab,
    searchData,
    stageFilterData,
    userFilterData,
    dateRangeFilterData,
    stageName,
    pagenumber,
    sortactivity,
  ]);
  const handleRefreshData = () => {
    // getClients(1, perPage, "", "", "", "", "", "");
    setPageNumber(1);
    setpaginationCount(1);
    // settablerefreshfilters("refresh")
    // setStageName("")
    getClients(
      pagenumber,
      perPage,
      searchData,
      userFilterData,
      dateRangeFilterData.fromDate === ""
        ? ""
        : formatDatePayload(dateRangeFilterData.fromDate),
      dateRangeFilterData.toDate === ""
        ? ""
        : formatDatePayload(dateRangeFilterData.toDate),
      stageFilterData,
      ""
    );
  };

  const getClients = (
    page: number,
    perpage: number,
    query: any,
    filter_user: any,
    filter_date_from: any,
    filter_date_to: any,
    filter_stage: any,
    // sortactivity:any,
    type?: any
  ) => {
    setloading(true);
    const reqObj: IClientsData = {
      page_no: page,
      page_size: perpage,
      query: query,
      type: type ? type : mappingValues[selectedTab],
      filter_user: filter_user,
      filter_date_from: filter_date_from,
      filter_date_to: filter_date_to,
      filter_stage: filter_stage,
      sort_activity: sortactivity,
    };

    GetNRIDashboardAPICall(reqObj)
      .then((response: any) => {
        setloading(false);
        const responseData = response.data;
        setdata(responseData.data);

        //console.log(responseData.data);
        setTotalRows(Math.ceil(responseData.total_records / 20));

        if (responseData.total_records) {
          setRecordsCount(responseData.total_records);
        } else {
          setRecordsCount(0);
        }
      })
      .catch((error) => {
        setloading(false);
      });
  };

  const handleClientLogs = (data: any) => {
    //console.log("logs", data.logs);
    if (data.assigned_user === null) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="confitmalertUI">
              <PersonIcon />
              <p>Please assign an user</p>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <button
                  onClick={() => {
                    onClose();
                  }}
                  className="confitmbutton"
                >
                  OK
                </button>
              </div>
            </div>
          );
        },
      });
      // window.alert("Please Asign User");
    } else {
      //  console.log(data.logs);
      setclientLogsPopup({
        status: true,
        data: data.logs,
      });
    }
  };
  const handleCloseClientLogsPopup = () => {
    setclientLogsPopup({
      status: false,
      data: "",
    });
  };

  const handleSearchChange = (data: any) => {
    setSearchData(data);
    setPageNumber(1);
    setpaginationCount(1);
    // getClients(1, perPage, data);
  };
  const handleDateRangeChange = (data: any) => {
    //console.log("dateData", data);
    setDateRangeFilterData({
      fromDate: !data[0].$d ? "" : formatDate(data[0].$d),
      toDate: !data[1].$d ? "" : formatDate(data[1].$d),
    });
  };
  const handleStageChange = (data: any) => {
    setPageNumber(1);
    setpaginationCount(1);
    setStageFilterData(data);

    // getClients(1, perPage, data);
  };
  const handleUserChange = (data: any) => {
    setPageNumber(1);
    setUserFilterData(data);
    setpaginationCount(1);
    // getClients(1, perPage, data);
  };

  const handleAssign = (data: any) => {
    if (isAdmin === "false") {
      const obj: IPartnerAssignData = {
        client_id: data.client_id,
        user_name: sessionStorage.getItem("userId"),
      };
      AssignNRIClientAPICall(obj).then((response: any) => {
        //  console.log("response", response);
        alert("Assigned to me");
        // getClients(1, perPage, "", "", "", "", "", "");
      });
    } else {
      setOpen(true);
      setClientId(data.client_id);
    }
  };

  const handleViewClientDetails = (data: any) => {
    // console.log("clientData", data);
    if (data.assigned_user === null) {
      window.alert("Please Asign User");
    } else {
      setclientDetailsPopup({
        status: true,
        data: data.client_id,
      });
      setClientId(data.client_id);
      setClientTimeStamp({
        MobileVerifiedTS: data.logs?.MobileVerifiedTS
          ? data.logs?.MobileVerifiedTS
          : "",
        EmailVerifiedTS: data.logs?.EmailVerifiedTS
          ? data.logs.EmailVerifiedTS
          : null,
      });
    }
  };
  const handleAdminLogintoClientAccount = (data: any) => {
    if (!data.completed_ts) {
      window.open(
        `${ADMINLOGINTONRI}?client_id=${
          data.client_id
        }&user_id=${sessionStorage.getItem("userId")}`,
        "_blank"
      );
    }
  };

  const handleCloseClientDetailsPopup = (data: any) => {
    sessionStorage.removeItem(`${clientDetailsPopup.data}_bank_proof`);
    sessionStorage.removeItem(
      `${clientDetailsPopup.data}_overseas_address_proof`
    );
    sessionStorage.removeItem(
      `${clientDetailsPopup.data}_permanent_address_proof`
    );
    sessionStorage.removeItem(
      `${clientDetailsPopup.data}_correspondence_address_proof`
    );
    sessionStorage.removeItem(`${clientDetailsPopup.data}_photography`);
    sessionStorage.removeItem(`${clientDetailsPopup.data}_pancard`);
    sessionStorage.removeItem(`${clientDetailsPopup.data}_signature`);
    sessionStorage.removeItem(`${clientDetailsPopup.data}_passport`);
    sessionStorage.removeItem(`${clientDetailsPopup.data}_visa`);
    sessionStorage.removeItem(`${clientDetailsPopup.data}_nominee_proof1`);
    sessionStorage.removeItem(`${clientDetailsPopup.data}_nominee_proof2`);
    sessionStorage.removeItem(`${clientDetailsPopup.data}_nominee_proof3`);
    sessionStorage.removeItem(`${clientDetailsPopup.data}_webcam_client_photo`);
    //  console.log("data", data);
    setclientDetailsPopup({
      status: false,
      data: "",
    });

    // getClients(1, perPage, "", "", "", "", "");

    if (data === "Deleted") {
      getClients(1, perPage, "", "", "", "", "", "");
      setClientMessage("Client Deleted Successfully");
      setTimeout(() => {
        setClientMessage("");
      }, 5000);
    } else if (data === "Client Approved") {
      getClients(1, perPage, "", "", "", "", "", "");
      setClientMessage("Client Approved Successfully");
      setTimeout(() => {
        setClientMessage("");
      }, 5000);
    } else if (data === "Client Rejected") {
      getClients(1, perPage, "", "", "", "", "", "");
      setClientMessage("Client Rejected Successfully");
      setTimeout(() => {
        setClientMessage("");
      }, 5000);
    } else {
      setClientMessage(""); // Reset the message for other cases
    }
  };

  const handlePermanentDeleteClient = () => {
    //  console.log("data", data);
    if (window.confirm("Are you sure, client will be permanently deleted")) {
      let reqObj: any = {
        client_id: clientId,
      };

      PermanentDeleteNRIClientAPICall(reqObj)
        .then((response: any) => {
          const responseData = response.data;
          getClients(1, perPage, "", "", "", "", "", "");
          setClientMessage("Client Deleted Successfully");
          setclientDetailsPopup({
            status: false,
            data: "",
          });
          setTimeout(() => {
            setClientMessage("");
          }, 5000);
        })
        .catch((error) => {});
    }
  };

  const handleAssignePopUpClose = () => {
    setOpen(false);
  };

  const handleAssigneID = (data: any) => {
    setOpen(false);

    const obj: IPartnerAssignData = {
      client_id: clientId,
      user_name: data,
    };
    AssignNRIClientAPICall(obj)
      .then((response: any) => {
        // alert(`Assigned to me ${response.data.data.User_name}`);
        setUserName(response.data.data.User_name);
        getClients(
          pagenumber,
          perPage,
          searchData,
          userFilterData,
          dateRangeFilterData.fromDate === ""
            ? ""
            : formatDatePayload(dateRangeFilterData.fromDate),
          dateRangeFilterData.toDate === ""
            ? ""
            : formatDatePayload(dateRangeFilterData.toDate),
          stageFilterData,
          ""
        );
      })
      .catch((error: any) => {});
  };

  const handleSubmitDetailsPopup = (data: any) => {
    //  console.log("data", data);
  };

  const handlePageChange = (value: any, page: number) => {
    // getClients(page, perPage, "", "", "", "", "");
    setpaginationCount(page);
    setPageNumber(page);
  };

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setPerPage(newPerPage);
    getClients(page, newPerPage, "", "", "", "", "", "");
  };

  const handleChangeTab = (tab: any) => {
    setselectedTab(tab);
  };

  const handleSelectApprovedChange = (event: SelectChangeEvent) => {
    setpaginationCount(1);
    setStageName(event.target.value as string);

    setPageNumber(1);
  };

  return (
    <>
      <Helmet>
        <title>NRI </title>
        <meta name="NRI" content="NRI" />
      </Helmet>
      <div>
        <Stack
          direction={"row"}
          // justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Tabs
            labels={[
              "Today",
              "All",
              "In Progress",
              "Approval Pending",
              "Rejected",
              "Approved",
              "Deleted",
            ]}
            onChangeTab={handleChangeTab}
            selectedTab={selectedTab}
            recordsCount={recordsCount}
            calledScreen={"NewKyc"}
          />
          <Tooltip title="Refresh Table Data">
            <IconButton
              sx={{
                marginRight: "20px",
                height: "40px",
                width: "40px",
                marginLeft: "100px",
                marginBottom: "15px",
                border: "1px solid grey",
              }}
              onClick={handleRefreshData}
            >
              <RefreshIcon sx={{ color: "grey" }} />
            </IconButton>
          </Tooltip>
        </Stack>

        <div>
          <DataTableComponentNRI
            columns={
              mappingValues[selectedTab] === "deleted" ||
              mappingValues[selectedTab] === "rejected"
                ? columnsForDeletedAndRejected
                : columns
            }
            data={data}
            loading={loading}
            searchparams={["client_name", "mobile", "source", "source_id"]}
            pagination={true}
            paginationServer={true}
            handleAdd={undefined}
            handlePerRowsChange={handlePerRowsChange}
            handlePageChange={handlePageChange}
            totalRows={totalRows}
            handleSearchChange={handleSearchChange}
            stagesData={stagesData}
            users={users}
            handleUserChange={handleUserChange}
            handleStageChange={handleStageChange}
            handleDateRangeChange={handleDateRangeChange}
            selectedTab={mappingValues[selectedTab]}
            paginationCount={paginationCount}
            tableRefreshFilters={tableRefreshFilters}
          />
        </div>
        {clientDetailsPopup.status && (
          <NRIDetailspopup
            data={clientDetailsPopup.data}
            handleSubmitData={handleSubmitDetailsPopup}
            open={clientDetailsPopup.status}
            handleClose={handleCloseClientDetailsPopup}
            rejectedReason={rejectedReason}
            deletedReason={deletedReason}
            selectedTabNew={mappingValues[selectedTab]}
            handlePermanentDeleteClient={handlePermanentDeleteClient}
            clientTimeStamp={clientTimeStamp}
            stageName={stageName}
          />
        )}

        {clientLogsPopup.status && (
          <NRILogsModal
            open={clientLogsPopup.status}
            handleClose={handleCloseClientLogsPopup}
            handleSubmitData={undefined}
            data={clientLogsPopup.data}
          />
        )}

        <SucessSnackBar message={clientMessage} />
        <AssignUserPartner
          open={open}
          handleClose={handleAssignePopUpClose}
          assigneID={handleAssigneID}
          userData={users}
        />
      </div>
    </>
  );
}
