import React, { useState, useRef, useEffect } from "react";

import {
  Box,
  Button,
  Divider,
  IconButton,
  Modal,
  TextField,
  ThemeProvider,
} from "@mui/material";
import classes from "./AddBank.module.scss";

import { Stack } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import { theme } from "../../ThemeProvider/ThemeProvider";
import { Formik } from "formik";
import { addbankaccountschema } from "../../../Schemas/Schemas";

interface Props {
  open: boolean;
  handleClose: any;
  handleSubmitData: any;
  setMicrNumber: any;
  data: any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "45%",
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  //   p: 4,
};

export default function AddBankDetailsModal({
  open,
  handleClose,
  handleSubmitData,
  setMicrNumber,
  data,
}: Props) {
  const [loading, setloading] = useState(false);

  const [micrnumber, setmicrnumber] = useState("");

  const fetchbranchName = (ifscdata: any) => {
    fetch(`https://ifsc.razorpay.com/${ifscdata}`)
      .then((response) => response.json())
      .then((data: any) => {
        console.log("response", data);
        setMicrNumber(data.MICR);
        return data;
      })
      .catch((error: any) => {
        // console.log("error", error);
      });
  };

  const addBankAccount = {
    bankName: "",
    priority: "",
    ifsc: "",
    // micr: "",
    accountnumber: "",
  };

  return (
    <Modal
      open={open}
      //   onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ThemeProvider theme={theme}>
        <Formik
          validationSchema={addbankaccountschema}
          initialValues={addBankAccount}
          onSubmit={(values) => {
            handleSubmitData(values);
            handleClose();
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => (
            <>
              <form noValidate onSubmit={handleSubmit}>
                <Box sx={style}>
                  <Stack
                    sx={{ position: "absolute", right: "20px", top: "20px" }}
                  >
                    <IconButton
                      size="small"
                      disableFocusRipple
                      disableRipple
                      sx={{
                        background: "white",
                      }}
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      <CloseIcon
                        sx={{ color: "rgba(79, 79, 79, 1)", fontSize: "12px" }}
                      />
                    </IconButton>
                  </Stack>
                  <div className={classes.mainWrapper}>
                    <div className={classes.imageTitleContainer}>
                      <p className={classes.title}>Add Bank Account</p>
                    </div>
                    <div className={classes.fieldsWrapper}>
                      <p className={classes.text}>
                        Enter all the details to add your account
                      </p>
                      <Stack
                        spacing={2}
                        sx={{
                          border: "1px dotted #E1E2E3",
                          padding: "20px",
                          borderRadius: "10px",
                        }}
                      >
                        <Stack
                          justifyContent={"space-between"}
                          spacing={2}
                          direction={"row"}
                        >
                          <TextField
                            id="bankname"
                            fullWidth
                            name="bankName"
                            label="Bank Name"
                            variant="outlined"
                            size="small"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.bankName}
                            error={touched.bankName && Boolean(errors.bankName)}
                            helperText={
                              touched.bankName && errors.bankName?.toString()
                            }
                          />
                          <TextField
                            id="priority"
                            fullWidth
                            name="priority"
                            label="Priority"
                            variant="outlined"
                            size="small"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.priority}
                            error={touched.priority && Boolean(errors.priority)}
                            helperText={
                              touched.priority && errors.priority?.toString()
                            }
                          />
                        </Stack>
                        <Stack
                          justifyContent={"space-between"}
                          spacing={2}
                          direction={"row"}
                        >
                          <TextField
                            id="ifsc"
                            fullWidth
                            label="IFSC Code"
                            variant="outlined"
                            size="small"
                            name="ifsc"
                            onChange={(event: any) => {
                              handleChange(event);

                              console.log("event", event);
                              if (event.target.value.length === 11) {
                                fetchbranchName(event.target.value);
                              }
                            }}
                            onBlur={handleBlur}
                            value={values.ifsc.toUpperCase()}
                            error={touched.ifsc && Boolean(errors.ifsc)}
                            helperText={touched.ifsc && errors.ifsc?.toString()}
                          />

                          {/* <TextField
                            margin="normal"
                            fullWidth
                            name="micr"
                            label="MICR Code"
                            id="micr"
                            // autoComplete="current-password"
                            size="small"
                            onChange={(event: any) => {
                              handleChange(event);
                              setFieldValue("micr", micrnumber);
                            }}
                            onBlur={handleBlur}
                            value={micrnumber}
                            // error={touched.micr && Boolean(errors.micr)}
                            // helperText={touched.micr && errors.micr?.toString()}
                          /> */}
                        </Stack>
                        <Stack>
                          <TextField
                            id="accountnumber"
                            fullWidth
                            label="Account Number"
                            variant="outlined"
                            size="small"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.accountnumber}
                            error={
                              touched.accountnumber &&
                              Boolean(errors.accountnumber)
                            }
                            helperText={
                              touched.accountnumber &&
                              errors.accountnumber?.toString()
                            }
                          />
                        </Stack>
                      </Stack>
                      <Stack width={"80%"} margin={"20px auto"}>
                        {values.bankName &&
                        values.priority &&
                        values.ifsc &&
                        values.accountnumber ? (
                          <Button
                            variant="contained"
                            type="submit"
                            sx={{
                              textTransform: "none",
                              background: "rgb(139, 147, 255)",
                              "&:hover": { background: "rgb(139, 147, 255)" },
                            }}
                          >
                            Add
                          </Button>
                        ) : (
                          <Button
                            variant="contained"
                            disabled
                            sx={{
                              textTransform: "none",
                              background: "rgb(139, 147, 255)",
                              "&:hover": { background: "rgb(139, 147, 255)" },
                            }}
                          >
                            Add
                          </Button>
                        )}
                      </Stack>
                    </div>
                  </div>
                </Box>
              </form>
            </>
          )}
        </Formik>
      </ThemeProvider>
    </Modal>
  );
}
