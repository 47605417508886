import React, { useState } from "react";
import classes from "./Tabs.module.scss";

interface Props {
  labels: any;
  onChangeTab: any;
  selectedTab: any;
  recordsCount?: number;
  calledScreen?:any
}

export default function Tabs({
  labels,
  onChangeTab,
  selectedTab,
  recordsCount,
  calledScreen
}: Props) {
  return (
    <div className={classes.tabsWrapper}>
      <div className={classes.tabsCatagory}>
        {labels.map((a: any) => (
          <div
            className={classes.tabsItem}
            onClick={() => onChangeTab(a)}
            style={{
              backgroundColor: selectedTab === a ? "#8B93FF" : "",
              color: selectedTab === a ? "white" : "",
              cursor: "pointer",
              
            }}
          >
            {a} {selectedTab === a && calledScreen === "NewKyc" && `(${recordsCount})`}
          </div>
        ))}
      </div>
    </div>
  );
}
