import React from "react";
import classes from "./Topbar.module.scss";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { setCollapsed } from "../../../Store/Project/ProjectSlice";
import { Avatar, Button, IconButton, Tooltip } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import RoutesLabelJson from "../../../Utils/RoutesLabel.json";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";

const Topbar = () => {
  const dispatch = useDispatch();
  const labels: any = RoutesLabelJson;
  const collapsed = useSelector((state: any) => state.project.collapsed);
  const dashboardScreen = useSelector((state: any) => state.project.dashboard);
  const pathName: string = useLocation().pathname;
  const title: string = labels[pathName];
  const navigate = useNavigate();

  return (
    <>
      <div className={classes.topbarWrapper}>
        <div className={classes.container}>
          <div className={classes.dashTitle}>
            <div className={classes.title}>{title}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Topbar;
