import React, { useState } from "react";
import classes from "./UploadPage.module.scss";
import { useNavigate } from "react-router-dom";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { useDropzone } from "react-dropzone";
import { Button, CircularProgress } from "@mui/material";
import uploadicon from "../../assets/Images/uploadicon.svg";
import { clientUploadApi } from "../../Utils/APICalls";
import { Toaster, toast } from "react-hot-toast";
import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import csvimage from "../../assets/Images/csvimage.svg";

export default function UploadPage() {
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [disableUpload, setdisableUpload] = React.useState(false);
  const [progress, setProgress] = React.useState(0);
  const [uploadloading, setuploadloading] = useState(false);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 0 : prevProgress + 10
      );
    }, 300);
    return () => {
      clearInterval(timer);
    };
  }, [uploadloading]);
  const accepectedFileTypes: any = ".csv";

  const onDrop = (acceptedFilestype: any) => {
    if (acceptedFilestype[0].path.includes(".csv")) {
      setSelectedFile(acceptedFilestype[0]);
      setdisableUpload(true);
    } else {
      setdisableUpload(false);
      setSelectedFile(null);
      setTimeout(() => {
        setdisableUpload(true);
      }, 3000);
    }
  };

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: accepectedFileTypes,
  });

  const files = acceptedFiles.map((file: any) =>
    disableUpload ? (
      selectedFile && <p key={file.path}>{file.path}</p>
    ) : (
      <li key={file.path} style={{ color: "red" }}>
        Only .csv Files are accepected
      </li>
    )
  );

  const requestBody1: any = new FormData();
  requestBody1.append("file", selectedFile);

  const handleUploadFile = () => {
    // const loadingToast = toast.loading("Uploading");
    setuploadloading(true);
    clientUploadApi(requestBody1)
      .then((res) => {
        console.log(res);
        // toast.dismiss(loadingToast);

        setuploadloading(false);
        toast.success("File Uploaded");
        // handleCloseUploadModel();
        navigate("/dashboard/clientadmin");
        // getClientAdminData(page, rowslimit);
      })
      .catch((res) => {
        console.log(res);
        setuploadloading(false);
        // toast.dismiss(loadingToast);
        toast.error("Upload Failed");
      });
  };

  const handleCloseUploadModel = () => {
    // setOpenUpload(false);
    setSelectedFile(null);
    navigate("/dashboard/clientadmin");
  };
  return (
    <>
      <Toaster />
      <div className={classes.wrapper}>
        <div className={classes.crumbs}>
          <span onClick={() => navigate("/dashboard/clientadmin")}>
            All Clients
          </span>{" "}
          &nbsp; / &nbsp; <span className={classes.crumbsLink}>Upload</span>
        </div>
        <div className={classes.mainWrapper}>
          <div className={classes.uploadTextArea}>
            <p className={classes.uploadText}>Please Upload your file</p>
            <p className={classes.uploadsubText}>
              Select relevant documents to add client
            </p>
          </div>

          <section className="container" style={{ width: "60%" }}>
            <div
              {...getRootProps()}
              className={classes.uploadArea}
              style={{ borderColor: "#1C2536" }}
            >
              <div style={{ display: "flex", justifyContent: "center" }}>
                {/* <FileUploadIcon fontSize="large" sx={{ fill: "grey" }} /> */}
                <img src={uploadicon} />
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <input {...getInputProps()} />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <p className={classes.uploadtext1}>
                    Select a file or drag and drop here
                  </p>
                  <p className={classes.uploadtext2}>
                    Select a CSV file to upload or drag and drop
                  </p>
                </div>
              </div>
              <Button
                variant="outlined"
                sx={{
                  width: "160px",
                  background: "#5A6ACF",
                  color: "white",
                  textTransform: "none",
                  boxShadow: "none",
                  margin: "auto",
                  "&:hover": {
                    background: "#5A6ACF",
                    boxShadow: "none",
                    color: "white",
                  },
                }}
              >
                Select File
              </Button>
            </div>
            <aside>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "50px",
                }}
              >
                {selectedFile && <img src={csvimage} />}
                <div>
                  <h4 style={{ marginLeft: "10px", color: "grey" }}>{files}</h4>
                  <div style={{ width: "200%", marginLeft: "10px" }}>
                    {/* {uploadloading && <LinearProgressWithLabel value={progress} />} */}
                    {uploadloading && <span className={classes.loader}></span>}
                  </div>
                </div>
              </div>
            </aside>
          </section>
        </div>
      </div>
      <div className={classes.buttonsection}>
        <Button
          onClick={handleCloseUploadModel}
          variant="outlined"
          sx={{ marginRight: "20px" }}
        >
          Cancel
        </Button>

        <Button
          disabled={!selectedFile}
          onClick={handleUploadFile}
          autoFocus
          variant="contained"
          endIcon={
            uploadloading ? (
              <CircularProgress
                color="inherit"
                style={{ width: 20, height: 20 }}
              />
            ) : (
              ""
            )
          }
        >
          Upload
        </Button>
      </div>
    </>
  );
}

function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number }
) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}
