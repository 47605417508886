import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

interface props {
  rowData: [];
  headerName:string;
}

export default function BasicTable({ rowData,headerName }: props) {
  const rows = rowData;
  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Sr No</TableCell>
            <TableCell align="right">{headerName}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((item, index) => (
            <TableRow
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {index+1}
              </TableCell>
              <TableCell align="right">{item}</TableCell>
            </TableRow>
          ))}
        </TableBody>

    
      </Table>
      {rows.length === 0 && (
          <div
            style={{
              height: "300px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              
              
            }}
          >
           <p style={{fontSize:'16px', fontWeight:'bold', color:'grey'}}>File uploaded successfully, and no duplicate records were found.</p>
          </div>
        )}
    </TableContainer>
  );
}
