import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Box, IconButton, Modal, Stack, Typography } from "@mui/material";
import Button from "@mui/joy/Button";

import DragandDropFile from "../../components/DragAndDrop/DragandDrop";
import CloseIcon from "@mui/icons-material/Close";
import {
  uploadClientInfoAPICall,
  uploadExistingLeadsAPICall,
} from "../../Utils/APICalls";
import SucessSnackBar from "../../components/SnackBar/SuccessSnackBar";
import ErrorSnackBar from "../../components/SnackBar/ErrorSnackBar";
import BasicTable from "../../containers/UploadDemat/DataTable";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

const styleNew = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "55%",
  transform: "translate(-50%, -50%)",
  width: "60vW",
  height: "50vH",
  bgcolor: "background.paper",

  p: 1,
  boxShadow: 24,
  borderRadius: "10px",
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50vW",
  height: "80vH",
  bgcolor: "background.paper",
  overflowY: "scroll",
  p: 1,
  boxShadow: 24,
  borderRadius: "10px",
};

export default function UploadReferralClients() {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState("");
  const [clientinfoUploadErrorMessage, setclientinfoUploadErrorMessage] =
    useState("");
  const [openTableModal, setopenTableModal] = useState(false);
  const [loading, setloading] = useState(false);
  const [duplicateMessage, setduplicateMessage] = useState("");
  const [duplicateData, setDuplicateData] = useState<any>([]);
  let acceptedFormats = ["csv"];

  const handleFileChangeUpload = (event: any) => {
    const file = event[0];
    // console.log(event);
    if (!file) {
      setSelectedFile(null);

      return;
    }
    const fileType = file.type.split("/")[1];

    if (!acceptedFormats.includes(fileType)) {
      setSelectedFile(null);
      setErrorMessage(
        `Invalid file format. Only ${acceptedFormats.join(
          ", "
        )} files are accepted.`
      );
      // console.log("error");
      return;
    }

    setSelectedFile(file);
    setOpen(true);
    setErrorMessage("");
  };

  const handleUpload = () => {
    setloading(true);
    const formData = new FormData();
    const obj: any = {
      file: selectedFile,
    };
    formData.append("file", obj.file);

    uploadExistingLeadsAPICall(formData)
      .then((response: any) => {
        const responseData = response.data;
        setloading(false);
        // console.log(
        //   responseData.data["Duplicate numbers "],
        //   responseData.data.message
        // );
        if (
          responseData.data &&
          responseData.data["Duplicate numbers "] === null
        ) {
          setDuplicateData([]);
          setSuccessMessage(responseData.data.message);
          setopenTableModal(false);
          setTimeout(() => {
            setSuccessMessage("");
          }, 5000);
        }
        if (responseData.data["Duplicate numbers "] && responseData.data["Duplicate numbers "].length) {
          setclientinfoUploadErrorMessage("Duplicate Records Found");
          setDuplicateData(responseData.data["Duplicate numbers "]);
          setopenTableModal(true);

          setTimeout(() => {
            setclientinfoUploadErrorMessage("");
          }, 5000);
        }

        setSelectedFile(null);
      })
      .catch((error: any) => {
        // console.log(error.response.data.error.message);
        setloading(false);
        if(error && error.response.data.error.message){
        setclientinfoUploadErrorMessage(error.response.data.error.message);
        setTimeout(() => {
          setclientinfoUploadErrorMessage("");
        }, 5000);
        }

        setSelectedFile(null);
      });
  };

  const handleCloseModal = () => {
    setopenTableModal(false);
  };
  return (
    <div>
      <Helmet>
        <title>Upload Referral Clients </title>
        <meta name="UploadDemat" content="UploadDemat" />
      </Helmet>
      {selectedFile ? (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          // onClose={handleCloseModal}
          closeAfterTransition
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Box sx={styleNew}>
            <Stack position={"absolute"} right={10}>
              <IconButton
                onClick={() => {
                  setSelectedFile(null);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Stack>
            <Stack
              alignItems={"center"}
              justifyContent={"center"}
              height={"100%"}
            >
              <Typography sx={{ margin: "20px 0" }}>
                {" "}
                {selectedFile && selectedFile.name}
              </Typography>
              <Button
                sx={{ width: "250px" }}
                onClick={handleUpload}
                loading={loading}
              >
                Upload Referral Client
              </Button>
            </Stack>
          </Box>
        </Modal>
      ) : (
        <Stack marginTop={"38px"}>
          <DragandDropFile
            handleFileChangeUpload={handleFileChangeUpload}
            acceptedFormats={acceptedFormats}
            errorMessage={errorMessage}
            selectedFile={selectedFile}
          />
        </Stack>
      )}
      <SucessSnackBar message={successMessage} />
      <ErrorSnackBar message={clientinfoUploadErrorMessage} />
      {duplicateData && (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={openTableModal}
          // onClose={handleCloseModal}
          closeAfterTransition
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Box sx={style}>
            <IconButton onClick={handleCloseModal}>
              <KeyboardBackspaceIcon />
            </IconButton>

            <BasicTable rowData={duplicateData} headerName={"Mobile Number"} />
          </Box>
        </Modal>
      )}
    </div>
  );
}
