import {
  Box,
  Button,
  Fade,
  IconButton,
  Modal,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DataTableComponent from "../../DataTableComponent/DataTableComponent";
import DataTable from "react-data-table-component";
import EditIcon from "@mui/icons-material/Edit";
import FollowUpDataTableComponent from "../../../pages/Followups/FollowUpsDataTable";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";

import {
  GetFollowUpHistoryAPICall,
  addFollowUpDateAPICall,
} from "../../../Utils/APICalls";

import { IAddFollowUpDate } from "../../../Utils/dtos";

interface Props {
  open: boolean;
  handleClose: any;
  handleSubmitData: any;
  data: any;
  followDate?: any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  height: "max-content",
  bgcolor: "background.paper",
  borderRadius: "20px",
  boxShadow: 24,
  p: 2,
};

const titletextStyle = {
  fontSize: "14px",
  fontWeight: "bold",
  color: "#8C8B8F",
};
const contenttextStyle = { fontSize: "12px" };

export default function RekycClientLogsModal({
  open,
  handleClose,
  handleSubmitData,
  data,
  followDate,
}: Props) {
  useEffect(() => {
    const close = (e: any) => {
      if (e.keyCode === 27) {
        handleClose();
      }
    };

    window.addEventListener("keydown", close);

    return () => {
      window.removeEventListener("keydown", close);
    };
  }, []);

  const useStyles = makeStyles({
    table: {
      width: "90%",
    },
  });
  const classes = useStyles();
  const RowStyles = {
    background: "rgb(243, 244, 255)",
    height: "20px",
    fontWeight: 600,
    border: "1px solid grey",
  };
  const formatDate = (dateString: any) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    const hour = date.getHours() % 12 || 12;
    const minute = date.getMinutes().toString().padStart(2, "0");
    const meridian = date.getHours() >= 12 ? "PM" : "AM";

    return `${day}-${month}-${year} ${hour}:${minute} ${meridian}`;
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        closeAfterTransition
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <>
          <Fade in={open}>
            <Box sx={style}>
              <Stack style={{ position: "absolute", top: 0, right: 10 }}>
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Stack>
              <Stack
                marginTop={"20px"}
                width={"100%"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Typography
                  variant="h6"
                  sx={{
                    textAlign: "left",
                    width: "90%",
                    marginBottom: "10px",
                  }}
                >
                  Log Details
                </Typography>
                <Table className={classes.table}>
                  {/* <TableRow style={RowStyles}>
                    <TableCell variant="head" style={RowStyles}>
                      Mobile Verified TS
                    </TableCell>
                    <TableCell style={{ ...RowStyles, fontWeight: 500 }}>
                      {data.MobileVerifiedTS
                        ? data.MobileVerifiedTS === ""
                          ? ""
                          : formatDate(data.MobileVerifiedTS?.slice(0, 16))
                        : ""}
                    </TableCell>
                  </TableRow> */}
                  {/* <TableRow style={RowStyles}>
                    <TableCell variant="head" style={RowStyles}>
                      Email Verified TS
                    </TableCell>
                    <TableCell style={{ ...RowStyles, fontWeight: 500 }}>
                      {data.EmailVerifiedTS
                        ? data.EmailVerifiedTS === ""
                          ? ""
                          : formatDate(data.EmailVerifiedTS?.slice(0, 16))
                        : ""}
                    </TableCell>
                  </TableRow> */}
                  <TableRow style={RowStyles}>
                    <TableCell variant="head" style={RowStyles}>
                      Profile Update TS
                    </TableCell>
                    <TableCell style={{ ...RowStyles, fontWeight: 500 }}>
                      {data.ProfileTS
                        ? data.ProfileTS === ""
                          ? ""
                          : formatDate(data.ProfileTS?.slice(0, 16))
                        : "-"}
                    </TableCell>
                  </TableRow>

                  <TableRow style={RowStyles}>
                    <TableCell variant="head" style={RowStyles}>
                      Digilocker TS
                    </TableCell>
                    <TableCell style={{ ...RowStyles, fontWeight: 500 }}>
                      {data.AadhaarFetchedTS
                        ? data.AadhaarFetchedTS === ""
                          ? ""
                          : formatDate(data.AadhaarFetchedTS?.slice(0, 16))
                        : "-"}
                    </TableCell>
                  </TableRow>

                  <TableRow style={RowStyles}>
                    <TableCell variant="head" style={RowStyles}>
                      KRA Verified TS
                    </TableCell>
                    <TableCell style={{ ...RowStyles, fontWeight: 500 }}>
                      {data.KRAVerifiedTS
                        ? data.KRAVerifiedTS === ""
                          ? ""
                          : formatDate(data.KRAVerifiedTS?.slice(0, 16))
                        : "-"}
                    </TableCell>
                  </TableRow>
                  <TableRow style={RowStyles}>
                    <TableCell variant="head" style={RowStyles}>
                      IPV TS
                    </TableCell>
                    <TableCell style={{ ...RowStyles, fontWeight: 500 }}>
                      {data.IpvTS
                        ? data.IpvTS === ""
                          ? ""
                          : formatDate(data.IpvTS?.slice(0, 16))
                        : "-"}
                    </TableCell>
                  </TableRow>
                  <TableRow style={RowStyles}>
                    <TableCell variant="head" style={RowStyles}>
                      Upload documents TS
                    </TableCell>
                    <TableCell style={{ ...RowStyles, fontWeight: 500 }}>
                      {data.UploadDocumentTS
                        ? data.UploadDocumentTS === ""
                          ? ""
                          : formatDate(data.UploadDocumentTS?.slice(0, 16))
                        : "-"}
                    </TableCell>
                  </TableRow>

                  <TableRow style={RowStyles}>
                    <TableCell variant="head" style={RowStyles}>
                      Esign TS
                    </TableCell>
                    <TableCell style={{ ...RowStyles, fontWeight: 500 }}>
                      {data.EsignTS
                        ? data.EsignTS === ""
                          ? ""
                          : formatDate(data.EsignTS?.slice(0, 16))
                        : "-"}
                    </TableCell>
                  </TableRow>
                  <TableRow style={RowStyles}>
                    <TableCell variant="head" style={RowStyles}>
                      Completed TS
                    </TableCell>
                    <TableCell style={{ ...RowStyles, fontWeight: 500 }}>
                      {data.CompletedTS
                        ? data.CompletedTS === ""
                          ? ""
                          : formatDate(data.CompletedTS?.slice(0, 16))
                        : "-"}
                    </TableCell>
                  </TableRow>
                  <TableRow style={RowStyles}>
                    <TableCell variant="head" style={RowStyles}>
                      Approved TS
                    </TableCell>
                    <TableCell style={{ ...RowStyles, fontWeight: 500 }}>
                      {data.ApprovedTS
                        ? data.ApprovedTS === ""
                          ? ""
                          : formatDate(data.ApprovedTS?.slice(0, 16))
                        : "-"}
                    </TableCell>
                  </TableRow>
                  <TableRow style={RowStyles}>
                    <TableCell variant="head" style={RowStyles}>
                      Rejected TS
                    </TableCell>
                    <TableCell style={{ ...RowStyles, fontWeight: 500 }}>
                      {data.RejectedTS
                        ? data.RejectedTS === ""
                          ? ""
                          : formatDate(data.RejectedTS?.slice(0, 16))
                        : "-"}
                    </TableCell>
                  </TableRow>
                </Table>
              </Stack>
            </Box>
          </Fade>
        </>
      </Modal>
    </div>
  );
}
