import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import trashIcon from "../../assets/Images/trash.png";
import DeleteIcon from "@mui/icons-material/Delete";
import AddNominee from "../Modals/AddNominee/AddNominee";
import { Box, Button, TextField, ThemeProvider } from "@mui/material";
import classes from "./NomineePage.module.scss";
import { Stack } from "@mui/system";
import { toast } from "react-hot-toast";

interface props {
  data?: any;
  updateNominees?: any;
}

const NomineePage = ({ data, updateNominees }: props) => {
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [error, setError] = useState(false);
  const [nominee, setnominee] = useState([
    {
      nominee_id: "",
      full_name: "",
      email: "",
      dob: "",
      mobile: "",
      percentage_share: "",
      relationship: "",
      other_relationship: "",
      guardian: {
        fullname: "",
        dob: "",
        email: "",
        mobile: "",
        relation_with_nominee: "",
        address: "",
        pincode: "",
        city: "",
        state: "",
        country: "",
      },
      id_proof: {
        type: "",
        details: "",
        id_proof_b64: "",
      },
      address: "",
      city: "",
      pincode: "",
      state: "",
      country: "",
    },
  ]);
  useEffect(() => {
    setnominee(data);
  }, []);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const handleChangeInput = (index: any, field: any, value: any) => {
    setnominee((prevNominees: any) => {
      const updatedNominees = [...prevNominees];
      updatedNominees[index] = {
        ...updatedNominees[index],
        [field]: value,
      };
      return updatedNominees;
    });
  };

  const handleUpdateChanges = () => {
    const totalShare = nominee.reduce(
      (acc, current) => acc + parseFloat(current.percentage_share),
      0
    );
    if (totalShare !== 100) {
      toast.error("Percentage Share must total to 100");

      return;
    }
    updateNominees(nominee);
  };

  const handleGuardianChange = (index: any, field: any, value: any) => {
    setnominee((prevNominees) => {
      const updatedNominees = [...prevNominees];
      updatedNominees[index] = {
        ...updatedNominees[index],
        guardian: {
          ...updatedNominees[index].guardian,
          [field]: value,
        },
      };
      return updatedNominees;
    });
  };

  const handleAddNominee = () => {
    setnominee((prevNominees) => [
      ...prevNominees,
      {
        nominee_id: "",
        full_name: "",
        email: "",
        dob: "",
        mobile: "",
        percentage_share: "",
        relationship: "",
        other_relationship: "",
        guardian: {
          fullname: "",
          dob: "",
          email: "",
          mobile: "",
          relation_with_nominee: "",
          address: "",
          pincode: "",
          city: "",
          state: "",
          country: "",
        },
        id_proof: {
          type: "",
          details: "",
          id_proof_b64: "",
        },
        address: "",
        city: "",
        pincode: "",
        state: "",
        country: "",
      },
    ]);
  };

  const handleRemoveNominee = (index: number) => {
    setnominee((prevNominees) => prevNominees.filter((_, i) => i !== index));
  };

  return (
    <>
      <div style={{ marginTop: "1rem" }}>
        {nominee.map((data: any, index: any) => (
          <Accordion
            key={index}
            expanded={expanded === `panel${index + 1}`}
            onChange={handleChange(`panel${index + 1}`)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index + 1}bh-content`}
              id={`panel${index + 1}bh-header`}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Typography sx={{ width: "33%", flexShrink: 0 }}>
                Nominee {index + 1}
              </Typography>
              <Typography sx={{ width: "23%", color: "text.secondary" }}>
                Percentage Share({data.percentage_share}%)
              </Typography>
              {index !== 0 && (
                <DeleteIcon
                  fontSize="small"
                  onClick={() => handleRemoveNominee(index)}
                />
              )}
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <Box>
                  <div className={classes.mainWrapper}>
                    <div className={classes.fieldsWrapper}>
                      <Stack
                        spacing={1.5}
                        sx={{
                          border: "1px dotted #E1E2E3",
                          padding: "20px",
                          borderRadius: "10px",
                        }}
                      >
                        <Stack
                          justifyContent={"space-between"}
                          spacing={2}
                          direction={"row"}
                        >
                          <TextField
                            id="nomineeId"
                            name="nomineeId"
                            label="Id"
                            variant="outlined"
                            size="small"
                            value={index + 1}
                            sx={{
                              input: {
                                color: "#000000",
                                fontSize: "12px",
                                fontWeight: 700,
                              },
                            }}
                          />
                          <TextField
                            id="fullname"
                            fullWidth
                            name="fullname"
                            label="Full Name"
                            variant="outlined"
                            size="small"
                            value={data.full_name}
                            onChange={(e) =>
                              handleChangeInput(
                                index,
                                "full_name",
                                e.target.value
                              )
                            }
                            sx={{
                              input: {
                                color: "#000000",
                                fontSize: "12px",
                                fontWeight: 700,
                              },
                            }}
                          />
                          <TextField
                            id="dob"
                            fullWidth
                            name="dob"
                            label="DOB"
                            variant="outlined"
                            size="small"
                            value={data.dob}
                            onChange={(e) =>
                              handleChangeInput(index, "dob", e.target.value)
                            }
                            sx={{
                              input: {
                                color: "#000000",
                                fontSize: "12px",
                                fontWeight: 700,
                              },
                            }}
                          />
                          <TextField
                            id="number"
                            fullWidth
                            name="number"
                            label="Mobile No."
                            variant="outlined"
                            size="small"
                            value={data.mobile}
                            sx={{
                              input: {
                                color: "#000000",
                                fontSize: "12px",
                                fontWeight: 700,
                              },
                            }}
                          />
                        </Stack>
                        <Stack
                          justifyContent={"space-between"}
                          spacing={2}
                          direction={"row"}
                        >
                          <TextField
                            id="email"
                            fullWidth
                            name="email"
                            label="Email"
                            variant="outlined"
                            size="small"
                            value={data.email}
                            onChange={(e) =>
                              handleChangeInput(index, "email", e.target.value)
                            }
                            sx={{
                              input: {
                                color: "#000000",
                                fontSize: "12px",
                                fontWeight: 700,
                              },
                            }}
                          />
                          <TextField
                            id="relationship"
                            fullWidth
                            name="relationship"
                            label="Relationship"
                            variant="outlined"
                            size="small"
                            onChange={(e) =>
                              handleChangeInput(
                                index,
                                "relationship",
                                e.target.value
                              )
                            }
                            value={data.relationship}
                            sx={{
                              input: {
                                color: "#000000",
                                fontSize: "12px",
                                fontWeight: 700,
                              },
                            }}
                          />
                          <TextField
                            id="share"
                            fullWidth
                            name="share"
                            label="Share"
                            variant="outlined"
                            size="small"
                            value={data.percentage_share}
                            onChange={(e) =>
                              handleChangeInput(
                                index,
                                "percentage_share",
                                e.target.value
                              )
                            }
                            sx={{
                              input: {
                                color: "#000000",
                                fontSize: "12px",
                                fontWeight: 700,
                              },
                            }}
                          />
                        </Stack>
                        <Stack
                          justifyContent={"space-between"}
                          spacing={2}
                          direction={"row"}
                        >
                          <TextField
                            id="address"
                            fullWidth
                            name="address"
                            label="Address"
                            variant="outlined"
                            size="small"
                            value={data.address}
                            onChange={(e) =>
                              handleChangeInput(
                                index,
                                "address",
                                e.target.value
                              )
                            }
                            sx={{
                              input: {
                                color: "#000000",
                                fontSize: "12px",
                                fontWeight: 700,
                              },
                            }}
                          />

                          <TextField
                            id="pincode"
                            fullWidth
                            name="pincode"
                            label="Pincode"
                            variant="outlined"
                            size="small"
                            onChange={(e) =>
                              handleChangeInput(
                                index,
                                "pincode",
                                e.target.value
                              )
                            }
                            value={data.pincode}
                            sx={{
                              input: {
                                color: "#000000",
                                fontSize: "12px",
                                fontWeight: 700,
                              },
                            }}
                          />
                        </Stack>
                        <Stack
                          justifyContent={"space-between"}
                          spacing={2}
                          direction={"row"}
                        >
                          <TextField
                            id="city"
                            fullWidth
                            name="city"
                            label="city"
                            variant="outlined"
                            onChange={(e) =>
                              handleChangeInput(index, "city", e.target.value)
                            }
                            sx={{
                              input: {
                                color: "#000000",
                                fontSize: "12px",
                                fontWeight: 700,
                              },
                            }}
                            size="small"
                            value={data.city}
                          />
                          <TextField
                            id="state"
                            fullWidth
                            name="state"
                            label="state"
                            variant="outlined"
                            size="small"
                            onChange={(e) =>
                              handleChangeInput(index, "state", e.target.value)
                            }
                            value={data.state}
                            sx={{
                              input: {
                                color: "#000000",
                                fontSize: "12px",
                                fontWeight: 700,
                              },
                            }}
                          />
                          <TextField
                            id="country"
                            fullWidth
                            name="country"
                            label="country"
                            variant="outlined"
                            size="small"
                            onChange={(e) =>
                              handleChangeInput(
                                index,
                                "country",
                                e.target.value
                              )
                            }
                            value={data.country}
                            sx={{
                              input: {
                                color: "#000000",
                                fontSize: "12px",
                                fontWeight: 700,
                              },
                            }}
                          />
                        </Stack>
                      </Stack>

                      <Stack
                        spacing={1.5}
                        sx={{
                          border: "1px dotted #E1E2E3",
                          padding: "20px",
                          borderRadius: "10px",
                          marginTop: "10px",
                        }}
                      >
                        <Stack
                          justifyContent={"space-between"}
                          spacing={2}
                          direction={"row"}
                        >
                          <TextField
                            id="gname"
                            fullWidth
                            name="gname"
                            label="Guardian Name"
                            variant="outlined"
                            size="small"
                            value={data.guardian.fullname}
                            onChange={(e) =>
                              handleGuardianChange(
                                index,
                                "fullname",
                                e.target.value
                              )
                            }
                            sx={{
                              input: {
                                color: "#000000",
                                fontSize: "12px",
                                fontWeight: 700,
                              },
                            }}
                          />
                          <TextField
                            id="gdob"
                            fullWidth
                            name="gdob"
                            label="Guardian DOB"
                            variant="outlined"
                            size="small"
                            value={data.guardian.dob}
                            onChange={(e) =>
                              handleGuardianChange(index, "dob", e.target.value)
                            }
                            sx={{
                              input: {
                                color: "#000000",
                                fontSize: "12px",
                                fontWeight: 700,
                              },
                            }}
                          />
                          <TextField
                            id="gemail"
                            fullWidth
                            name="gemail"
                            label="Guardian Email"
                            variant="outlined"
                            size="small"
                            value={data.guardian.email}
                            onChange={(e) =>
                              handleGuardianChange(
                                index,
                                "email",
                                e.target.value
                              )
                            }
                            sx={{
                              input: {
                                color: "#000000",
                                fontSize: "12px",
                                fontWeight: 700,
                              },
                            }}
                          />
                        </Stack>
                        <Stack
                          justifyContent={"space-between"}
                          spacing={2}
                          direction={"row"}
                        >
                          <TextField
                            id="gmobile"
                            fullWidth
                            name="gmobile"
                            label="Guardian No."
                            variant="outlined"
                            size="small"
                            value={data.guardian.mobile}
                            onChange={(e) =>
                              handleGuardianChange(
                                index,
                                "mobile",
                                e.target.value
                              )
                            }
                            sx={{
                              input: {
                                color: "#000000",
                                fontSize: "12px",
                                fontWeight: 700,
                              },
                            }}
                          />
                          <TextField
                            id="grelation"
                            fullWidth
                            name="grelation"
                            label="Guardian Relation"
                            variant="outlined"
                            size="small"
                            value={data.guardian.relation}
                            onChange={(e) =>
                              handleGuardianChange(
                                index,
                                "relation_with_nominee",
                                e.target.value
                              )
                            }
                            sx={{
                              input: {
                                color: "#000000",
                                fontSize: "12px",
                                fontWeight: 700,
                              },
                            }}
                          />
                          <TextField
                            id="gpincode"
                            fullWidth
                            name="gpincode"
                            label="Guardian Pincode"
                            variant="outlined"
                            size="small"
                            value={data.guardian.pincode}
                            onChange={(e) =>
                              handleGuardianChange(
                                index,
                                "pincode",
                                e.target.value
                              )
                            }
                            sx={{
                              input: {
                                color: "#000000",
                                fontSize: "12px",
                                fontWeight: 700,
                              },
                            }}
                          />
                        </Stack>
                        <Stack
                          justifyContent={"space-between"}
                          spacing={2}
                          direction={"row"}
                        >
                          <TextField
                            id="gaddress"
                            fullWidth
                            name="gaddress"
                            label="Guardian Address"
                            variant="outlined"
                            size="small"
                            value={data.guardian.address}
                            onChange={(e) =>
                              handleGuardianChange(
                                index,
                                "address",
                                e.target.value
                              )
                            }
                            sx={{
                              input: {
                                color: "#000000",
                                fontSize: "12px",
                                fontWeight: 700,
                              },
                            }}
                          />
                        </Stack>
                        <Stack
                          justifyContent={"space-between"}
                          spacing={2}
                          direction={"row"}
                        >
                          <TextField
                            id="gcity"
                            fullWidth
                            name="gcity"
                            label="Guardian City"
                            variant="outlined"
                            size="small"
                            value={data.guardian.city}
                            onChange={(e) =>
                              handleGuardianChange(
                                index,
                                "city",
                                e.target.value
                              )
                            }
                            sx={{
                              input: {
                                color: "#000000",
                                fontSize: "12px",
                                fontWeight: 700,
                              },
                            }}
                          />
                          <TextField
                            id="gstate"
                            fullWidth
                            name="gstate"
                            label="Guardian State"
                            variant="outlined"
                            size="small"
                            value={data.guardian.state}
                            onChange={(e) =>
                              handleGuardianChange(
                                index,
                                "state",
                                e.target.value
                              )
                            }
                            sx={{
                              input: {
                                color: "#000000",
                                fontSize: "12px",
                                fontWeight: 700,
                              },
                            }}
                          />
                          <TextField
                            id="gcountry"
                            fullWidth
                            name="gcountry"
                            label="Guardian Country"
                            variant="outlined"
                            size="small"
                            value={data.guardian.country}
                            onChange={(e) =>
                              handleGuardianChange(
                                index,
                                "country",
                                e.target.value
                              )
                            }
                            sx={{
                              input: {
                                color: "#000000",
                                fontSize: "12px",
                                fontWeight: 700,
                              },
                            }}
                          />
                        </Stack>
                      </Stack>
                      <Stack width={"80%"} margin={"20px auto"}></Stack>
                    </div>
                  </div>
                </Box>
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
        {/* {error && (
          <div style={{ color: "red", fontSize: "14px" }}>
            Percentage Share must be 100
          </div>
        )} */}

        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          {nominee.length < 3 && (
            <Button
              id="basic-button"
              variant="contained"
              sx={{
                background: "rgb(139, 147, 255)",
                textTransform:"none",
                m: "1rem",
                "&:hover": {
                  background: "rgb(139, 147, 255)",
                },
              }}
              onClick={handleAddNominee}
            >
              Add Nominee
            </Button>
          )}
          <Button
            id="basic-button"
            variant="contained"
            sx={{
              background: "rgb(139, 147, 255)",
              m: "1rem 1rem 1rem 0rem",
              textTransform:"none",

              width: "fit-content",
              "&:hover": {
                background: "rgb(139, 147, 255)",
              },
            }}
            onClick={() => {
              handleUpdateChanges();
            }}
          >
            Update Changes
          </Button>
        </div>
      </div>
    </>
  );
};

export default NomineePage;
