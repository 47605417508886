import React from "react";
import { Routes, Route, Navigate, useRoutes } from "react-router-dom";

import LoginPage from "../../pages/Login/LoginPage";
import DashboardPage from "../../pages/Dashboard/DashboardPage";
import NewKYCPage from "../../pages/Clients/NewKYCPage";
import ReKYCPage from "../../pages/Clients/ReKYCPage";
import NRIPage from "../../pages/Clients/NRIPage";
import DashboardLayout from "../../containers/Dashboard/Dashboard";
import UsersPage from "../../pages/Users/UsersPage";
import FollowupsPage from "../../pages/Followups/FollowupsPage";
import RolesPage from "../../pages/Roles/RolesPage";
import ForgotPassword from "../../containers/ForgotPassword/ForgotPassword";
import OtpScreen from "../../containers/OtpScreen/OtpScreen";
import ChangePassword from "../../containers/ChangePassword/ChangePassword";
import UploadDemat from "../../containers/UploadDemat/UploadDemat";
import UploadClientDetails from "../../pages/UploadClientDetails/UploadClientDetails";
import UploadReferralClients from "../../pages/UploadClientDetails/UploadReferralClients";
import TariffPage from "../../pages/Tariff/Tariff";
import UploadCMR from "../../pages/UploadCMR/UploadCMR";
import PanAadhaarCheck from "../../pages/PanAadhaarCheck/PanAadhaarCheck";
import PartnerPage from "../../pages/Clients/PartnerPage";
import FinancialUpdate from "../../pages/Clients/FinancialUpdate";
import AccountClosure from "../../pages/Clients/AccountClosure";
import PDFAnnotator from "../../pages/PDFAnnotator/PDFAnnotator";
import PDFAnnotations from "../../pages/PDFAnnotator/PdfEditor/PdfEditor";
import DDPI from "../../pages/Clients/DDPI";
import BankUpdate from "../../pages/Clients/BankUpdate";
import EmailandMobileUpdate from "../../pages/Clients/EmailMobileUpdate";
import NomineeUpdate from "../../pages/Clients/NomineeUpdate";
import SegmentUpdate from "../../pages/Clients/SegmentUpdate";
import LocationLogs from "../../pages/Clients/LocationLogs";
import ClientAdmin from "../../containers/ClientAdminTable/ClientAdminTable";
import UploadPage from "../../containers/UploadPage/UploadPage";
import UploadandClearPage from "../../containers/UploadPage/UploadandClearPage";
import ProfileDetails from "../../containers/ProfileDetails/ProfileDetails";
import Demat from "../../pages/Clients/Demat";

function Router() {
  const routes = useRoutes([
    {
      path: "",
      element: <LoginPage />,
    },
    {
      path: "/forgotPassword",
      element: <ForgotPassword />,
    },
    {
      path: "/otpScreen",
      element: <OtpScreen />,
    },
    {
      path: "/changePassword",
      element: <ChangePassword />,
    },

    {
      path: "/dashboard",
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="analytics" />, index: true },
        {
          path: `analytics`,
          element: <DashboardPage />,
        },
        {
          path: `clients`,
          // element: <NewKYCPage />,
          children: [
            {
              element: <Navigate to="/dashboard/clients/newkyc" />,
              index: true,
            },
            {
              path: `newkyc`,
              element: <NewKYCPage />,
            },
            {
              path: `rekyc`,
              element: <ReKYCPage />,
            },
            {
              path: `partner`,
              element: <PartnerPage />,
            },
            {
              path: `nri`,
              element: <NRIPage />,
            },
          ],
        },
        {
          path: `updates`,
          // element: <NewKYCPage />,
          children: [
            {
              element: <Navigate to="/dashboard/updates/financialupdate" />,
              index: true,
            },

            {
              path: `financialupdate`,
              element: <FinancialUpdate />,
            },
            {
              path: `accountclosure`,
              element: <AccountClosure />,
            },
            {
              path: `ddpi`,
              element: <DDPI />,
            },
            {
              path: `bankupdate`,
              element: <BankUpdate />,
            },
            {
              path: `emailmobileupdate`,
              element: <EmailandMobileUpdate />,
            },
            {
              path: `nomineeupdate`,
              element: <NomineeUpdate />,
            },
            {
              path: `segmentupdate`,
              element: <SegmentUpdate />,
            },
          ],
        },
        {
          path: `users`,
          element: <UsersPage />,
        },
        {
          path: `tariff`,
          element: <TariffPage />,
        },
        {
          path: `pan-aadhaar-check`,
          element: <PanAadhaarCheck />,
        },
        {
          path: `pdfannotator`,
          element: <PDFAnnotator />,
        },
        {
          path: `locationlogs`,
          element: <LocationLogs />,
        },
        {
          path: `clientadmin`,
          element: <ClientAdmin />,
        },
        {
          path: `clientadmin/profile`,
          element: <ProfileDetails />,
        },
        {
          path: `clientadmin/upload`,
          element: <UploadPage />,
        },
        {
          path: `clientadmin/upload&clear`,
          element: <UploadandClearPage />,
        },
        {
          path: `pdfannotatoreditor`,
          element: <PDFAnnotations />,
        },
        {
          path: `followups`,
          element: <FollowupsPage />,
        },
        {
          path: `roles`,
          element: <RolesPage />,
        },
        {
          path: `uploaddemat`,
          element: <UploadDemat />,
        },
        {
          path: `uploadclientdetails`,
          element: <UploadClientDetails />,
        },
        {
          path: `uploadreferralclients`,
          element: <UploadReferralClients />,
        },
        {
          path: `uploadcmr`,
          element: <UploadCMR />,
        },
        {
          path: `demat`,
          element: <Demat />,
        },
      ],
    },
    {
      path: "/clients",
      // element: <DashboardLayout />,
    },
  ]);
  return routes;
}

export default Router;
