import axios, { AxiosResponse } from "axios";

import { ADMINURL } from "./constants";
import {
  IAdminLogin,
  ICreateUser,
  IEditUser,
  IDeleteUser,
  IClientData,
  IClientsData,
  IClientsDocuments,
  IGetOTP,
  IForgetPassword,
  IChangePassword,
  IRejectClientApplication,
  IApproveClientApplication,
  IDeleteClient,
  IAddFollowUpDate,
  IEditFollowUpDate,
  IInsertFollowUpHistory,
  IEditFollowUpHistory,
  IKRARegister,
  IGeoJith,
  ICreateTariffData,
  IEditTariffData,
  IDeleteTariffRecord,
  IRekycDashboardData,
  IRekycClientsDocuments,
  IRekycRejectClientApplication,
  IRekycApproveClientApplication,
  IRekycAssignUser,
  IRekycDeleteClient,
  IPartnerAssignData,
  IGetFinancialUpdateDashboardData,
  IFinancialAssignData,
  IRejectFinancialUpdateApplication,
  IGetNRIDashboardData,
  IGetDocumentEmailMobileUpdate,
  IDeleteClientBankUpdate,
  IRejectApplicationBankUpdate,
  IApproveClientRequestEmailMobileUpdate,
  IAssignUserBankUpdate,
  IClientDataBankUpdate,
  IGetDashboardBankUpdate,
  IAssignUserDDPI,
  IGetDashboardDDPI,
} from "./dtos";

const axiosInterceptor = axios.create({
  baseURL: ADMINURL,
});

axiosInterceptor.interceptors.request.use(
  async (req) => {
    const token = sessionStorage.getItem("access-token");

    req.headers.Authorization = `Bearer ${token}`;
    return req;
  },
  (error) => Promise.reject(error)
);

axiosInterceptor.interceptors.response.use(
  (res) => res,
  (err) => {
    const status = err.response ? err.response.status : null;
    if (status === 401) {
      console.log("Logout", status);
      handleLogout();
    }
    return Promise.reject(err);
  }
);
const handleLogout = () => {
  sessionStorage.clear();
  window.location.href = "/";
};

export const AdminLoginAPICall = (requestBody: IAdminLogin) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/v1/api/admin/login`, requestBody, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

//----------------------------------------------EKYC------------------------------------------------------

export const GetDashBoardDataAPICall = () => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .get(`/v1/api/admin/dashboard_analytics`, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error: any) => {
        console.error(error);
        reject(error);
      });
  });
};

export const GetClientsAPICall = ({
  page_no,
  page_size,
  query,
  type,
  filter_user,
  filter_date_from,
  filter_date_to,
  filter_stage,
  sort_activity,
}: IClientsData) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .get(`/v1/api/admin/dashboard`, {
        params: {
          page_no,
          page_size,
          query,
          type,
          filter_user,
          filter_date_from,
          filter_date_to,
          filter_stage,
          sort_activity,
        },
      })
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error: any) => {
        console.error(error);
        reject(error);
      });
  });
};
export const GetAdminFollowupsAPICall = ({
  page_no,
  page_size,
  query,
  type,
  filter_user,
  filter_date_from,
  filter_date_to,
  filter_stage,
  sort_activity,
}: IClientsData) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .get(`/v1/api/followUp/get_followup_dashboard`, {
        params: {
          page_no,
          page_size,
          query,
          type,
          filter_user,
          filter_date_from,
          filter_date_to,
          filter_stage,
          sort_activity,
        },
      })
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error: any) => {
        console.error(error);
        reject(error);
      });
  });
};
export const GetFollowUpHistoryAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .get(
        `/v1/api/followUp/get_followup_history`,

        { params: { user_id: requestBody.user_id } }
      )
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error: any) => {
        console.error(error);
        reject(error);
      });
  });
};

export const approveClientAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/admin/approve_client`, requestBody, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const AssignClientAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/v1/api/admin/assignadmin/`, requestBody, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const GetLocationLogsAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .get(`/v1/api/admin/location_logs`, {
        params: requestBody,
      })
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error: any) => {
        console.error(error);
        reject(error);
      });
  });
};

// Users API Start
export const CreateUserAPICall = (requestBody: ICreateUser) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/v1/api/admin/create_user`, requestBody, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const EditUserAPICall = (requestBody: IEditUser) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/v1/api/admin/update_user`, requestBody, {
        params: { admin_id: requestBody.user_id },
      })
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const DeletUserAPICall = (requestBody: IDeleteUser) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .delete(`/v1/api/admin/delete_user`, { data: requestBody })
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const permanentDeleteClientAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .delete(`/v1/api/admin/clients/permanent_delete`, { data: requestBody })
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const DeletClientAPICall = (requestBody: IDeleteClient) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .delete(`/v1/api/admin/clients/delete`, { data: requestBody })
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const ListUsersAPICall = () => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .get(`/v1/api/admin/get_all_users`, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error: any) => {
        console.error(error);
        reject(error);
      });
  });
};
// Users API End
export const ProfileDetailsAPICall = () => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/v1/api/admin/profile_details`)
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error: any) => {
        console.error(error);
        reject(error);
      });
  });
};
export const GetClientDetailsAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/v1/api/ekyc/admin/client_data/`, requestBody)
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error: any) => {
        console.error(error);
        reject(error);
      });
  });
};
export const GetClientDOcumentsAPICall = (requestBody: IClientsDocuments) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/v1/api/ekyc/admin/client_document/`, requestBody)
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error: any) => {
        console.error(error);
        reject(error);
      });
  });
};
export const GetOTPAPICall = (requestBody: IGetOTP) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/v1/api/admin/get_otp`, requestBody)
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error: any) => {
        console.error(error);
        reject(error);
      });
  });
};
export const forgetPasswordAPICall = (requestBody: IForgetPassword) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/v1/api/admin/validate_otp`, requestBody)
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error: any) => {
        console.error(error);
        reject(error);
      });
  });
};
export const changePasswordAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/v1/api/admin/new_password`, requestBody)
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error: any) => {
        console.error(error);
        reject(error);
      });
  });
};
export const GetRolesAPICall = () => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .get(`/admin/getroles/`, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error: any) => {
        console.error(error);
        reject(error);
      });
  });
};
export const GetStagesAPICall = () => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .get(`/v1/api/admin/stages`, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error: any) => {
        console.error(error);
        reject(error);
      });
  });
};
export const RejectClientApplicationAPICall = (
  requestBody: IRejectClientApplication
) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/v1/api/ekyc/admin/reject`, requestBody)
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error: any) => {
        console.error(error);
        reject(error);
      });
  });
};
export const ApproveClientApplicationAPICall = (
  requestBody: IApproveClientApplication
) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/v1/api/admin/admin/approve`, requestBody)
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error: any) => {
        console.error(error);
        reject(error);
      });
  });
};

export const uploadDocumentsAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/v1/api/admin/demat/upload_dematid`, requestBody, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const uploadClientInfoAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/admin/uploadclientinfo/`, requestBody, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const uploadExistingLeadsAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/admin/uploadexistingleads/`, requestBody, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const addFollowUpDateAPICall = (requestBody: IAddFollowUpDate) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/admin/addfollowup/`, requestBody, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const editFollowUpDateAPICall = (requestBody: IEditFollowUpDate) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/admin/editfollowupdate/`, requestBody, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const insertFollowUpHistoryAPICall = (
  requestBody: IInsertFollowUpHistory
) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/v1/api/followUp/insert_followup`, requestBody, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const editFollowUpHistoryAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(
        `/v1/api/followUp/followups/edit`,
        { status: requestBody.status, remarks: requestBody.remarks },
        {
          params: {
            followup_id: requestBody.followup_id,
            user_id: requestBody.user_id,
          },
        }
      )
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const kraRegisterAPICall = (requestBody: IKRARegister) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/admin/kraregister`, requestBody, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const geojithAPICall = (requestBody: IGeoJith) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/admin/giojith_api`, requestBody, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const createBokerageDataAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/v1/api/brokerage/create_brokerage_template`, requestBody, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const createTariffDataAPICall = (requestBody: ICreateTariffData) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/v1/api/tariff/create_tariff`, requestBody, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const editBrokerageDataAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(
        `/v1/api/admin/brokerage_templates/edit`,
        { name: requestBody.name, description: requestBody.description },
        {
          params: { brokerage_template_id: requestBody.brokerage_template_id },
        }
      )
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const editTariffDataAPICall = (requestBody: IEditTariffData) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/v1/api/admin/tariffs/edit/`, requestBody, {
        params: { tariff_id: requestBody.tariff_id },
      })
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const GetBrokerageTemplatesAPICall = () => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .get(`/v1/api/admincommon/ekyc_brokerage_values`, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error: any) => {
        console.error(error);
        reject(error);
      });
  });
};
export const getBrokeragedetailsAPICall = () => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .get(`/v1/api/brokerage/get_brokerage_template`, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error: any) => {
        console.error(error);
        reject(error);
      });
  });
};
export const gettariffdetailsAPICall = () => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .get(`/v1/api/tariff/get_tariff`, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error: any) => {
        console.error(error);
        reject(error);
      });
  });
};

export const DeleteBrokerageRecordAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .delete(`/v1/api/admin/brokerage_templates/delete`, {
        params: requestBody,
      })
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const DeletetariffRecordAPICall = (requestBody: IDeleteTariffRecord) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .delete(`/v1/api/tariff/delete_tariff`, {
        params: requestBody,
      })
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const uploadCMRAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/cmr/cmr_upload/`, requestBody, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const GetCMRStatusAPICall = ({ page_no, limit }: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .get(`/cmr/cmr_status/`, {
        params: {
          page_no,
          limit,
        },
      })
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error: any) => {
        console.error(error);
        reject(error);
      });
  });
};

//---------------------------------Client Admin----------------------------------------------------------
export const clientAdminSearch = (query: any, limit: any) => {
  return axiosInterceptor.get(`compositedge/sso/admin/search-client`, {
    params: {
      query: query ? query : "",
      limit: limit,
    },
    headers: {
      Authorization: "Basic UmFqOkZpcnN0b2NrQDIwMjQ=",
    },
  });
};
export const clientAdminTableApi = (skip: any, limit: any, query: any) => {
  return axiosInterceptor.get(`compositedge/sso/admin/get-clients`, {
    params: {
      skip: skip,
      limit: limit,
      query: query ? query : "",
    },
    headers: {
      Authorization: "Basic UmFqOkZpcnN0b2NrQDIwMjQ=",
    },
  });
};
export const clientUploadAndResetApi = (requestBody: any) => {
  return axiosInterceptor.post(`compositedge/firstock/uploadClientMaster`, requestBody, {
    auth: {
      username: "tCTzC54r8bJUCUvh9T6y",
      password: "q5NL8T9zdCiKYv24VwBbRB2rYt4hiRKjVkjCzVZUqxu5hpQii6",
    },
  });
};
export const clientUploadApi = (requestBody: any) => {
  return axiosInterceptor.post(`compositedge/sso/admin/update-upsert`, requestBody, {
    headers: {
      Authorization: "Basic UmFqOkZpcnN0b2NrQDIwMjQ=",
    },
  });
};
export const clientUploadresetApi = () => {
  return axiosInterceptor.get(`compositedge/firstock/resetClientMaster`, {
    auth: {
      username: "tCTzC54r8bJUCUvh9T6y",
      password: "q5NL8T9zdCiKYv24VwBbRB2rYt4hiRKjVkjCzVZUqxu5hpQii6",
    },
  });
};
export const clientAddBankApi = (requestBody: any) => {
  return axiosInterceptor.post(`compositedge/sso/admin/add-bank`, requestBody, {
    headers: {
      Authorization: "Basic UmFqOkZpcnN0b2NrQDIwMjQ=",
    },
  });
};
export const clientAddNomineeApi = (requestBody: any) => {
  return axiosInterceptor.post(`compositedge/sso/admin/add-nominee`, requestBody, {
    headers: {
      Authorization: "Basic UmFqOkZpcnN0b2NrQDIwMjQ=",
    },
  });
};
export const clientEditApi = (requestBody: any) => {
  return axiosInterceptor.post(`compositedge/sso/admin/edit-client-manually`, requestBody, {
    headers: {
      Authorization: "Basic UmFqOkZpcnN0b2NrQDIwMjQ=",
    },
  });
};

// ----------------------------RE KYC --------------------------------------------------------------------

export const GetRekycDashboardAPICall = ({
  page_no,
  page_size,
  query,
  type,
  filter_user,
  filter_stage,
  sort_activity,
}: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .get(`/v1/api/rekyc/admin/dashboard`, {
        params: {
          page_no,
          page_size,
          query,
          type,
          filter_user,
          filter_stage,
          sort_activity,
        },
      })
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error: any) => {
        console.error(error);
        reject(error);
      });
  });
};
export const GetPanAadhaarAPICall = ({ pan_no }: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .get(`/admin/pan_aadhaar_seeding`, {
        params: {
          pan_no,
        },
      })
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error: any) => {
        console.error(error);
        reject(error);
      });
  });
};

export const GetRekycclientDataAPICall = (user_id: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/v1/api/rekyc/admin/client_data/`, user_id, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error: any) => {
        console.error(error);
        reject(error);
      });
  });
};

export const GetRekycClientDocumentsAPICall = (
  requestBody: IRekycClientsDocuments
) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/v1/api/rekyc/admin/client_document/`, requestBody)
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error: any) => {
        console.error(error);
        reject(error);
      });
  });
};

export const GetRekycStagesAPICall = () => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .get(`/v1/api/rekyc/admin/stages`, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error: any) => {
        console.error(error);
        reject(error);
      });
  });
};

export const RekycRejectClientApplicationAPICall = (
  requestBody: IRekycRejectClientApplication
) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/v1/api/rekyc/admin/reject`, requestBody)
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error: any) => {
        console.error(error);
        reject(error);
      });
  });
};

export const RekycApproveClientApplicationAPICall = (
  requestBody: IRekycApproveClientApplication
) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/v1/api/rekyc/admin/approve`, requestBody)
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error: any) => {
        console.error(error);
        reject(error);
      });
  });
};

export const GetRekycclientLogsDataAPICall = ({ rekyc_client_id }: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .get(`/rekyc/getlogs`, {
        params: {
          rekyc_client_id,
        },
      })
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error: any) => {
        console.error(error);
        reject(error);
      });
  });
};

export const RekycAssignUserAPICall = (requestBody: IRekycAssignUser) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/v1/api/rekyc/admin/assignadmin/`, requestBody, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const RekycDeleteClientAPICall = (requestBody: IRekycDeleteClient) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .delete(`/v1/api/rekyc/admin/delete`, { data: requestBody })
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error: any) => {
        console.error(error);
        reject(error);
      });
  });
};
export const RekycpermanentDeleteClientAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .delete(`/v1/api/rekyc/admin/permanent_delete`, { data: requestBody })
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const AddClientAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/v1/api/rekyc/add_client`, requestBody)
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error: any) => {
        console.error(error);
        reject(error);
      });
  });
};

// -------------------------------------------------- Partner ---------------------------------------------------------------

export const GetPartnerdashBoardAPICall = ({
  page_no,
  page_size,
  query,
  type,
  filter_user,
  filter_date_from,
  filter_date_to,
  filter_stage,
  sort_activity,
}: IClientsData) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .get(`/admin/partner/get_dashboard_data/`, {
        params: {
          page_no,
          page_size,
          query,
          type,
          filter_user,
          filter_date_from,
          filter_date_to,
          filter_stage,
          sort_activity,
        },
      })
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error: any) => {
        console.error(error);
        reject(error);
      });
  });
};

export const AssignPartnerAPICall = (requestBody: IPartnerAssignData) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/admin/partner/assign_client/`, requestBody, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const GetPartnerStagesAPICall = () => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .get(`/admin/partner/get_stages`, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error: any) => {
        console.error(error);
        reject(error);
      });
  });
};

export const GetPartnerDetailsAPICall = (requestBody: IClientData) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/admin/partner/get_client_data`, requestBody)
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error: any) => {
        console.error(error);
        reject(error);
      });
  });
};

export const RejectPartnerApplicationAPICall = (
  requestBody: IRejectClientApplication
) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/admin/partner/reject_client/`, requestBody)
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error: any) => {
        console.error(error);
        reject(error);
      });
  });
};

export const ApprovePartnerApplicationAPICall = (
  requestBody: IApproveClientApplication
) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/admin/partner/approve_client/`, requestBody)
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error: any) => {
        console.error(error);
        reject(error);
      });
  });
};

export const permanentDeletePartnerAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .delete(`/admin/partner/permanent_delete_client/`, { data: requestBody })
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const GetPartnerDocumentsAPICall = (requestBody: IClientsDocuments) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/admin/partner/clientdocument`, requestBody)
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error: any) => {
        console.error(error);
        reject(error);
      });
  });
};
export const DeletPartnerClientAPICall = (requestBody: IDeleteClient) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .delete(`/admin/partner/delete_client/`, { data: requestBody })
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

//----------------------------------------------NRI -----------------------------------------------

export const GetNRIDashboardAPICall = ({
  page_no,
  page_size,
  query,
  type,
  filter_user,
  filter_date_from,
  filter_date_to,
  filter_stage,
  sort_activity,
}: IGetNRIDashboardData) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .get(`/api/V1/nri/get_dashboard_data/`, {
        params: {
          page_no,
          page_size,
          query,
          type,
          filter_user,
          filter_date_from,
          filter_date_to,
          filter_stage,
          sort_activity,
        },
      })
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error: any) => {
        console.error(error);
        reject(error);
      });
  });
};
export const GetNRIStagesAPICall = () => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .get(`/api/V1/nri/get_stages`, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error: any) => {
        console.error(error);
        reject(error);
      });
  });
};
export const AssignNRIClientAPICall = (requestBody: IPartnerAssignData) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/api/V1/nri/assign_client/`, requestBody, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const GetNRIDetailsAPICall = (requestBody: IClientData) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/api/V1/nri/get_client_data`, requestBody)
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error: any) => {
        console.error(error);
        reject(error);
      });
  });
};
export const GetNRIClientDocumentsAPICall = (
  requestBody: IClientsDocuments
) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/api/V1/nri/clientdocument`, requestBody)
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error: any) => {
        console.error(error);
        reject(error);
      });
  });
};
export const RejectNRIApplicationAPICall = (
  requestBody: IRejectClientApplication
) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/api/V1/nri/reject_client/`, requestBody)
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error: any) => {
        console.error(error);
        reject(error);
      });
  });
};
export const DeleteNRIClientAPICall = (requestBody: IDeleteClient) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .delete(`/api/V1/nri/delete_client/`, { data: requestBody })
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const PermanentDeleteNRIClientAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .delete(`/api/V1/nri/permanent_delete_client/`, { data: requestBody })
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const ApproveNRIApplicationAPICall = (
  requestBody: IApproveClientApplication
) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/api/V1/nri/approve_client/`, requestBody)
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error: any) => {
        console.error(error);
        reject(error);
      });
  });
};

//-------------------------------------------------------------Financial Update-------------------------------------------------------

export const GetFinancialUpdateDashboardAPICall = ({
  page_no,
  page_size,
  query,
  type,
  filter_user,
  filter_date_from,
  filter_date_to,
  sort_activity,
}: IGetFinancialUpdateDashboardData) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .get(`/v1/api/financial_update/admin/dashboard`, {
        params: {
          page_no,
          page_size,
          query,
          type,
          filter_user,
          filter_date_from,
          filter_date_to,
          sort_activity,
        },
      })
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error: any) => {
        console.error(error);
        reject(error);
      });
  });
};

export const AssignFinancilaUserAPICall = (
  requestBody: any
) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/v1/api/financial_update/admin/assignadmin/`, requestBody, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const GetFinancialUpdateClientDataAPICall = (
  requestBody: any
) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/v1/api/financial_update/admin/client_data/`, requestBody)
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error: any) => {
        console.error(error);
        reject(error);
      });
  });
};
export const GetFinancialUpdateDocumentsAPICall = (
  requestBody: any
) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/v1/api/financial_update/admin/client_document/`, requestBody)
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error: any) => {
        console.error(error);
        reject(error);
      });
  });
};

export const DeleteFinancialUpdateClientAPICall = (
  requestBody: any
) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .delete(`/v1/api/financial_update/admin/delete`, { data: requestBody })
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const PermanentDeleteFinancialUpdateClientAPICall = (
  requestBody: any
) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .delete(`/v1/api/financial_update/admin/permanent_delete`, {
        data: requestBody,
      })
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const ApproveFinancialUpdateApplicationAPICall = (
  requestBody: any
) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/v1/api/financial_update/admin/approve`, requestBody)
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error: any) => {
        console.error(error);
        reject(error);
      });
  });
};
export const RejectFinancialUpdateApplicationAPICall = (
  requestBody: any
) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/v1/api/financial_update/admin/reject`, requestBody)
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error: any) => {
        console.error(error);
        reject(error);
      });
  });
};

//----------------------------------------------Account Closure -----------------------------------------------

export const GetAccountClosureDashboardAPICall = ({
  page_no,
  page_size,
  query,
  type,
  filter_user,
  filter_date_from,
  filter_date_to,
  sort_activity,
}: IGetFinancialUpdateDashboardData) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .get(`/v1/api/segment_update/admin/dashboard`, {
        params: {
          page_no,
          page_size,
          query,
          type,
          filter_user,
          filter_date_from,
          filter_date_to,
          sort_activity,
        },
      })
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error: any) => {
        console.error(error);
        reject(error);
      });
  });
};

export const AssignAccountClosureUserAPICall = (
  requestBody: any
) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/v1/api/segment_update/admin/assignadmin/`, requestBody, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const GetAccountClosureClientDataAPICall = (
  requestBody: any
) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/v1/api/segment_update/admin/client_data/`, requestBody)
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error: any) => {
        console.error(error);
        reject(error);
      });
  });
};
export const ApproveAccountClosureApplicationAPICall = (
  requestBody: any
) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/v1/api/segment_update/admin/approve`, requestBody)
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error: any) => {
        console.error(error);
        reject(error);
      });
  });
};
export const DeleteAccountClosureClientAPICall = (
  requestBody: any
) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .delete(`/v1/api/segment_update/admin/delete`, { data: requestBody })
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const GetAccountClosureDocumentsAPICall = (
  requestBody: any
) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/v1/api/segment_update/admin/client_document/`, requestBody)
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error: any) => {
        console.error(error);
        reject(error);
      });
  });
};
export const RejectAccountClosureApplicationAPICall = (
  requestBody: any
) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/v1/api/segment_update/admin/reject`, requestBody)
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error: any) => {
        console.error(error);
        reject(error);
      });
  });
};
export const PermanentDeleteAccountClosureClientAPICall = (
  requestBody: any
) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .delete(`/v1/api/segment_update/admin/permanent_delete`, {
        data: requestBody,
      })
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

//------------------------------------------------DDPI---------------------------------------------------------

export const GetDashboardDDPIAPICall = ({
  page_no,
  page_size,
  query,
  type,
  filter_user,
  filter_date_from,
  filter_date_to,
  sort_activity,
}: IGetDashboardDDPI) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .get(`/admin/ddpi/get_dashboard_data/`, {
        params: {
          page_no,
          page_size,
          query,
          type,
          filter_user,
          filter_date_from,
          filter_date_to,
          sort_activity,
        },
      })
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error: any) => {
        console.error(error);
        reject(error);
      });
  });
};
export const AssignUserDDPIAPICall = (requestBody: IAssignUserDDPI) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/admin/ddpi/assign_client/`, requestBody, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const GetClientDataDDPIAPICall = (requestBody: IClientData) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/admin/ddpi/get_client_data`, requestBody)
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error: any) => {
        console.error(error);
        reject(error);
      });
  });
};
export const GetDocumentsDDPIAPICall = (requestBody: IClientsDocuments) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/admin/ddpi/clientdocument`, requestBody)
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error: any) => {
        console.error(error);
        reject(error);
      });
  });
};
export const DeleteClientDDPIAPICall = (requestBody: IDeleteClient) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .delete(`/admin/ddpi/delete_client/`, { data: requestBody })
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const PermanentDeleteClientDDPIAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .delete(`/admin/ddpi/permanent_delete_client/`, { data: requestBody })
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const ApproveApplicationDDPIAPICall = (
  requestBody: IApproveClientApplication
) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/admin/ddpi/approve_client/`, requestBody)
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error: any) => {
        console.error(error);
        reject(error);
      });
  });
};
export const RejectApplicationDDPIAPICall = (
  requestBody: IRejectFinancialUpdateApplication
) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/admin/ddpi/reject_client/`, requestBody)
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error: any) => {
        console.error(error);
        reject(error);
      });
  });
};

//------------------------------------------------Bank Update---------------------------------------------------------

export const GetDashboardBankUpdateAPICall = ({
  page_no,
  page_size,
  query,
  type,
  filter_user,
  filter_date_from,
  filter_date_to,
  sort_activity,
}: IGetDashboardBankUpdate) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .get(`/v1/api/bank_update/admin/dashboard`, {
        params: {
          page_no,
          page_size,
          query,
          type,
          filter_user,
          filter_date_from,
          filter_date_to,
          sort_activity,
        },
      })
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error: any) => {
        console.error(error);
        reject(error);
      });
  });
};
export const AssignUserBankUpdateAPICall = (
  requestBody: any
) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/v1/api/bank_update/admin/assignadmin/`, requestBody, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const GetClientDataBankUpdateAPICall = (
  requestBody: any
) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/v1/api/bank_update/admin/client_data/`, requestBody)
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error: any) => {
        console.error(error);
        reject(error);
      });
  });
};
export const GetDocumentsBankUpdateAPICall = (
  requestBody: any
) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/v1/api/bank_update/admin/client_document/`, requestBody)
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error: any) => {
        console.error(error);
        reject(error);
      });
  });
};
export const DeleteClientBankUpdateAPICall = (
  requestBody: any
) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .delete(`/v1/api/bank_update/admin/delete`, { data: requestBody })
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const PermanentDeleteClientBankUpdateAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .delete(`/v1/api/bank_update/admin/permanent_delete`, {
        data: requestBody,
      })
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const ApproveApplicationBankUpdateAPICall = (
  requestBody: any
) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/v1/api/bank_update/admin/approve`, requestBody)
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error: any) => {
        console.error(error);
        reject(error);
      });
  });
};
export const RejectApplicationBankUpdateAPICall = (
  requestBody: any
) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/v1/api/bank_update/admin/reject`, requestBody)
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error: any) => {
        console.error(error);
        reject(error);
      });
  });
};

//------------------------------------------------Email & mobile Update---------------------------------------------------------
export const GetDashboardEMUpdateAPICall = ({
  page_no,
  page_size,
  query,
  type,
  filter_user,
  filter_date_from,
  filter_date_to,
  sort_activity,
}: IGetDashboardBankUpdate) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .get(`/v1/api/login_update/admin/dashboard`, {
        params: {
          page_no,
          page_size,
          query,
          type,
          filter_user,
          filter_date_from,
          filter_date_to,
          sort_activity,
        },
      })
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error: any) => {
        console.error(error);
        reject(error);
      });
  });
};
export const GetClientDataEmailMobileUpdateAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/v1/api/login_update/admin/client_data/`, requestBody)
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error: any) => {
        console.error(error);
        reject(error);
      });
  });
};
export const AssignUserEmailMobileUpdateAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/v1/api/login_update/admin/assignadmin/`, requestBody, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const ApproveApplicationEmailMobileUpdateAPICall = (
  requestBody: any
) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/v1/api/login_update/admin/approve`, requestBody)
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error: any) => {
        console.error(error);
        reject(error);
      });
  });
};
export const RejectApplicationEmailMobileUpdateAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/v1/api/login_update/admin/reject`, requestBody)
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error: any) => {
        console.error(error);
        reject(error);
      });
  });
};
export const GetRequestListEmailMobileUpdateAPICall = () => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .get(`/v1/api/login_update/logs`, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error: any) => {
        console.error(error);
        reject(error);
      });
  });
};
export const DeleteClientEmailMobileUpdateAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .delete(`/v1/api/login_update/admin/delete`, { data: requestBody })
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const PermanentDeleteClientEmialMobileAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .delete(`/v1/api/login_update/admin/permanent_delete`, {
        data: requestBody,
      })
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const GetDocumentsEmailMobileUpdateAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/v1/api/login_update/admin/client_document/`, requestBody)
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error: any) => {
        console.error(error);
        reject(error);
      });
  });
};

//------------------------------------------------Nominee Update---------------------------------------------------------
export const GetDashboardNomineeUpdateAPICall = ({
  page_no,
  page_size,
  query,
  type,
  filter_user,
  filter_date_from,
  filter_date_to,
  sort_activity,
}: IGetDashboardBankUpdate) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .get(`/v1/api/nominee_update/admin/dashboard`, {
        params: {
          page_no,
          page_size,
          query,
          type,
          filter_user,
          filter_date_from,
          filter_date_to,
          sort_activity,
        },
      })
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error: any) => {
        console.error(error);
        reject(error);
      });
  });
};
export const GetClientDataNomineeUpdateAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/v1/api/nominee_update/admin/client_data/`, requestBody)
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error: any) => {
        console.error(error);
        reject(error);
      });
  });
};
export const AssignUserEmailNomineeUpdateAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/v1/api/nominee_update/admin/assignadmin/`, requestBody, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const ApproveApplicationNomineeUpdateAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/v1/api/nominee_update/admin/approve`, requestBody)
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error: any) => {
        console.error(error);
        reject(error);
      });
  });
};
export const RejectApplicationNomineeUpdateAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/v1/api/nominee_update/admin/reject`, requestBody)
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error: any) => {
        console.error(error);
        reject(error);
      });
  });
};
export const DeleteClientNomineeUpdateAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .delete(`/v1/api/nominee_update/admin/delete`, { data: requestBody })
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const PermanentDeleteClientNomineeUpdateAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .delete(`/v1/api/nominee_update/admin/permanent_delete`, {
        data: requestBody,
      })
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const GetDocumentsNomineeUpdateAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/v1/api/nominee_update/admin/client_document/`, requestBody)
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error: any) => {
        console.error(error);
        reject(error);
      });
  });
};
export const GetRequestListNomineeUpdateAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/api/V1/nominee_updation/request_list/`, requestBody)
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error: any) => {
        console.error(error);
        reject(error);
      });
  });
};

//-------------------------------------------------Segment Update -------------------------------------------------------
export const GetDashboardSegmentUpdateAPICall = ({
  page_no,
  page_size,
  query,
  type,
  filter_user,
  filter_date_from,
  filter_date_to,
  sort_activity,
}: IGetDashboardBankUpdate) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .get(`/v1/api/segment_update/admin/dashboard`, {
        params: {
          page_no,
          page_size,
          query,
          type,
          filter_user,
          filter_date_from,
          filter_date_to,
          sort_activity,
        },
      })
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error: any) => {
        console.error(error);
        reject(error);
      });
  });
};
export const GetClientDataSegmentUpdateAPICall = (
  requestBody: any
) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/v1/api/segment_update/admin/client_data/`, requestBody)
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error: any) => {
        console.error(error);
        reject(error);
      });
  });
};
export const AssignUserSegmentUpdateAPICall = (
  requestBody: any
) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/v1/api/segment_update/admin/assignadmin/`, requestBody, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const ApproveApplicationSegmentUpdateAPICall = (
  requestBody: any
) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/v1/api/segment_update/admin/approve`, requestBody)
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error: any) => {
        console.error(error);
        reject(error);
      });
  });
};
export const RejectApplicationSegmentUpdateAPICall = (
  requestBody: any
) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/v1/api/segment_update/admin/reject`, requestBody)
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error: any) => {
        console.error(error);
        reject(error);
      });
  });
};
export const DeleteClientSegmentUpdateAPICall = (
  requestBody: any
) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .delete(`/v1/api/segment_update/admin/delete`, { data: requestBody })
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const PermanentDeleteClientSegmentUpdateAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .delete(`/v1/api/segment_update/admin/permanent_delete`, {
        data: requestBody,
      })
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const GetDocumentsSegmentUpdateAPICall = (
  requestBody: any
) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/v1/api/segment_update/admin/client_document/`, requestBody)
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error: any) => {
        console.error(error);
        reject(error);
      });
  });
};
export const GetRequestListSegmentUpdateAPICall = (requestBody: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .post(`/api/V1/segments_updation/request_list/`, requestBody)
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error: any) => {
        console.error(error);
        reject(error);
      });
  });
};

//----------------------------------------------DEMAT---------------------------------------------------
export const GetDashboardDematAPICall = ({
  page_no,
  page_size,
  type,
  filter_date_from,
  filter_date_to,
  sort_activity,
}: any) => {
  return new Promise((resolve, reject) => {
    axiosInterceptor
      .get(`/v1/api/admin/dp_data/dashboard`, {
        params: {
          page_no,
          page_size,
          type,
          filter_date_from,
          filter_date_to,
          sort_activity,
        },
      })
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((error: any) => {
        console.error(error);
        reject(error);
      });
  });
};
