import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import styles from "./PdfAnnotator.module.scss";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import DragandDropFilePdfAnnotations from "../../components/DragAndDropPdfAnnotations/DragandDropPdfAnnotations";
import { useDropzone } from "react-dropzone";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setselectedfile } from "../../Store/Project/ProjectSlice";

function PDFAnnotator() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [selectedFile, setSelectedFile] = React.useState<[] | any>([]);

  const onDrop = (acceptedFilestype: any) => {
    const files = acceptedFilestype.map((a: any) => a.path);
    let AllFiles: any = [...selectedFile, ...acceptedFilestype];

    function hasDuplicates(array: any) {
      const uniqueSet = new Set(array);
      return uniqueSet.size !== array.length;
    }

    const duplicates: any = hasDuplicates(AllFiles.map((a: any) => a.path));

    if (selectedFile) {
      setSelectedFile((prev: any) => [...prev, ...acceptedFilestype]);
    } else {
      setSelectedFile(acceptedFilestype);
    }
  };
  let formats: any = ["jpg"];

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: formats,
    // disabled: selectedFile,
    multiple: true,
  });

  useEffect(() => {
    if (selectedFile.length > 0) {
      dispatch(setselectedfile(selectedFile));
      navigate("/dashboard/pdfannotatoreditor");
    }
  }, [selectedFile]);
  return (
    <>
      <Helmet>
        <title>PDF Annotator </title>
        <meta name="PDF Annotator" content="PDF Annotator" />
      </Helmet>
      <div className={styles.mainWrapper}>
        <div className={styles.uploadContainer}>
          <DragandDropFilePdfAnnotations
            getRootProps={getRootProps}
            getInputProps={getInputProps}
            acceptedFormats={formats}
            errorMessage={errorMessage}
            selectedFile={selectedFile}
          />
        </div>
        <div className={styles.recentFilesContainer}>
          <p className={styles.recentText}>Recent Annotated Files</p>
          <div className={styles.dataContainer}>
            <div className={styles.dataLeftContainer}>
              <div className={styles.icon}>
                <DescriptionOutlinedIcon />
              </div>
              <div className={styles.details}>
                <p className={styles.fileName}>Loremipsum.pdf</p>
                <p className={styles.fileSize}>2 MB</p>
              </div>
            </div>
            <div className={styles.dataRightContainer}>
              <div className={styles.reEditButton}>Re Edit</div>
              <div className={styles.removeButton}>Remove</div>
            </div>
          </div>
          <div className={styles.dataContainer}>
            <div className={styles.dataLeftContainer}>
              <div className={styles.icon}>
                <DescriptionOutlinedIcon />
              </div>
              <div className={styles.details}>
                <p className={styles.fileName}>Loremipsum.pdf</p>
                <p className={styles.fileSize}>2 MB</p>
              </div>
            </div>
            <div className={styles.dataRightContainer}>
              <div className={styles.reEditButton}>Re Edit</div>
              <div className={styles.removeButton}>Remove</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PDFAnnotator;
