import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import DataTableComponent from "../../components/DataTableComponent/DataTableComponent";
import { Button, Icon, IconButton, Stack } from "@mui/material";
import DeleteIcon from "../../assets/Images/userdeleteicon.svg";
import EditIcon from "../../assets/Images/userediticon.svg";
import {
  CreateUserAPICall,
  DeletUserAPICall,
  EditUserAPICall,
  ListUsersAPICall,
} from "../../Utils/APICalls";
import UserFormModalpopup from "../../components/Modals/UserFormModalpopup";
import { IDeleteUser, IEditUser } from "../../Utils/dtos";
import UserDeleteUserDeleteFormModalpopup from "../../components/Modals/UserDeleteFormModalpopup";
import ErrorSnackBar from "../../components/SnackBar/ErrorSnackBar";
import SucessSnackBar from "../../components/SnackBar/SuccessSnackBar";
import styles from "./UsersPage.module.scss";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import SearchIcon from "@mui/icons-material/Search";

const titletextStyle = {
  fontSize: "14px",
  fontWeight: "bold",
  color: "#0F1419",
  cursor: "pointer",
};
const contenttextStyle = { fontSize: "12px", color: "#555555" };
export default function UsersPage() {
  const columns = [
    {
      name: <b style={titletextStyle}>Name</b>,
      selector: "emailTitle",
      sortable: true,
      cell: (row: any) => <b style={contenttextStyle}>{row.full_name}</b>,
    },
    {
      name: <b style={titletextStyle}>Email</b>,
      selector: "publishDate",
      sortable: true,
      cell: (row: any) => <b style={contenttextStyle}>{row.email}</b>,
    },
    {
      name: <b style={titletextStyle}>Mobile</b>,
      selector: "sent",
      sortable: true,
      cell: (row: any) => <b style={contenttextStyle}>{row.mobile}</b>,
    },

    // {
    //   name: <b style={titletextStyle}>Role</b>,
    //   selector: "unsubscribedRate",
    //   sortable: true,
    //   cell: (row: any) => <b style={contenttextStyle}>{"Role"}</b>,
    // },
    {
      name: <b style={titletextStyle}>Admin</b>,
      selector: "clickThroughRate",
      sortable: true,
      cell: (row: any) => (
        <b style={contenttextStyle}>{row.is_admin ? "Yes" : "No"}</b>
      ),
      width: "100px",
    },
    {
      name: <b style={titletextStyle}>Active</b>,
      selector: "deliveredRate",
      sortable: true,
      width: "100px",
      cell: (row: any) => (
        <b style={contenttextStyle}>{row.is_active ? "Yes" : "No"}</b>
      ),
    },
    {
      name: <b style={titletextStyle}>Actions</b>,
      selector: "spamReportRate",
      sortable: false,
      allowOverflow: true,
      button: true,
      width: "100px",
      cell: (row: any) => (
        <b style={contenttextStyle}>
          <Stack direction={"row"} gap={"10px"}>
            <img
              src={EditIcon}
              style={{ cursor: "pointer" }}
              onClick={() => {
                handleEdit(row);
              }}
            />
            <img
              src={DeleteIcon}
              style={{ cursor: "pointer" }}
              onClick={() => {
                handleDelete(row);
              }}
            />
          </Stack>
        </b>
      ),
    },
  ];

  const [data, setdata] = useState([]);
  const [loading, setloading] = useState(false);
  const [userAddErrorMessage, setUserAdderrorMessage] = useState("");
  const [searchData, setSearchData] = useState("");
  const [openUserModal, setopenUserModal] = useState({
    status: false,
    deleteStatus: false,
    data: {},
    user_id: "",
  });
  const [message, setmessage] = useState("");
  const [addUserMessage, setAddUserMessage] = useState("");
  const [totalRecords, setTotalRecords] = useState("");
  const [recordsCount, setRecordsCount] = useState(0);
  const [paginationCount, setpaginationCount] = useState(1);
  const [pagenumber, setPageNumber] = useState(1);

  useEffect(() => {
    getUsers();
  }, []);
  const getUsers = () => {
    setloading(true);
    ListUsersAPICall()
      .then((response: any) => {
        setloading(false);
        const responseData = response.data;
        if (responseData.data.data) {
          // const filteredData = responseData.data.users.filter(
          //   (a: any) => a.is_deleted === false
          // );
          setdata(responseData.data.data);
          setTotalRecords(responseData.data.total_count);
          setRecordsCount(responseData.data.total_count);
        }
      })
      .catch((error) => {
        setloading(false);
      });
  };
  const handleEdit = (data: any) => {
    //  console.log("editData", data);
    let obj: IEditUser = {
      is_active: data.is_active,
      is_admin: data.is_admin,
      mobile: data.mobile,
      email: data.email,
      user_id: data.admin_id,
      full_name: data.full_name,
    };
    handleUserEditModalOpen(obj);
  };
  const handleDelete = (data: any) => {
    handleDeleteModalPopup(data);
  };

  // User Modal Popup Start

  const handleUserModalOpen = () => {
    setopenUserModal({
      status: true,
      deleteStatus: false,
      data: {},
      user_id: "",
    });
  };
  const handleUserEditModalOpen = (data: IEditUser) => {
    setopenUserModal({
      status: true,
      deleteStatus: false,
      data: {
        ...data,
      },
      user_id: "",
    });
  };
  const handleUserModalClose = () => {
    setopenUserModal({
      status: false,
      deleteStatus: false,
      data: {},
      user_id: "",
    });
  };
  const handleUserModalSubmit = (data: any) => {
    if (Object.keys(openUserModal.data).length) {
      EditUserAPICall({
        ...data,
        user_code: "a83c8e56-07b5-4974-a817-0fe40f2aacb3",
      })
        .then((response) => {
          handleUserModalClose();
          getUsers();
          setAddUserMessage("Changes Saved Successfully");
          setTimeout(() => {
            setAddUserMessage("");
          }, 5000);
        })
        .catch((error) => {
          // handleUserModalClose();
          if (error.response.data.message) {
            setUserAdderrorMessage(error.response.data.message);
            setTimeout(() => {
              setUserAdderrorMessage("");
            }, 5000);
          }
        });
    } else {
      console.log("data", data);
      CreateUserAPICall({
        ...data,
        admin_id: data.email.split("@")[0],
      })
        .then((response: any) => {
          handleUserModalClose();
          getUsers();
          setAddUserMessage("User Created Successfully");
          setTimeout(() => {
            setAddUserMessage("");
          }, 5000);
        })
        .catch((error) => {
          //  console.log("error", error.response.data.error.message);
          if (error.response.data.message) {
            setUserAdderrorMessage(error.response.data.message);
            setTimeout(() => {
              setUserAdderrorMessage("");
            }, 5000);
          }
          // setUserAdderrorMessage(error)
          // handleUserModalClose();
        });
    }
  };

  const handleDeleteModalPopup = (data: any) => {
    // console.log("data", data)
    setopenUserModal({
      status: false,
      deleteStatus: true,
      data: {},
      user_id: data.admin_id,
    });
  };

  const handleDeleteUser = (data: IDeleteUser) => {
    handleUserModalClose();
    DeletUserAPICall(data)
      .then((response: any) => {
        getUsers();
        setAddUserMessage("User deleted Successfully");
        setTimeout(() => {
          setAddUserMessage("");
        }, 5000);
      })
      .catch((error: any) => {
        if (error.response.data.message) {
          setUserAdderrorMessage(error.response.data.message);
          setTimeout(() => {
            setUserAdderrorMessage("");
          }, 5000);
        }
      });
  };
  const handlePageChange = (value: any, page: any) => {
    // console.log(page);
    setpaginationCount(page);
    setPageNumber(page);
  };
  // User Modal Popup End

  const filterdata = data.filter((a: any) => {
    return (
      a.email.toLowerCase().includes(searchData.toLowerCase()) ||
      a.mobile.toLowerCase().includes(searchData.toLowerCase()) ||
      a.full_name.toLowerCase().includes(searchData.toLowerCase())
    );
    // ? a.PartnerName.toLowerCase().includes(search.toLowerCase())
    // : data;
  });
  return (
    <>
      <Helmet>
        <title>Users </title>
        <meta name="Users" content="Users" />
      </Helmet>
      <div className={styles.mainWrapper}>
        <div className={styles.topContainer}>
          <div>
            <Button
              variant="contained"
              startIcon={<AddBoxOutlinedIcon />}
              onClick={handleUserModalOpen}
              sx={{
                background: "#8B93FF",
                boxShadow: "none",
                textTransform: "none",
                "&:hover": {
                  background: "#8B93FF",
                  boxShadow: "none",
                },
              }}
            >
              Add User
            </Button>
          </div>
          <div className={styles.searchInput}>
            <div className={styles.searchIcon}>
              <SearchIcon style={{ color: "#8B93FF" }} />
            </div>
            <input
              type="text"
              className={styles.inputBox}
              placeholder="Search...."
              value={searchData}
              onChange={(e) => {
                setSearchData(e.target.value);
              }}
            />
          </div>
        </div>
        <div className={styles.tableContainer}>
          <DataTableComponent
            columns={columns}
            data={filterdata}
            loading={loading}
            pagination={false}
            handlePageChange={handlePageChange}
            recordsCount={recordsCount}
          />
        </div>
      </div>
      <ErrorSnackBar message={userAddErrorMessage} />
      <SucessSnackBar message={addUserMessage} />
      <UserFormModalpopup
        open={openUserModal.status}
        handleClose={handleUserModalClose}
        handleSubmitData={handleUserModalSubmit}
        data={
          Object.keys(openUserModal.data).length
            ? openUserModal.data
            : undefined
        }
      />
      <UserDeleteUserDeleteFormModalpopup
        open={openUserModal.deleteStatus}
        handleClose={handleUserModalClose}
        handleSubmitData={handleDeleteUser}
        data={openUserModal.user_id}
      />
    </>
  );
}
