import * as React from "react";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { Formik } from "formik";
import {
  Stack,
  Fade,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  ThemeProvider,
  Container,
  FormHelperText,
  Typography,
  Box,
  Button,
  InputAdornment,
  IconButton,
} from "@mui/material";
import {

    TariffDeleteScheme,
  UserDeleteScheme,

} from "../../../Schemas/Schemas";


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

interface Props {
  open: boolean;
  handleClose: any;
  handleSubmitData: any;
  data: string;
}

export default function TariffDeleteModal({
  open,
  handleClose,
  data,
  handleSubmitData,
}: Props) {
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <BootstrapDialog

      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        Delete Tariff
      </BootstrapDialogTitle>

      <Formik
        validationSchema={TariffDeleteScheme}
        initialValues={{
          
          deleted_reason: "",
        }}
        onSubmit={(values) => {
         // console.log("values" , values)
          handleSubmitData(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <>
          
            <form noValidate onSubmit={handleSubmit}>
              <DialogContent dividers>
                <Box component="form" noValidate>
                  <Stack spacing={2} width={500}>
                    <Stack
                      justifyContent={"space-between"}
                      spacing={2}
                      direction={"row"}
                    >
                      <TextField
                        id="deleted_reason"
                        fullWidth
                        label="Reason for Deletion"
                        variant="outlined"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.deleted_reason}
                        error={
                          touched.deleted_reason &&
                          Boolean(errors.deleted_reason)
                        }
                        helperText={
                          touched.deleted_reason &&
                          errors.deleted_reason?.toString()
                        }
                      />
                    </Stack>
                  </Stack>
                </Box>
              </DialogContent>
              <DialogActions>
                <Button type="submit">Save changes</Button>
              </DialogActions>
            </form>
          </>
        )}
      </Formik>
    </BootstrapDialog>
  );
}
