import React, { useEffect, useState } from "react";
import {
  Stack,
  Button,
  TextField,
  Modal,
  Fade,
  Box,
  IconButton,
} from "@mui/material";
import { FileUploader } from "react-drag-drop-files";
import styles from "./DrapandDropPdfAnnotations.module.scss";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import ErrorSnackBar from "../SnackBar/ErrorSnackBar";

interface Props {
  getInputProps: any;
  acceptedFormats: any;
  errorMessage: string;
  selectedFile: any;
  getRootProps: any;
}

export default function DragandDropFilePdfAnnotations({
  getInputProps,
  acceptedFormats,
  errorMessage,
  selectedFile,
  getRootProps,
}: Props) {
  const [file, setFile] = useState<any>(null);
  const [fileErrorMessage, setFileErrorMessage] = useState("");
  const handleChange = (file: any) => {
    setFile(file);
  };

  return (
    <div className={styles.mainWrapper}>
      <div className={styles.wrapper}>
        <div
          {...getRootProps()}
          style={{
            border: "2px dotted rgba(0, 0, 0, 0.25)",
            borderRadius: "10px",
            width: "100%",

            padding: "30px 0px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <input {...getInputProps()} />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CloudUploadOutlinedIcon
              style={{ fontSize: "80px", color: "rgba(0, 0, 0, 0.4)" }}
            />
            <p className={styles.UploadText}>
              Upload Your PDF Document for Annotation
            </p>
            <p className={styles.desc}>
              Select a .{acceptedFormats} file to upload or drag and drop (Max.
              File size: 10 MB){" "}
            </p>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                marginTop: "30px",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  width: "200px",
                  background: "#5A6ACF",
                  textTransform: "none",
                  boxShadow: "none",
                  "&:hover": {
                    background: "#5A6ACF",
                    boxShadow: "none",
                  },
                }}
              >
                Select File
              </Button>
            </div>
          </div>
        </div>
      </div>
      <ErrorSnackBar message={fileErrorMessage} />
    </div>
  );
}
