import React, { useEffect, useState } from "react";
import classes from "./ClientAdminTable.module.scss";
import { deepOrange, deepPurple } from "@mui/material/colors";
import DataTable from "react-data-table-component";
import Avatar from "@mui/material/Avatar";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import MailIcon from "@mui/icons-material/Mail";
import SearchIcon from "@mui/icons-material/Search";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useDropzone } from "react-dropzone";
import {
  clientAdminSearch,
  clientAdminTableApi,
  clientUploadAndResetApi,
  clientUploadApi,
} from "../../Utils/APICalls";
import StyledLoader from "../../components/Styledloader/StyledLoader";
import { Button, Stack, ThemeProvider } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { Pagination } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Toaster, toast } from "react-hot-toast";
import phoneimage from "../../assets/Images/phonevector.svg";
import emailimage from "../../assets/Images/emailvector.svg";
import { theme } from "../../components/ThemeProvider/ThemeProvider";
import PersonIcon from "@mui/icons-material/Person";

let randomClr = Math.floor(Math.random() * 16777215).toString(16);

// const filteredData = data.filter((email) =>
// email.tradeCode.toLowerCase().includes(query)  || email.tradeCode.toUpperCase().includes(query)
// );

const ClientAdmin = () => {
  const [selectedFile, setSelectedFile] = React.useState<any>(null);
  const accepectedFileTypes: any = ".csv";

  const [openUpload, setOpenUpload] = React.useState(false);
  const [query, setQuery] = useState("");
  const [search, setSearch] = useState([]);
  const [data, setData] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [rowslimit, setRowsLimit] = useState(30);
  const [loading, setloading] = useState(false);
  const [paginationCount, setpaginationCount] = useState(1);
  const [totalRows, setTotalRows] = useState(1);
  const [totalClients, settotalClients] = useState(0);
  const [disableUpload, setdisableUpload] = React.useState(false);
  const [clear, setclear] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const startIndex = (currentPage - 1) * 10;
  const endIndex = startIndex + 10;
  const currentData: any = data.slice(startIndex, endIndex);

  useEffect(() => {
    window.scrollTo(0, 0);
    getClientAdminData(startIndex, endIndex, query);
  }, [currentPage, query]);

  useEffect(() => {
    query !== "" && clientSearch();
  }, [query]);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    // handleUpload()
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleUpload = () => {
    navigate("/dashboard/clientadmin/upload");
    // setAnchorEl(null);
    // setOpenUpload(true);
  };
  const handleCloseUploadModel = () => {
    setOpenUpload(false);
    setSelectedFile(null);
  };
  const handleClearandUpload = () => {
    navigate("/dashboard/clientadmin/upload&clear");
    // setAnchorEl(null);
    // setOpenUpload(true);
    // setclear(true);
  };
  const onDrop = (acceptedFilestype: any) => {
    if (acceptedFilestype[0].path.includes(".csv")) {
      setSelectedFile(acceptedFilestype[0]);
      setdisableUpload(true);
    } else {
      setdisableUpload(false);
      setSelectedFile(null);
      setTimeout(() => {
        setdisableUpload(true);
      }, 3000);
    }
  };

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: accepectedFileTypes,
  });
  const requestBody1 = new FormData();
  requestBody1.append("file", selectedFile);

  const handleUploadFile = () => {
    const loadingToast = toast.loading("Uploading");
    clientUploadApi(requestBody1)
      .then((res) => {
        console.log(res);
        toast.dismiss(loadingToast);
        toast.success("File Uploaded");
        handleCloseUploadModel();
        getClientAdminData(currentPage, rowslimit, "");
      })
      .catch((res) => {
        console.log(res);
        toast.dismiss(loadingToast);
        toast.error("Upload Failed");
      });
  };
  const handleUploadClearFile = () => {
    alert("Are you sure clear and Upload data?");
    const loadingToast = toast.loading("Uploading");
    clientUploadAndResetApi(requestBody1)
      .then((res) => {
        console.log(res);
        toast.dismiss(loadingToast);
        toast.success("File Uploaded");
        handleCloseUploadModel();
        getClientAdminData(currentPage, rowslimit, "");
      })
      .catch((res) => {
        console.log(res);
        toast.dismiss(loadingToast);
        toast.error("Upload Failed");
      });
    setclear(false);
  };

  const clientSearch = () => {
    clientAdminSearch(query, 10)
      .then((res) => {
        setSearch(res.data?.client_codes);
      })
      .catch(() => setSearch([]));
  };

  const handleRowClick = (row: any) => {
    getClientDetails(row.client_code);
  };

  const getClientDetails = (row: any) => {
    sessionStorage.setItem("id", row);
    navigate("/dashboard/clientadmin/profile");
    // dispatch(setProfile(res.data.data));
  };

  const getClientAdminData = (page: any, rowslimit: any, query: any) => {
    setloading(true);
    clientAdminTableApi(page, rowslimit, query?.toUpperCase())
      .then((res) => {
        setData(res.data.data.client_details);
        // console.log(res.data.total_pages);
        setTotalRows(Math.ceil(res.data.data.total_clients / 20));
        settotalClients(res.data.data.total_clients);
        setloading(false);
      })
      .catch((res) => {
        console.log(res.data);
        setloading(false);
      });
  };

  const handlePageChange = (event: any, value: any) => {
    setCurrentPage(value);
  };

  const columns: any = [
    {
      name: (
        <b style={{ fontSize: "15px", fontWeight: "bolder", color: "#2D3748" }}>
          Username
        </b>
      ),

      selector: "client_info.client_name",
      sortable: true,
      width: "20rem",
      cell: (row: any) => (
        <>
          <div
            className={classes.userNameWrap}
            onClick={() => {
              handleRowClick(row);
            }}
          >
            <Avatar
              sx={{
                width: 34,
                height: 34,
                bgcolor: "#8b93ffb8",
                fontSize: "16px",
                p: "0px",
              }}
            >
              {row.client_info.client_name?.toUpperCase().slice(0, 2)}
            </Avatar>
            <div className={classes.userName}>
              <h5
                style={{ fontSize: "13px", color: "#4F5B67", fontWeight: 600 }}
              >
                {row.client_info.client_name}
              </h5>
              <div
                className={classes.location}
                style={{ color: "#4F5B67", fontWeight: 500 }}
              >
                {row.client_info.account_open_date}
              </div>
            </div>
          </div>
        </>
      ),
    },
    {
      name: (
        <b style={{ fontSize: "15px", fontWeight: "bolder", color: "#2D3748" }}>
          Tradecode
        </b>
      ),
      selector: "client_code",
      sortable: true,
      width: "125px",
      cell: (row: any) => (
        <b
          style={{ fontSize: "13px", color: "#2D3748", fontWeight: 600 }}
          onClick={() => {
            handleRowClick(row);
          }}
        >
          {row.client_code}
        </b>
      ),
    },
    {
      name: (
        <b style={{ fontSize: "15px", fontWeight: "bolder", color: "#2D3748" }}>
          Contact
        </b>
      ),
      selector: "email_id",
      sortable: true,
      width: "20rem",
      cell: (row: any) => (
        <div
          className={classes.contact}
          onClick={() => {
            handleRowClick(row);
          }}
        >
          <h5
            style={{ fontSize: "13px", color: "#4F5B67", fontWeight: 700 }}
            className={classes.contactMail}
          >
            {" "}
            <img src={emailimage} />
            {row.email_id == "" ? "-" : row.email_id}
          </h5>
          <div className={classes.contactPhone}>
            <img src={phoneimage} />
            {row.mobile_no == "" ? "-" : row.mobile_no}
          </div>
        </div>
      ),
    },

    {
      name: (
        <b style={{ fontSize: "15px", fontWeight: "bolder", color: "#2D3748" }}>
          BO Id
        </b>
      ),
      selector: "client_info.boid",
      sortable: true,
      cell: (row: any) => (
        <b
          style={{ fontSize: "13px" }}
          onClick={() => {
            handleRowClick(row);
          }}
        >
          {row.client_info.boid === "" ? "-" : row.client_info.boid}
        </b>
      ),
    },

    {
      name: (
        <b style={{ fontSize: "15px", fontWeight: "bolder", color: "#2D3748" }}>
          Status
        </b>
      ),
      selector: "client_status.account_status",
      sortable: true,
      cell: (row: any) => (
        <div
          className={
            row.client_status.account_status === "A"
              ? classes.statusGreen
              : row.client_status.account_status === "F"
              ? classes.statusOrange
              : classes.status
          }
          onClick={() => {
            handleRowClick(row);
          }}
        >
          <b style={{ fontSize: "13px" }}>
            {row.client_status.account_status === "A"
              ? "Active"
              : row.client_status.account_status === "F"
              ? "Freeze"
              : "Inactive"}
          </b>
        </div>
      ),
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#F3F6FA",
        // display: "flex",
        // justifyContent: "center",
        // alignItems: "center",
      },
    },

    headRow: {
      style: {
        borderBottom: "none",
        marginBottom: "10px",
      },
    },
    header: {
      style: {
        fontSize: "16px",
        minHeight: "38px",
        paddingLeft: "5px",
        paddingRight: "5px",
      },
    },
    contextMenu: {
      style: {
        fontSize: "14px",
        fontWeight: 400,
        paddingLeft: "16px",
        paddingRight: "8px",
        transform: "translate3d(0, -100%, 0)",
        transitionDuration: "125ms",
        transitionTimingFunction: "cubic-bezier(0, 0, 0.2, 1)",
        willChange: "transform",
      },
      activeStyle: {
        transform: "translate3d(0, 0, 0)",
      },
      rows: {
        style: {
          cursor: "pointer",
          fontSize: "13px",
          fontWeight: 400,
          backgroundColor: "red",
          minHeight: "48px",
          "&:not(:last-of-type)": {
            borderBottomStyle: "solid",
            borderBottomWidth: "1px",
          },
        },
      },
    },
    cells: {
      style: {
        paddingTop: "16px",
        paddingBottom: "16px",
        wordBreak: "break-word",
        cursor: "pointer",
      },
      draggingStyle: {},
    },
  };

  const files = acceptedFiles.map((file: any) =>
    disableUpload ? (
      selectedFile && (
        <li key={file.path}>
          {file.path} - {file.size} bytes
        </li>
      )
    ) : (
      <li key={file.path} style={{ color: "red" }}>
        Only .csv Files are accepected
      </li>
    )
  );
  return (
    <>
      <ThemeProvider theme={theme}>
        <Toaster />
        <div className={classes.wrapper}>
          <div className={classes.topbar}>
            <div>
              <Button
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                variant="contained"
                sx={{
                  background: "#8B93FF",

                  "&:hover": {
                    background: "#8B93FF",
                  },
                }}
              >
                Upload
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                sx={{ marginTop: "10px" }}
              >
                <MenuItem onClick={handleUpload}>General Upload</MenuItem>
                <MenuItem onClick={handleUpload}>
                  Nominee Changes upload
                </MenuItem>
                <MenuItem onClick={handleClearandUpload}>
                  Bank Acc changes uplaod
                </MenuItem>
              </Menu>
            </div>
            <div>Total Clients: {totalClients}</div>
            <div
              style={{
                fontSize: "14px",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={() => {
                window.location.reload();
              }}
            >
              Update Table
            </div>
            <div className={classes.searchInput}>
              <div style={{ display: "flex" }}>
                <div className={classes.searchIcon}>
                  <SearchIcon sx={{ fill: "black" }} />
                </div>

                <input
                  type="text"
                  className={classes.inputBox}
                  value={query?.toUpperCase()}
                  placeholder="Search using Trade Code...."
                  onChange={(e) => setQuery(e.target.value)}
                />
              </div>
              {search?.length > 0 && query !== "" && (
                <>
                  <div
                    style={{ background: "white" }}
                    className={classes.searchSuggestWrap}
                  >
                    {search.map((searchItem, index) => (
                      <div
                        className={classes.searchSuggestItem}
                        onClick={() => {
                          getClientDetails(searchItem);
                        }}
                      >
                        {searchItem}
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>
          <div className={classes.tablewrapper}>
            <DataTable
              columns={columns}
              data={currentData}
              // fixedHeader
              customStyles={customStyles}
              progressPending={loading}
              progressComponent={<StyledLoader />}
              onRowClicked={(row) => {
                handleRowClick(row);
              }}
            />
          </div>
          <Stack
            alignItems={"center"}
            // marginTop={data.length >= 9 ? "10px" : "300px"}
            marginTop={"20px"}
            marginBottom={"20px"}
          >
            <Pagination
              onChange={handlePageChange}
              page={currentPage}
              count={totalRows}
              variant="outlined"
              shape="rounded"
            />
          </Stack>
        </div>

        <Dialog
          open={openUpload}
          // onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title"></DialogTitle>
          <DialogContent>
            <section className="container">
              <div
                {...getRootProps()}
                className={classes.uploadArea}
                style={{ borderColor: "grey" }}
              >
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <FileUploadIcon fontSize="large" sx={{ fill: "grey" }} />
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <input {...getInputProps()} />
                  <p style={{ color: "#000000" }}>
                    Select a CSV file to Upload or Drag and Drop
                  </p>
                </div>
                <Button
                  variant="outlined"
                  sx={{
                    width: "max-content",
                    margin: "auto",
                    color: "#0F91D2",
                    textTransform: "none",
                    borderColor: "#0F91D2",
                    "&:hover": {
                      borderColor: "#0F91D2",
                    },
                  }}
                >
                  Select File
                </Button>
              </div>
              <aside>
                <h4>{files}</h4>
              </aside>
            </section>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseUploadModel}>Cancel</Button>
            {!clear ? (
              <>
                <Button
                  disabled={!selectedFile}
                  onClick={handleUploadFile}
                  autoFocus
                >
                  Upload
                </Button>
              </>
            ) : (
              <Button
                disabled={!selectedFile}
                onClick={handleUploadClearFile}
                autoFocus
              >
                Upload
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </>
  );
};

export default ClientAdmin;
