import * as React from "react";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { Formik } from "formik";
import {
  Stack,
  Fade,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  ThemeProvider,
  Container,
  FormHelperText,
  Typography,
  Box,
  Button,
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

import { TodayFollowUpScheme } from "../../../Schemas/Schemas";
import { AddFollowUp } from "../../../Utils/InitialValues";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import dayjs, { Dayjs } from "dayjs";
// import { styled } from '@mui/system';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

interface Props {
  openModal: boolean;
  handleClose: any;
  geojithResponseData: any;
}

const statusData = [
  "Positive",
  "Call back",
  "Come back",
  "No response",
  "Hard to convert",
  "DND",
  "Negative",
];

export default function GeojithResponseModalpopup({
  openModal,
  handleClose,
  geojithResponseData,
}: Props) {
  const blue = {
    100: "#DAECFF",
    200: "#b6daff",
    400: "#3399FF",
    500: "#007FFF",
    600: "#0072E5",
    900: "#003A75",
  };

  const grey = {
    50: "#f6f8fa",
    100: "#eaeef2",
    200: "#d0d7de",
    300: "#afb8c1",
    400: "#8c959f",
    500: "#6e7781",
    600: "#57606a",
    700: "#424a53",
    800: "#32383f",
    900: "#24292f",
  };

  const StyledTextarea = styled(TextareaAutosize)(
    ({ theme }) => `
    width: 100%;
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 12px;
    border-radius: 12px 12px 0 12px;
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
    box-shadow: 0px 2px 2px ${
      theme.palette.mode === "dark" ? grey[900] : grey[50]
    };
  
    &:hover {
      border-color: ${blue[400]};
    }
  
    &:focus {
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${
        theme.palette.mode === "dark" ? blue[500] : blue[200]
      };
    }
  
    // firefox
    &:focus-visible {
      outline: 0;
    }
  `
  );

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={openModal}
    >
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        Response Data
      </BootstrapDialogTitle>
      <Stack width={500} padding={"20px 50px"}>
      <Stack direction={"row"}>
        <Typography>ErrorCode</Typography> :{" "}
        {geojithResponseData.errorCode && geojithResponseData.errorCode}
      </Stack>
      <Stack direction={"row"}>
        <Typography>Status</Typography> :{" "}
        {geojithResponseData.resultSet.length &&
          geojithResponseData.resultSet[0][0].Status}
      </Stack>
      <Stack direction={"row"}>
        <Typography>Response Message</Typography> :{" "}
        {geojithResponseData.resultSet.length &&
          geojithResponseData.resultSet[0][0].ResponseMessage}
      </Stack>
      <Stack direction={"row"}>
        <Typography>Error Message</Typography> :{" "}
        {geojithResponseData.errorMsg && geojithResponseData.errorMsg}
      </Stack>
      </Stack>
    </BootstrapDialog>
  );
}
