import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  IApproveClientRequestEmailMobileUpdate,
  IClientDataBankUpdate,
  IDeleteClientBankUpdate,
  IRejectApplicationBankUpdate,
} from "../../../Utils/dtos";
import {
  Stack,
  Fade,
  Box,
  IconButton,
  Modal,
  Divider,
  Tooltip,
} from "@mui/material";
import Button from "@mui/joy/Button";
import {
  ApproveApplicationEmailMobileUpdateAPICall,
  GetClientDataEmailMobileUpdateAPICall,
  GetRekycStagesAPICall,
  RejectApplicationEmailMobileUpdateAPICall,
  GetRequestListEmailMobileUpdateAPICall,
  DeleteClientEmailMobileUpdateAPICall,
} from "../../../Utils/APICalls";
import Styles from "./EmailMobileUpdateDetailspopup.module.scss";
import Tabs from "../../Tabs/Tabs";
import ErrorSnackBar from "../../SnackBar/ErrorSnackBar";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteClient from "../DeleteClient/DeleteClient";
import SucessSnackBar from "../../SnackBar/SuccessSnackBar";
import EmailMobileUpdateBase64ToImage from "../../EmailMobileUpdateBase64/EmailMobileUpdateBase64";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import VerticalTabs from "../../Tabs/TabsVeritical";
import BankUpdateReject from "../RejectComponent/BankUpdateReject";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "96%",
  height: "95%",
  bgcolor: "background.paper",
  borderRadius: "20px",
  boxShadow: 24,
  p: 2,
};

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

interface Props {
  open: boolean;
  handleClose: any;
  handleSubmitData: any;
  data: string;
  rejectedReason?: any;
  deletedReason?: any;
  selectedTabNew?: string;
  handlePermanentDeleteClient?: any;
  clientDeleted: boolean;
  stageName?: any;
  clientCompleted: boolean;
}

export default function EmailMobileUpdateDetailspopup({
  open,
  handleClose,
  data,
  handleSubmitData,
  rejectedReason,
  selectedTabNew,
  deletedReason,
  handlePermanentDeleteClient,
  clientDeleted,
  clientCompleted,
  stageName,
}: Props) {
  // const [open, setOpen] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [openDeletePopUp, setDeletePopUp] = useState(false);
  const [clientData, setClientData] = useState<any>();

  const [selectedTab, setselectedTab] = useState<string>("Esign");
  const [successMessage, setSuccessMessage] = useState("");

  const [tabLabels, setTabLabels] = useState(["Esign", "IPV"]);
  const [docType, setDocType] = useState("e_sign_pdf");

  const [stagesData, setStagesData] = useState<any>([]);
  const [rejectLoading, setRejectLoading] = useState(false);
  const [approveLoading, setApproveLoading] = useState(false);
  const [requestList, setRequestList] = useState([]);

  useEffect(() => {
    getDetails();

    GetRekycStagesAPICall()
      .then((response: any) => {
        const responseData = response.data;
        const sortedStages = responseData.sort(
          (a: any, b: any) => a.Sequence - b.Sequence
        );

        setStagesData(sortedStages);
      })
      .catch((error: any) => {
        //  console.log("error", error);
      });
  }, []);

  useEffect(() => {
    sessionStorage.removeItem(`${data}_e_sign_pdf`);
    sessionStorage.removeItem(`${data}_webcam_client_photo`);
  }, [handleClose]);

  // console.log(data);
  const isAdmin = sessionStorage.getItem("isAdmin");
  const getDetails = () => {
    const obj: any = {
      user_id: data,
    };
    GetClientDataEmailMobileUpdateAPICall(obj)
      .then((response: any) => {
        console.log("responseclient", response.data.data);
        setClientData(response.data.data);
        // GetRequestList(response.data.client_data.ExistingDetails.ClientCode);
      })
      .catch((error: any) => {});
  };

  const GetRequestList = (clientcode: any) => {
    GetRequestListEmailMobileUpdateAPICall()
      .then((response: any) => {
        // console.log("requestlist", response.data.data[0]);
        setRequestList(response.data.data[0]);
      })
      .catch((error: any) => {});
  };

  const handleRejectClient = (reject: any) => {
    //  console.log("data", reject);

    let obj: any = {
      user_id: data,
      rejected_reason: reject.reasonForRejection,
    };
    setRejectLoading(true);
    RejectApplicationEmailMobileUpdateAPICall(obj)
      .then((response: any) => {
        const responseData = response.data;
        // console.log("responseData", responseData);
        setRejectLoading(false);
        handleClose("Client Rejected");
      })
      .catch((error: any) => {
        setRejectLoading(false);
        if (error.response.data.message) {
          seterrorMessage(error.response.data.message);
          setTimeout(() => {
            seterrorMessage("");
          }, 5000);
        }
        // console.log("error", error);
      });
  };
  const handleApproveClient = () => {
    let obj: any = {
      user_id: data,
    };
    setApproveLoading(true);
    ApproveApplicationEmailMobileUpdateAPICall(obj)
      .then((response: any) => {
        const responseData = response.data;
        //  console.log("responseData", responseData);
        setApproveLoading(false);
        handleClose("Client Approved");
      })
      .catch((error: any) => {
        // console.log("error", error);
        setApproveLoading(false);
        if (error.response.data.message) {
          seterrorMessage(error.response.data.message);
          setTimeout(() => {
            seterrorMessage("");
          }, 5000);
        }
      });
  };

  const handleChangeTab = (tab: any) => {
    //  console.log(tab);
    setselectedTab(tab);

    if (tab === "Esign") {
      setDocType("e_sign_pdf");
    }
    if (tab === "IPV") {
      setDocType("webcam_client_photo");
    }
  };

  const handleOpenDeletePopUp = () => {
    setDeletePopUp(true);
  };
  const handleCloseDeletepopUp = () => {
    setDeletePopUp(false);
  };

  const handleDeleteClient = (dataDelete: any) => {
    let obj: any = {
      user_id: data,
      deleted_reason: dataDelete.deleted_reason,
    };
    DeleteClientEmailMobileUpdateAPICall(obj)
      .then((response: any) => {
        const responseData = response.data;
        // console.log("responseData", responseData);
        setDeletePopUp(false);
        handleClose("Deleted");
        setSuccessMessage("Client Deleted Successfully");
        setTimeout(() => {
          setSuccessMessage("");
        }, 5000);
      })
      .catch((error: any) => {
        // console.log("error", error);
        setDeletePopUp(false);
        //  handleClose("Deleted");
        if (error.response.data.message) {
          seterrorMessage(error.response.data.message);
          setTimeout(() => {
            seterrorMessage("");
          }, 5000);
        }
      });
  };

  const close = (e: any) => {
    if (e.keyCode === 27) {
      handleClose();
    }
  };

  const convertToFormattedDateTimetoTextField = (dateTimeString: any) => {
    const dateTime = new Date(dateTimeString);

    const day = dateTime.getDate();
    const month = dateTime.getMonth() + 1; // Months are zero-indexed
    const year = dateTime.getFullYear();

    let hours = dateTime.getHours();
    let minutes = dateTime.getMinutes();
    const period = hours >= 12 ? "PM" : "AM";

    // Convert to 12-hour format
    if (hours > 12) {
      hours -= 12;
    } else if (hours === 0) {
      hours = 12;
    }

    return `${day < 10 ? "0" : ""}${day}-${
      month < 10 ? "0" : ""
    }${month}-${year} ${hours}:${minutes < 10 ? "0" : ""}${minutes} ${period}`;
  };
  function convertUTCToIST(utcDateStr: any) {
    // Create a new Date object from the UTC date string
    const utcDate = new Date(utcDateStr);

    // Get the UTC time in milliseconds since January 1, 1970
    const utcTime = utcDate.getTime();

    // Add the offset for IST (5 hours 30 minutes) in milliseconds
    const istOffset = 5.5 * 60 * 60 * 1000;

    // Create a new date object with IST time
    const istDate = new Date(utcTime + istOffset);

    // Format the IST date to match the required format
    const year = istDate.getFullYear();
    const month = String(istDate.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(istDate.getDate()).padStart(2, "0");
    const hours = String(istDate.getHours()).padStart(2, "0");
    const minutes = String(istDate.getMinutes()).padStart(2, "0");
    const seconds = String(istDate.getSeconds()).padStart(2, "0");
    const milliseconds = String(istDate.getMilliseconds()).padStart(3, "0");

    // Return the formatted date string in the desired format
    return convertToFormattedDateTimetoTextField(istDate);
  }

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        closeAfterTransition
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
        onClose={close}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className={Styles.topContainerTitle}>
              <p
                style={{
                  margin: "0px 10px",
                  fontSize: "14px",
                  fontWeight: 500,
                }}
              >
                <IconButton
                  onClick={handleClose}
                  size="small"
                  style={{
                    background: "#8B93FF",
                    color: "white",
                    borderRadius: 4,
                  }}
                >
                  <KeyboardBackspaceIcon
                    style={{
                      fontSize: "18px",
                      fontWeight: 600,
                    }}
                  />
                </IconButton>
                <span style={{ marginLeft: "10px" }}>Client Details</span>
              </p>
              <div className={Styles.title}>
                {clientData?.ClientDetails &&
                  clientData?.ClientDetails.ClientName}
              </div>
            </div>
            <div className={Styles.wrapper}>
              <div className={Styles.leftContainer}>
                <p className={Styles.clientInfo}>Client Info</p>
                {rejectedReason && stageName === "rejected" && (
                  <>
                    <div style={{ color: "red" }}>
                      <span style={{ fontWeight: 700 }}>Rejected Reason:</span>{" "}
                      <span style={{ fontSize: "14px" }}>
                        {" "}
                        {rejectedReason}
                      </span>
                    </div>
                    <Divider sx={{ borderColor: "grey", margin: "10px 0" }} />
                  </>
                )}
                {deletedReason && stageName === "deleted" && (
                  <>
                    <div style={{ color: "red" }}>
                      <span style={{ fontWeight: 700 }}>Deleted Reason:</span>{" "}
                      <span style={{ fontSize: "14px" }}> {deletedReason}</span>
                    </div>
                    <Divider sx={{ borderColor: "grey", margin: "10px 0" }} />
                  </>
                )}

                <div className={Styles.contentdiv}>
                  <p className={Styles.title}>
                    Existing Mobile :{" "}
                    <span className={Styles.content}>
                      {clientData?.ExistingDetails &&
                        clientData?.ExistingDetails?.mobile}
                    </span>
                  </p>
                  <p className={Styles.title}>
                    Existing Email :{" "}
                    <span className={Styles.content}>
                      {clientData?.ExistingDetails &&
                        clientData?.ExistingDetails?.email}
                    </span>
                  </p>
                </div>
                <Divider sx={{ borderColor: "grey", margin: "10px 0" }} />
                <div className={Styles.contentdiv}>
                  <p className={Styles.title}>
                    Client Name :{" "}
                    <span className={Styles.content}>
                      {clientData?.ClientDetails &&
                        clientData?.ClientDetails.ClientName}
                    </span>
                  </p>
                  <p className={Styles.title}>
                    CLIENT Code :{" "}
                    <span className={Styles.content}>
                      {clientData?.ClientDetails &&
                        clientData?.ClientDetails?.ClientCode}
                    </span>
                  </p>
                </div>

                <Divider sx={{ borderColor: "grey", margin: "10px 0" }} />
                <div className={Styles.contentdiv}>
                  <p className={Styles.title}>
                    Pan No :{" "}
                    <span className={Styles.content}>
                      {clientData?.ClientDetails &&
                        clientData?.ClientDetails.PANNumber}
                    </span>
                  </p>
                  <p className={Styles.title}>
                    DpId :{" "}
                    <span className={Styles.content}>
                      {clientData?.ExistingDetails &&
                        clientData?.ExistingDetails?.DpId}
                    </span>
                  </p>
                </div>

                <Divider sx={{ borderColor: "grey", margin: "10px 0" }} />
                <div className={Styles.contentdiv}>
                  <p className={Styles.title}>
                    Approved TS :{" "}
                    <span className={Styles.content}>
                      {clientData?.EventTimestamps &&
                      clientData?.EventTimestamps?.approved_ts
                        ? convertUTCToIST(
                            clientData?.EventTimestamps?.approved_ts
                          )
                        : "-"}
                    </span>
                  </p>
                  <p className={Styles.title}>
                    Completed TS :{" "}
                    <span className={Styles.content}>
                      {clientData?.EventTimestamps &&
                      clientData?.EventTimestamps?.completed_ts
                        ? convertUTCToIST(
                            clientData?.EventTimestamps?.completed_ts
                          )
                        : "-"}
                    </span>
                  </p>
                </div>

                <Divider sx={{ borderColor: "grey", margin: "10px 0" }} />

                <p
                  className={Styles.title}
                  style={{
                    // textDecoration: "underline",
                    background: "#d9e4fd",
                    // width: "max-content",
                    padding: "3px 20px",
                    borderRadius: "5px",
                    textAlign: "center",
                  }}
                >
                  New Update Details
                </p>

                <div className={Styles.contentdiv}>
                  <div style={{ width: "360px" }}>
                    <div>
                      <div>
                        <p className={Styles.title}>
                          New Mobile :{" "}
                          <span className={Styles.content}>
                            {clientData?.NewDetails &&
                              clientData?.NewDetails.mobile}
                          </span>
                        </p>
                      </div>
                      <div>
                        <p className={Styles.title}>
                          New Email :{" "}
                          <span className={Styles.content}>
                            {clientData?.NewDetails &&
                              clientData?.NewDetails.email}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <Divider sx={{ borderColor: "grey", margin: "10px 0" }} />
                <p
                  className={Styles.title}
                  style={{
                    // textDecoration: "underline",
                    background: "#d9e4fd",
                    // width: "max-content",
                    padding: "3px 20px",
                    borderRadius: "5px",
                    textAlign: "center",
                  }}
                >
                  Request List
                </p>
                <div className={Styles.contentdiv}>
                  <p className={Styles.title} style={{ width: "15%" }}>
                    Sl No
                  </p>
                  <p className={Styles.title} style={{ width: "55%" }}>
                    New Email
                  </p>
                  <p className={Styles.title} style={{ width: "30%" }}>
                    New Mobile
                  </p>
                </div>
                {requestList.map((a: any, index: any) => (
                  <div
                    className={Styles.contentdiv}
                    style={{
                      margin: "5px 0px",
                      borderTop: "1px solid rgba(0, 0, 0, 0.12)",
                    }}
                  >
                    <p className={Styles.title} style={{ width: "15%" }}>
                      {index + 1}
                    </p>
                    <p className={Styles.title} style={{ width: "55%" }}>
                      {a.NewEmail}
                    </p>
                    <p className={Styles.title} style={{ width: "30%" }}>
                      {a.NewMobile}
                    </p>
                  </div>
                ))} */}
              </div>

              <div className={Styles.rightContainer} style={{ width: "75%" }}>
                <div style={{ width: "20%" }}>
                  <VerticalTabs
                    labels={tabLabels}
                    onChangeTab={handleChangeTab}
                    selectedTab={selectedTab}
                  />
                </div>

                <div
                  style={{
                    width: "80%",
                    border: "1px solid #DDDCE1",
                    height: "100%",
                    borderRadius: "4px",
                  }}
                >
                  <EmailMobileUpdateBase64ToImage
                    data={data}
                    docType={docType}
                  />
                </div>
              </div>
            </div>
            <div className={Styles.buttonWrapper}>
              {clientData?.BooleanValues.is_deleted === false && (
                <Tooltip title="Delete Client">
                  <IconButton
                    onClick={handleOpenDeletePopUp}
                    sx={{
                      // position: "absolute",
                      // bottom: 30,
                      border: "1px solid red",
                    }}
                  >
                    <DeleteIcon sx={{ color: "red" }} />
                  </IconButton>
                </Tooltip>
              )}
              {clientData?.BooleanValues?.is_deleted === true && (
                <Tooltip title="Permanent Delete Client">
                  <IconButton
                    onClick={handlePermanentDeleteClient}
                    sx={{
                      // position: "absolute",
                      // // bottom: 30,
                      border: "1px solid red",
                    }}
                  >
                    <DeleteIcon sx={{ color: "red" }} />
                  </IconButton>
                </Tooltip>
              )}

              <DeleteClient
                open={openDeletePopUp}
                handleDeleteClient={handleDeleteClient}
                handleCloseDeletepopUp={handleCloseDeletepopUp}
              />
              <SucessSnackBar message={successMessage} />
              <ErrorSnackBar message={errorMessage} />

              <Stack
                direction={"row"}
                justifyContent={"space-evenly"}
                // marginTop={"15px"}
                alignItems={"center"}
                marginLeft={"20px"}
              >
                {stageName !== "deleted" &&
                  stageName !== "rejected" &&
                  clientData?.BooleanValues?.is_rejected === false &&
                  clientData?.BooleanValues?.is_completed === true && (
                    <BankUpdateReject
                      stagesData={stagesData}
                      handleRejectClient={handleRejectClient}
                      rejectLoading={rejectLoading}
                      disabled={false}
                    />
                  )}
                {isAdmin === "true" && (
                  <div>
                    {stageName !== "deleted" &&
                      stageName !== "rejected" &&
                      clientData?.BooleanValues?.is_completed === true &&
                      clientData?.BooleanValues?.is_approved === false &&
                      clientData?.BooleanValues?.is_rejected === false && (
                        <Button
                          type="submit"
                          onClick={handleApproveClient}
                          loading={approveLoading}
                          // disabled={
                          //   clientData?.BooleanValues &&
                          //   clientData.BooleanValues.is_completed === true
                          //     ? false
                          //     : true
                          // }
                          disabled={false}
                          sx={{
                            background: "#3CBC49",
                            color: "white",
                            width: "160px",
                            borderRadius: "4px",
                            marginLeft: "20px",
                            "&:hover": {
                              background: "#3CBC49",
                              color: "white",
                            },
                          }}
                        >
                          Approve
                        </Button>
                      )}
                  </div>
                )}

                <ErrorSnackBar message={errorMessage} />
              </Stack>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
