import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  collapsed: false,
  onHover: false,
  dashboard: " ",
  otpFlag: false,
  selectedfile: [],
  profile:"",
};

export const projectSlice = createSlice({
  name: "project",
  initialState: initialState,
  reducers: {
    resetaAllProjectDetails: () => initialState,

    setCollapsed(state, action) {
      state.collapsed = action.payload;
    },
    setOnHover(state, action) {
      state.onHover = action.payload;
    },
    setDashboard(state, action) {
      state.dashboard = action.payload;
    },
    setOtpFlag(state, action) {
      state.otpFlag = action.payload;
    },
    setselectedfile(state, action) {
      state.selectedfile = action.payload;
    },
    setProfile(state, action){
      state.profile =action.payload;
    }
  },
});

export const {
  setCollapsed,
  setOnHover,
  setDashboard,
  setOtpFlag,
  setselectedfile,
  setProfile,
} = projectSlice.actions;

export default projectSlice.reducer;
