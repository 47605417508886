import * as React from "react";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { Formik } from "formik";
import {
  Stack,
  Fade,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  ThemeProvider,
  Container,
  FormHelperText,
  Typography,
  Box,
  Button,
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

import { TodayFollowUpScheme } from "../../../Schemas/Schemas";
import { AddFollowUp } from "../../../Utils/InitialValues";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import dayjs, { Dayjs } from "dayjs";
import Paper, { PaperProps } from "@mui/material/Paper";
import Draggable from "react-draggable";
// import { styled } from '@mui/system';

function PaperComponent(props: PaperProps) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

interface Props {
  openModal: boolean;
  handleClose: any;

  handleSubmitData: any;
  //   data?: IEditUser | any;
}

const statusData = [
  "Positive",
  "Call back",
  "Come back",
  "No response",
  "Hard to convert",
  "DND",
  "Negative",
];

export default function TodayFollowUpModalpopup({
  openModal,
  handleClose,
  handleSubmitData,
}: Props) {
  const [showPassword, setShowPassword] = React.useState(false);

  const [role, setRole] = React.useState<any>();
  const [roleData, setRoleData] = React.useState<any>();

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const blue = {
    100: "#DAECFF",
    200: "#b6daff",
    400: "#3399FF",
    500: "#007FFF",
    600: "#0072E5",
    900: "#003A75",
  };

  const grey = {
    50: "#f6f8fa",
    100: "#eaeef2",
    200: "#d0d7de",
    300: "#afb8c1",
    400: "#8c959f",
    500: "#6e7781",
    600: "#57606a",
    700: "#424a53",
    800: "#32383f",
    900: "#24292f",
  };

  const StyledTextarea = styled(TextareaAutosize)(
    ({ theme }) => `
    width: 100%;
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 12px;
    border-radius: 12px 12px 0 12px;
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
    box-shadow: 0px 2px 2px ${
      theme.palette.mode === "dark" ? grey[900] : grey[50]
    };
  
    &:hover {
      border-color: ${blue[400]};
    }
  
    &:focus {
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${
        theme.palette.mode === "dark" ? blue[500] : blue[200]
      };
    }
  
    // firefox
    &:focus-visible {
      outline: 0;
    }
  `
  );

  const InitialValues = {
    remarks: "",
    status: "",
    date_time: dayjs(new Date()),
  };

  //   const type = data ? "edit" : "add";
  return (
    <BootstrapDialog
      onClose={(event: any) => {
        if (event.keyCode === 27) {
          handleClose();
        }
      }}
      aria-labelledby="draggable-dialog-title"
      open={openModal}
      PaperComponent={PaperComponent}
    >
      <BootstrapDialogTitle id="draggable-dialog-title" onClose={handleClose}>
        FollowUp
      </BootstrapDialogTitle>

      <Formik
        validationSchema={TodayFollowUpScheme}
        initialValues={InitialValues}
        onSubmit={(values) => {
          //  console.log("values", values);

          handleSubmitData(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <>
            {/* {console.log("errors", errors)} */}
            <form noValidate onSubmit={handleSubmit}>
              <DialogContent dividers>
                <Box component="form" noValidate>
                  <Stack spacing={2} width={500}>
                    <Stack
                      justifyContent={"space-between"}
                      spacing={2}
                      direction={"row"}
                    >
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Status
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={values.status}
                          name="status"
                          label="Status"
                          onChange={(event: any) => {
                            // console.log(event.target.value);
                            setFieldValue("status", event.target.value);
                          }}
                          error={touched.status && Boolean(errors.status)}
                        >
                          {statusData.map((item: any) => (
                            <MenuItem key={item} value={item}>
                              {item}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText style={{ color: "#d32f2f" }}>
                          {touched.status && errors.status}
                        </FormHelperText>
                      </FormControl>
                    </Stack>
                    <Stack>
                      <StyledTextarea
                        id="remarks"
                        minRows={3}
                        aria-label="Remarks"
                        placeholder="Remarks"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="remarks"
                        value={values.remarks}
                        // error={touched.remarks && Boolean(errors.remarks)}
                        // helperText={
                        //   touched.remarks && errors.remarks?.toString()
                        // }
                      />
                      <FormHelperText
                        style={{ color: "#d32f2f", paddingLeft: "15px" }}
                      >
                        {touched.remarks && errors.remarks?.toString()}
                      </FormHelperText>
                    </Stack>
                    <Stack>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                          label="Next Followup Date&Time"
                          name="date_time"
                          viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                            seconds: renderTimeViewClock,
                          }}
                          value={values.date_time}
                          // minDate={renderDate}
                          disablePast
                          minTime={dayjs().set("hour", 8)}
                          maxTime={dayjs().set("hour", 20)}
                          format="DD/MM/YYYY hh:mm A"
                          onChange={(date) => {
                            handleChange({
                              target: {
                                name: "date_time",
                                value: date,
                              },
                            });
                          }}
                          {...(DateTimePicker as any)}
                          error={touched.date_time && Boolean(errors.date_time)}
                          helperText={touched.date_time && errors.date_time}
                        />
                        <FormHelperText
                          style={{ color: "#d32f2f", paddingLeft: "15px" }}
                        >
                          {(
                            touched.date_time && Boolean(errors.date_time)
                          )?.toString() === "true"
                            ? "Date and time must be more than present"
                            : ""}
                        </FormHelperText>
                      </LocalizationProvider>
                    </Stack>
                  </Stack>
                </Box>
              </DialogContent>
              <DialogActions>
                <Button type="submit">Save changes</Button>
              </DialogActions>
            </form>
          </>
        )}
      </Formik>
    </BootstrapDialog>
  );
}
