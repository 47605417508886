import {
  Box,
  Button,
  Fade,
  IconButton,
  Modal,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DataTableComponent from "../../DataTableComponent/DataTableComponent";
import DataTable from "react-data-table-component";
import EditIcon from "@mui/icons-material/Edit";
import FollowUpDataTableComponent from "../../../pages/Followups/FollowUpsDataTable";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TodayFollowUpModalpopup from "./TodayFollowUpModal";
import {
  GetFollowUpHistoryAPICall,
  addFollowUpDateAPICall,
  editFollowUpHistoryAPICall,
  insertFollowUpHistoryAPICall,
} from "../../../Utils/APICalls";
import FollowUpDateChange from "./FollowUpDateChange";
import EditFollowUpHistoryModalpopup from "./EditFollowUpHistory";
import {
  IAddFollowUpDate,
  IEditFollowUpHistory,
  IInsertFollowUpHistory,
} from "../../../Utils/dtos";
import SuccessModal from "../../SuccessModal/SuccessModal";
import SucessSnackBar from "../../SnackBar/SuccessSnackBar";

interface Props {
  open: boolean;
  handleClose: any;
  handleSubmitData: any;
  data: string;
  followDate?: any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  // overflow: "scroll",
  height: "90%",
  bgcolor: "background.paper",
  borderRadius: "20px",
  boxShadow: 24,
  p: 2,
};

const titletextStyle = {
  fontSize: "14px",
  fontWeight: "bold",
  color: "#8C8B8F",
};
const contenttextStyle = { fontSize: "12px" };

export default function FollowUpsModal({
  open,
  handleClose,
  handleSubmitData,
  data,
  followDate,
}: Props) {
  const [loading, setLoading] = useState(false);
  const [age, setAge] = React.useState("");
  const [openFollowUp, setOpenFollowUp] = useState(false);
  const [openDateModal, setOpenDateModal] = useState(false);
  const [editHistoryModal, setEditHistoryModal] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [followUpDate, setFollowUpDate] = useState<any>("");
  const [followUpID, setFollowUpID] = useState<any>("");
  const [followUpStatus, setFollowUpStatus] = useState<any>("");
  const [date, setDate] = useState("");
  const [followUpDatetoSubmit, setfollowUpDatetoSubmit] = useState<any>("");
  const [message, setMessage] = useState("");
  const followtype: any = sessionStorage.getItem("followtype");

  const [historyRowData, setHistoryRowData] = useState({
    id: "",
    Status: "",
    Remarks: "",
    UserId: "",
    UserName: "",
    FollowUpDate: "",
  });
  const today = new Date();
  const convertToFormattedDate = (dateString: any) => {
    const dateTime = dateString;

    const day = dateTime.getDate();
    const month = dateTime.getMonth() + 1; // Months are zero-indexed
    const year = dateTime.getFullYear();

    let hours = dateTime.getHours();
    let minutes = dateTime.getMinutes();
    const period = hours >= 12 ? "PM" : "AM";

    // Convert to 12-hour format
    if (hours > 12) {
      hours -= 12;
    } else if (hours === 0) {
      hours = 12;
    }
    return `${day < 10 ? "0" : ""}${day}-${
      month < 10 ? "0" : ""
    }${month}-${year} ${hours}:${minutes < 10 ? "0" : ""}${minutes} ${period}`;
  };

  useEffect(() => {
    getFollowUpHistory();
  }, []);

  function convertDateFormat(inputDate: any) {
    const options: any = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };

    const utcDate = new Date(inputDate);
    const localDate = new Date(
      utcDate.getTime() + utcDate.getTimezoneOffset() * 60000
    );
    const formattedDate = localDate.toLocaleString("en-US", options);

    return formattedDate;
  }
  function convertDateFormatToDisplay(inputDate: any) {
    const options: any = {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };

    const utcDate = new Date(inputDate);
    const localDate = new Date(
      utcDate.getTime() + utcDate.getTimezoneOffset() * 60000
    );

    const formattedDate = `${localDate.getUTCDate()}/${(
      localDate.getUTCMonth() + 1
    )
      .toString()
      .padStart(
        2,
        "0"
      )}/${localDate.getUTCFullYear()}, ${localDate.toLocaleString(
      "en-US",
      options
    )}`;

    return formattedDate;
  }

  const getFollowUpHistory = () => {
    const reqObj: any = {
      user_id: data,
    };
    setLoading(true);
    GetFollowUpHistoryAPICall(reqObj)
      .then((response: any) => {
        const responseData = response.data;
        setLoading(false);

        if (responseData.data[0].id) {
          setFollowUpID(responseData.data[0].id);
        }

        if (responseData.data[0].status) {
          setFollowUpStatus(responseData.data[0].status);
        }

        if (responseData.data) {
          // const HistoryData = responseData.data;
          // let ArrayData = Object.keys(HistoryData);
          // const TableData: any = ArrayData.map((a: any) => ({
          //   id: a,
          //   ...HistoryData[a],
          // }));

          const sortedHistory = responseData.data.sort((a: any, b: any) => {
            return (
              new Date(b.followUpDate).getTime() -
              new Date(a.followUpDate).getTime()
            );
          });

          setTableData(sortedHistory);
          setFollowUpDate(sortedHistory[0].nextFollowUpDate.slice(0, 10));
          if (responseData.data[0].nextFollowUpDate) {
            const sortedHistory = responseData.data.sort((a: any, b: any) => {
              return (
                new Date(b.followUpDate).getTime() -
                new Date(a.followUpDate).getTime()
              );
            });
            const utcDate = sortedHistory[0].nextFollowUpDate;
            const convertedDate = convertDateFormatToDisplay(utcDate);

            setfollowUpDatetoSubmit(convertedDate);
          }
        }
      })
      .catch((error: any) => {
        setLoading(false);
        // console.log("error", error);
      });
  };

  const formattedToday = `${String(today.getDate()).padStart(2, "0")}-${String(
    today.getMonth() + 1
  ).padStart(2, "0")}-${today.getFullYear()}`;

  const isDateEqualToday = formattedToday === followUpDate;
  const isDateLessThanToday = followUpDate < formattedToday;

  // Check if the given date is greater than today's date
  const isDateGreaterThanToday = formattedToday > followUpDate;

  const handleChange = (event: SelectChangeEvent) => {
    setAge(event.target.value as string);
  };
  const handleOpenFollowUpModal = () => {
    setOpenFollowUp(true);
  };
  const handleOpenAddFollowUpModal = () => {
    setOpenDateModal(true);
  };
  const handleCloseFollowUpModal = () => {
    setOpenFollowUp(false);
  };
  const handleSubmitTodayFollowUpData = (AddedData: any) => {
    //  console.log("AddedData", AddedData);
    setOpenFollowUp(false);
    const reqObj: IInsertFollowUpHistory = {
      user_id: data,
      status: AddedData.status,
      remarks: AddedData.remarks,

      next_followup_date: convertToFormattedDate(
        new Date(AddedData.date_time.$d)
      ),
    };

    insertFollowUpHistoryAPICall(reqObj)
      .then((response: any) => {
        const responseData = response.data;
        //  console.log(responseData);
        getFollowUpHistory();
        setMessage("Successfully Updated");
        setTimeout(() => {
          setMessage(" ");
        }, 5000);
      })
      .catch((error: any) => {
        setLoading(false);
        // console.log("error", error);
      });
    getFollowUpHistory();
  };

  const handleUpdateDate = (followUpData: any) => {
    setOpenDateModal(false);
    // console.log("data", data.update_date.$d);
    const originalDate = new Date(followUpData.update_date.$d);

    const year = originalDate.getFullYear();
    const month = String(originalDate.getMonth() + 1).padStart(2, "0");
    const day = String(originalDate.getDate()).padStart(2, "0");
    const hour = String(originalDate.getHours()).padStart(2, "0");
    const minute = String(originalDate.getMinutes()).padStart(2, "0");
    const second = String(originalDate.getSeconds()).padStart(2, "0");

    const formattedDateString = `${year}-${month}-${day} ${hour}:${minute}:${second}`;
    // console.log(
    //   "formattedDateString",
    //   formattedDateString,
    //   followUpData.status,
    //   followUpData.remarks
    // );

    const reqObj: IInsertFollowUpHistory = {
      user_id: data,
      status: followUpData.status,
      remarks: followUpData.remarks,

      next_followup_date: convertToFormattedDate(
        new Date(followUpData.update_date.$d)
      ),
    };

    insertFollowUpHistoryAPICall(reqObj)
      .then((response: any) => {
        const responseData = response.data;
        console.log(responseData);
        getFollowUpHistory();
        setMessage("Successfully Updated");
        setTimeout(() => {
          setMessage(" ");
        }, 5000);
      })
      .catch((error: any) => {
        setLoading(false);
        // console.log("error", error);
      });
    getFollowUpHistory();
  };
  const handleCloseDatepopUp = () => {
    setOpenDateModal(false);
  };
  const handleEditHistoryClick = (rowData: any) => {
    // console.log("rowData", rowData);
    setEditHistoryModal(true);
    setHistoryRowData(rowData);
  };
  const handleEditFollowUpHistoryModalClose = () => {
    setEditHistoryModal(false);
  };
  const handleSubmitEditFollowUpHistoryData = (dataValues: any) => {
    setEditHistoryModal(false);
    // console.log("dataValues", dataValues);
    // const originalDate = new Date(dataValues.date_time.$d);

    // const year = originalDate.getFullYear();
    // const month = String(originalDate.getMonth() + 1).padStart(2, "0");
    // const day = String(originalDate.getDate()).padStart(2, "0");
    // const hour = String(originalDate.getHours()).padStart(2, "0");
    // const minute = String(originalDate.getMinutes()).padStart(2, "0");
    // const second = String(originalDate.getSeconds()).padStart(2, "0");

    // const formattedDateString = `${year}-${month}-${day} ${hour}:${minute}:${second}`;
    const reqObj: any = {
      user_id: data,
      status: dataValues.status,
      remarks: dataValues.remarks,
      // follow_up_TS: historyRowData.FollowUpDate,
      followup_id: historyRowData.id,
    };

    editFollowUpHistoryAPICall(reqObj)
      .then((response: any) => {
        const responseData = response.data;
        //  console.log(responseData);
        getFollowUpHistory();
        setMessage("Successfully Updated");
        setTimeout(() => {
          setMessage(" ");
        }, 5000);
      })
      .catch((error: any) => {
        setLoading(false);
        // console.log("error", error);
      });
    getFollowUpHistory();
  };
  function convertUTCToIST(utcDateStr: any) {
    // Create a new Date object from the UTC date string
    const utcDate = new Date(utcDateStr);

    // Get the UTC time in milliseconds since January 1, 1970
    const utcTime = utcDate.getTime();

    // Add the offset for IST (5 hours 30 minutes) in milliseconds
    const istOffset = 5.5 * 60 * 60 * 1000;

    // Create a new date object with IST time
    const istDate = new Date(utcTime + istOffset);

    // Format the IST date to match the required format
    const year = istDate.getFullYear();
    const month = String(istDate.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(istDate.getDate()).padStart(2, "0");
    const hours = String(istDate.getHours()).padStart(2, "0");
    const minutes = String(istDate.getMinutes()).padStart(2, "0");
    const seconds = String(istDate.getSeconds()).padStart(2, "0");
    const milliseconds = String(istDate.getMilliseconds()).padStart(3, "0");

    // Return the formatted date string in the desired format
    return convertToFormattedDateTimetoTextField(istDate);
  }

  const columns = [
    {
      name: <b style={titletextStyle}>Created at</b>,
      selector: "FollowUpDate",
      // sortable: true,
      width: "160px",

      cell: (row: any) => {
        return (
          <b style={{ fontSize: "12px" }}>
            {row.followUpDate === "" ? "" : convertUTCToIST(row.followUpDate)}
          </b>
        );
      },
    },
    {
      name: <b style={titletextStyle}>Next FollowUp Date</b>,
      selector: "NextFollowUpDate",
      // sortable: true,
      width: "200px",

      cell: (row: any) => {
        return (
          <b style={{ fontSize: "12px" }}>
            {row.nextFollowUpDate === ""
              ? ""
              : convertDateFormatToDisplay(row.nextFollowUpDate)}
          </b>
        );
      },
    },

    {
      name: <b style={titletextStyle}>Status</b>,
      selector: "Status",
      width: "150px",
      // sortable: true,
      cell: (row: any) => (
        <b style={contenttextStyle}>
          {row.status}
          {/* <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">Status</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={age}
                label="Age"
                onChange={handleChange}
              >
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </FormControl>
          </Box> */}
        </b>
      ),
    },

    {
      name: <b style={titletextStyle}>Remarks</b>,
      selector: "Remarks",
      sortable: false,

      button: true,
      width: "500px",
      cell: (row: any) => (
        <b style={contenttextStyle}>
          {/* <div > */}
          {/* {textFeild && <TextField value={row.remarks} size="small"  /> } */}
          {row.remarks}

          {/* <Tooltip title="View Details">
              <IconButton
                onClick={() => {
                    setTextFeild(true)
                }}
              >
                <EditIcon />
              </IconButton>
            </Tooltip> */}
          {/* </div> */}
        </b>
      ),
    },
    {
      name: <b style={titletextStyle}>Actions</b>,
      selector: "spamReportRate",
      sortable: false,
      allowOverflow: true,
      button: true,
      width: "100px",
      cell: (row: any) => (
        <b style={contenttextStyle}>
          <Tooltip title="View Details">
            <IconButton
              onClick={() => {
                handleEditHistoryClick(row);
              }}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        </b>
      ),
    },
  ];

  const convertToFormattedDateTimetoTextField = (dateTimeString: any) => {
    const dateTime = new Date(dateTimeString);

    const day = dateTime.getDate();
    const month = dateTime.getMonth() + 1; // Months are zero-indexed
    const year = dateTime.getFullYear();

    let hours = dateTime.getHours();
    let minutes = dateTime.getMinutes();
    const period = hours >= 12 ? "PM" : "AM";

    // Convert to 12-hour format
    if (hours > 12) {
      hours -= 12;
    } else if (hours === 0) {
      hours = 12;
    }

    return `${day < 10 ? "0" : ""}${day}-${
      month < 10 ? "0" : ""
    }${month}-${year} ${hours}:${minutes < 10 ? "0" : ""}${minutes} ${period}`;
  };
  useEffect(() => {
    const close = (e: any) => {
      // console.log("e.keyCode", e.keyCode)
      if (e.keyCode === 27) {
        handleClose();
      }
    };

    window.addEventListener("keydown", close);

    return () => {
      window.removeEventListener("keydown", close);
    };
  }, []);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        closeAfterTransition
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
        onClose={(event: any) => {
          if (event.keyCode === 27) {
            handleClose();
          }
        }}
      >
        <>
          <Fade in={open}>
            <Box sx={style}>
              <Stack style={{ position: "absolute", top: 0, right: 10 }}>
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Stack>
              <Stack marginTop={"20px"}>
                <Stack alignItems={"start"} direction={"row"}>
                  {isDateEqualToday && (
                    <Button
                      variant="contained"
                      onClick={handleOpenFollowUpModal}
                      sx={{
                        background: "#8B93FF",
                        boxShadow: "none",
                        textTransform: "none",
                        "&:hover": {
                          background: "#8B93FF",
                          boxShadow: "none",
                        },
                      }}
                    >
                      Today FollowUp
                    </Button>
                  )}

                  {!followUpDate && (
                    <Button
                      variant="contained"
                      onClick={handleOpenAddFollowUpModal}
                      sx={{
                        background: "#8B93FF",
                        boxShadow: "none",
                        textTransform: "none",
                        marginLeft: "20px",
                        "&:hover": {
                          background: "#8B93FF",
                          boxShadow: "none",
                        },
                      }}
                    >
                      Add FollowUp
                    </Button>
                  )}
                  {isDateGreaterThanToday && followUpDate && (
                    <Button
                      variant="contained"
                      onClick={handleOpenFollowUpModal}
                      sx={{
                        background: "#8B93FF",
                        boxShadow: "none",
                        textTransform: "none",
                        "&:hover": {
                          background: "#8B93FF",
                          boxShadow: "none",
                        },
                      }}
                    >
                      Reschedule FollowUp
                    </Button>
                  )}
                  {isDateGreaterThanToday && followUpDate && (
                    <TextField
                      value={followUpDatetoSubmit}
                      size="small"
                      label="Upcoming FollowUp Date"
                      sx={{ marginLeft: "20px" }}
                    />
                  )}
                  {followUpDate && (
                    <Button
                      variant="contained"
                      onClick={handleOpenFollowUpModal}
                      sx={{
                        background: "#8B93FF",
                        boxShadow: "none",
                        textTransform: "none",
                        "&:hover": {
                          background: "#8B93FF",
                          boxShadow: "none",
                        },
                      }}
                    >
                      Missed FollowUp
                    </Button>
                  )}

                  {followUpDate && (
                    <TextField
                      value={followUpDatetoSubmit}
                      size="small"
                      label="Missed FollowUp Date"
                      sx={{ marginLeft: "20px" }}
                    />
                  )}
                </Stack>
                <Stack style={{ overflowY: "scroll", height: "70vh" }}>
                  <FollowUpDataTableComponent
                    columns={columns}
                    data={tableData}
                    loading={loading}
                    searchparams={[
                      "client_name",
                      "mobile",
                      "source",
                      "source_id",
                    ]}
                    pagination={true}
                    paginationServer={true}
                    handleAdd={undefined}
                    // handlePerRowsChange={handlePerRowsChange}
                    // handlePageChange={handlePageChange}
                    // totalRows={totalRows}
                    // handleSearchChange={handleSearchChange}
                    // stagesData={stagesData}
                    // users={users}
                  />
                </Stack>
              </Stack>
            </Box>
          </Fade>
          <SucessSnackBar message={message} />
          <FollowUpDateChange
            openDateModal={openDateModal}
            handleUpdateDate={handleUpdateDate}
            handleCloseDatepopUp={handleCloseDatepopUp}
          />

          <TodayFollowUpModalpopup
            openModal={openFollowUp}
            handleClose={handleCloseFollowUpModal}
            handleSubmitData={handleSubmitTodayFollowUpData}
          />

          <EditFollowUpHistoryModalpopup
            openModal={editHistoryModal}
            handleClose={handleEditFollowUpHistoryModalClose}
            handleSubmitData={handleSubmitEditFollowUpHistoryData}
            historyRowData={historyRowData}
          />
        </>
      </Modal>
    </div>
  );
}
