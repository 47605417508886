import React, { useState } from "react";
import styles from "./LocationLogs.module.scss";
import {
  DateRangePicker,
  LocalizationProvider,
  DateRange,
} from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";

import dayjs, { Dayjs } from "dayjs";
import { Button } from "@mui/material";
import { GetLocationLogsAPICall } from "../../Utils/APICalls";

function LocationLogs() {
  const [datevalue, setdateValue] = useState<DateRange<Dayjs | any>>([
    dayjs().subtract(2, "month"),
    dayjs(),
  ]);

  const GetLocationLogs = () => {
    let obj: any = {
      from_date: formatDatePayload(datevalue[0]),
      to_date: formatDatePayload(datevalue[1]),
    };

    GetLocationLogsAPICall(obj)
      .then((response: any) => {
        // console.log("response", response.data);
        downloadCSV(response.data);
      })
      .catch((error: any) => {});
  };

  const downloadCSV = (data: any) => {
    const csvData = data;
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = URL.createObjectURL(blob);

    // Create a temporary link to download the CSV file
    const link = document.createElement("a");
    link.href = url;
    link.download = "Logs Data.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const convertToCSV = (array: any) => {
    return array.map((row: any) => row.join(",")).join("\n");
  };

  function formatDatePayload(dateString: any) {
    // Create a new Date object from the provided date string
    const date = new Date(dateString);

    // Extract the year, month, and day from the Date object
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-indexed, so we add 1
    const day = String(date.getDate()).padStart(2, "0");

    // Concatenate the year, month, and day with a hyphen separator to get the desired format
    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
  }

  return (
    <div>
      <div className={styles.singleFilter}>
        <p className={styles.textfieldLabel}>Date Range </p>
        <div style={{ display: "flex", alignItems: "baseline", gap: "30px" }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DateRangePicker", "DateRangePicker"]}>
              <DemoItem component="DateRangePicker">
                <DateRangePicker
                  value={datevalue}
                  sx={{ width: "350px" }}
                  format="DD/MM/YYYY"
                  onChange={(newValue: any) => {
                    setdateValue(newValue);
                    //   if (newValue[0] && newValue[1]) {
                    //     handleDateRangeChange(newValue);
                    //   }
                  }}
                  slotProps={{
                    textField: {
                      size: "small",
                      sx: {
                        borderRadius: "4px",
                        background: "#F2F2F6",
                        outline: "none",
                        fontSize: "14px",
                        fontWeight: 500,
                        padding: "0 0px",
                        color: "#000000",
                        boxShadow: "none",
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      },
                    },
                  }}
                />
              </DemoItem>
            </DemoContainer>
          </LocalizationProvider>

          <Button
            variant="contained"
            onClick={GetLocationLogs}
            sx={{
              background: "#8B93FF",
              boxShadow: "none",
              textTransform: "none",
              "&:hover": {
                background: "#8B93FF",
                boxShadow: "none",
              },
            }}
          >
            Download
          </Button>
        </div>
      </div>
    </div>
  );
}

export default LocationLogs;
