import React, { useEffect, useState } from "react";
import styles from "./Filters.module.scss";
import { DateRangePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { FormControl, IconButton, MenuItem, Select } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const mappingStagesData: any = {
  "pan": "Pan details",
  "aadhaar": "KRA verification or Digi-locker",
  "nominee": "Nominee ",
  "esign": "Esign section",

  "update_profile": "Personal details",
  "bank": "Bank details",
  "ipv": "Identification verification",
  "upload_document": "Upload documents",
  
};

interface props {
  stagesData: any;
  stageFilter: any;
  setStageFilter: any;
  handleStageChange: any;
  userId: any;
  isAdmin: any;
  setUserId: any;
  handleUserChange: any;
  users: any;
  handleCloseFilters: any;
}

function FiltersRekyc({
  stagesData,
  stageFilter,
  setStageFilter,
  handleStageChange,
  userId,
  isAdmin,
  setUserId,
  handleUserChange,
  users,
  handleCloseFilters,
}: props) {
  const [userDataAdded, setuserDataAdded] = useState([]);
  useEffect(() => {
    if (users) {
      const userData: any = [
        ...users,
        { UserId: "non_assigned", FullName: "Non Assigned" },
      ];
      setuserDataAdded(userData);
    }
  }, [users]);
 // console.log("userDataAdded", userDataAdded);

  return (
    <div className={styles.filterWrapper}>
      <div className={styles.topContainer}>
        <p className={styles.title}>Filters</p>
        <div>
          <IconButton onClick={handleCloseFilters}>
            <HighlightOffIcon style={{ color: "#8B93FF" }} />
          </IconButton>
        </div>
      </div>
      <div className={styles.filterSection}>
        <div className={styles.singleFilter}>
          <p className={styles.textfieldLabel} style={{ marginBottom: "10px" }}>
            Stage{" "}
          </p>
          <FormControl sx={{ width: "200px" }} size="small">
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={stageFilter}
              name="selectStage"
              placeholder="Stage"
              displayEmpty
              renderValue={
                stageFilter !== ""
                  ? undefined
                  : () => {
                      return (
                        <p style={{ margin: 0, fontWeight: 400 }}>
                          Select stage
                        </p>
                      );
                    }
              }
              onChange={(event: any) => {
                setStageFilter(event.target.value);
                handleStageChange(event.target.value);
              }}
              sx={{
                borderRadius: "4px",
                // width: "-webkit-fill-available",
                background: "#F2F2F6",
                outline: "none",
                fontSize: "14px",
                fontWeight: 500,
                padding: "0 0px",
                color: "#000000",
                boxShadow: "none",
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
              }}
            >
              {stagesData &&
                stagesData.map((item: any) => (
                  <MenuItem key={item} value={item}>
                    {mappingStagesData[item]}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>

        {isAdmin === "true" && (
          <div className={styles.singleFilter}>
            <p
              className={styles.textfieldLabel}
              style={{ marginBottom: "10px" }}
            >
              Users{" "}
            </p>
            <FormControl sx={{ width: "200px" }} size="small">
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={userId}
                placeholder="Users"
                displayEmpty
                renderValue={
                  userId !== ""
                    ? undefined
                    : () => {
                        return (
                          <p style={{ margin: 0, fontWeight: 400 }}>
                            Select User
                          </p>
                        );
                      }
                }
                onChange={(event: any) => {
                  setUserId(event.target.value);
                  handleUserChange(event.target.value);
                }}
                sx={{
                  borderRadius: "4px",
                  // width: "-webkit-fill-available",
                  background: "#F2F2F6",
                  outline: "none",
                  fontSize: "14px",
                  fontWeight: 500,
                  padding: "0 0px",
                  color: "#000000",
                  boxShadow: "none",
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                }}
              >
                {userDataAdded &&
                  userDataAdded.map((data: any) => {
                    return (
                      <MenuItem value={data} key={data.full_name}>
                        {data.full_name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </div>
        )}
      </div>
    </div>
  );
}

export default FiltersRekyc;
