import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { orderBy } from "lodash";
import classes from "./CMRUpload.module.scss";
import SearchIcon from "@mui/icons-material/Search";
import {
  Button,
  Stack,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  IconButton,
  Tooltip,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Pagination from "@mui/material/Pagination";
import dayjs, { Dayjs } from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateRange } from "@mui/x-date-pickers-pro";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { LicenseInfo } from "@mui/x-date-pickers-pro";
import { width } from "@mui/system";
import StageMapingData from "../../Utils/stages.json";
import StyledLoader from "../Styledloader/StyledLoader";
import { Icon } from "@material-ui/core";
import RefreshRoundedIcon from "@mui/icons-material/RefreshRounded";

interface Props {
  columns: any;
  checkboxEnabled?: boolean;
  loading?: boolean;
  data?: any;
  totalRows?: number;

  pagination?: boolean;
  paginationServer?: boolean;
  handleAdd: any;
  handlePageChange?: any;
  handlePerRowsChange?: any;
  handleRefreshTable?:any;
  paginationCount?: any;
}

const CMRUploadDataTable = ({
  columns,
  checkboxEnabled = false,
  loading = false,
  pagination = false,
  paginationServer = false,
  data = [],

  handleAdd,
  handlePageChange,
  handlePerRowsChange,
  totalRows,
  handleRefreshTable,
  paginationCount,
}: Props) => {
  const handleChange = (data: any) => {
    // console.log("Selected Rows: ", data.selectedRows);
  };
  const [oldData, setoldData] = useState([]);
  const [query, setQuery] = useState("");
  // const [paginationCount, setpaginationCount] = useState(1);
  const [dataValue, setData] = useState(1);
  const [stageName, setStageName] = useState<any>("");
  const [userId, setUserId] = useState("");
  const [value, setValue] = useState<DateRange<Dayjs>>([
    dayjs().subtract(1, "month"),
    dayjs(),
  ]);

  const isAdmin = sessionStorage.getItem("isAdmin");

 
  const handlePaginationChange = (event: any, page: any) => {
    setData(page);
    //console.log( page);
  };

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#F3F6FA",
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
        // height:'40px'
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
    headRow: {
      style: {
        borderBottom: "none",
      },
    },
    header: {
      style: {
        fontSize: "16px",
        minHeight: "30px",
        paddingLeft: "3px",
        paddingRight: "3px",
      },
    },
    contextMenu: {
      style: {
        fontSize: "14px",
        fontWeight: 400,
        paddingLeft: "5px",
        paddingRight: "5px",
        transform: "translate3d(0, -100%, 0)",
        transitionDuration: "125ms",
        transitionTimingFunction: "cubic-bezier(0, 0, 0.2, 1)",
        willChange: "transform",
      },
      activeStyle: {
        transform: "translate3d(0, 0, 0)",
      },
    },
  };

  const handleSort = (column: any, sortDirection: any) => {
    // console.log("column", column, "sortDirection", sortDirection);
  };
  return (
    <>
      <div className={classes.tableWrapper}>
        <div className={classes.emailListHeader}>
          <Tooltip title="Refresh Table Data">
            <IconButton onClick={handleRefreshTable}>
              <RefreshRoundedIcon />
            </IconButton>
          </Tooltip>

          <Button
            variant="contained"
            onClick={handleAdd}
            sx={{
              width: "160px",
              background: "#5A6ACF",
              textTransform: "none",
              boxShadow: "none",
              "&:hover": {
                background: "#5A6ACF",
                boxShadow: "none",
              },
            }}
          >
            Upload CMR
          </Button>
        </div>
        <div>
          <DataTable
            data={data}
            columns={columns}
            title=" "
            // pagination={pagination}
            onSort={handleSort}
            highlightOnHover
            selectableRows={checkboxEnabled}
            customStyles={customStyles}
            onSelectedRowsChange={handleChange}
            fixedHeader
            // fixedHeaderScrollHeight={"600px"}
            actions
            progressPending={loading}
            paginationServer={paginationServer}
            // paginationTotalRows={totalRows}
            // selectableRows
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            progressComponent={<StyledLoader />}
          />
        </div>
        {data.length > 0 && !loading && (
          <Stack
            alignItems={"center"}
            // marginTop={data.length >= 9 ? "10px" : "300px"}
            marginTop={"10px"}
            marginBottom={"20px"}
          >
            <Pagination
              onChange={(value: any, page: any) => {
                handlePageChange(value, page);
                // setpaginationCount(page);
              }}
              page={paginationCount}
              count={totalRows}
              variant="outlined"
              shape="rounded"
            />
          </Stack>
        )}
      </div>
    </>
  );
};

export default CMRUploadDataTable;
