import React, { useEffect, useState } from "react";
import {
  Button,
  Chip,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import RefreshIcon from "@mui/icons-material/Refresh";
import PersonIcon from "@mui/icons-material/Person";
import { Helmet } from "react-helmet";
import {
  ListUsersAPICall,
  GetRekycStagesAPICall,
  GetDashboardNomineeUpdateAPICall,
  AssignUserEmailNomineeUpdateAPICall,
  PermanentDeleteClientNomineeUpdateAPICall,
  GetDashboardDematAPICall,
} from "../../Utils/APICalls";
import {
  IAssignUserBankUpdate,
  IGetDashboardBankUpdate,
} from "../../Utils/dtos";
import Tooltip from "@mui/material/Tooltip";
import Tabs from "../../components/Tabs/Tabs";
import SucessSnackBar from "../../components/SnackBar/SuccessSnackBar";
import { DateRange } from "@mui/x-date-pickers-pro";
import dayjs, { Dayjs } from "dayjs";
import DataTableComponentNomineeUpdate from "../../components/DataTableComponentNomineeUpdate/DataTableComponentNomineeUpdate";
import NomineeUpdateDetailspopup from "../../components/Modals/NomineeUpdateDetailsModal/NomineeUpdateDetailspopup";
import NomineeUpdateAssignUser from "../../components/Modals/NomineeUpdateAssignUser/NomineeUpdateAssignUser";
import classes from "./NewKyc.module.scss";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import FinUpdateFilters from "../../components/Filters/FinUpdateFilters";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { confirmAlert } from "react-confirm-alert";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import AssignUser from "../../components/Modals/AssignUser/AssignUser";
import ClientDetailspopup from "../../components/Modals/ClientDetailspopup";
import DematPopup from "../../components/Modals/DematPopup";
import DematFilters from "../../components/Filters/FiltersDemat";

const titletextStyle = {
  fontSize: "14px",
  fontWeight: "bold",
  color: "#0F1419",
  cursor: "pointer",
};
const contenttextStyle = { fontSize: "12px", color: "#555555" };

const mappingValues: any = {
  All: "all",
  "In Progress": "non_esigned",
  "Approval Pending": "approval_pending",
  Rejected: "rejected",
  Approved: "approved",
  Deleted: "deleted",
};
export default function Demat() {
  const [data, setdata] = useState([]);
  const [loading, setloading] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedTab, setselectedTab] = useState<string>("All");
  const [totalRows, setTotalRows] = useState(5);
  const [perPage, setPerPage] = useState(20);
  const [rekycClientDetailspopup, setclientDetailsPopup] = useState({
    status: false,
    data: "",
  });
  const [clientId, setClientId] = useState("");
  const [userName, setUserName] = useState("");
  let profileDetails: any = sessionStorage.getItem("user-details");
  profileDetails = profileDetails
    ? JSON.parse(profileDetails)
    : {
        FullName: "",
        Mobile: "",
        Email: "",
      };
  const [clientMessage, setClientMessage] = useState("");
  const [searchData, setSearchData] = useState("");
  const [userFilterData, setUserFilterData] = useState("");
  const [rejectedReason, setRejectedReason] = useState("");
  const [deletedReason, setDeletedReason] = useState("");
  const [pagenumber, setPageNumber] = useState(1);
  const [paginationCount, setpaginationCount] = useState(1);
  const [dateRangeFilterData, setDateRangeFilterData] = useState<any>({
    fromDate: dayjs().subtract(2, "month"),
    toDate: dayjs(new Date()),
  });
  const [recordsCount, setRecordsCount] = useState(0);
  const [stageName, setStageName] = useState<any>("all");
  const [sortactivity, setsortactivity] = useState<any>("DESC");
  const [clientDeleted, setClientDeleted] = useState(false);
  const [clientCompleted, setClientCompleted] = useState(false);
  const [rotate, setRotate] = useState(false);
  // const [isAdmin, setIsAdmin] = useState(true);
  const isAdmin = sessionStorage.getItem("isAdmin");
  const [value, setValue] = useState<DateRange<Dayjs>>([
    dayjs().subtract(2, "month"),
    dayjs(),
  ]);
  const [datevalue, setdateValue] = useState<DateRange<Dayjs | any>>([
    dayjs().subtract(2, "month"),
    dayjs(),
  ]);
  const [userId, setUserId] = useState<any>("");
  const [openFilters, setOpenFilters] = useState(false);

  function formatDatePayload(dateString: any) {
    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
  }

  const columns = [
    {
      name: <b style={titletextStyle}>SrNo</b>,
      selector: "srno",
      sortable: true,

      cell: (row: any, index: any) => (
        <b style={contenttextStyle}>{index + 1 + paginationCount * 20 - 20}</b>
      ),
    },

    {
      name: <b style={titletextStyle}>Demat Number</b>,
      selector: "pan_no",
      sortable: true,

      cell: (row: any) => (
        // <Tooltip title={!row.is_completed ? "Click to Login" : ""}>
        <b style={{ fontSize: "12px", cursor: "pointer" }}>
          {row.demat_number}
        </b>
        // </Tooltip>
      ),
    },
    {
      name: <b style={titletextStyle}>Client ID</b>,
      selector: "client_name",
      sortable: true,

      cell: (row: any) => <b style={contenttextStyle}>{row.client_id}</b>,
    },

    {
      name: <b style={titletextStyle}>Actions</b>,
      selector: "spamReportRate",
      sortable: false,
      allowOverflow: true,
      button: true,

      cell: (row: any) => (
        <b style={contenttextStyle}>
          <Tooltip title="View Details">
            <IconButton
              onClick={() => {
                handleViewClientDetails(row);
              }}
            >
              <RemoveRedEyeOutlinedIcon style={{ color: "black" }} />
            </IconButton>
          </Tooltip>
        </b>
      ),
    },
  ];

  useEffect(() => {
    setRecordsCount(0);
    setdata([]);
    const delayDebounceFn = setTimeout(() => {
      GetRekycDashboard();
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [selectedTab, stageName, pagenumber, sortactivity, dateRangeFilterData]);

  const GetRekycDashboard = () => {
    setloading(true);
    let obj: any = {
      page_no: pagenumber,
      page_size: perPage,
      type: stageName !== "" ? stageName : mappingValues[selectedTab],
      filter_date_from:
        dateRangeFilterData.fromDate === ""
          ? ""
          : formatDatePayload(dateRangeFilterData.fromDate),
      filter_date_to:
        dateRangeFilterData.toDate === ""
          ? ""
          : formatDatePayload(dateRangeFilterData.toDate),
      sort_activity: sortactivity,
    };
    GetDashboardDematAPICall(obj)
      .then((response: any) => {
        // console.log("GetFinancialUpdateDashboardAPICall", response.data.data);

        setdata(response.data.data.data ? response.data.data.data : []);

        setTotalRows(Math.ceil(response.data.data.total_count / 20));
        setRecordsCount(
          response.data.data.total_count ? response.data.data.total_count : 0
        );
        setloading(false);
      })
      .catch((error: any) => {
        // console.log("error", error);
        setloading(false);
      });
  };

  const handleViewClientDetails = (data: any) => {
    setclientDetailsPopup({
      status: true,
      data: data.client_id,
    });
    setClientId(data.client_id);
  };

  const handleCloseClientDetailsPopup = (data: any) => {
    sessionStorage.removeItem(`${rekycClientDetailspopup.data}_aadhaar`);
    sessionStorage.removeItem(
      `${rekycClientDetailspopup.data}_webcam_client_photo`
    );

    sessionStorage.removeItem(`${rekycClientDetailspopup.data}_income_proof`);
    sessionStorage.removeItem(`${rekycClientDetailspopup.data}_e_sign_pdf`);
    sessionStorage.removeItem(`${rekycClientDetailspopup.data}_kra_upload`);

    sessionStorage.removeItem(`${rekycClientDetailspopup.data}_cmr`);
    sessionStorage.removeItem(
      `${rekycClientDetailspopup.data}_e_sign_extracted_pdf`
    );
    //  console.log("data", data);
    setclientDetailsPopup({
      status: false,
      data: "",
    });

    // getClients(1, perPage, "", "", "", "", "");

    if (data === "Deleted") {
      GetRekycDashboard();
      setClientMessage("Client Deleted Successfully");
      setTimeout(() => {
        setClientMessage("");
      }, 5000);
    } else if (data === "Client Approved") {
      GetRekycDashboard();
      setClientMessage("Client Approved Successfully");
      setTimeout(() => {
        setClientMessage("");
      }, 5000);
    } else if (data === "Client Rejected") {
      GetRekycDashboard();
      setClientMessage("Client Rejected Successfully");
      setTimeout(() => {
        setClientMessage("");
      }, 5000);
    } else {
      setClientMessage(""); // Reset the message for other cases
    }
  };

  const handlePageChange = (value: any, page: number) => {
    // getClients(page, perPage, "", "", "", "", "");
    setpaginationCount(page);
    setPageNumber(page);
  };

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setPerPage(newPerPage);
    GetRekycDashboard();
  };

  const formatDate = (date: any) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hour = String(date.getHours()).padStart(2, "0");
    const minute = String(date.getMinutes()).padStart(2, "0");
    const second = String(date.getSeconds()).padStart(2, "0");
    return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
  };

  const handleDateRangeChange = (data: any) => {
    setDateRangeFilterData({
      fromDate: !data[0].$d ? "" : formatDate(data[0].$d),
      toDate: !data[1].$d ? "" : formatDate(data[1].$d),
    });
  };

  const handleClearFilters = () => {
    setdateValue([null, null]);
    setSearchData("");
    setUserId("");
    setUserFilterData("");
    handleDateRangeChange(["", ""]);
  };
  function formatDatePayloadFilters(dateString: any) {
    // Create a new Date object from the provided date string
    const date = new Date(dateString);

    // Extract the year, month, and day from the Date object
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-indexed, so we add 1
    const day = String(date.getDate()).padStart(2, "0");

    // Concatenate the year, month, and day with a hyphen separator to get the desired format
    const formattedDate = `${day}-${month}-${year}`;

    return formattedDate;
  }
  const handleCloseFilters = () => {
    setOpenFilters(false);
  };
  const handleSelectChange = (event: SelectChangeEvent) => {
    setpaginationCount(1);
    setStageName(event.target.value as string);

    setPageNumber(1);
  };

  return (
    <div style={{ marginTop: "6px", width: "100%" }}>
      <Helmet>
        <title>Demat</title>
        <meta name="Demat" content="Demat" />
      </Helmet>

      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "space-between",
          position: "relative",
        }}
      >
        <div className={classes.category}>
          <FormControl sx={{ width: "230px" }} size="small">
            <InputLabel id="demo-simple-select-label">Category</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={stageName}
              name="selectStage"
              label="Category"
              onChange={handleSelectChange}
            >
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="assigned">Assigned</MenuItem>
              <MenuItem value="non_assigned">Non Assigned</MenuItem>
            </Select>
          </FormControl>
        </div>

        {/* {datevalue &&
          datevalue[0] &&
          datevalue[0]?.$d &&
          datevalue &&
          datevalue[1] &&
          datevalue[1]?.$d  && ( */}
        <div className={classes.chipsSection}>
          {/* <p className={classes.appliedFilters}>Applied Filters</p> */}
          {datevalue && datevalue[0] && datevalue[0]?.$d && (
            <div className={classes.chips}>
              From Date
              <div>
                <Chip
                  label={
                    !datevalue[0].$d
                      ? ""
                      : formatDatePayloadFilters(datevalue[0]?.$d)
                  }
                  variant="outlined"
                  size="small"
                  onDelete={() => {
                    setdateValue(["", ""]);
                    handleDateRangeChange(["", ""]);
                  }}
                />
              </div>
            </div>
          )}
          {datevalue && datevalue[1] && datevalue[1]?.$d && (
            <div className={classes.chips}>
              To Date
              <div>
                <Chip
                  label={
                    !datevalue[1].$d
                      ? ""
                      : formatDatePayloadFilters(datevalue[1]?.$d)
                  }
                  variant="outlined"
                  size="small"
                  onDelete={() => {
                    setdateValue([null, null]);
                    handleDateRangeChange(["", ""]);
                  }}
                />
              </div>
            </div>
          )}

          {userId && (
            <div className={classes.chips}>
              User
              <div>
                <Chip
                  label={userId?.full_name}
                  variant="outlined"
                  size="small"
                  onDelete={() => {
                    setUserId("");
                    setUserFilterData("");
                  }}
                />
              </div>
            </div>
          )}
        </div>
        {/* )} */}

        <div className={classes.emailListHeader}>
          <Button
            variant="contained"
            onClick={handleClearFilters}
            sx={{
              background: "#8B93FF",
              boxShadow: "none",
              textTransform: "none",
              "&:hover": {
                background: "#8B93FF",
                boxShadow: "none",
              },
            }}
          >
            Clear
          </Button>

          <Button
            variant="outlined"
            startIcon={<FilterListIcon style={{ color: "#8B93FF" }} />}
            onClick={() => setOpenFilters(true)}
            sx={{
              textTransform: "none",
              color: "#000",
              border: "1px solid #DDDCE1",
              "&:hover": {
                color: "#000",
                border: "1px solid #DDDCE1",
              },
            }}
          >
            Filters
          </Button>
          {openFilters && (
            <DematFilters
              selectedTab={selectedTab}
              datevalue={datevalue}
              setdateValue={setdateValue}
              handleDateRangeChange={handleDateRangeChange}
              handleCloseFilters={handleCloseFilters}
            />
          )}
        </div>
      </div>

      <div>
        <DataTableComponentNomineeUpdate
          columns={columns}
          data={data}
          loading={loading}
          pagination={true}
          paginationServer={true}
          handlePerRowsChange={handlePerRowsChange}
          handlePageChange={handlePageChange}
          totalRows={totalRows}
          paginationCount={paginationCount}
          recordsCount={recordsCount}
        />
      </div>
      {rekycClientDetailspopup.status && (
        <DematPopup
          data={rekycClientDetailspopup.data}
          open={rekycClientDetailspopup.status}
          handleClose={handleCloseClientDetailsPopup}
          rejectedReason={rejectedReason}
          deletedReason={deletedReason}
          selectedTabNew={stageName}
          stageName={stageName}
        />
      )}

      <SucessSnackBar message={clientMessage} />
    </div>
  );
}
