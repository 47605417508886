import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { Formik } from "formik";
import {
  IApproveClientApplication,
  IClientData,
  IDeleteClient,
  IDeleteUser,
  IGeoJith,
  IKRARegister,
  IRejectClientApplication,
} from "../../../Utils/dtos";
import {
  Stack,
  Fade,
  Dialog,
  DialogTitle,
  Box,
  IconButton,
  Modal,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import Button from "@mui/joy/Button";
import { js2xml } from "xml-js";
import {
  ApproveNRIApplicationAPICall,
  ApprovePartnerApplicationAPICall,
  DeletPartnerClientAPICall,
  DeletUserAPICall,
  DeleteNRIClientAPICall,
  GetNRIDetailsAPICall,
  GetNRIStagesAPICall,
  GetPartnerDetailsAPICall,
  GetPartnerStagesAPICall,
  RejectNRIApplicationAPICall,
  RejectPartnerApplicationAPICall,
  geojithAPICall,
  kraRegisterAPICall,
} from "../../../Utils/APICalls";
import Styles from "./NRIDetailsPopUp.module.scss";
import Tabs from "../../Tabs/Tabs";
import Base64ToImage from "../../Base64ToImage/Base64ToImage";
import Reject from "../RejectComponent/Reject";
import ErrorSnackBar from "../../SnackBar/ErrorSnackBar";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteClient from "../DeleteClient/DeleteClient";
import SucessSnackBar from "../../SnackBar/SuccessSnackBar";
import CheckedBox from "../../../assets/Images/CheckedBox.png";
import EmptyCheckBox from "../../../assets/Images/EmptyCheckBox.png";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import { unset } from "lodash";
import GeojithResponseModalpopup from "../GeojithResponseModal/GeojithResponseModalPopUp";
import Base64ToImagePartner from "../../Base64ToImage/Base64toImagePartner";
import TabsNRI from "../../TabsNRI/TabsNRI";
import NRIBase64toImage from "../../Base64ToImage/NRIBase64toImage";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "96%",
  height: "95%",
  bgcolor: "background.paper",
  borderRadius: "20px",
  boxShadow: 24,
  p: 2,
};

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const documentTypes: any = {
  BankProof: "bank_proof",
  PISProof: "pis_proof",
  Overseas: "overseas_address_proof",
  Permanent: "permanent_address_proof",
  Correspondance: "correspondence_address_proof",
  Photo: "photography",
  Pan: "pancard",
  Signature: "signature",
  Passport: "passport",
  VISA: "visa",
  Nominee1: "nominee_proof1",
  Nominee2: "nominee_proof2",
  Nominee3: "nominee_proof3",
  IPV: "webcam_client_photo",
  Final: "",
};

const TradingMappingValuesNew: any = {
  futures_and_options: "Futures and Options",
  mutual_funds: "Mutual Funds",
  equity: "Equity",
};

interface Props {
  open: boolean;
  handleClose: any;
  handleSubmitData: any;
  data: string;
  rejectedReason?: any;
  deletedReason?: any;
  selectedTabNew?: string;
  handlePermanentDeleteClient?: any;
  clientTimeStamp?: any;
  stageName?: any;
}

export default function NRIDetailspopup({
  open,
  handleClose,
  data,
  handleSubmitData,
  rejectedReason,
  selectedTabNew,
  deletedReason,
  handlePermanentDeleteClient,
  clientTimeStamp,
  stageName,
}: Props) {
  // const [open, setOpen] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [openDeletePopUp, setDeletePopUp] = useState(false);
  const [clientData, setClientData] = useState<any>();

  const [tradingPreference, setTradingPrefernce] = useState<any>({
    mutual_funds: "",
    equity: "",
    futures_and_options: "",
  });
  const [selectedTab, setselectedTab] = useState<string>("BankProof");
  const [successMessage, setSuccessMessage] = useState("");

  const [tabLabels, setTabLabels] = useState([
    "BankProof",
    "Overseas",
    "Permanent",
    "Correspondance",
    "Photo",
    "Pan",
    "Signature",
    "Passport",
    "VISA",
    "Nominee1",
    "Nominee2",
    "Nominee3",
    "IPV",
    "Final",
  ]);
  const [docType, setDocType] = useState("bank_proof");
  const [stagesData, setStagesData] = useState<any>();
  const [rejectLoading, setRejectLoading] = useState(false);
  const [approveLoading, setApproveLoading] = useState(false);
  const name = clientData && clientData.client_details.NameOnPan;
  const AadhaarXMLData = clientData && clientData.client_details.AadhaarXml;

  const removeStages = (stages: any) => {
    const stagesToRemove = ["Terms and Conditions", "Segment Details"];
    const filteredStages = stages.filter(
      (stage: any) => !stagesToRemove.includes(stage.Stage_Name)
    );
    return filteredStages;
  };

  useEffect(() => {
    getDetails();

    GetNRIStagesAPICall()
      .then((response: any) => {
        const responseData = response.data;
        const updatedStages = removeStages(responseData.data.stage_list);
        setStagesData(updatedStages);
      })
      .catch((error: any) => {
        //  console.log("error", error);
      });
  }, []);

  useEffect(() => {
    sessionStorage.removeItem(`${data}_bank_proof`);
    sessionStorage.removeItem(`${data}_overseas_address_proof`);
    sessionStorage.removeItem(`${data}_permanent_address_proof`);
    sessionStorage.removeItem(`${data}_correspondence_address_proof`);
    sessionStorage.removeItem(`${data}_photography`);
    sessionStorage.removeItem(`${data}_pancard`);
    sessionStorage.removeItem(`${data}_signature`);
    sessionStorage.removeItem(`${data}_passport`);
    sessionStorage.removeItem(`${data}_visa`);
    sessionStorage.removeItem(`${data}_nominee_proof1`);
    sessionStorage.removeItem(`${data}_nominee_proof2`);
    sessionStorage.removeItem(`${data}_nominee_proof3`);
    sessionStorage.removeItem(`${data}_webcam_client_photo`);
  }, [handleClose]);

  // console.log(data);
  const isAdmin = sessionStorage.getItem("isAdmin");
  const getDetails = () => {
    const obj: IClientData = {
      client_id: data,
    };
    GetNRIDetailsAPICall(obj)
      .then((response: any) => {
       // console.log("GetNRIDetailsAPICall", response.data.client_data);

        setClientData(response.data.client_data);
      })
      .catch((error: any) => {});
  };

  const handleRejectClient = (reject: any) => {
    //  console.log("data", reject);

    let obj: IRejectClientApplication = {
      client_id: data,
      stage_id: reject.selectStage.Stage_Id,
      error_msg: reject.reasonForRejection,
    };
    setRejectLoading(true);
    RejectNRIApplicationAPICall(obj)
      .then((response: any) => {
        const responseData = response.data;
        // console.log("responseData", responseData);
        setRejectLoading(false);
        handleClose("Client Rejected");
      })
      .catch((error: any) => {
        setRejectLoading(false);
        // console.log("error", error);
      });
  };
  const handleApproveClient = () => {
    let obj: IApproveClientApplication = {
      client_id: data,
    };
    setApproveLoading(true);
    ApproveNRIApplicationAPICall(obj)
      .then((response: any) => {
        const responseData = response.data;
        //  console.log("responseData", responseData);
        setApproveLoading(false);
        handleClose("Client Approved");
      })
      .catch((error: any) => {
        // console.log("error", error);
        setApproveLoading(false);
        if (error.response.data.error.message) {
          seterrorMessage(error.response.data.error.message);
          setTimeout(() => {
            seterrorMessage("");
          }, 5000);
        }
      });
  };

  const handleChangeTab = (tab: any) => {
    // console.log("tab", tab);
    setselectedTab(tab);
    setDocType(documentTypes[tab]);
  };

  const handleOpenDeletePopUp = () => {
    setDeletePopUp(true);
  };
  const handleCloseDeletepopUp = () => {
    setDeletePopUp(false);
  };

  const handleDeleteClient = (dataDelete: any) => {
    let obj: IDeleteClient = {
      client_id: data,
      deleted_reason: dataDelete.deleted_reason,
    };
    DeleteNRIClientAPICall(obj)
      .then((response: any) => {
        const responseData = response.data;
        //  console.log("responseData", responseData);
        setDeletePopUp(false);
        handleClose("Deleted");
        setSuccessMessage("Client Deleted Successfully");
        setTimeout(() => {
          setSuccessMessage("");
        }, 5000);
      })
      .catch((error: any) => {
        // console.log("error", error);
        setDeletePopUp(false);
        //  handleClose("Deleted");
        if (error.response.data.error.message) {
          seterrorMessage(error.response.data.error.message);
          setTimeout(() => {
            seterrorMessage("");
          }, 5000);
        }
      });
  };

  const formatDate = (dateString: any) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    const hour = date.getHours() % 12 || 12;
    const minute = date.getMinutes().toString().padStart(2, "0");
    const meridian = date.getHours() >= 12 ? "PM" : "AM";

    return `${day}-${month}-${year} ${hour}:${minute} ${meridian}`;
  };

  const formatArrangeDOB = (inputDate: any) => {
    const parts = inputDate.split("-");
    const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
    return formattedDate;
  };

  const close = (e: any) => {
    if (e.keyCode === 27) {
      handleClose();
    }
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        closeAfterTransition
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
        onClose={close}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Stack style={{ position: "absolute", top: 0, right: 10 }}>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Stack>
            <div className={Styles.wrapper}>
              <div className={Styles.leftContainer}>
                {rejectedReason &&
                  (selectedTabNew === "rejected" ||
                    selectedTabNew === "all") && (
                    <>
                      <div style={{ color: "red" }}>
                        <span style={{ fontWeight: 700 }}>
                          Rejected Reason:
                        </span>{" "}
                        <span style={{ fontSize: "14px" }}>
                          {" "}
                          {rejectedReason}
                        </span>
                      </div>
                      <Divider sx={{ borderColor: "grey", margin: "10px 0" }} />
                    </>
                  )}
                {deletedReason && selectedTabNew === "deleted" && (
                  <>
                    <div style={{ color: "red" }}>
                      <span style={{ fontWeight: 700 }}>Deleted Reason:</span>{" "}
                      <span style={{ fontSize: "14px" }}> {deletedReason}</span>
                    </div>
                    <Divider sx={{ borderColor: "grey", margin: "10px 0" }} />
                  </>
                )}

                <div className={Styles.contentdiv}>
                  <p className={Styles.title}>
                    Mobile :{" "}
                    <span className={Styles.content}>
                      {clientData && clientData.client_details.Mobile}
                    </span>
                  </p>
                  <p className={Styles.title}>
                    Email :{" "}
                    <span className={Styles.content}>
                      {clientData && clientData.client_details.Email}
                    </span>
                  </p>
                  {/* <p className={Styles.title}>
                    Type : <span className={Styles.content}>Indian</span>
                  </p> */}
                </div>
                <Divider sx={{ borderColor: "grey", margin: "10px 0" }} />
                <div className={Styles.contentdiv}>
                  <p className={Styles.title}>
                    Country Code :{" "}
                    <span className={Styles.content}>
                      {clientData && clientData.client_details.CountryCode}
                    </span>
                  </p>
                  <p className={Styles.title}>
                    CLIENT ID :{" "}
                    <span className={Styles.content}>
                      {clientData && clientData.client_details.NRICode}
                    </span>
                  </p>
                  <p className={Styles.title}>
                    Gender :{" "}
                    <span className={Styles.content}>
                      {clientData && clientData.client_details.Gender}
                    </span>
                  </p>
                </div>

                <Divider sx={{ borderColor: "grey", margin: "10px 0" }} />
                <div className={Styles.contentdiv}>
                  <p className={Styles.title}>
                    PAN :{" "}
                    <span className={Styles.content}>
                      {clientData && clientData.client_details.PanNum}
                    </span>
                  </p>
                  <p className={Styles.title}>
                    PAN NAME :{" "}
                    <span className={Styles.content}>
                      {clientData && clientData.client_details.ClientPanName}
                    </span>
                  </p>
                </div>
                <Divider sx={{ borderColor: "grey", margin: "10px 0" }} />
                <div className={Styles.contentdiv}>
                  <p className={Styles.title}>
                    DOB :{" "}
                    <span className={Styles.content}>
                      {clientData &&
                        clientData.client_details.DOB &&
                        formatArrangeDOB(clientData.client_details.DOB)}
                    </span>
                  </p>
                  <p className={Styles.title}>
                    Email Verified At :{" "}
                    <span className={Styles.content}>
                      {clientTimeStamp
                        ? clientTimeStamp.EmailVerifiedTS === null
                          ? ""
                          : formatDate(
                              clientTimeStamp.EmailVerifiedTS.slice(0, 16)
                            )
                        : ""}
                    </span>
                  </p>
                </div>

                <Divider sx={{ borderColor: "grey", margin: "10px 0" }} />

                {/* ----------------------------Basic Info------------------------------- */}
                <p
                  className={Styles.title}
                  style={{
                    // textDecoration: "underline",
                    background: "#d9e4fd",
                    // width: "max-content",
                    padding: "3px 20px",
                    borderRadius: "5px",
                    textAlign: "center",
                  }}
                >
                  Basic Info
                </p>

                <div className={Styles.contentdiv}>
                  <div style={{ width: "360px" }}>
                    <div>
                      <p className={Styles.title}>
                        Application No :{" "}
                        <span className={Styles.content}>
                          {clientData &&
                            clientData.client_details.ApplicationNum}
                        </span>
                      </p>

                      <div>
                        <p className={Styles.title}>
                          Mobile No :{" "}
                          <span className={Styles.content}>
                            {clientData && clientData.client_details.Mobile}
                          </span>
                        </p>
                      </div>
                      <div>
                        <p className={Styles.title}>
                          PAN Number :{" "}
                          <span className={Styles.content}>
                            {clientData && clientData.client_details.PanNum}
                          </span>
                        </p>
                      </div>
                      <div>
                        <p className={Styles.title}>
                          DOB :{" "}
                          <span className={Styles.content}>
                            {clientData &&
                              clientData.client_details.DOB &&
                              formatArrangeDOB(clientData.client_details.DOB)}
                          </span>
                        </p>
                      </div>
                      <div>
                        <p className={Styles.title}>
                          Maritalstatus :{" "}
                          <span className={Styles.content}>
                            {clientData &&
                            clientData.client_details.MaritalStatus === "true"
                              ? "Married"
                              : "Single"}
                          </span>
                        </p>
                      </div>
                      <div>
                        <p className={Styles.title}>
                          Father/SpouseName :{" "}
                          <span className={Styles.content}>
                            {clientData && clientData.client_details.FatherName}
                          </span>
                        </p>
                      </div>
                      <div>
                        <p className={Styles.title}>
                          Location :{" "}
                          <span className={Styles.content}>
                            {clientData && clientData.client_details.Latitude},
                            {clientData && clientData.client_details.Longitude}
                          </span>
                        </p>
                      </div>
                      <div>
                        <p className={Styles.title}>
                          WebImage TS :{" "}
                          <span className={Styles.content}>
                            {clientData &&
                              clientData.client_details.TimeStampJson &&
                              clientData.client_details.TimeStampJson
                                .stage_web_image_TS &&
                              formatDate(
                                clientData.client_details.TimeStampJson.stage_web_image_TS.slice(
                                  0,
                                  16
                                )
                              )}
                          </span>
                        </p>
                      </div>
                      {/* <div>
                        <p className={Styles.title}>
                          ResidentialStatus :{" "}
                          <span className={Styles.content}>
                            {clientData && clientData.client_details.Country}
                          </span>
                        </p>
                      </div> */}
                      <div>
                        <p className={Styles.title}>
                          Politically Exposed :{" "}
                          <span className={Styles.content}>
                            {clientData &&
                              clientData.client_details.PoliticallyExposed ===
                                false &&
                              "No"}
                            {clientData &&
                              clientData.client_details.PoliticallyExposed ===
                                true &&
                              "Yes"}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div style={{ marginTop: "0px" }}>
                    <p className={Styles.title} style={{ marginLeft: "20px" }}>
                      Client Name :{" "}
                      <span className={Styles.content}>
                        {clientData && clientData.client_details.ClientName}
                      </span>
                    </p>
                    <p className={Styles.title} style={{ marginLeft: "20px" }}>
                      Email :{" "}
                      <span className={Styles.content}>
                        {clientData && clientData.client_details.Email}
                      </span>
                    </p>
                    {/* <p className={Styles.title} style={{ marginLeft: "20px" }}>
                      Created Time :{" "}
                      <span className={Styles.content}>12/07/2023</span>
                    </p> */}
                    <p className={Styles.title} style={{ marginLeft: "20px" }}>
                      Gender :{" "}
                      <span className={Styles.content}>
                        {clientData && clientData.client_details.Gender}
                      </span>
                    </p>
                    <p className={Styles.title} style={{ marginLeft: "20px" }}>
                      MothersName :{" "}
                      <span className={Styles.content}>
                        {clientData && clientData.client_details.MotherName}
                      </span>
                    </p>
                    <p className={Styles.title} style={{ marginLeft: "20px" }}>
                      Occupation :{" "}
                      <span className={Styles.content}>
                        {clientData && clientData.client_details.Occupation}
                      </span>
                    </p>
                    <p className={Styles.title} style={{ marginLeft: "20px" }}>
                      Occupation Others :{" "}
                      <span className={Styles.content}>
                        {clientData &&
                          clientData.client_details.OccupationOthers}
                      </span>
                    </p>
                    <p className={Styles.title} style={{ marginLeft: "20px" }}>
                      AnnualIncome :{" "}
                      <span className={Styles.content}>
                        {clientData && clientData.client_details.AnnualIncome}
                      </span>
                    </p>

                    <p className={Styles.title} style={{ marginLeft: "20px" }}>
                      Trading Experience :{" "}
                      <span className={Styles.content}>
                        {clientData && clientData.client_details.TradingExp}
                      </span>
                    </p>
                  </div>
                </div>

                {/* ----------------------------Trading Preference------------------------------- */}

                <Divider sx={{ borderColor: "grey", margin: "10px 0" }} />
                <div className={Styles.contentdiv}>
                  <div style={{ width: "100%" }}>
                    <p
                      className={Styles.title}
                      style={{
                        // textDecoration: "underline",
                        background: "#d9e4fd",
                        width: "100%",
                        padding: "3px 20px",
                        borderRadius: "5px",
                        textAlign: "center",
                      }}
                    >
                      Trading Prefernce
                    </p>

                    {clientData &&
                      clientData.TradingPref &&
                      clientData.TradingPref.length > 0 &&
                      clientData.TradingPref.map((a: any) => (
                        <p
                          className={Styles.title}
                          style={{
                            width: "max-content",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <p style={{ width: "150px" }}> {TradingMappingValuesNew[Object.keys(a)[0]]}</p>{" "}
                          :
                          <span className={Styles.content}>
                            {/* {tradingPreference.futures_and_options} */}
                            <img
                              src={
                                Object.values(a)[0] === "true"
                                  ? CheckedBox
                                  : EmptyCheckBox
                              }
                              style={{ width: "20px", marginLeft: "10px" }}
                            />
                          </span>
                        </p>
                      ))}
                  </div>
                </div>

                <Divider sx={{ borderColor: "grey", margin: "10px 0" }} />

                {/* ----------------------------Overseas address------------------------------- */}
                <p
                  className={Styles.title}
                  style={{
                    // textDecoration: "underline",
                    background: "#d9e4fd",
                    // width: "max-content",
                    padding: "3px 20px",
                    borderRadius: "5px",
                    textAlign: "center",
                  }}
                >
                  Overseas Address
                </p>
                <div className={Styles.contentdiv}>
                  <div>
                    <p className={Styles.title}>
                      Address1 :{" "}
                      <span className={Styles.content}>
                        {clientData &&
                          clientData.client_details?.OverseasAddressJson
                            ?.Address[0].address1}
                      </span>
                    </p>
                    <p className={Styles.title}>
                      Address2 :{" "}
                      <span className={Styles.content}>
                        {clientData &&
                          clientData.client_details?.OverseasAddressJson
                            ?.Address[0].address2}
                      </span>
                    </p>
                    <p className={Styles.title}>
                      Address3 :{" "}
                      <span className={Styles.content}>
                        {clientData &&
                          clientData.client_details?.OverseasAddressJson
                            ?.Address[0].address3}
                      </span>
                    </p>
                    <p className={Styles.title}>
                      Country :{" "}
                      <span className={Styles.content}>
                        {clientData &&
                          clientData.client_details?.OverseasAddressJson
                            ?.Address[0].country}
                      </span>
                    </p>
                    <p className={Styles.title}>
                      State :{" "}
                      <span className={Styles.content}>
                        {clientData &&
                          clientData.client_details?.OverseasAddressJson
                            ?.Address[0].state}
                      </span>
                    </p>
                    <p className={Styles.title}>
                      City :{" "}
                      <span className={Styles.content}>
                        {clientData &&
                          clientData.client_details?.OverseasAddressJson
                            ?.Address[0].city}
                      </span>
                    </p>
                    <p className={Styles.title}>
                      Pincode :{" "}
                      <span className={Styles.content}>
                        {clientData &&
                          clientData.client_details?.OverseasAddressJson
                            ?.Address[0].pincode}
                      </span>
                    </p>
                  </div>
                </div>
                <Divider sx={{ borderColor: "grey", margin: "10px 0" }} />

                {/* ----------------------------Permanent address------------------------------- */}

                <p
                  className={Styles.title}
                  style={{
                    // textDecoration: "underline",
                    background: "#d9e4fd",
                    // width: "max-content",
                    padding: "3px 20px",
                    borderRadius: "5px",
                    textAlign: "center",
                  }}
                >
                  Permanent Address{" "}
                  {clientData &&
                    clientData.client_details?.OverseasAddressJson
                      ?.is_permanent &&
                    "(Same as Overseas)"}
                </p>
                <div className={Styles.contentdiv}>
                  <div>
                    <p className={Styles.title}>
                      Address1 :{" "}
                      <span className={Styles.content}>
                        {clientData &&
                          clientData.client_details?.PermanentAddressJson
                            ?.PermanentAddress[0].address1}
                      </span>
                    </p>
                    <p className={Styles.title}>
                      Address2 :{" "}
                      <span className={Styles.content}>
                        {clientData &&
                          clientData.client_details?.PermanentAddressJson
                            ?.PermanentAddress[0].address2}
                      </span>
                    </p>
                    <p className={Styles.title}>
                      Address3 :{" "}
                      <span className={Styles.content}>
                        {clientData &&
                          clientData.client_details?.PermanentAddressJson
                            ?.PermanentAddress[0].address3}
                      </span>
                    </p>
                    <p className={Styles.title}>
                      Country :{" "}
                      <span className={Styles.content}>
                        {clientData &&
                          clientData.client_details?.PermanentAddressJson
                            ?.PermanentAddress[0].country}
                      </span>
                    </p>
                    <p className={Styles.title}>
                      State :{" "}
                      <span className={Styles.content}>
                        {clientData &&
                          clientData.client_details?.PermanentAddressJson
                            ?.PermanentAddress[0].state}
                      </span>
                    </p>
                    <p className={Styles.title}>
                      City :{" "}
                      <span className={Styles.content}>
                        {clientData &&
                          clientData.client_details?.PermanentAddressJson
                            ?.PermanentAddress[0].city}
                      </span>
                    </p>
                    <p className={Styles.title}>
                      Pincode :{" "}
                      <span className={Styles.content}>
                        {clientData &&
                          clientData.client_details?.PermanentAddressJson
                            ?.PermanentAddress[0].pincode}
                      </span>
                    </p>
                  </div>
                </div>
                <Divider sx={{ borderColor: "grey", margin: "10px 0" }} />

                {/* ----------------------------Coorespondance address------------------------------- */}

                <p
                  className={Styles.title}
                  style={{
                    // textDecoration: "underline",
                    background: "#d9e4fd",
                    // width: "max-content",
                    padding: "3px 20px",
                    borderRadius: "5px",
                    textAlign: "center",
                  }}
                >
                  Correspondance Address{" "}
                  {clientData &&
                    clientData.client_details?.OverseasAddressJson
                      ?.is_correspondance &&
                    "(Same as Overseas)"}
                  {clientData &&
                    clientData.client_details?.PermanentAddressJson
                      ?.is_permanent_same_as_correspondence &&
                    "(Same as Permanent Address)"}
                </p>
                <div className={Styles.contentdiv}>
                  <div>
                    <p className={Styles.title}>
                      Address1 :{" "}
                      <span className={Styles.content}>
                        {clientData &&
                          clientData.client_details?.CorresspondanceAddressJson
                            ?.CorresspondanceAddress[0].address1}
                      </span>
                    </p>
                    <p className={Styles.title}>
                      Address2 :{" "}
                      <span className={Styles.content}>
                        {clientData &&
                          clientData.client_details?.CorresspondanceAddressJson
                            ?.CorresspondanceAddress[0].address2}
                      </span>
                    </p>
                    <p className={Styles.title}>
                      Address3 :{" "}
                      <span className={Styles.content}>
                        {clientData &&
                          clientData.client_details?.CorresspondanceAddressJson
                            ?.CorresspondanceAddress[0].address3}
                      </span>
                    </p>
                    <p className={Styles.title}>
                      Country :{" "}
                      <span className={Styles.content}>
                        {clientData &&
                          clientData.client_details?.CorresspondanceAddressJson
                            ?.CorresspondanceAddress[0].country}
                      </span>
                    </p>
                    <p className={Styles.title}>
                      State :{" "}
                      <span className={Styles.content}>
                        {clientData &&
                          clientData.client_details?.CorresspondanceAddressJson
                            ?.CorresspondanceAddress[0].state}
                      </span>
                    </p>
                    <p className={Styles.title}>
                      City :{" "}
                      <span className={Styles.content}>
                        {clientData &&
                          clientData.client_details?.CorresspondanceAddressJson
                            ?.CorresspondanceAddress[0].city}
                      </span>
                    </p>
                    <p className={Styles.title}>
                      Pincode :{" "}
                      <span className={Styles.content}>
                        {clientData &&
                          clientData.client_details?.CorresspondanceAddressJson
                            ?.CorresspondanceAddress[0].pincode}
                      </span>
                    </p>
                  </div>
                </div>
                <Divider sx={{ borderColor: "grey", margin: "10px 0" }} />

                {/* ----------------------------Fatca Details------------------------------- */}

                <p
                  className={Styles.title}
                  style={{
                    // textDecoration: "underline",
                    background: "#d9e4fd",
                    // width: "max-content",
                    padding: "3px 20px",
                    borderRadius: "5px",
                    textAlign: "center",
                  }}
                >
                  Fatca Details{" "}
                </p>
                <div className={Styles.contentdiv}>
                  <div>
                    <p className={Styles.title}>
                      Country of Birth :{" "}
                      <span className={Styles.content}>
                        {clientData &&
                          clientData.fatca_details?.CountryOfBirth &&
                          clientData.fatca_details?.CountryOfBirth}
                      </span>
                    </p>
                    <p className={Styles.title}>
                      State of Birth :{" "}
                      <span className={Styles.content}>
                        {clientData &&
                          clientData.fatca_details?.StateOfBirth &&
                          clientData.fatca_details?.StateOfBirth}
                      </span>
                    </p>
                    <p className={Styles.title}>
                      City of Birth :{" "}
                      <span className={Styles.content}>
                        {clientData &&
                          clientData.fatca_details?.CityOfBirth &&
                          clientData.fatca_details?.CityOfBirth}
                      </span>
                    </p>
                    <p className={Styles.title}>
                      Country of Tax Residency :{" "}
                      <span className={Styles.content}>
                        {clientData &&
                          clientData.fatca_details?.CountryOfTaxResidency &&
                          clientData.fatca_details?.CountryOfTaxResidency}
                      </span>
                    </p>
                    <p className={Styles.title}>
                      Nationality :{" "}
                      <span className={Styles.content}>
                        {clientData &&
                          clientData.fatca_details?.Nationality &&
                          clientData.fatca_details?.Nationality}
                      </span>
                    </p>
                    <p className={Styles.title}>
                      Tax ID :{" "}
                      <span className={Styles.content}>
                        {clientData &&
                          clientData.fatca_details?.TaxIdentificationNumber &&
                          clientData.fatca_details?.TaxIdentificationNumber}
                      </span>
                    </p>
                  </div>
                </div>
                <Divider sx={{ borderColor: "grey", margin: "10px 0" }} />

                {/* ----------------------------Bank details------------------------------- */}
                <div className={Styles.contentdiv}>
                  <div style={{ width: "100%" }}>
                    <p
                      className={Styles.title}
                      style={{
                        // textDecoration: "underline",
                        background: "#d9e4fd",

                        padding: "3px 20px",
                        borderRadius: "5px",
                        textAlign: "center",
                      }}
                    >
                      Bank Details
                    </p>
                    <div>
                      <p className={Styles.title}>
                        BankName :{" "}
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <span className={Styles.content}>
                          {clientData &&
                            clientData.Bank_Details?.penny_drop_bank_details &&
                            clientData.Bank_Details?.penny_drop_bank_details[0]
                              .BankName}
                        </span>
                      </p>

                      <div>
                        <p className={Styles.title}>
                          IFSC Code :{" "}
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <span className={Styles.content}>
                            {clientData &&
                              clientData.Bank_Details
                                ?.penny_drop_bank_details &&
                              clientData.Bank_Details
                                ?.penny_drop_bank_details[0].Ifsc}
                          </span>
                        </p>
                      </div>
                      <div>
                        <p className={Styles.title}>
                          Micr Code :{" "}
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <span className={Styles.content}>
                            {clientData &&
                              clientData.Bank_Details
                                ?.penny_drop_bank_details &&
                              clientData.Bank_Details
                                ?.penny_drop_bank_details[0].Micr}
                          </span>
                        </p>
                      </div>
                      <div>
                        <p className={Styles.title}>
                          AcNo :{" "}
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <span className={Styles.content}>
                            {clientData &&
                              clientData.Bank_Details
                                ?.penny_drop_bank_details &&
                              clientData.Bank_Details
                                ?.penny_drop_bank_details[0].BankAccNum}
                          </span>
                        </p>
                      </div>

                      <div>
                        <p className={Styles.title}>
                          Name As In Bank :{" "}
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <span className={Styles.content}>
                            {clientData &&
                              clientData.Bank_Details
                                ?.penny_drop_bank_details &&
                              clientData.Bank_Details
                                ?.penny_drop_bank_details[0].CustomerNameInBank}
                          </span>
                        </p>
                      </div>
                      <div>
                        <p className={Styles.title}>
                          UPI Id :{" "}
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <span className={Styles.content}>
                            {clientData &&
                              clientData.Bank_Details
                                ?.penny_drop_bank_details &&
                              clientData.Bank_Details
                                ?.penny_drop_bank_details[0].UPIId}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* ----------------------------PIS Bank details------------------------------- */}

                {clientData && clientData.Bank_Details?.pis_bank_details && (
                  <div className={Styles.contentdiv}>
                    <div style={{ width: "100%" }}>
                      <p
                        className={Styles.title}
                        style={{
                          // textDecoration: "underline",
                          background: "#d9e4fd",

                          padding: "3px 20px",
                          borderRadius: "5px",
                          textAlign: "center",
                        }}
                      >
                        PIS Bank Details
                      </p>
                      <div>
                        <p className={Styles.title}>
                          BankName :{" "}
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <span className={Styles.content}>
                            {clientData &&
                              clientData.Bank_Details?.pis_bank_details &&
                              clientData.Bank_Details?.pis_bank_details[0]
                                .BankName}
                          </span>
                        </p>

                        <div>
                          <p className={Styles.title}>
                            IFSC Code :{" "}
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <span className={Styles.content}>
                              {clientData &&
                                clientData.Bank_Details?.pis_bank_details &&
                                clientData.Bank_Details?.pis_bank_details[0]
                                  .Ifsc}
                            </span>
                          </p>
                        </div>
                        <div>
                          <p className={Styles.title}>
                            Micr Code :{" "}
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <span className={Styles.content}>
                              {clientData &&
                                clientData.Bank_Details?.pis_bank_details &&
                                clientData.Bank_Details?.pis_bank_details[0]
                                  .Micr}
                            </span>
                          </p>
                        </div>
                        <div>
                          <p className={Styles.title}>
                            AcNo :{" "}
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <span className={Styles.content}>
                              {clientData &&
                                clientData.Bank_Details?.pis_bank_details &&
                                clientData.Bank_Details?.pis_bank_details[0]
                                  .BankAccNum}
                            </span>
                          </p>
                        </div>

                        <div>
                          <p className={Styles.title}>
                            Name As In Bank :{" "}
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <span className={Styles.content}>
                              {clientData &&
                                clientData.Bank_Details?.pis_bank_details &&
                                clientData.Bank_Details?.pis_bank_details[0]
                                  .CustomerNameInBank}
                            </span>
                          </p>
                        </div>
                        <div>
                          <p className={Styles.title}>
                            UPI Id :{" "}
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <span className={Styles.content}>
                              {clientData &&
                                clientData.Bank_Details?.pis_bank_details &&
                                clientData.Bank_Details?.pis_bank_details[0]
                                  .UPIId}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/* ----------------------------Nominee details------------------------------- */}

                <div className={Styles.contentdiv}>
                  <div>
                    <p className={Styles.title}>Nominee Details</p>
                    {clientData &&
                      clientData?.nominee_details?.NomineeDetails &&
                      clientData.nominee_details.NomineeDetails.map(
                        (data: any, index: any) => {
                          return (
                            <>
                              <p
                                className={Styles.title}
                                style={{
                                  background: "#d9e4fd",
                                  width: "100%",
                                  padding: "3px 20px",
                                  borderRadius: "5px",
                                  textAlign: "center",
                                }}
                              >
                                Nominee {index + 1}
                              </p>
                              <div>
                                <p className={Styles.title}>
                                  Name :{" "}
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  <span className={Styles.content}>
                                    {" "}
                                    {data.FullName}
                                  </span>
                                </p>

                                <div>
                                  <p className={Styles.title}>
                                    RelationShip :{" "}
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <span className={Styles.content}>
                                      {data.RelationshipName}
                                    </span>
                                  </p>
                                </div>
                                <div>
                                  <p className={Styles.title}>
                                    Relation Others : &nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;
                                    <span className={Styles.content}>
                                      {data.RelationshipOthersValue}
                                    </span>
                                  </p>
                                </div>
                                <div>
                                  <p className={Styles.title}>
                                    Email :{" "}
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    &nbsp;&nbsp;
                                    <span className={Styles.content}>
                                      {data.Email}
                                    </span>
                                  </p>
                                </div>
                                <div>
                                  <p className={Styles.title}>
                                    Mobile :{" "}
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <span className={Styles.content}>
                                      {data.Mobile}
                                    </span>
                                  </p>
                                </div>
                                <div>
                                  <p className={Styles.title}>
                                    Share Percentage :{" "}
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <span className={Styles.content}>
                                      {data.SharePercentage}
                                    </span>
                                  </p>
                                </div>
                                <div>
                                  <p className={Styles.title}>
                                    DOB :{" "}
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;
                                    <span className={Styles.content}>
                                      {data.DOB && formatArrangeDOB(data.DOB)}
                                    </span>
                                  </p>
                                </div>
                                <div>
                                  <p className={Styles.title}>
                                    Nominee Address :{" "}
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <span className={Styles.content}>
                                      {data.Address}
                                    </span>
                                  </p>
                                </div>
                                <div>
                                  <p className={Styles.title}>
                                    Pincode :{" "}
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <span className={Styles.content}>
                                      {data.Pincode}
                                    </span>
                                  </p>
                                </div>
                                <div>
                                  <p className={Styles.title}>
                                    City :{" "}
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;
                                    <span className={Styles.content}>
                                      {data.City}
                                    </span>
                                  </p>
                                </div>
                                <div>
                                  <p className={Styles.title}>
                                    State :{" "}
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;
                                    <span className={Styles.content}>
                                      {data.State}
                                    </span>
                                  </p>
                                </div>
                                <div>
                                  <p className={Styles.title}>
                                    Guardian FullName:{" "}
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <span className={Styles.content}>
                                      {data.GuradianFullName}
                                    </span>
                                  </p>
                                </div>
                                <div>
                                  <p className={Styles.title}>
                                    Guardian DOB: &nbsp;&nbsp;&nbsp;&nbsp;
                                    &nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;
                                    &nbsp;
                                    <span className={Styles.content}>
                                      {data.GuradianDOB &&
                                        formatArrangeDOB(data.GuradianDOB)}
                                    </span>
                                  </p>
                                </div>
                                <div>
                                  <p className={Styles.title}>
                                    Guardian Email: &nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;
                                    <span className={Styles.content}>
                                      {data.GuradianEmail}
                                    </span>
                                  </p>
                                </div>
                                <div>
                                  <p className={Styles.title}>
                                    Guardian Address: &nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;
                                    <span className={Styles.content}>
                                      {data.GuradianAddress}
                                    </span>
                                  </p>
                                </div>
                                <div>
                                  <p className={Styles.title}>
                                    Guardian City: &nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <span className={Styles.content}>
                                      {data.GuradianAddressCity}
                                    </span>
                                  </p>
                                </div>
                                <div>
                                  <p className={Styles.title}>
                                    Guardian PinCode: &nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <span className={Styles.content}>
                                      {data.GuradianAddressPinCode}
                                    </span>
                                  </p>
                                </div>
                                <div>
                                  <p className={Styles.title}>
                                    Guardian State: &nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <span className={Styles.content}>
                                      {data.GuradianAddressState}
                                    </span>
                                  </p>
                                </div>
                                <div>
                                  <p className={Styles.title}>
                                    Guardian Relation : &nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <span className={Styles.content}>
                                      {data.GuradianRealtionWithNominee}
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </>
                          );
                        }
                      )}
                  </div>
                </div>
              </div>

              <div
                className={Styles.rightContainer}
                style={{ width: "75%", overflowX: "scroll" }}
              >
                <TabsNRI
                  labels={tabLabels}
                  onChangeTab={handleChangeTab}
                  selectedTab={selectedTab}
                  clientData={clientData}
                />

                <NRIBase64toImage data={data} docType={docType} />
              </div>
            </div>
            {clientData && clientData.client_details.DeletedReason === "" && (
              <Tooltip title="Delete Client">
                <IconButton
                  onClick={handleOpenDeletePopUp}
                  sx={{
                    position: "absolute",
                    bottom: 30,
                    border: "1px solid red",
                  }}
                >
                  <DeleteIcon sx={{ color: "red" }} />
                </IconButton>
              </Tooltip>
            )}
            {clientData && clientData.client_details.DeletedReason !== "" && (
              <Tooltip title="Permanent Delete Client">
                <IconButton
                  onClick={handlePermanentDeleteClient}
                  sx={{
                    position: "absolute",
                    bottom: 30,
                    border: "1px solid red",
                  }}
                >
                  <DeleteIcon sx={{ color: "red" }} />
                </IconButton>
              </Tooltip>
            )}

            <DeleteClient
              open={openDeletePopUp}
              handleDeleteClient={handleDeleteClient}
              handleCloseDeletepopUp={handleCloseDeletepopUp}
            />
            <SucessSnackBar message={successMessage} />
            <ErrorSnackBar message={errorMessage} />

            <Stack
              direction={"row"}
              justifyContent={"space-evenly"}
              marginTop={"15px"}
              alignItems={"center"}
              marginLeft={"20px"}
            >
              {clientData &&
                clientData.client_details.IsCompleted === true && (
                  <>
                    {isAdmin === "true" && (
                      <div>
                        {selectedTabNew !== "deleted" &&
                          selectedTabNew !== "approved" && (
                            <Button
                              type="submit"
                              onClick={handleApproveClient}
                              loading={approveLoading}
                              // disabled={
                              //   clientData &&
                              //   clientData.client_details.IsCompleted === "true"
                              //     ? false
                              //     : true
                              // }
                            >
                              Approve
                            </Button>
                          )}
                      </div>
                    )}
                  </>
                )}

              <ErrorSnackBar message={errorMessage} />

              {selectedTabNew !== "deleted" && (
                <Reject
                  stagesData={stagesData}
                  handleRejectClient={handleRejectClient}
                  rejectLoading={rejectLoading}
                  disabled={false}
                />
              )}
            </Stack>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
