import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import classes from "../../components/DataTableComponent/DataTableComponent.module.scss";
import SearchIcon from "@mui/icons-material/Search";
import {
  Button,
  Stack,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Pagination from "@mui/material/Pagination";
import dayjs, { Dayjs } from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateRange } from "@mui/x-date-pickers-pro";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { LicenseInfo } from "@mui/x-date-pickers-pro";
import { width } from "@mui/system";
import StyledLoader from "../../components/Styledloader/StyledLoader";

// LicenseInfo.setLicenseKey(
//   'x0jTPl0USVkVZV0SsMjM1kDNyADM5cjM2ETPZJVSQhVRsIDN0YTM6IVREJ1T0b9586ef25c9853decfa7709eee27a1e',
// );

interface Props {
  columns: any;
  checkboxEnabled?: boolean;
  loading?: boolean;
  data?: any;
  totalRows?: number;
  searchparams: any;
  pagination?: boolean;
  paginationServer?: boolean;
  handleAdd: any;
  handlePageChange?: any;
  handlePerRowsChange?: any;
  addButton?: boolean;
  handleSearchChange?: any;
  stagesData?: any;
  users?: any;
}
const FollowUpDataTableComponent = ({
  columns,
  checkboxEnabled = false,
  loading = false,
  pagination = false,
  paginationServer = false,
  data = [],
  searchparams,
  handleAdd,
  handlePageChange,
  handlePerRowsChange,
  totalRows,
  addButton = false,
  handleSearchChange,
  stagesData,
  users,
}: Props) => {
  const handleChange = (data: any) => {
    // console.log("Selected Rows: ", data.selectedRows);
  };
  const [oldData, setoldData] = useState([]);
  const [query, setQuery] = useState("");
  const [paginationCount, setpaginationCount] = useState(1);
  const [dataValue, setData] = useState(1);
  const [stageName, setStageName] = useState<any>();
  const [userId, setUserId] = useState("");
  const [value, setValue] = useState<DateRange<Dayjs>>([
    dayjs(new Date()),
    dayjs(new Date()),
  ]);

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#F3F6FA",
      },
    },

    headRow: {
      style: {
        borderBottom: "none",
      },
    },
    header: {
      style: {
        fontSize: "16px",

        minHeight: "30px",
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
    contextMenu: {
      style: {
        fontSize: "14px",
        fontWeight: 400,

        paddingLeft: "16px",
        paddingRight: "8px",
        transform: "translate3d(0, -100%, 0)",
        transitionDuration: "125ms",
        transitionTimingFunction: "cubic-bezier(0, 0, 0.2, 1)",
        willChange: "transform",
      },
      activeStyle: {
        transform: "translate3d(0, 0, 0)",
      },
    },
  };
  return (
    <>
      <div className={classes.tableWrapper}>
        <DataTable
          data={data}
          columns={columns}
          title=" "
          // pagination={pagination}
          highlightOnHover
          selectableRows={checkboxEnabled}
          customStyles={customStyles}
          onSelectedRowsChange={handleChange}
          fixedHeader
          actions
          progressPending={loading}
          paginationServer={paginationServer}
          // paginationTotalRows={totalRows}
          // selectableRows
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          progressComponent={<StyledLoader />}
        />
      </div>
    </>
  );
};

export default FollowUpDataTableComponent;
